import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  File: any;
  ISO8601DateTime: any;
  JSON: any;
};

export type AcceptTeamInvitationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of AcceptTeamInvitation */
export type AcceptTeamInvitationPayload = {
  __typename?: 'AcceptTeamInvitationPayload';
  errors?: Maybe<Array<Error>>;
  team?: Maybe<Team>;
};

export type ActivatePilotInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ActivatePilot */
export type ActivatePilotPayload = {
  __typename?: 'ActivatePilotPayload';
  errors?: Maybe<Array<Error>>;
  pilot?: Maybe<Pilot>;
};

export type ActivateUserInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ActivateUser */
export type ActivateUserPayload = {
  __typename?: 'ActivateUserPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type ActiveUsersAnalytic = {
  __typename?: 'ActiveUsersAnalytic';
  activeUsersBuyGameCredit?: Maybe<ActiveUsersMetric>;
  activeUsersCombatArena?: Maybe<ActiveUsersMetric>;
  activeUsersCreatePost?: Maybe<ActiveUsersMetric>;
  activeUsersCreateTeam?: Maybe<ActiveUsersMetric>;
  activeUsersGameBundles?: Maybe<ActiveUsersMetric>;
  activeUsersJoinTeam?: Maybe<ActiveUsersMetric>;
  activeUsersOnPostComment?: Maybe<ActiveUsersMetric>;
  activeUsersOnPostLike?: Maybe<ActiveUsersMetric>;
  activeUsersPartneringRequest?: Maybe<ActiveUsersMetric>;
  activeUsersTopUp?: Maybe<ActiveUsersMetric>;
  registeredUsers?: Maybe<ActiveUsersMetric>;
  uniqueUsers?: Maybe<ActiveUsersMetric>;
};


export type ActiveUsersAnalyticActiveUsersBuyGameCreditArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticActiveUsersCombatArenaArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticActiveUsersCreatePostArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticActiveUsersCreateTeamArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticActiveUsersGameBundlesArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticActiveUsersJoinTeamArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticActiveUsersOnPostCommentArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticActiveUsersOnPostLikeArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticActiveUsersPartneringRequestArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticActiveUsersTopUpArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticRegisteredUsersArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ActiveUsersAnalyticUniqueUsersArgs = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ActiveUsersMetric = {
  __typename?: 'ActiveUsersMetric';
  label?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

/** Sponsor Ads */
export type AdClick = {
  __typename?: 'AdClick';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  sponsorAd: SponsorAd;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export type AddGameRankInput = {
  gameId: Scalars['ID'];
  image: Scalars['File'];
  name: Scalars['String'];
  ordering: Scalars['Int'];
  rankPointMaxCount?: Maybe<Array<Scalars['Int']>>;
  rankStarMaxCount: Scalars['Int'];
  rankTierMaxCount: Scalars['Int'];
};

/** Autogenerated return type of AddGameRank */
export type AddGameRankPayload = {
  __typename?: 'AddGameRankPayload';
  errors?: Maybe<Array<Error>>;
  gameRank?: Maybe<GameRank>;
};

export type AddMemberInput = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of AddMember */
export type AddMemberPayload = {
  __typename?: 'AddMemberPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type Admin = {
  __typename?: 'Admin';
  canEdit: Scalars['Boolean'];
  canManage: Scalars['Boolean'];
  canViewFinance: Scalars['Boolean'];
  canViewJobs: Scalars['Boolean'];
  canViewUsers: Scalars['Boolean'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isSuper: Scalars['Boolean'];
  role: Scalars['String'];
  supervisor: Scalars['Boolean'];
};

export type AdminAnalytic = {
  __typename?: 'AdminAnalytic';
  flaggedCombatRooms: Scalars['Int'];
  pendingPayouts: Scalars['Int'];
  pendingPilots: Scalars['Int'];
  pendingReportedRequests: Scalars['Int'];
  reportsGameBundle: Scalars['Int'];
  reportsPartneringRequest: Scalars['Int'];
  totalApprovePilots: Scalars['Int'];
  totalGamers: Scalars['Int'];
  totalPilots: Scalars['Int'];
  totalUsers: Scalars['Int'];
  vocaCreditBalance: Scalars['Int'];
};

export type AdminAuthorizationInput = {
  email: Scalars['String'];
  ipAddress: Scalars['String'];
  password: Scalars['String'];
};

/** Autogenerated return type of AdminAuthorization */
export type AdminAuthorizationPayload = {
  __typename?: 'AdminAuthorizationPayload';
  admin?: Maybe<Admin>;
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
};

/** Game Bundle */
export type AdminGameBundle = {
  __typename?: 'AdminGameBundle';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  bookingId: Scalars['String'];
  chatroom?: Maybe<Chatroom>;
  commission: Scalars['Float'];
  communicationRating?: Maybe<Scalars['Int']>;
  completionScreenshotUrl?: Maybe<Scalars['String']>;
  completionTime?: Maybe<Scalars['ISO8601DateTime']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  currentGameRank: GameRank;
  currentRankPoint?: Maybe<Scalars['Int']>;
  currentRankStar?: Maybe<Scalars['Int']>;
  currentRankTier?: Maybe<Scalars['Int']>;
  estimatedDurationInHour?: Maybe<Scalars['Float']>;
  game: Game;
  id: Scalars['ID'];
  openMic?: Maybe<Scalars['Boolean']>;
  originalAmount: Scalars['Float'];
  perspectives?: Maybe<Array<ProficientRole>>;
  pilot?: Maybe<Pilot>;
  pilotGameAccount?: Maybe<SimpleGameAccount>;
  pilotReview?: Maybe<Scalars['String']>;
  proficientHeros: Array<ProficientHero>;
  proficientLanes: Array<ProficientRole>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  promoCode?: Maybe<Scalars['String']>;
  queues?: Maybe<Array<ProficientRole>>;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  reporter?: Maybe<SimpleUser>;
  reportReason?: Maybe<Scalars['String']>;
  skillRating?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: GameBundleState;
  targetGameRank: GameRank;
  targetRankPoint?: Maybe<Scalars['Int']>;
  targetRankStar?: Maybe<Scalars['Int']>;
  targetRankTier?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<Pilot>;
};

export type AdminLog = {
  __typename?: 'AdminLog';
  admin: Admin;
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
};

/** Admin transfer logs include counter */
export type AdminLogsCount = {
  __typename?: 'AdminLogsCount';
  count: Scalars['Int'];
  data: Array<AdminLog>;
};

/** Partnering Request */
export type AdminPartneringRequest = {
  __typename?: 'AdminPartneringRequest';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  cancellable?: Maybe<Scalars['Boolean']>;
  chatroom?: Maybe<Chatroom>;
  commission: Scalars['Float'];
  communicationRating?: Maybe<Scalars['Int']>;
  completeTime?: Maybe<Scalars['ISO8601DateTime']>;
  completionScreenshotUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  durationHour?: Maybe<Scalars['Float']>;
  game: Game;
  gameRank: GameRank;
  id: Scalars['ID'];
  inGamePilotsGameAccounts?: Maybe<Array<SimpleGameAccount>>;
  matchCount?: Maybe<Scalars['Int']>;
  matchedServices?: Maybe<Array<PilotService>>;
  openMic?: Maybe<Scalars['Boolean']>;
  originalAmount: Scalars['Float'];
  paid: Scalars['Boolean'];
  partnerRateType: PartnerRate;
  penaltyAmount: Scalars['Float'];
  pendingServices?: Maybe<Array<PilotService>>;
  perspectives?: Maybe<Array<ProficientRole>>;
  pilot?: Maybe<Pilot>;
  pilotCount: Scalars['Int'];
  pilotGameAccount?: Maybe<SimpleGameAccount>;
  pilotNicknames?: Maybe<Scalars['String']>;
  pilotPrice: Scalars['Float'];
  pilotReview?: Maybe<Scalars['String']>;
  pilots?: Maybe<Array<SimpleUser>>;
  pilotServices?: Maybe<Array<PilotService>>;
  proficientLanes: Array<ProficientRole>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  promoApplied: Scalars['Boolean'];
  promoCode?: Maybe<Scalars['String']>;
  queues?: Maybe<Array<ProficientRole>>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  readiedServices?: Maybe<Array<PilotService>>;
  reporter?: Maybe<SimpleUser>;
  reportReason?: Maybe<Scalars['String']>;
  requestedPilots?: Maybe<Array<Pilot>>;
  skillRating?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: PartneringRequestState;
  totalAmount: Scalars['Float'];
  totalDurationInMinutes?: Maybe<Scalars['Float']>;
  totalOriginalAmount: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<Pilot>;
  userGameAccount?: Maybe<SimpleGameAccount>;
};

export type AdminTransferInput = {
  amount?: Maybe<Scalars['Float']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of AdminTransfer */
export type AdminTransferPayload = {
  __typename?: 'AdminTransferPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type ApproveBankInfoInput = {
  pilotId: Scalars['ID'];
};

/** Autogenerated return type of ApproveBankInfo */
export type ApproveBankInfoPayload = {
  __typename?: 'ApproveBankInfoPayload';
  bankInfo?: Maybe<BankInfo>;
  errors?: Maybe<Array<Error>>;
};

export type ApproveInput = {
  id: Scalars['ID'];
};

export type ApproveOrRejectReportRequestInput = {
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
};

/** Autogenerated return type of ApproveOrRejectReportRequest */
export type ApproveOrRejectReportRequestPayload = {
  __typename?: 'ApproveOrRejectReportRequestPayload';
  errors?: Maybe<Array<Error>>;
  reportRequests?: Maybe<Array<ReportRequest>>;
};

/** Autogenerated return type of Approve */
export type ApprovePayload = {
  __typename?: 'ApprovePayload';
  errors?: Maybe<Array<Error>>;
  payout?: Maybe<Payout>;
};

export type ApproveScreenshotInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ApproveScreenshot */
export type ApproveScreenshotPayload = {
  __typename?: 'ApproveScreenshotPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type ApproveSuspendGamerRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ApproveSuspendGamerRequest */
export type ApproveSuspendGamerRequestPayload = {
  __typename?: 'ApproveSuspendGamerRequestPayload';
  errors?: Maybe<Array<Error>>;
  suspendRequest?: Maybe<SuspendGamerRequest>;
};

export type ApproveSuspendRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ApproveSuspendRequest */
export type ApproveSuspendRequestPayload = {
  __typename?: 'ApproveSuspendRequestPayload';
  errors?: Maybe<Array<Error>>;
  suspendRequest?: Maybe<SuspendRequest>;
};

export type ApproveVerificationCodeInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ApproveVerificationCode */
export type ApproveVerificationCodePayload = {
  __typename?: 'ApproveVerificationCodePayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

/** Bank Info */
export type BankInfo = {
  __typename?: 'BankInfo';
  bankAccountNo: Scalars['String'];
  bankName: Scalars['String'];
  country: Scalars['String'];
  ic: Scalars['String'];
  icImage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pilot?: Maybe<Pilot>;
  rejectReason?: Maybe<Scalars['String']>;
  selfie?: Maybe<Scalars['String']>;
  state: BankInfoState;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export enum BankInfoState {
  Approved = 'approved',
  PendingResubmission = 'pending_resubmission',
  Submitted = 'submitted'
}

/** Banner */
export type Banner = {
  __typename?: 'Banner';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  endTime?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  image: Scalars['String'];
  poster: Scalars['Boolean'];
  screen?: Maybe<Scalars['String']>;
  sponsorAd?: Maybe<SponsorAd>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** Battle */
export type Battle = {
  __typename?: 'Battle';
  battleId?: Maybe<Scalars['String']>;
  camps?: Maybe<Array<Camp>>;
  createdAt: Scalars['ISO8601DateTime'];
  dataId?: Maybe<Scalars['Int']>;
  gameTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  paused?: Maybe<Scalars['Boolean']>;
  roomName: Scalars['String'];
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: BattleState;
  stateLeftTime?: Maybe<Scalars['String']>;
  tournamentRoom: TournamentRoom;
  updatedAt: Scalars['ISO8601DateTime'];
  winCampId?: Maybe<Scalars['Int']>;
};

export enum BattleState {
  Adjust = 'adjust',
  Ban = 'ban',
  CampFirstSubmitted = 'camp_first_submitted',
  CampSecondSubmitted = 'camp_second_submitted',
  ConfirmCompleted = 'confirm_completed',
  End = 'end',
  Flagged = 'flagged',
  Init = 'init',
  Loading = 'loading',
  Pending = 'pending',
  Pick = 'pick',
  Play = 'play',
  Started = 'started',
  Unscanned = 'unscanned'
}

/** Autogenerated return type of BlastGamers */
export type BlastGamersPayload = {
  __typename?: 'BlastGamersPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type BlastInput = {
  body: Scalars['String'];
  isScheduled?: Maybe<Scalars['Boolean']>;
  scheduleDate?: Maybe<Scalars['ISO8601DateTime']>;
  scheduleDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  scheduleTime?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of BlastPartners */
export type BlastPartnersPayload = {
  __typename?: 'BlastPartnersPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Block User */
export type BlockUser = {
  __typename?: 'BlockUser';
  blockedUser: User;
  id: Scalars['ID'];
  user: User;
};

/** Booking */
export type Booking = {
  __typename?: 'Booking';
  commission?: Maybe<Scalars['Float']>;
  createdAt: Scalars['ISO8601DateTime'];
  gameId: Scalars['ID'];
  id: Scalars['ID'];
  isBuyer: Scalars['Boolean'];
  isCreator: Scalars['Boolean'];
  matchMaking: MatchMaking;
  owner: User;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  reporter?: Maybe<User>;
  reportReason?: Maybe<Scalars['String']>;
  state: BookingState;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Booking include counter */
export type BookingCount = {
  __typename?: 'BookingCount';
  count: Scalars['Int'];
  data: Array<Booking>;
};

export enum BookingState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmCompleted = 'confirm_completed',
  Pending = 'pending',
  Rated = 'rated',
  Refunded = 'refunded',
  Reported = 'reported',
  Reviewed = 'reviewed',
  Started = 'started'
}

/** Cai Leaderboard */
export type CaiLeaderboard = {
  __typename?: 'CaiLeaderboard';
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  totalMatches?: Maybe<Scalars['Int']>;
  town?: Maybe<Scalars['String']>;
};

/** Camp */
export type Camp = {
  __typename?: 'Camp';
  battle: Battle;
  campId?: Maybe<Scalars['Int']>;
  campPlayers?: Maybe<Array<CampPlayer>>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  killLord?: Maybe<Scalars['Int']>;
  killTower?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  teamName?: Maybe<Scalars['String']>;
  teamSimpleName?: Maybe<Scalars['String']>;
  totalMoney?: Maybe<Scalars['String']>;
  tournamentTeam?: Maybe<TournamentTeam>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Camp Player */
export type CampPlayer = {
  __typename?: 'CampPlayer';
  assistNum?: Maybe<Scalars['Int']>;
  avgFps?: Maybe<Scalars['Int']>;
  banHeroId?: Maybe<Scalars['Int']>;
  banning?: Maybe<Scalars['Boolean']>;
  bigReconnect?: Maybe<Scalars['Int']>;
  camp?: Maybe<Camp>;
  clientIp?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  damageEffect?: Maybe<Scalars['Int']>;
  damagePercent?: Maybe<Scalars['Int']>;
  dead?: Maybe<Scalars['Boolean']>;
  deadNum?: Maybe<Scalars['Int']>;
  equipList?: Maybe<Array<Scalars['String']>>;
  exp?: Maybe<Scalars['Int']>;
  gold?: Maybe<Scalars['Int']>;
  goldMap?: Maybe<Array<Scalars['String']>>;
  heroId?: Maybe<Scalars['Int']>;
  hitRate?: Maybe<Scalars['String']>;
  hurtPercent?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  judger?: Maybe<Scalars['Boolean']>;
  kda?: Maybe<Scalars['Int']>;
  killNum?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  majorLeftTime?: Maybe<Scalars['Int']>;
  mapPos?: Maybe<Array<Scalars['String']>>;
  minDamage?: Maybe<Scalars['Int']>;
  minFps?: Maybe<Scalars['Int']>;
  minMoney?: Maybe<Scalars['Int']>;
  moneyPercent?: Maybe<Scalars['Int']>;
  moveLag?: Maybe<Scalars['Int']>;
  mvp?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  offeredRate?: Maybe<Scalars['Int']>;
  picking?: Maybe<Scalars['Boolean']>;
  pos?: Maybe<Scalars['ID']>;
  reviveLeftTime?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  runeId?: Maybe<Scalars['Int']>;
  runeMap?: Maybe<Array<Scalars['JSON']>>;
  score?: Maybe<Scalars['String']>;
  skillId?: Maybe<Scalars['Int']>;
  skillLeftTime?: Maybe<Scalars['Int']>;
  skillUseTimes?: Maybe<Scalars['Int']>;
  skinId?: Maybe<Scalars['Int']>;
  smallReconnect?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  teamSimpleName?: Maybe<Scalars['String']>;
  timeDelay?: Maybe<Scalars['Int']>;
  totalDamage?: Maybe<Scalars['Int']>;
  totalDamageTower?: Maybe<Scalars['Int']>;
  totalHeal?: Maybe<Scalars['Int']>;
  totalHurt?: Maybe<Scalars['Int']>;
  totalMoney?: Maybe<Scalars['Int']>;
  towerDamagePercent?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  xpm?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['ID']>;
};

export type CancelOrderInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of CancelOrder */
export type CancelOrderPayload = {
  __typename?: 'CancelOrderPayload';
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type CancelTeamInvitationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of CancelTeamInvitation */
export type CancelTeamInvitationPayload = {
  __typename?: 'CancelTeamInvitationPayload';
  errors?: Maybe<Array<Error>>;
  teamInvitation?: Maybe<TeamInvitation>;
};

export type CancelTeamWalletInput = {
  combatTeamId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CancelTeamWallet */
export type CancelTeamWalletPayload = {
  __typename?: 'CancelTeamWalletPayload';
  errors?: Maybe<Array<Error>>;
  poolPrize?: Maybe<PoolPrize>;
};

/** Career Highlight */
export type CareerHighlight = {
  __typename?: 'CareerHighlight';
  endYear: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  position: Scalars['Int'];
  startYear: Scalars['Int'];
  title: Scalars['String'];
};

/** Cart */
export type Cart = {
  __typename?: 'Cart';
  orders: Array<Order>;
  totalAmount: Scalars['Float'];
};

/** Category */
export type Category = {
  __typename?: 'Category';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum CategoryColumns {
  General = 'general',
  Pricing = 'Pricing',
  Services = 'Services'
}

export type ChangeCombatRoomStateInput = {
  action: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of ChangeCombatRoomState */
export type ChangeCombatRoomStatePayload = {
  __typename?: 'ChangeCombatRoomStatePayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

/** Chatroom */
export type Chatroom = {
  __typename?: 'Chatroom';
  admin?: Maybe<User>;
  avatarUrl?: Maybe<Scalars['String']>;
  chatroomHolder: ChatroomHolder;
  clan?: Maybe<Clan>;
  combatRoom?: Maybe<CombatRoom>;
  createdAt: Scalars['ISO8601DateTime'];
  gameBundle?: Maybe<GameBundle>;
  id: Scalars['ID'];
  lastMessage?: Maybe<Message>;
  messages: Array<Message>;
  messagesCount: Scalars['Int'];
  name: Scalars['String'];
  partneringRequest?: Maybe<PartneringRequest>;
  pilot?: Maybe<SimpleUser>;
  scrim?: Maybe<Scrim>;
  team?: Maybe<Team>;
  unreadCount: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<SimpleUser>;
  users: Array<User>;
};

/** Chatroom Holder */
export type ChatroomHolder = Clan | CombatRoom | GameBundle | PartneringRequest | Scrim | Team | User;

export enum ChatroomMessage {
  Announcement = 'announcement',
  Profile = 'profile',
  Scrim = 'scrim',
  System = 'system',
  Team = 'team',
  Text = 'text'
}

export type CheckCategoryInput = {
  name: Scalars['String'];
};

/** Autogenerated return type of CheckCategory */
export type CheckCategoryPayload = {
  __typename?: 'CheckCategoryPayload';
  categories?: Maybe<Array<Category>>;
  errors?: Maybe<Array<Error>>;
};

export type CheckGamerBannerInput = {
  title: Scalars['String'];
};

/** Autogenerated return type of CheckGamerBanner */
export type CheckGamerBannerPayload = {
  __typename?: 'CheckGamerBannerPayload';
  errors?: Maybe<Array<Error>>;
  gamerBanners?: Maybe<Array<GamerBanner>>;
};

/** Check Location */
export type CheckLocation = {
  __typename?: 'CheckLocation';
  valid: Scalars['Boolean'];
};

export type CheckMerchandiseVariantInput = {
  sku: Scalars['String'];
};

/** Autogenerated return type of CheckMerchandiseVariant */
export type CheckMerchandiseVariantPayload = {
  __typename?: 'CheckMerchandiseVariantPayload';
  errors?: Maybe<Array<Error>>;
  merchandiseVariants?: Maybe<Array<MerchandiseVariant>>;
};

export type CheckPilotGameBundleInput = {
  nickname: Scalars['String'];
};

/** Autogenerated return type of CheckPilotGameBundle */
export type CheckPilotGameBundlePayload = {
  __typename?: 'CheckPilotGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  pilots?: Maybe<Array<Pilot>>;
};

export type CheckPilotInput = {
  nickname: Scalars['String'];
};

/** Autogenerated return type of CheckPilot */
export type CheckPilotPayload = {
  __typename?: 'CheckPilotPayload';
  errors?: Maybe<Array<Error>>;
  pilots?: Maybe<Array<Pilot>>;
};

export type CheckPostInput = {
  title: Scalars['String'];
};

/** Autogenerated return type of CheckPost */
export type CheckPostPayload = {
  __typename?: 'CheckPostPayload';
  errors?: Maybe<Array<Error>>;
  posts?: Maybe<Array<Post>>;
};

export type CheckUserGameBundleInput = {
  nickname: Scalars['String'];
};

/** Autogenerated return type of CheckUserGameBundle */
export type CheckUserGameBundlePayload = {
  __typename?: 'CheckUserGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  users?: Maybe<Array<User>>;
};

export type ChooseWinnerCombatRoomInput = {
  additionScore: Scalars['Int'];
  combatRoomId: Scalars['ID'];
  combatTeamAccepterId: Scalars['ID'];
  combatTeamChallengerId: Scalars['ID'];
  combatTeamType: Scalars['String'];
  deductionScore: Scalars['Int'];
};

/** Autogenerated return type of ChooseWinnerCombatRoom */
export type ChooseWinnerCombatRoomPayload = {
  __typename?: 'ChooseWinnerCombatRoomPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

/** Clan */
export type Clan = {
  __typename?: 'Clan';
  active: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  chatroomId: Scalars['ID'];
  countryCode: Scalars['String'];
  coverPictureUrl: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  featured: Scalars['Boolean'];
  followed: Scalars['Boolean'];
  followersCount: Scalars['Int'];
  id: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  isAdmin: Scalars['Boolean'];
  joined: Scalars['Boolean'];
  liked: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  memberLimit?: Maybe<Scalars['Int']>;
  members: Array<User>;
  membersCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  onlineCount: Scalars['Int'];
  owner: User;
  postsCount: Scalars['Int'];
  public: Scalars['Boolean'];
  rules?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};


/** Clan */
export type ClanMembersArgs = {
  searchUser?: Maybe<Scalars['String']>;
};

/** Clans include counter */
export type ClansCount = {
  __typename?: 'ClansCount';
  count: Scalars['Int'];
  data: Array<Clan>;
};

/** Club */
export type Club = {
  __typename?: 'Club';
  beStar: Scalars['Boolean'];
  clansCount: Scalars['Int'];
  countryCode?: Maybe<Scalars['String']>;
  coverPictureUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  followed: Scalars['Boolean'];
  followersCount: Scalars['Int'];
  founded?: Maybe<Scalars['ISO8601DateTime']>;
  games?: Maybe<Array<Game>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  isManager: Scalars['Boolean'];
  liked: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  manager: User;
  name: Scalars['String'];
  origin?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  postsCount: Scalars['Int'];
  tagName?: Maybe<Scalars['String']>;
  teamCount: Scalars['Int'];
  teams: Array<Team>;
  themeColor?: Maybe<Scalars['String']>;
  tier?: Maybe<ClubTier>;
  verified: Scalars['Boolean'];
};

/** Club include counter */
export type ClubCounts = {
  __typename?: 'ClubCounts';
  count: Scalars['Int'];
  data: Array<Club>;
};

/** Clubs Game Account include counter */
export type ClubsCount = {
  __typename?: 'ClubsCount';
  count: Scalars['Int'];
  data: Array<GameAccountIndex>;
};

export enum ClubTier {
  Amateur = 'amateur',
  Professional = 'professional',
  SemiPro = 'semi_pro'
}

export enum CodeState {
  CodeApproved = 'code_approved',
  CodeSubmitted = 'code_submitted',
  CodeUnverified = 'code_unverified',
  PendingCodeResubmission = 'pending_code_resubmission'
}

/** Combat Queue */
export type CombatQueue = {
  __typename?: 'CombatQueue';
  combatRoom?: Maybe<CombatRoom>;
  combatTeam: CombatTeam;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  score: Scalars['Int'];
  state: CombatQueueState;
};

export enum CombatQueueState {
  Available = 'available',
  Cancelled = 'cancelled',
  Cooldowned = 'cooldowned',
  Expired = 'expired',
  Matched = 'matched',
  Pending = 'pending'
}

/** Combat Room */
export type CombatRoom = {
  __typename?: 'CombatRoom';
  accepter?: Maybe<CombatTeam>;
  accepterPlayerIds?: Maybe<Array<Pilot>>;
  accepterWinScores: Array<Scalars['Int']>;
  adminNote?: Maybe<Scalars['String']>;
  challenger: CombatTeam;
  challengerPlayerIds?: Maybe<Array<Pilot>>;
  challengerWinScores: Array<Scalars['Int']>;
  challengeStatement?: Maybe<Scalars['String']>;
  chatroom?: Maybe<Chatroom>;
  combatQueues?: Maybe<Array<CombatQueue>>;
  completeTime?: Maybe<Scalars['ISO8601DateTime']>;
  countryCode: CountryCode;
  createdAt: Scalars['ISO8601DateTime'];
  currentAccepterScore?: Maybe<Scalars['Int']>;
  currentChallengerScore?: Maybe<Scalars['Int']>;
  currentScores?: Maybe<Array<Scalars['Int']>>;
  duration?: Maybe<Scalars['Float']>;
  entitledScore?: Maybe<Scalars['Int']>;
  host?: Maybe<User>;
  id: Scalars['ID'];
  isChallenger: Scalars['Boolean'];
  isHost: Scalars['Boolean'];
  isHostTeam: Scalars['Boolean'];
  isOwner?: Maybe<Scalars['Boolean']>;
  isWinner?: Maybe<Scalars['Boolean']>;
  loseTeam?: Maybe<Team>;
  matchResult?: Maybe<MatchResult>;
  matchResults?: Maybe<Array<MatchResult>>;
  owner?: Maybe<User>;
  preferEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  preferStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  readiedTime?: Maybe<Scalars['ISO8601DateTime']>;
  rivalTeam?: Maybe<Team>;
  roomUrl?: Maybe<Scalars['String']>;
  scoreUpdate?: Maybe<Scalars['Int']>;
  screenshotBattleId?: Maybe<Scalars['String']>;
  screenshotDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  screenshotRemark?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: CombatRoomState;
  updatedAt: Scalars['ISO8601DateTime'];
  updatedScores?: Maybe<Array<Scalars['Int']>>;
  validationRemark?: Maybe<Scalars['String']>;
  winTeam?: Maybe<Team>;
};

/** Combat Rooms include counter */
export type CombatRoomsCount = {
  __typename?: 'CombatRoomsCount';
  count: Scalars['Int'];
  data: Array<CombatRoom>;
};

export enum CombatRoomState {
  AccepterCompleted = 'accepter_completed',
  AccepterIdled = 'accepter_idled',
  AccepterReadied = 'accepter_readied',
  Cancelled = 'cancelled',
  ChallengerCompleted = 'challenger_completed',
  ChallengerIdled = 'challenger_idled',
  ChallengerReadied = 'challenger_readied',
  Completed = 'completed',
  ConfirmCompleted = 'confirm_completed',
  ConfirmStarted = 'confirm_started',
  Flagged = 'flagged',
  Idled = 'idled',
  Matched = 'matched',
  Opened = 'opened',
  Pending = 'pending',
  Reported = 'reported',
  Resolved = 'resolved',
  Reviewed = 'reviewed',
  Started = 'started',
  Unscanned = 'unscanned'
}

/** Combat Team */
export type CombatTeam = {
  __typename?: 'CombatTeam';
  acceptedCombats: Array<CombatRoom>;
  allCombatRooms: Array<CombatRoom>;
  challengedCombats: Array<CombatRoom>;
  combatRoomAdminLogs: Array<AdminLog>;
  combatRooms: Array<CombatRoom>;
  completedMatchesCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  currentPosition?: Maybe<Scalars['Int']>;
  currentQueueId?: Maybe<Scalars['ID']>;
  endWeek: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isOnline: Scalars['Boolean'];
  leaderboard: Leaderboard;
  leaderboardId?: Maybe<Scalars['String']>;
  leaderboards: Array<Leaderboard>;
  matchedQueue?: Maybe<CombatQueue>;
  played: Scalars['Boolean'];
  players: Array<User>;
  poolPrize?: Maybe<PoolPrize>;
  position?: Maybe<Scalars['Int']>;
  preferEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  preferStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  reason?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
  state: CombatTeamState;
  suspended?: Maybe<Scalars['Boolean']>;
  team: Team;
  teamId?: Maybe<Scalars['String']>;
  totalMatches: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Combat Team */
export type CombatTeamAllCombatRoomsArgs = {
  leaderboardId?: Maybe<Scalars['ID']>;
};

export enum CombatTeamState {
  Banned = 'banned',
  Cancelled = 'cancelled',
  MarkUnderLimit = 'mark_under_limit',
  Participated = 'participated',
  Reactivated = 'reactivated',
  Registered = 'registered',
  Unbanned = 'unbanned'
}

export type CompleteInput = {
  id: Scalars['ID'];
};

export type CompletePartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of CompletePartneringRequest */
export type CompletePartneringRequestPayload = {
  __typename?: 'CompletePartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

/** Autogenerated return type of Complete */
export type CompletePayload = {
  __typename?: 'CompletePayload';
  errors?: Maybe<Array<Error>>;
  payout?: Maybe<Payout>;
};

export type ConfirmCompletedGameBundleInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ConfirmCompletedGameBundle */
export type ConfirmCompletedGameBundlePayload = {
  __typename?: 'ConfirmCompletedGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export enum CountryCode {
  Cn = 'CN',
  Id = 'ID',
  My = 'MY',
  Ph = 'PH',
  Sg = 'SG'
}

export type CreateAddPointForFlaggedTeamInput = {
  combatRoomId: Scalars['ID'];
  id: Scalars['ID'];
  score: Scalars['Int'];
};

/** Autogenerated return type of CreateAddPointForFlaggedTeam */
export type CreateAddPointForFlaggedTeamPayload = {
  __typename?: 'CreateAddPointForFlaggedTeamPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type CreateCareerHighlightInput = {
  endYear?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['Int']>;
  startYear?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateCareerHighlight */
export type CreateCareerHighlightPayload = {
  __typename?: 'CreateCareerHighlightPayload';
  careerHighlight?: Maybe<CareerHighlight>;
  errors?: Maybe<Array<Error>>;
};

export type CreateCategoryInput = {
  name: Scalars['String'];
};

/** Autogenerated return type of CreateCategory */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category?: Maybe<Category>;
  errors?: Maybe<Array<Error>>;
};

export type CreateClubInput = {
  description?: Maybe<Scalars['String']>;
  icon: Scalars['File'];
  name: Scalars['String'];
  tagName?: Maybe<Scalars['String']>;
  themeColor: Scalars['String'];
  tier: ClubTier;
  userId: Scalars['ID'];
};

/** Autogenerated return type of CreateClub */
export type CreateClubPayload = {
  __typename?: 'CreateClubPayload';
  club?: Maybe<Club>;
  errors?: Maybe<Array<Error>>;
};

export type CreateFaqInput = {
  answer: Scalars['String'];
  apps: FaqApps;
  categoryColumns: CategoryColumns;
  language: Language;
  position: Scalars['Int'];
  question: Scalars['String'];
};

/** Autogenerated return type of CreateFaq */
export type CreateFaqPayload = {
  __typename?: 'CreateFaqPayload';
  errors?: Maybe<Array<Error>>;
  faq?: Maybe<Faq>;
};

export type CreateGameBundleInput = {
  currentGameRankId: Scalars['ID'];
  currentRankPoint: Scalars['String'];
  currentRankStar: Scalars['String'];
  currentRankTier: Scalars['String'];
  gameId: Scalars['ID'];
  pilotId: Scalars['ID'];
  promoCode?: Maybe<Scalars['String']>;
  targetGameRankId: Scalars['ID'];
  targetRankPoint: Scalars['String'];
  targetRankStar: Scalars['String'];
  targetRankTier: Scalars['String'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of CreateGameBundle */
export type CreateGameBundlePayload = {
  __typename?: 'CreateGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type CreateGameInput = {
  active?: Maybe<Scalars['Boolean']>;
  combat?: Maybe<Scalars['Boolean']>;
  developerName: Scalars['String'];
  genre: GameGenre;
  icon: Scalars['File'];
  interestIds?: Maybe<Array<Scalars['ID']>>;
  logo: Scalars['File'];
  mascot: Scalars['File'];
  matchmaking?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ordering?: Maybe<Scalars['Int']>;
  platform: GamePlatform;
  realTime?: Maybe<Scalars['Boolean']>;
  schedule?: Maybe<Scalars['Boolean']>;
  screenshotSample: Scalars['File'];
  scrim?: Maybe<Scalars['Boolean']>;
  shortname: Scalars['String'];
  themeColor: Scalars['String'];
  tournament?: Maybe<Scalars['Boolean']>;
  vocaCode?: Maybe<Scalars['String']>;
};

export type CreateGameMessageInput = {
  adminId?: Maybe<Scalars['ID']>;
  gameChatroomId: Scalars['ID'];
  messageType?: Maybe<ChatroomMessage>;
  text?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateGameMessage */
export type CreateGameMessagePayload = {
  __typename?: 'CreateGameMessagePayload';
  errors?: Maybe<Array<Error>>;
  gameMessage?: Maybe<GameMessage>;
};

/** Autogenerated return type of CreateGame */
export type CreateGamePayload = {
  __typename?: 'CreateGamePayload';
  errors?: Maybe<Array<Error>>;
  game?: Maybe<Game>;
};

export type CreateGamerBannerInput = {
  active: Scalars['Boolean'];
  image: Scalars['File'];
  poster: Scalars['Boolean'];
  screen?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateGamerBanner */
export type CreateGamerBannerPayload = {
  __typename?: 'CreateGamerBannerPayload';
  errors?: Maybe<Array<Error>>;
  gamerBanner?: Maybe<Banner>;
};

export type CreateGiftInput = {
  amount: Scalars['Int'];
  icon: Scalars['File'];
  name: Scalars['String'];
};

/** Autogenerated return type of CreateGift */
export type CreateGiftPayload = {
  __typename?: 'CreateGiftPayload';
  errors?: Maybe<Array<Error>>;
  gift?: Maybe<Gift>;
};

export type CreateInterestInput = {
  active: Scalars['Boolean'];
  iconOff: Scalars['File'];
  iconOn: Scalars['File'];
  name: Scalars['String'];
};

/** Autogenerated return type of CreateInterest */
export type CreateInterestPayload = {
  __typename?: 'CreateInterestPayload';
  errors?: Maybe<Array<Error>>;
  interest?: Maybe<Interest>;
};

export type CreateMarkupPricingInput = {
  active: Scalars['Boolean'];
  countryCode: CountryCode;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['ID']>;
  merchantId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  uniqueId: Scalars['String'];
  vendorId: Scalars['ID'];
};

/** Autogenerated return type of CreateMarkupPricing */
export type CreateMarkupPricingPayload = {
  __typename?: 'CreateMarkupPricingPayload';
  errors?: Maybe<Array<Error>>;
  markupPricing?: Maybe<MarkupPricing>;
};

export type CreateMerchandiseInput = {
  active: Scalars['Boolean'];
  categoryId: Scalars['ID'];
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  images: Array<Scalars['File']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  postId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateMerchandise */
export type CreateMerchandisePayload = {
  __typename?: 'CreateMerchandisePayload';
  errors?: Maybe<Array<Error>>;
  merchandise?: Maybe<Merchandise>;
};

export type CreateMerchandiseVariantInput = {
  merchandiseId: Scalars['ID'];
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  sku: Scalars['String'];
  stock: Scalars['Int'];
};

/** Autogenerated return type of CreateMerchandiseVariant */
export type CreateMerchandiseVariantPayload = {
  __typename?: 'CreateMerchandiseVariantPayload';
  errors?: Maybe<Array<Error>>;
  merchandiseVariant?: Maybe<MerchandiseVariant>;
};

export type CreateMerchandiseVariantsInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of CreateMerchandiseVariants */
export type CreateMerchandiseVariantsPayload = {
  __typename?: 'CreateMerchandiseVariantsPayload';
  errors?: Maybe<Array<Error>>;
  merchandiseVariants?: Maybe<MerchandiseVariant>;
};

export type CreateOfficialChannelInput = {
  accountId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateOfficialChannel */
export type CreateOfficialChannelPayload = {
  __typename?: 'CreateOfficialChannelPayload';
  errors?: Maybe<Array<Error>>;
  officialChannel?: Maybe<OfficialChannel>;
};

export type CreatePayoutInput = {
  amount: Scalars['Int'];
  pilotId: Scalars['ID'];
};

/** Autogenerated return type of CreatePayout */
export type CreatePayoutPayload = {
  __typename?: 'CreatePayoutPayload';
  errors?: Maybe<Array<Error>>;
  payout?: Maybe<Payout>;
};

export type CreatePilotBannerInput = {
  active: Scalars['Boolean'];
  image: Scalars['File'];
  poster: Scalars['Boolean'];
  screen?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePilotBanner */
export type CreatePilotBannerPayload = {
  __typename?: 'CreatePilotBannerPayload';
  errors?: Maybe<Array<Error>>;
  pilotBanner?: Maybe<PilotBanner>;
};

export type CreatePilotInput = {
  accountId: Scalars['String'];
  bankAccountNo: Scalars['String'];
  bankName: Scalars['String'];
  birthday: Scalars['ISO8601DateTime'];
  country: Scalars['String'];
  email: Scalars['String'];
  gameId: Scalars['ID'];
  gameRankId?: Maybe<Scalars['ID']>;
  ic: Scalars['String'];
  icImage?: Maybe<Scalars['File']>;
  image1?: Maybe<Scalars['File']>;
  image2?: Maybe<Scalars['File']>;
  image3?: Maybe<Scalars['File']>;
  inGameName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nickname: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  screenshots: Array<Scalars['File']>;
  selfie?: Maybe<Scalars['File']>;
  serverId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePilot */
export type CreatePilotPayload = {
  __typename?: 'CreatePilotPayload';
  errors?: Maybe<Array<Error>>;
  pilot?: Maybe<Pilot>;
};

export type CreatePostInput = {
  active?: Maybe<Scalars['Boolean']>;
  isYoutube?: Maybe<Scalars['Boolean']>;
  newsImage?: Maybe<Scalars['File']>;
  postType?: Maybe<PostType>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePost */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  errors?: Maybe<Array<Error>>;
  post?: Maybe<Post>;
};

export type CreatePrizeInput = {
  active: Scalars['Boolean'];
  icon: Scalars['File'];
  limit?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  prizeName: Scalars['String'];
  prizeType: PrizeType;
  value: Scalars['Float'];
};

/** Autogenerated return type of CreatePrize */
export type CreatePrizePayload = {
  __typename?: 'CreatePrizePayload';
  errors?: Maybe<Array<Error>>;
  prize?: Maybe<Prize>;
};

export type CreateProficientRoleInput = {
  gameId: Scalars['ID'];
  image: Scalars['File'];
  kind: ProficientRoleKind;
  name: Scalars['String'];
  selectedImage: Scalars['File'];
};

/** Autogenerated return type of CreateProficientRole */
export type CreateProficientRolePayload = {
  __typename?: 'CreateProficientRolePayload';
  errors?: Maybe<Array<Error>>;
  proficientRole?: Maybe<ProficientRole>;
};

export type CreatePromoCodeInput = {
  active: Scalars['Boolean'];
  amount: Scalars['Int'];
  availableFor: Scalars['String'];
  clubId?: Maybe<Scalars['ID']>;
  code: Scalars['String'];
  gameType: Scalars['String'];
  minimumAmount: Scalars['Int'];
  pilotTierId?: Maybe<Scalars['ID']>;
  prosOnly?: Maybe<Scalars['Boolean']>;
  redeemLimit?: Maybe<Scalars['Int']>;
  userIds?: Maybe<Array<Scalars['String']>>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  whitelistsOnly?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreatePromoCode */
export type CreatePromoCodePayload = {
  __typename?: 'CreatePromoCodePayload';
  errors?: Maybe<Array<Error>>;
  promoCode?: Maybe<PromoCode>;
};

export type CreatePublisherInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  publisherLogo?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of CreatePublisher */
export type CreatePublisherPayload = {
  __typename?: 'CreatePublisherPayload';
  errors?: Maybe<Array<Error>>;
  publisher?: Maybe<Publisher>;
};

export type CreateSponsorAdInput = {
  active?: Maybe<Scalars['Boolean']>;
  adsName: Scalars['String'];
  assetType?: Maybe<SponsorAdAsset>;
  gamerBannerId?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['File']>;
  layoutType?: Maybe<SponsorAdLayout>;
  position?: Maybe<Scalars['Int']>;
  screenRoute?: Maybe<Scalars['String']>;
  sponsorName: Scalars['String'];
  url: Scalars['String'];
  video?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of CreateSponsorAd */
export type CreateSponsorAdPayload = {
  __typename?: 'CreateSponsorAdPayload';
  errors?: Maybe<Array<Error>>;
  sponsorAd?: Maybe<SponsorAd>;
};

export type CreateStarPlayerInput = {
  career: Scalars['String'];
  fullName: Scalars['String'];
  nationality: Scalars['String'];
  officialName: Scalars['String'];
  overview: Scalars['String'];
  proPhoto: Scalars['File'];
  themeColors: Array<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of CreateStarPlayer */
export type CreateStarPlayerPayload = {
  __typename?: 'CreateStarPlayerPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type CreateStarTeamInput = {
  founded: Scalars['ISO8601DateTime'];
  location: Scalars['String'];
  overview: Scalars['String'];
  teamId: Scalars['ID'];
  themeColors: Array<Scalars['String']>;
};

/** Autogenerated return type of CreateStarTeam */
export type CreateStarTeamPayload = {
  __typename?: 'CreateStarTeamPayload';
  errors?: Maybe<Array<Error>>;
  team?: Maybe<Team>;
};

export type CreateSuspendCombatTeamInput = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};

/** Autogenerated return type of CreateSuspendCombatTeam */
export type CreateSuspendCombatTeamPayload = {
  __typename?: 'CreateSuspendCombatTeamPayload';
  combatTeam?: Maybe<CombatTeam>;
  errors?: Maybe<Array<Error>>;
};

export type CreateSuspendGamerRequestInput = {
  reason: Scalars['String'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of CreateSuspendGamerRequest */
export type CreateSuspendGamerRequestPayload = {
  __typename?: 'CreateSuspendGamerRequestPayload';
  errors?: Maybe<Array<Error>>;
  suspendRequest?: Maybe<SuspendGamerRequest>;
};

export type CreateSuspendRequestInput = {
  pilotId: Scalars['ID'];
  reason: Scalars['String'];
};

/** Autogenerated return type of CreateSuspendRequest */
export type CreateSuspendRequestPayload = {
  __typename?: 'CreateSuspendRequestPayload';
  errors?: Maybe<Array<Error>>;
  suspendRequest?: Maybe<SuspendRequest>;
};

export type CreateSuspendTeamAndMembersInput = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};

/** Autogenerated return type of CreateSuspendTeamAndMembers */
export type CreateSuspendTeamAndMembersPayload = {
  __typename?: 'CreateSuspendTeamAndMembersPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateSuspendTeamInput = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};

/** Autogenerated return type of CreateSuspendTeam */
export type CreateSuspendTeamPayload = {
  __typename?: 'CreateSuspendTeamPayload';
  combatTeam?: Maybe<CombatTeam>;
  errors?: Maybe<Array<Error>>;
};

export type CreateTeamInput = {
  description?: Maybe<Scalars['String']>;
  icon: Scalars['File'];
  name: Scalars['String'];
  themeColor: Scalars['String'];
  tier: ClubTier;
  userId: Scalars['ID'];
};

export type CreateTeamInvitationInput = {
  teamId: Scalars['ID'];
  teamRoleId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of CreateTeamInvitation */
export type CreateTeamInvitationPayload = {
  __typename?: 'CreateTeamInvitationPayload';
  errors?: Maybe<Array<Error>>;
  teamInvitation?: Maybe<TeamInvitation>;
};

/** Autogenerated return type of CreateTeam */
export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload';
  errors?: Maybe<Array<Error>>;
  team?: Maybe<Team>;
};

export type CreateTopUpProductInput = {
  avatar?: Maybe<Scalars['File']>;
  categoryId: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
  cover?: Maybe<Scalars['File']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  genre?: Maybe<GameGenre>;
  helper?: Maybe<Scalars['File']>;
  isActive?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['File']>;
  ordering?: Maybe<Scalars['Int']>;
  originId: Scalars['ID'];
  publisherId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  vendorId: Scalars['ID'];
  vocaId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateTopUpProduct */
export type CreateTopUpProductPayload = {
  __typename?: 'CreateTopUpProductPayload';
  errors?: Maybe<Array<Error>>;
  topUpProduct?: Maybe<TopupProduct>;
};

export type CreateTournamentEventInput = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  eventType?: Maybe<TournamentEventEvent>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  state?: Maybe<TournamentEventState>;
  tournamentId: Scalars['ID'];
};

/** Autogenerated return type of CreateTournamentEvent */
export type CreateTournamentEventPayload = {
  __typename?: 'CreateTournamentEventPayload';
  errors?: Maybe<Array<Error>>;
  tournamentEvent?: Maybe<TournamentEvent>;
};

export type CreateTournamentInput = {
  backgroundImage: Scalars['File'];
  logo: Scalars['File'];
  name: Scalars['String'];
};

/** Autogenerated return type of CreateTournament */
export type CreateTournamentPayload = {
  __typename?: 'CreateTournamentPayload';
  errors?: Maybe<Array<Error>>;
  tournament?: Maybe<Tournament>;
};

export type CreateVendorInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreateVendor */
export type CreateVendorPayload = {
  __typename?: 'CreateVendorPayload';
  errors?: Maybe<Array<Error>>;
  vendor?: Maybe<Vendor>;
};

/** Credit Transaction */
export type CreditTransaction = {
  __typename?: 'CreditTransaction';
  amount: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  currency: Scalars['String'];
  id: Scalars['ID'];
  isCredit: Scalars['Boolean'];
  isDebit: Scalars['Boolean'];
  leaderboardPoolPrize?: Maybe<Leaderboard>;
  order?: Maybe<Order>;
  spinnable: Scalars['Boolean'];
  state: Scalars['String'];
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
  teamWallet: TeamWallet;
  token?: Maybe<Scalars['String']>;
  transactionCategory: Scalars['Boolean'];
  transactionType: Transaction;
  userPrizes?: Maybe<Array<UserPrize>>;
};

/** Credit Transaction include counter */
export type CreditTransactionsCount = {
  __typename?: 'CreditTransactionsCount';
  count: Scalars['Int'];
  data: Array<CreditTransaction>;
};

/** Credit Wallet */
export type CreditWallet = {
  __typename?: 'CreditWallet';
  amount: Scalars['Float'];
  amountAsMoney?: Maybe<Scalars['String']>;
  countryCode: CountryCode;
  credits: Array<CreditTransaction>;
  creditTransactions: Array<CreditTransaction>;
  currency?: Maybe<Scalars['String']>;
  debits: Array<CreditTransaction>;
  id: Scalars['ID'];
  kohaiCredit: Scalars['Float'];
  kohaiReceiveCreditTotal: Scalars['Float'];
  kohaiSendCreditTotal: Scalars['Float'];
  payouts: Array<Payout>;
  transactionHistory: Array<CreditTransaction>;
  user: User;
};

export enum Day {
  Anytime = 'anytime',
  Weekdays = 'weekdays',
  WeekendsHolidays = 'weekends_holidays'
}

export type DeductPointTeamInput = {
  combatRoomId: Scalars['ID'];
  deductScore: Scalars['Int'];
  id: Scalars['ID'];
};

/** Autogenerated return type of DeductPointTeam */
export type DeductPointTeamPayload = {
  __typename?: 'DeductPointTeamPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type DeleteTeamInput = {
  teamId: Scalars['ID'];
};

/** Autogenerated return type of DeleteTeam */
export type DeleteTeamPayload = {
  __typename?: 'DeleteTeamPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type DeleteUserBlastScheduleInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteUserBlastSchedule */
export type DeleteUserBlastSchedulePayload = {
  __typename?: 'DeleteUserBlastSchedulePayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeliverPilotRewardInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of DeliverPilotReward */
export type DeliverPilotRewardPayload = {
  __typename?: 'DeliverPilotRewardPayload';
  errors?: Maybe<Array<Error>>;
  pilotReward?: Maybe<PilotReward>;
};

export type DeliverUserPrizeInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of DeliverUserPrize */
export type DeliverUserPrizePayload = {
  __typename?: 'DeliverUserPrizePayload';
  errors?: Maybe<Array<Error>>;
  userPrize?: Maybe<UserPrize>;
};

export type EditMerchandiseVariantsInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of EditMerchandiseVariants */
export type EditMerchandiseVariantsPayload = {
  __typename?: 'EditMerchandiseVariantsPayload';
  errors?: Maybe<Array<Error>>;
  merchandiseVariants?: Maybe<MerchandiseVariant>;
};

/** Error */
export type Error = {
  __typename?: 'Error';
  detail: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

export type FailedInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of Failed */
export type FailedPayload = {
  __typename?: 'FailedPayload';
  errors?: Maybe<Array<Error>>;
  payout?: Maybe<Payout>;
};

/** FAQ */
export type Faq = {
  __typename?: 'Faq';
  answer: Scalars['String'];
  apps?: Maybe<FaqApps>;
  categoryColumns: CategoryColumns;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  language: Language;
  position: Scalars['Int'];
  question: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum FaqApps {
  Gamer = 'gamer',
  Infiniti = 'infiniti',
  Partner = 'partner'
}

/** Faq include counter */
export type FaqCount = {
  __typename?: 'FaqCount';
  count: Scalars['Int'];
  data: Array<Faq>;
};


export type FilterPartneringQuery = {
  state?: Maybe<Scalars['String']>;
};

export type FilterQuery = {
  codeState?: Maybe<CodeState>;
  screenshotState?: Maybe<ScreenshotState>;
  testState?: Maybe<TestState>;
};

/** Game */
export type Game = {
  __typename?: 'Game';
  active?: Maybe<Scalars['Boolean']>;
  combat?: Maybe<Scalars['Boolean']>;
  developerName: Scalars['String'];
  gameAccount?: Maybe<GameAccount>;
  gameAccountsCount?: Maybe<Scalars['Int']>;
  gameChatroomId: Scalars['ID'];
  gameRanks: Array<GameRank>;
  genre?: Maybe<GameGenre>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interests?: Maybe<Array<Interest>>;
  logoUrl?: Maybe<Scalars['String']>;
  mascotUrl?: Maybe<Scalars['String']>;
  matchmaking?: Maybe<Scalars['Boolean']>;
  minRank?: Maybe<GameRank>;
  name: Scalars['String'];
  ordering?: Maybe<Scalars['Int']>;
  partneringPenaltyRate?: Maybe<Scalars['Int']>;
  partneringRate?: Maybe<Scalars['Int']>;
  perspectives: Array<ProficientRole>;
  platform?: Maybe<GamePlatform>;
  proficientHeros: Array<ProficientHero>;
  proficientLanes?: Maybe<Array<ProficientRole>>;
  proficientRoles: Array<ProficientRole>;
  queues: Array<ProficientRole>;
  realTime?: Maybe<Scalars['Boolean']>;
  schedule?: Maybe<Scalars['Boolean']>;
  screenshotSampleUrl?: Maybe<Array<Scalars['String']>>;
  scrim?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  teamsCount?: Maybe<Scalars['Int']>;
  testPassingScore?: Maybe<Scalars['Int']>;
  themeColor: Scalars['String'];
  vocaCode?: Maybe<Scalars['String']>;
};


/** Game */
export type GameGameAccountArgs = {
  userId: Scalars['ID'];
};

/** Game Account */
export type GameAccount = {
  __typename?: 'GameAccount';
  accountId: Scalars['String'];
  approve: Scalars['Boolean'];
  club?: Maybe<Club>;
  codeState: CodeState;
  createdAt: Scalars['ISO8601DateTime'];
  currentLevel?: Maybe<PilotLevel>;
  currentReport?: Maybe<MonthlyReport>;
  game: Game;
  gameRank: GameRank;
  id: Scalars['ID'];
  inGameName?: Maybe<Scalars['String']>;
  kd?: Maybe<Scalars['Float']>;
  monthlyReports: Array<MonthlyReport>;
  nextLevel?: Maybe<PilotLevel>;
  onlineSchedules: Array<OnlineSchedule>;
  pairableGameRanks: Array<GameRank>;
  pilot: Pilot;
  pilotTier?: Maybe<PilotTier>;
  proficientHeros: Array<ProficientHero>;
  proficientLanes?: Maybe<Array<ProficientRole>>;
  proficientRoles: Array<ProficientRole>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  rejectReason?: Maybe<Scalars['String']>;
  remindAt?: Maybe<Scalars['ISO8601DateTime']>;
  remindCount: Scalars['Int'];
  screenshotState: ScreenshotState;
  screenshotUrl?: Maybe<Array<Scalars['String']>>;
  serverId?: Maybe<Scalars['String']>;
  suspended: Scalars['Boolean'];
  testRetryCount: Scalars['Int'];
  testScore?: Maybe<Scalars['Float']>;
  testState: TestState;
  typeformUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  verificationCode: Scalars['String'];
  winRate?: Maybe<Scalars['Float']>;
};

/** Game Account include counter */
export type GameAccountCount = {
  __typename?: 'GameAccountCount';
  count: Scalars['Int'];
  data: Array<GameAccountIndex>;
};

/** Game Account Index */
export type GameAccountIndex = {
  __typename?: 'GameAccountIndex';
  accountId: Scalars['String'];
  aggregateRating: Scalars['Float'];
  approve?: Maybe<Scalars['Boolean']>;
  club?: Maybe<Club>;
  codeState: CodeState;
  completeGameBundlesCount: Scalars['Int'];
  completePartneringRequestsCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  game: Game;
  gameRank: GameRank;
  id: Scalars['ID'];
  pilot: PilotIndex;
  pilotTier?: Maybe<PilotTier>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  screenshotState: ScreenshotState;
  screenshotUrl?: Maybe<Array<Scalars['String']>>;
  suspended: Scalars['Boolean'];
  testScore?: Maybe<Scalars['Float']>;
  testState: TestState;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Game Bundle */
export type GameBundle = {
  __typename?: 'GameBundle';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  bookingId: Scalars['String'];
  chatroom?: Maybe<Chatroom>;
  commission: Scalars['Float'];
  communicationRating?: Maybe<Scalars['Int']>;
  completionScreenshotUrl?: Maybe<Scalars['String']>;
  completionTime?: Maybe<Scalars['ISO8601DateTime']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  currentGameRank: GameRank;
  currentRankPoint?: Maybe<Scalars['Int']>;
  currentRankStar?: Maybe<Scalars['Int']>;
  currentRankTier?: Maybe<Scalars['Int']>;
  estimatedDurationInHour?: Maybe<Scalars['Float']>;
  game: Game;
  id: Scalars['ID'];
  openMic?: Maybe<Scalars['Boolean']>;
  originalAmount: Scalars['Float'];
  perspectives?: Maybe<Array<ProficientRole>>;
  pilot?: Maybe<SimpleUser>;
  pilotGameAccount?: Maybe<SimpleGameAccount>;
  pilotReview?: Maybe<Scalars['String']>;
  proficientHeros: Array<ProficientHero>;
  proficientLanes: Array<ProficientRole>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  promoCode?: Maybe<Scalars['String']>;
  queues?: Maybe<Array<ProficientRole>>;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  reporter?: Maybe<SimpleUser>;
  reportReason?: Maybe<Scalars['String']>;
  skillRating?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: GameBundleState;
  targetGameRank: GameRank;
  targetRankPoint?: Maybe<Scalars['Int']>;
  targetRankStar?: Maybe<Scalars['Int']>;
  targetRankTier?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: SimpleUser;
};

/** Game bundles include counter */
export type GameBundleCount = {
  __typename?: 'GameBundleCount';
  count: Scalars['Int'];
  data: Array<AdminGameBundle>;
};

export enum GameBundleState {
  Available = 'available',
  Completed = 'completed',
  ConfirmStarted = 'confirm_started',
  ConfirmedCompletion = 'confirmed_completion',
  Expired = 'expired',
  GamerStarted = 'gamer_started',
  Grabbed = 'grabbed',
  Rated = 'rated',
  Refunded = 'refunded',
  Reported = 'reported',
  Reviewed = 'reviewed',
  Started = 'started'
}

export type GameChatroom = {
  __typename?: 'GameChatroom';
  createdAt: Scalars['ISO8601DateTime'];
  game: Game;
  id: Scalars['ID'];
  state: GameChatroomState;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum GameChatroomState {
  Active = 'active',
  Paused = 'paused',
  Resume = 'resume'
}

/** Game include counter */
export type GameCount = {
  __typename?: 'GameCount';
  count: Scalars['Int'];
  data: Array<Game>;
};

export enum GameGenre {
  Action = 'action',
  Adventure = 'adventure',
  BattleRoyale = 'battle_royale',
  Card = 'card',
  Casual = 'casual',
  Fighting = 'fighting',
  Fps = 'fps',
  Mmorpg = 'mmorpg',
  Moba = 'moba',
  Puzzle = 'puzzle',
  Racing = 'racing',
  Rpg = 'rpg',
  Sandbox = 'sandbox',
  Shooter = 'shooter',
  Simulation = 'simulation',
  Sports = 'sports',
  Strategy = 'strategy',
  Survival = 'survival',
  TowerDefense = 'tower_defense'
}

/** Game Chatroom Message */
export type GameMessage = {
  __typename?: 'GameMessage';
  _id: Scalars['ID'];
  accountId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  admin?: Maybe<Admin>;
  createdAt: Scalars['ISO8601DateTime'];
  gameAccountId?: Maybe<Scalars['ID']>;
  gameChatroom: GameChatroom;
  gameChatroomId: Scalars['ID'];
  gameRank?: Maybe<Scalars['String']>;
  hostCountry?: Maybe<Scalars['String']>;
  hostIcon?: Maybe<Scalars['String']>;
  hostId?: Maybe<Scalars['ID']>;
  hostName?: Maybe<Scalars['String']>;
  hostThemeColor?: Maybe<Scalars['String']>;
  hostTier?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  memberCount?: Maybe<Scalars['Int']>;
  messageType: ChatroomMessage;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  screenshotUrl?: Maybe<Scalars['String']>;
  scrim?: Maybe<Scrim>;
  scrimChatroomId?: Maybe<Scalars['ID']>;
  scrimDuration?: Maybe<Scalars['Float']>;
  scrimId?: Maybe<Scalars['ID']>;
  scrimMatchCount?: Maybe<Scalars['Int']>;
  scrimStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  team?: Maybe<Team>;
  teamCountry?: Maybe<Scalars['String']>;
  teamIcon?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['ID']>;
  teamName?: Maybe<Scalars['String']>;
  teamState?: Maybe<Scalars['String']>;
  teamTier?: Maybe<Scalars['String']>;
  teamTown?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  themeColor?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userAvatar?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userNickname?: Maybe<Scalars['String']>;
};

/** Game Message include counter */
export type GameMessageCount = {
  __typename?: 'GameMessageCount';
  chatroom: GameChatroom;
  count: Scalars['Int'];
  endReached: Scalars['Boolean'];
  id: Scalars['ID'];
  messages: Array<GameMessage>;
};

export enum GameMode {
  Casual = 'casual',
  Other = 'other',
  Ranked = 'ranked'
}

export enum GamePlatform {
  Console = 'console',
  CrossPlatform = 'cross_platform',
  Desktop = 'desktop',
  Mobile = 'mobile'
}

/** Game Rank */
export type GameRank = {
  __typename?: 'GameRank';
  game: Game;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ordering: Scalars['Int'];
  rankPointMaxCount?: Maybe<Array<Scalars['Int']>>;
  rankStarMaxCount?: Maybe<Scalars['Int']>;
  rankStarMinCount?: Maybe<Scalars['Int']>;
  rankTierMaxCount?: Maybe<Scalars['Int']>;
};

/** Gamer Banner */
export type GamerBanner = {
  __typename?: 'GamerBanner';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  endTime?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  image: Scalars['String'];
  poster: Scalars['Boolean'];
  screen?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** Gamer Banner include counter */
export type GamerBannerCount = {
  __typename?: 'GamerBannerCount';
  count: Scalars['Int'];
  data: Array<Banner>;
};

export enum Gender {
  Female = 'female',
  Male = 'male'
}

export type GenerateLeaderboardAdminInput = {
  string: Scalars['String'];
};

/** Autogenerated return type of GenerateLeaderboardAdmin */
export type GenerateLeaderboardAdminPayload = {
  __typename?: 'GenerateLeaderboardAdminPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Gift */
export type Gift = {
  __typename?: 'Gift';
  amount: Scalars['Int'];
  currency: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Indicators */
export type Indicator = {
  __typename?: 'Indicator';
  joinRequests: Scalars['Boolean'];
  messages: Scalars['Boolean'];
  teamInvitations: Scalars['Boolean'];
  teamMessages: Scalars['Boolean'];
};

/** Interest */
export type Interest = {
  __typename?: 'Interest';
  active: Scalars['Boolean'];
  iconOffUrl?: Maybe<Scalars['String']>;
  iconOnUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Interest include counter */
export type InterestsCount = {
  __typename?: 'InterestsCount';
  count: Scalars['Int'];
  data: Array<Interest>;
};


/** Join Request */
export type JoinRequest = {
  __typename?: 'JoinRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  proficientRoles: Array<ProficientRole>;
  state: JoinRequestState;
  team: Team;
  user: User;
};

export enum JoinRequestState {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Pending = 'pending',
  Rejected = 'rejected'
}


export type Judge = {
  __typename?: 'Judge';
  apiKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  ipAddress?: Maybe<Scalars['String']>;
  judgeId: Scalars['String'];
  role: Scalars['String'];
  tournaments?: Maybe<Array<Tournament>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum Language {
  Bm = 'bm',
  Cn = 'cn',
  En = 'en',
  Id = 'id',
  Ms = 'ms',
  Zh = 'zh'
}

/** Leaderboard */
export type Leaderboard = {
  __typename?: 'Leaderboard';
  beginningTime?: Maybe<Scalars['ISO8601DateTime']>;
  beginningWeek: Scalars['ISO8601DateTime'];
  combatEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  combatLimit: Scalars['Int'];
  combatTeams: Array<CombatTeam>;
  countryCode: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  currentTargetTeamCounts?: Maybe<Scalars['Int']>;
  currentTeam?: Maybe<CombatTeam>;
  endTime?: Maybe<Scalars['ISO8601DateTime']>;
  endWeek: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isMaxPool?: Maybe<Scalars['Boolean']>;
  isOpen: Scalars['Boolean'];
  month: Scalars['String'];
  nextPoolPrizes?: Maybe<Array<PoolPrize>>;
  nextTotalPrize?: Maybe<Scalars['Float']>;
  nextTotalTeamCounts?: Maybe<Scalars['Int']>;
  numberOfWeek: Scalars['Int'];
  poolPrizes?: Maybe<Array<PoolPrize>>;
  registerEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  registrationFee: Scalars['Float'];
  state: LeaderboardState;
  templatePoolPrizes?: Maybe<Array<PoolPrize>>;
  totalPrize: Scalars['Float'];
  totalRegisteredTeamCounts: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  year: Scalars['String'];
};


/** Leaderboard */
export type LeaderboardCombatTeamsArgs = {
  page: Scalars['Int'];
};

/** Leaderboard include counter */
export type LeaderboardCount = {
  __typename?: 'LeaderboardCount';
  count: Scalars['Int'];
  data: Array<CombatTeam>;
};

/** Leaderboard pool prize include counter */
export type LeaderboardPoolPrizeCount = {
  __typename?: 'LeaderboardPoolPrizeCount';
  count: Scalars['Int'];
  data: Array<Leaderboard>;
};

export enum LeaderboardState {
  Cancelled = 'cancelled',
  CombatStarted = 'combat_started',
  Completed = 'completed',
  RegistrationPeriod = 'registration_period'
}

/** Manager */
export type Manager = {
  __typename?: 'Manager';
  avatarUrl?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  chatroom?: Maybe<Chatroom>;
  club?: Maybe<Club>;
  combatQueue?: Maybe<CombatQueue>;
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Language;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  managingTeam?: Maybe<Team>;
  managingTeams: Array<Team>;
  nickname?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  privateChatroom?: Maybe<Chatroom>;
  referralCode: Scalars['String'];
  signInCount: Scalars['Int'];
  teamChatroom: Chatroom;
  teamChatrooms: Array<Chatroom>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Manager */
export type ManagerChatroomArgs = {
  id: Scalars['ID'];
};


/** Manager */
export type ManagerManagingTeamArgs = {
  id: Scalars['ID'];
};


/** Manager */
export type ManagerPrivateChatroomArgs = {
  userId: Scalars['ID'];
};


/** Manager */
export type ManagerTeamChatroomArgs = {
  teamId: Scalars['ID'];
};


/** Manager */
export type ManagerTeamChatroomsArgs = {
  teamId: Scalars['ID'];
};

/** Markup Pricing */
export type MarkupPricing = {
  __typename?: 'MarkupPricing';
  active: Scalars['Boolean'];
  countryCode: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  invoiceId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['ID']>;
  merchantId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  percentage: Scalars['Float'];
  productId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  uniqueId?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
};

/** Markup Pricing include counter */
export type MarkupPricingCount = {
  __typename?: 'MarkupPricingCount';
  count: Scalars['Int'];
  data: Array<MarkupPricing>;
};

export type MatchMaking = {
  __typename?: 'MatchMaking';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  bookingAccounts: BookingCount;
  bookings?: Maybe<Array<Booking>>;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Scalars['String']>;
  currentBooking?: Maybe<Booking>;
  endTime: Scalars['ISO8601DateTime'];
  game: Game;
  gameMode: GameMode;
  id: Scalars['ID'];
  inQueue: Scalars['Boolean'];
  isCreator: Scalars['Boolean'];
  matchCount?: Maybe<Scalars['Int']>;
  maximumRank?: Maybe<GameRank>;
  maximumRankId?: Maybe<Scalars['ID']>;
  minimumRank?: Maybe<GameRank>;
  minimumRankId?: Maybe<Scalars['ID']>;
  post: Post;
  price: Scalars['Int'];
  prosOnly?: Maybe<Scalars['Boolean']>;
  queueCount: Scalars['Int'];
  queueLimit?: Maybe<Scalars['Int']>;
  startTime: Scalars['ISO8601DateTime'];
  state: MatchMakingState;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};


export type MatchMakingBookingAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
};

/** Match making include counter */
export type MatchMakingCount = {
  __typename?: 'MatchMakingCount';
  count: Scalars['Int'];
  data: Array<MatchMaking>;
};

export enum MatchMakingState {
  Available = 'available',
  Inactived = 'inactived'
}

export type MatchResult = {
  __typename?: 'MatchResult';
  combatTeam?: Maybe<CombatTeam>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  loseTeam?: Maybe<Team>;
  resultScreenshots?: Maybe<Array<Scalars['String']>>;
  screenshotRemark?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  winTeam?: Maybe<Team>;
};

/** Merchandise */
export type Merchandise = {
  __typename?: 'Merchandise';
  active: Scalars['Boolean'];
  category: Category;
  club?: Maybe<Club>;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['String']>>;
  merchandiseVariants: Array<MerchandiseVariant>;
  name: Scalars['String'];
  position: Scalars['Int'];
  post: Post;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

/** Pilot Credit Transaction include counter */
export type MerchandiseCount = {
  __typename?: 'MerchandiseCount';
  count: Scalars['Int'];
  data: Array<Merchandise>;
};

/** Merchandise Variant */
export type MerchandiseVariant = {
  __typename?: 'MerchandiseVariant';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  merchandise: Merchandise;
  name: Scalars['String'];
  orders: Array<Order>;
  position: Scalars['Int'];
  price: Scalars['Float'];
  sku: Scalars['String'];
  stock: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Chatroom Message */
export type Message = {
  __typename?: 'Message';
  _id: Scalars['ID'];
  chatroomId: Scalars['ID'];
  clan?: Maybe<Clan>;
  clanId?: Maybe<Scalars['ID']>;
  contact?: Maybe<User>;
  contactId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  forwardId?: Maybe<Scalars['ID']>;
  forwardMessage?: Maybe<Message>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  joinRequest?: Maybe<JoinRequest>;
  joinRequestId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['ID']>;
  read: Scalars['Boolean'];
  replyId?: Maybe<Scalars['ID']>;
  replyMessage?: Maybe<Message>;
  scrim?: Maybe<Scrim>;
  scrimId?: Maybe<Scalars['ID']>;
  system: Scalars['Boolean'];
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
  teamInvitation?: Maybe<TeamInvitation>;
  teamInvitationId?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
  user: User;
};

export enum Month {
  April = 'April',
  August = 'August',
  December = 'December',
  February = 'February',
  January = 'January',
  July = 'July',
  June = 'June',
  March = 'March',
  May = 'May',
  November = 'November',
  October = 'October',
  September = 'September'
}

/** Monthly Report */
export type MonthlyReport = {
  __typename?: 'MonthlyReport';
  cancelCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  gameAccount: GameAccount;
  id: Scalars['ID'];
  matchCount?: Maybe<Scalars['Int']>;
  month: Scalars['String'];
  nextLevel?: Maybe<PilotLevel>;
  pilotLevel: PilotLevel;
  pilotRewards: Array<PilotReward>;
  rating: Scalars['Float'];
  realtimeHours: Scalars['Float'];
  respondTime?: Maybe<Scalars['Float']>;
  scheduleCount: Scalars['Int'];
  year: Scalars['String'];
};

/** Monthly report histories include counter */
export type MonthlyReportHistoriesCount = {
  __typename?: 'MonthlyReportHistoriesCount';
  count: Scalars['Int'];
  data: Array<MonthlyReport>;
};

/** Monthly reports include counter */
export type MonthlyReportsCount = {
  __typename?: 'MonthlyReportsCount';
  count: Scalars['Int'];
  data: Array<MonthlyReport>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Manager Accept Team Invitation */
  acceptTeamInvitation?: Maybe<AcceptTeamInvitationPayload>;
  /** Activate suspend Pilot */
  activatePilot?: Maybe<ActivatePilotPayload>;
  /** Activate suspend User */
  activateUser?: Maybe<ActivateUserPayload>;
  /** Create game rank */
  addGameRank?: Maybe<AddGameRankPayload>;
  /** Admin add team member */
  addMember?: Maybe<AddMemberPayload>;
  /** Authorizations */
  adminAuthorization?: Maybe<AdminAuthorizationPayload>;
  /** Admin Transfer credit wallet to user */
  adminTransfer?: Maybe<AdminTransferPayload>;
  /** Approve Bank Info */
  approveBankInfo?: Maybe<ApproveBankInfoPayload>;
  /** Update state report request */
  approveOrRejectReportRequest?: Maybe<ApproveOrRejectReportRequestPayload>;
  /** Approve Payout Transaction */
  approvePayoutTransaction?: Maybe<ApprovePayload>;
  /** Approve Game Account Screenshot */
  approveScreenshot?: Maybe<ApproveScreenshotPayload>;
  /** Approve Suspend Gamer Request */
  approveSuspendGamerRequest?: Maybe<ApproveSuspendGamerRequestPayload>;
  /** Approve Suspend Request */
  approveSuspendRequest?: Maybe<ApproveSuspendRequestPayload>;
  /** Approve Team Payout Transaction */
  approveTeamPayoutTransaction?: Maybe<TeamApprovePayload>;
  /** Approve Game Account Verification Code */
  approveVerificationCode?: Maybe<ApproveVerificationCodePayload>;
  /** Blast gamers */
  blastGamers?: Maybe<BlastGamersPayload>;
  /** Blast partners */
  blastPartners?: Maybe<BlastPartnersPayload>;
  /** Cancel Order */
  cancelOrder?: Maybe<CancelOrderPayload>;
  /** Manager Cancel Team Invitation */
  cancelTeamInvitation?: Maybe<CancelTeamInvitationPayload>;
  /** Cancel Pool Prize */
  cancelTeamWallet?: Maybe<CancelTeamWalletPayload>;
  /** Change state combat room */
  changeCombatRoomState?: Maybe<ChangeCombatRoomStatePayload>;
  /** Check Category */
  checkCategory?: Maybe<CheckCategoryPayload>;
  /** Check Gamer Banner */
  checkGamerBanner?: Maybe<CheckGamerBannerPayload>;
  /** Check Merchandise Variant */
  checkMerchandiseVariant?: Maybe<CheckMerchandiseVariantPayload>;
  /** Admin check pilot with nickname */
  checkPilot?: Maybe<CheckPilotPayload>;
  /** Admin check pilot game bundle */
  checkPilotGameBundle?: Maybe<CheckPilotGameBundlePayload>;
  /** Check Post */
  checkPost?: Maybe<CheckPostPayload>;
  /** Admin check user game bundle */
  checkUserGameBundle?: Maybe<CheckUserGameBundlePayload>;
  /** Flag combat room */
  chooseWinnerCombatRoom?: Maybe<ChooseWinnerCombatRoomPayload>;
  /** Complete Partnering Request */
  completePartneringRequest?: Maybe<CompletePartneringRequestPayload>;
  /** Payout Transaction Success */
  completePayoutTransaction?: Maybe<CompletePayload>;
  /** Payout Team Transaction Success */
  completeTeamPayoutTransaction?: Maybe<TeamCompletePayload>;
  /** Confirm completion game bundles */
  confirmCompletedGameBundle?: Maybe<ConfirmCompletedGameBundlePayload>;
  /** Add score to combat room */
  createAddPointForFlaggedTeam?: Maybe<CreateAddPointForFlaggedTeamPayload>;
  /** Create Career Highlight */
  createCareerHighlight?: Maybe<CreateCareerHighlightPayload>;
  /** Create Category */
  createCategory?: Maybe<CreateCategoryPayload>;
  /** Create Club */
  createClub?: Maybe<CreateClubPayload>;
  /** Admin create faq */
  createFaq?: Maybe<CreateFaqPayload>;
  /** Create Game */
  createGame?: Maybe<CreateGamePayload>;
  /** Admin create game bundle */
  createGameBundle?: Maybe<CreateGameBundlePayload>;
  /** Admin create game message */
  createGameMessage?: Maybe<CreateGameMessagePayload>;
  /** Create Gamer Banner */
  createGamerBanner?: Maybe<CreateGamerBannerPayload>;
  /** Admin delete team */
  createGift?: Maybe<CreateGiftPayload>;
  /** Create Interest */
  createInterest?: Maybe<CreateInterestPayload>;
  /** Create Markup Pricing */
  createMarkupPricing?: Maybe<CreateMarkupPricingPayload>;
  /** Create Merchandise */
  createMerchandise?: Maybe<CreateMerchandisePayload>;
  /** Create Merchandise Variant */
  createMerchandiseVariant?: Maybe<CreateMerchandiseVariantPayload>;
  /** Manager Cancel Team Invitation */
  createMerchandiseVariants?: Maybe<CreateMerchandiseVariantsPayload>;
  /** Create Official Channel */
  createOfficialChannel?: Maybe<CreateOfficialChannelPayload>;
  /** Admin create payout */
  createPayout?: Maybe<CreatePayoutPayload>;
  /** Admin create pilot */
  createPilot?: Maybe<CreatePilotPayload>;
  /** Create Pilot Banner */
  createPilotBanner?: Maybe<CreatePilotBannerPayload>;
  /** Create Post */
  createPost?: Maybe<CreatePostPayload>;
  /** Create Prize */
  createPrize?: Maybe<CreatePrizePayload>;
  /** Create proficient role */
  createProficientRole?: Maybe<CreateProficientRolePayload>;
  /** Create Promo Code */
  createPromoCode?: Maybe<CreatePromoCodePayload>;
  /** Create Publisher */
  createPublisher?: Maybe<CreatePublisherPayload>;
  /** Create Sponsor Ad */
  createSponsorAd?: Maybe<CreateSponsorAdPayload>;
  /** Create Star Player */
  createStarPlayer?: Maybe<CreateStarPlayerPayload>;
  /** Create Star Team */
  createStarTeam?: Maybe<CreateStarTeamPayload>;
  /** Create suspend combat team */
  createSuspendCombatTeam?: Maybe<CreateSuspendCombatTeamPayload>;
  /** Create Suspend Gamer Request */
  createSuspendGamerRequest?: Maybe<CreateSuspendGamerRequestPayload>;
  /** Create Suspend Request */
  createSuspendRequest?: Maybe<CreateSuspendRequestPayload>;
  /** Create suspend combat team */
  createSuspendTeam?: Maybe<CreateSuspendTeamPayload>;
  /** Admin suspend team and members */
  createSuspendTeamAndMembers?: Maybe<CreateSuspendTeamAndMembersPayload>;
  /** Admin create team */
  createTeam?: Maybe<CreateTeamPayload>;
  /** Manager Create Team Invitation */
  createTeamInvitation?: Maybe<CreateTeamInvitationPayload>;
  /** Create Top Up */
  createTopUpProduct?: Maybe<CreateTopUpProductPayload>;
  /** Admin create tournament */
  createTournament?: Maybe<CreateTournamentPayload>;
  /** Admin create tournament events */
  createTournamentEvent?: Maybe<CreateTournamentEventPayload>;
  /** Create Vendor */
  createVendor?: Maybe<CreateVendorPayload>;
  /** Deduct point team */
  deductPointTeam?: Maybe<DeductPointTeamPayload>;
  /** Admin delete team */
  deleteTeam?: Maybe<DeleteTeamPayload>;
  /** Delete user blast schedule */
  deleteUserBlastSchedule?: Maybe<DeleteUserBlastSchedulePayload>;
  /** Delivered pilot reward */
  deliverPilotReward?: Maybe<DeliverPilotRewardPayload>;
  /** Deliver User Prize */
  deliverUserPrize?: Maybe<DeliverUserPrizePayload>;
  /** Manager Accept Team Invitation */
  editMerchandiseVariants?: Maybe<EditMerchandiseVariantsPayload>;
  /** Payout Transaction Failed */
  failedPayoutTransaction?: Maybe<FailedPayload>;
  /** Payout Team Transaction Failed */
  failedTeamPayoutTransaction?: Maybe<TeamFailedPayload>;
  /** Admin generate new leaderboard */
  generateLeaderboardAdmin?: Maybe<GenerateLeaderboardAdminPayload>;
  /** Admin check preview amount game bundle */
  previewAmountGameBundle?: Maybe<PreviewAmountGameBundlePayload>;
  /** Refund Game Schedule */
  refundBundle?: Maybe<RefundBundlePayload>;
  /** Refund match making */
  refundMatchMaking?: Maybe<RefundBookingPayload>;
  /** Refund Real Time */
  refundPartneringRequest?: Maybe<RefundPartneringRequestPayload>;
  /** Reject Bank Info */
  rejectBankInfo?: Maybe<RejectBankInfoPayload>;
  /** Reject Payout Transaction */
  rejectPayoutTransaction?: Maybe<RejectPayload>;
  /** Reject Game Account Screenshot */
  rejectScreenshot?: Maybe<RejectScreenshotPayload>;
  /** Reject Suspend Gamer Request */
  rejectSuspendGamerRequest?: Maybe<RejectSuspendGamerRequestPayload>;
  /** Reject Suspend Request */
  rejectSuspendRequest?: Maybe<RejectSuspendRequestPayload>;
  /** Manager Reject Team Invitation */
  rejectTeamInvitation?: Maybe<RejectTeamInvitationPayload>;
  /** Reject Team Payout Transaction */
  rejectTeamPayoutTransaction?: Maybe<TeamRejectPayload>;
  /** Reject Game Account Verification Code */
  rejectVerificationCode?: Maybe<RejectVerificationCodePayload>;
  /** Remind Pilot to Take Test */
  remindPilotTest?: Maybe<RemindPilotTestPayload>;
  /** Remove Club Member */
  removeClubMember?: Maybe<RemoveClubMemberPayload>;
  /** Remove Game */
  removeGameRank?: Maybe<RemoveGameRankPayload>;
  /** Manager Reject Team Invitation */
  removeMerchandiseVariants?: Maybe<RemoveMerchandiseVariantsPayload>;
  /** Admin remove pilot credit transaction */
  removePilotCreditTransaction?: Maybe<RemovePilotCreditTransactionPayload>;
  /** Remove Pilot Tier */
  removePilotTier?: Maybe<RemovePilotTierPayload>;
  /** Remove team member */
  removeTeamMember?: Maybe<RemoveTeamMemberPayload>;
  /** Admin remove user credit transaction */
  removeUserCreditTransaction?: Maybe<RemoveCreditTransactionPayload>;
  /** Report Game Schedule */
  reportGameBundle?: Maybe<ReportGameBundlePayload>;
  /** Report Real Time */
  reportPartneringRequest?: Maybe<ReportPartneringRequestPayload>;
  /** Reschedule and destroy later user blast schedule */
  rescheduleBlast?: Maybe<RescheduleBlastPayload>;
  /** Review Game Schedule */
  reviewBundle?: Maybe<ReviewBundlePayload>;
  /** Review match making */
  reviewMatchMaking?: Maybe<ReviewBookingPayload>;
  /** Review Real Time */
  reviewPartneringRequest?: Maybe<ReviewPartneringRequestPayload>;
  /** Revoke suspend current combat team */
  revokeSuspendCurrentCombatTeam?: Maybe<RevokeSuspendCurrentCombatTeamPayload>;
  /** Admin revoke team suspension */
  revokeTeamSuspension?: Maybe<RevokeTeamSuspensionPayload>;
  /** Set Game Partnering Rate */
  setGamePartneringRate?: Maybe<SetPartneringRatePayload>;
  /** Admin change state tournament team */
  tournamentTeam?: Maybe<TournamentTeamPayload>;
  /** Update Admin Note */
  updateAdminNoteCombatRoom?: Maybe<UpdateAdminNoteCombatRoomPayload>;
  /** Update Admin Note */
  updateAdminNoteGameBundle?: Maybe<UpdateAdminNoteGameBundlePayload>;
  /** Update Admin Note */
  updateAdminNotePartneringRequest?: Maybe<UpdateAdminNotePartneringRequestPayload>;
  /** Update Admin Note */
  updateAdminNotePayout?: Maybe<UpdateAdminNotePayoutPayload>;
  /** Update Admin Note */
  updateAdminNoteTeamPayout?: Maybe<UpdateAdminNoteTeamPayoutPayload>;
  /** Update Admin Note */
  updateAdminNoteUser?: Maybe<UpdateAdminNoteUserPayload>;
  /** Update Career Highlight */
  updateCareerHighlight?: Maybe<UpdateCareerHighlightPayload>;
  /** Update Club */
  updateClub?: Maybe<UpdateClubPayload>;
  /** Update Club Member */
  updateClubMember?: Maybe<UpdateClubMemberPayload>;
  /** Admin update faq */
  updateFaq?: Maybe<UpdateFaqPayload>;
  /** Update Game */
  updateGame?: Maybe<UpdateGamePayload>;
  /** Update Game Rank */
  updateGameRank?: Maybe<UpdateGameRankPayload>;
  /** Update Gamer Banner */
  updateGamerBanner?: Maybe<UpdateGamerBannerPayload>;
  /** Admin delete team */
  updateGift?: Maybe<UpdateGiftPayload>;
  /** Update Interest */
  updateInterest?: Maybe<UpdateInterestPayload>;
  /** Update Markup Pricing */
  updateMarkupPricing?: Maybe<UpdateMarkupPricingPayload>;
  /** Update Match Result */
  updateMatchResult?: Maybe<UpdateMatchResultPayload>;
  /** Update Match Result */
  updateMatchResultAdmin?: Maybe<UpdateMatchResultAdminPayload>;
  /** Update Merchandise */
  updateMerchandise?: Maybe<UpdateMerchandisePayload>;
  /** Update Merchandise Image */
  updateMerchandiseImages?: Maybe<UpdateMerchandiseImagesPayload>;
  /** Update Merchandise Variant */
  updateMerchandiseVariant?: Maybe<UpdateMerchandiseVariantPayload>;
  /** Update Official Channel */
  updateOfficialChannel?: Maybe<UpdateOfficialChannelPayload>;
  /** Update Order Status */
  updateOrderStatus?: Maybe<UpdateOrderStatusPayload>;
  /** Update Pilot Banner */
  updatePilotBanner?: Maybe<UpdatePilotBannerPayload>;
  /** Update Pilot Tier */
  updatePilotTier?: Maybe<UpdatePilotTierPayload>;
  /** Update Pool Prize */
  updatePoolPrize?: Maybe<UpdatePoolPrizePayload>;
  /** Update Post */
  updatePost?: Maybe<UpdatePostPayload>;
  /** Update Prize */
  updatePrize?: Maybe<UpdatePrizePayload>;
  /** Update proficient role */
  updateProficientRole?: Maybe<UpdateProficientRolePayload>;
  /** Update Promo Code */
  updatePromoCode?: Maybe<UpdatePromoCodePayload>;
  /** Update Publisher */
  updatePublisher?: Maybe<UpdatePublisherPayload>;
  /** Update rating */
  updateRatingGameBundle?: Maybe<UpdateRatingGameBundlePayload>;
  /** Update rating */
  updateRatingPartneringRequest?: Maybe<UpdateRatingPartneringRequestPayload>;
  /** Update Sponsor Ad */
  updateSponsorAd?: Maybe<UpdateSponsorAdPayload>;
  /** Update Pool Prize */
  updateTeamWallet?: Maybe<UpdateTeamWalletPayload>;
  /** Update Top Up */
  updateTopUpProduct?: Maybe<UpdateTopUpProductPayload>;
  /** Admin update tournament */
  updateTournament?: Maybe<UpdateTournamentPayload>;
  /** Admin update tournament events */
  updateTournamentEvent?: Maybe<UpdateTournamentEventPayload>;
  /** Update Tracking Order */
  updateTrackingOrder?: Maybe<UpdateTrackingOrderPayload>;
  /** Update trained Pilot */
  updateTrainedPilot?: Maybe<UpdateTrainedPilotPayload>;
  /** Set Star Player */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Update user blast schedule */
  updateUserBlastSchedule?: Maybe<UpdateUserBlastSchedulePayload>;
  /** Update Vendor */
  updateVendor?: Maybe<UpdateVendorPayload>;
};


export type MutationAcceptTeamInvitationArgs = {
  input: AcceptTeamInvitationInput;
};


export type MutationActivatePilotArgs = {
  input: ActivatePilotInput;
};


export type MutationActivateUserArgs = {
  input: ActivateUserInput;
};


export type MutationAddGameRankArgs = {
  input: AddGameRankInput;
};


export type MutationAddMemberArgs = {
  input: AddMemberInput;
};


export type MutationAdminAuthorizationArgs = {
  input: AdminAuthorizationInput;
};


export type MutationAdminTransferArgs = {
  input: AdminTransferInput;
};


export type MutationApproveBankInfoArgs = {
  input: ApproveBankInfoInput;
};


export type MutationApproveOrRejectReportRequestArgs = {
  input: ApproveOrRejectReportRequestInput;
};


export type MutationApprovePayoutTransactionArgs = {
  input: ApproveInput;
};


export type MutationApproveScreenshotArgs = {
  input: ApproveScreenshotInput;
};


export type MutationApproveSuspendGamerRequestArgs = {
  input: ApproveSuspendGamerRequestInput;
};


export type MutationApproveSuspendRequestArgs = {
  input: ApproveSuspendRequestInput;
};


export type MutationApproveTeamPayoutTransactionArgs = {
  input: TeamApproveInput;
};


export type MutationApproveVerificationCodeArgs = {
  input: ApproveVerificationCodeInput;
};


export type MutationBlastGamersArgs = {
  input: BlastInput;
};


export type MutationBlastPartnersArgs = {
  input: BlastInput;
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationCancelTeamInvitationArgs = {
  input: CancelTeamInvitationInput;
};


export type MutationCancelTeamWalletArgs = {
  input: CancelTeamWalletInput;
};


export type MutationChangeCombatRoomStateArgs = {
  input: ChangeCombatRoomStateInput;
};


export type MutationCheckCategoryArgs = {
  input: CheckCategoryInput;
};


export type MutationCheckGamerBannerArgs = {
  input: CheckGamerBannerInput;
};


export type MutationCheckMerchandiseVariantArgs = {
  input: CheckMerchandiseVariantInput;
};


export type MutationCheckPilotArgs = {
  input: CheckPilotInput;
};


export type MutationCheckPilotGameBundleArgs = {
  input: CheckPilotGameBundleInput;
};


export type MutationCheckPostArgs = {
  input: CheckPostInput;
};


export type MutationCheckUserGameBundleArgs = {
  input: CheckUserGameBundleInput;
};


export type MutationChooseWinnerCombatRoomArgs = {
  input: ChooseWinnerCombatRoomInput;
};


export type MutationCompletePartneringRequestArgs = {
  input: CompletePartneringRequestInput;
};


export type MutationCompletePayoutTransactionArgs = {
  input: CompleteInput;
};


export type MutationCompleteTeamPayoutTransactionArgs = {
  input: TeamCompleteInput;
};


export type MutationConfirmCompletedGameBundleArgs = {
  input: ConfirmCompletedGameBundleInput;
};


export type MutationCreateAddPointForFlaggedTeamArgs = {
  input: CreateAddPointForFlaggedTeamInput;
};


export type MutationCreateCareerHighlightArgs = {
  input: CreateCareerHighlightInput;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateClubArgs = {
  input: CreateClubInput;
};


export type MutationCreateFaqArgs = {
  input: CreateFaqInput;
};


export type MutationCreateGameArgs = {
  input: CreateGameInput;
};


export type MutationCreateGameBundleArgs = {
  input: CreateGameBundleInput;
};


export type MutationCreateGameMessageArgs = {
  input: CreateGameMessageInput;
};


export type MutationCreateGamerBannerArgs = {
  input: CreateGamerBannerInput;
};


export type MutationCreateGiftArgs = {
  input: CreateGiftInput;
};


export type MutationCreateInterestArgs = {
  input: CreateInterestInput;
};


export type MutationCreateMarkupPricingArgs = {
  input: CreateMarkupPricingInput;
};


export type MutationCreateMerchandiseArgs = {
  input: CreateMerchandiseInput;
};


export type MutationCreateMerchandiseVariantArgs = {
  input: CreateMerchandiseVariantInput;
};


export type MutationCreateMerchandiseVariantsArgs = {
  input: CreateMerchandiseVariantsInput;
};


export type MutationCreateOfficialChannelArgs = {
  input: CreateOfficialChannelInput;
};


export type MutationCreatePayoutArgs = {
  input: CreatePayoutInput;
};


export type MutationCreatePilotArgs = {
  input: CreatePilotInput;
};


export type MutationCreatePilotBannerArgs = {
  input: CreatePilotBannerInput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationCreatePrizeArgs = {
  input: CreatePrizeInput;
};


export type MutationCreateProficientRoleArgs = {
  input: CreateProficientRoleInput;
};


export type MutationCreatePromoCodeArgs = {
  input: CreatePromoCodeInput;
};


export type MutationCreatePublisherArgs = {
  input: CreatePublisherInput;
};


export type MutationCreateSponsorAdArgs = {
  input: CreateSponsorAdInput;
};


export type MutationCreateStarPlayerArgs = {
  input: CreateStarPlayerInput;
};


export type MutationCreateStarTeamArgs = {
  input: CreateStarTeamInput;
};


export type MutationCreateSuspendCombatTeamArgs = {
  input: CreateSuspendCombatTeamInput;
};


export type MutationCreateSuspendGamerRequestArgs = {
  input: CreateSuspendGamerRequestInput;
};


export type MutationCreateSuspendRequestArgs = {
  input: CreateSuspendRequestInput;
};


export type MutationCreateSuspendTeamArgs = {
  input: CreateSuspendTeamInput;
};


export type MutationCreateSuspendTeamAndMembersArgs = {
  input: CreateSuspendTeamAndMembersInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTeamInvitationArgs = {
  input: CreateTeamInvitationInput;
};


export type MutationCreateTopUpProductArgs = {
  input: CreateTopUpProductInput;
};


export type MutationCreateTournamentArgs = {
  input: CreateTournamentInput;
};


export type MutationCreateTournamentEventArgs = {
  input: CreateTournamentEventInput;
};


export type MutationCreateVendorArgs = {
  input: CreateVendorInput;
};


export type MutationDeductPointTeamArgs = {
  input: DeductPointTeamInput;
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationDeleteUserBlastScheduleArgs = {
  input: DeleteUserBlastScheduleInput;
};


export type MutationDeliverPilotRewardArgs = {
  input: DeliverPilotRewardInput;
};


export type MutationDeliverUserPrizeArgs = {
  input: DeliverUserPrizeInput;
};


export type MutationEditMerchandiseVariantsArgs = {
  input: EditMerchandiseVariantsInput;
};


export type MutationFailedPayoutTransactionArgs = {
  input: FailedInput;
};


export type MutationFailedTeamPayoutTransactionArgs = {
  input: TeamFailedInput;
};


export type MutationGenerateLeaderboardAdminArgs = {
  input: GenerateLeaderboardAdminInput;
};


export type MutationPreviewAmountGameBundleArgs = {
  input: PreviewAmountGameBundleInput;
};


export type MutationRefundBundleArgs = {
  input: RefundBundleInput;
};


export type MutationRefundMatchMakingArgs = {
  input: RefundBookingInput;
};


export type MutationRefundPartneringRequestArgs = {
  input: RefundPartneringRequestInput;
};


export type MutationRejectBankInfoArgs = {
  input: RejectBankInfoInput;
};


export type MutationRejectPayoutTransactionArgs = {
  input: RejectInput;
};


export type MutationRejectScreenshotArgs = {
  input: RejectScreenshotInput;
};


export type MutationRejectSuspendGamerRequestArgs = {
  input: RejectSuspendGamerRequestInput;
};


export type MutationRejectSuspendRequestArgs = {
  input: RejectSuspendRequestInput;
};


export type MutationRejectTeamInvitationArgs = {
  input: RejectTeamInvitationInput;
};


export type MutationRejectTeamPayoutTransactionArgs = {
  input: TeamRejectInput;
};


export type MutationRejectVerificationCodeArgs = {
  input: RejectVerificationCodeInput;
};


export type MutationRemindPilotTestArgs = {
  input: RemindPilotTestInput;
};


export type MutationRemoveClubMemberArgs = {
  input: RemoveClubMemberInput;
};


export type MutationRemoveGameRankArgs = {
  input: RemoveGameRankInput;
};


export type MutationRemoveMerchandiseVariantsArgs = {
  input: RemoveMerchandiseVariantsInput;
};


export type MutationRemovePilotCreditTransactionArgs = {
  input: RemovePilotCreditTransactionInput;
};


export type MutationRemovePilotTierArgs = {
  input: RemovePilotTierInput;
};


export type MutationRemoveTeamMemberArgs = {
  input: RemoveTeamMemberInput;
};


export type MutationRemoveUserCreditTransactionArgs = {
  input: RemoveCreditTransactionInput;
};


export type MutationReportGameBundleArgs = {
  input: ReportGameBundleInput;
};


export type MutationReportPartneringRequestArgs = {
  input: ReportPartneringRequestInput;
};


export type MutationRescheduleBlastArgs = {
  input: RescheduleBlastInput;
};


export type MutationReviewBundleArgs = {
  input: ReviewBundleInput;
};


export type MutationReviewMatchMakingArgs = {
  input: ReviewBookingInput;
};


export type MutationReviewPartneringRequestArgs = {
  input: ReviewPartneringRequestInput;
};


export type MutationRevokeSuspendCurrentCombatTeamArgs = {
  input: RevokeSuspendCurrentCombatTeamInput;
};


export type MutationRevokeTeamSuspensionArgs = {
  input: RevokeTeamSuspensionInput;
};


export type MutationSetGamePartneringRateArgs = {
  input: SetPartneringRateInput;
};


export type MutationTournamentTeamArgs = {
  input: TournamentTeamInput;
};


export type MutationUpdateAdminNoteCombatRoomArgs = {
  input: UpdateAdminNoteCombatRoomInput;
};


export type MutationUpdateAdminNoteGameBundleArgs = {
  input: UpdateAdminNoteGameBundleInput;
};


export type MutationUpdateAdminNotePartneringRequestArgs = {
  input: UpdateAdminNotePartneringRequestInput;
};


export type MutationUpdateAdminNotePayoutArgs = {
  input: UpdateAdminNotePayoutInput;
};


export type MutationUpdateAdminNoteTeamPayoutArgs = {
  input: UpdateAdminNoteTeamPayoutInput;
};


export type MutationUpdateAdminNoteUserArgs = {
  input: UpdateAdminNoteUserInput;
};


export type MutationUpdateCareerHighlightArgs = {
  input: UpdateCareerHighlightInput;
};


export type MutationUpdateClubArgs = {
  input: UpdateClubInput;
};


export type MutationUpdateClubMemberArgs = {
  input: UpdateClubMemberInput;
};


export type MutationUpdateFaqArgs = {
  input: UpdateFaqInput;
};


export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};


export type MutationUpdateGameRankArgs = {
  input: UpdateGameRankInput;
};


export type MutationUpdateGamerBannerArgs = {
  input: UpdateGamerBannerInput;
};


export type MutationUpdateGiftArgs = {
  input: UpdateGiftInput;
};


export type MutationUpdateInterestArgs = {
  input: UpdateInterestInput;
};


export type MutationUpdateMarkupPricingArgs = {
  input: UpdateMarkupPricingInput;
};


export type MutationUpdateMatchResultArgs = {
  input: UpdateMatchResultInput;
};


export type MutationUpdateMatchResultAdminArgs = {
  input: UpdateMatchResultAdminInput;
};


export type MutationUpdateMerchandiseArgs = {
  input: UpdateMerchandiseInput;
};


export type MutationUpdateMerchandiseImagesArgs = {
  input: UpdateMerchandiseImagesInput;
};


export type MutationUpdateMerchandiseVariantArgs = {
  input: UpdateMerchandiseVariantInput;
};


export type MutationUpdateOfficialChannelArgs = {
  input: UpdateOfficialChannelInput;
};


export type MutationUpdateOrderStatusArgs = {
  input: UpdateOrderStatusInput;
};


export type MutationUpdatePilotBannerArgs = {
  input: UpdatePilotBannerInput;
};


export type MutationUpdatePilotTierArgs = {
  input: UpdatePilotTierInput;
};


export type MutationUpdatePoolPrizeArgs = {
  input: UpdatePoolPrizeInput;
};


export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


export type MutationUpdatePrizeArgs = {
  input: UpdatePrizeInput;
};


export type MutationUpdateProficientRoleArgs = {
  input: UpdateProficientRoleInput;
};


export type MutationUpdatePromoCodeArgs = {
  input: UpdatePromoCodeInput;
};


export type MutationUpdatePublisherArgs = {
  input: UpdatePublisherInput;
};


export type MutationUpdateRatingGameBundleArgs = {
  input: UpdateRatingGameBundleInput;
};


export type MutationUpdateRatingPartneringRequestArgs = {
  input: UpdateRatingPartneringRequestInput;
};


export type MutationUpdateSponsorAdArgs = {
  input: UpdateSponsorAdInput;
};


export type MutationUpdateTeamWalletArgs = {
  input: UpdateTeamWalletInput;
};


export type MutationUpdateTopUpProductArgs = {
  input: UpdateTopUpProductInput;
};


export type MutationUpdateTournamentArgs = {
  input: UpdateTournamentInput;
};


export type MutationUpdateTournamentEventArgs = {
  input: UpdateTournamentEventInput;
};


export type MutationUpdateTrackingOrderArgs = {
  input: UpdateTrackingOrderInput;
};


export type MutationUpdateTrainedPilotArgs = {
  input: UpdateTrainedPilotInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserBlastScheduleArgs = {
  input: UpdateUserBlastScheduleInput;
};


export type MutationUpdateVendorArgs = {
  input: UpdateVendorInput;
};

/** Next Level Count */
export type NextLevelCount = {
  __typename?: 'NextLevelCount';
  conquerorCount: Scalars['Int'];
  creepCount: Scalars['Int'];
  dominatorCount: Scalars['Int'];
  savageCount: Scalars['Int'];
  survivorCount: Scalars['Int'];
};

/** Notifications */
export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  game?: Maybe<Game>;
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  target: NotificationTarget;
  targetId: Scalars['ID'];
  targetType?: Maybe<NotificationTargetString>;
};

export type NotificationTarget = GameBundle | PartneringRequest | SuspendRequest;

export enum NotificationTargetString {
  Announcement = 'Announcement',
  Clan = 'Clan',
  Club = 'Club',
  CombatRoom = 'CombatRoom',
  CreditTransaction = 'CreditTransaction',
  GameBundle = 'GameBundle',
  Message = 'Message',
  PartneringRequest = 'PartneringRequest',
  Pilot = 'Pilot',
  Post = 'Post',
  PostComment = 'PostComment',
  Scrim = 'Scrim',
  SuspendRequest = 'SuspendRequest',
  User = 'User'
}

/** Official Channel */
export type OfficialChannel = {
  __typename?: 'OfficialChannel';
  accountId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Og Data */
export type OgData = {
  __typename?: 'OgData';
  image: Scalars['String'];
  title: Scalars['String'];
};

/** Online Schedule */
export type OnlineSchedule = {
  __typename?: 'OnlineSchedule';
  dayType: Day;
  hours?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Order */
export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float'];
  channel?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditTransaction?: Maybe<CreditTransaction>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  gameAccountId?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  merchandiseVariant?: Maybe<MerchandiseVariant>;
  orderNumber?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  shippingAddress?: Maybe<ShippingAddress>;
  snNumber?: Maybe<Scalars['String']>;
  status: OrderStatus;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  zoneId?: Maybe<Scalars['String']>;
};

/** Order include counter */
export type OrderCount = {
  __typename?: 'OrderCount';
  count: Scalars['Int'];
  data: Array<Order>;
};

export enum OrderStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Failed = 'failed',
  Paid = 'paid',
  Pending = 'pending',
  Refund = 'refund',
  Shipping = 'shipping',
  Succeeded = 'succeeded'
}

/** Partnering Request */
export type PartneringRequest = {
  __typename?: 'PartneringRequest';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  cancellable?: Maybe<Scalars['Boolean']>;
  chatroom?: Maybe<Chatroom>;
  commission: Scalars['Float'];
  communicationRating?: Maybe<Scalars['Int']>;
  completeTime?: Maybe<Scalars['ISO8601DateTime']>;
  completionScreenshotUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  durationHour?: Maybe<Scalars['Float']>;
  game: Game;
  gameRank: GameRank;
  id: Scalars['ID'];
  inGamePilotsGameAccounts?: Maybe<Array<SimpleGameAccount>>;
  matchCount?: Maybe<Scalars['Int']>;
  matchedServices?: Maybe<Array<PilotService>>;
  openMic?: Maybe<Scalars['Boolean']>;
  originalAmount: Scalars['Float'];
  paid: Scalars['Boolean'];
  partnerRateType: PartnerRate;
  penaltyAmount: Scalars['Float'];
  pendingServices?: Maybe<Array<PilotService>>;
  perspectives?: Maybe<Array<ProficientRole>>;
  pilot?: Maybe<SimpleUser>;
  pilotCount: Scalars['Int'];
  pilotGameAccount?: Maybe<SimpleGameAccount>;
  pilotNicknames?: Maybe<Scalars['String']>;
  pilotPrice: Scalars['Float'];
  pilotReview?: Maybe<Scalars['String']>;
  pilots?: Maybe<Array<SimpleUser>>;
  pilotServices?: Maybe<Array<PilotService>>;
  proficientLanes: Array<ProficientRole>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  promoApplied: Scalars['Boolean'];
  promoCode?: Maybe<Scalars['String']>;
  queues?: Maybe<Array<ProficientRole>>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  readiedServices?: Maybe<Array<PilotService>>;
  reporter?: Maybe<SimpleUser>;
  reportReason?: Maybe<Scalars['String']>;
  skillRating?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: PartneringRequestState;
  totalAmount: Scalars['Float'];
  totalDurationInMinutes?: Maybe<Scalars['Float']>;
  totalOriginalAmount: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: SimpleUser;
  userGameAccount?: Maybe<SimpleGameAccount>;
};

/** Partnering requests include counter */
export type PartneringRequestCount = {
  __typename?: 'PartneringRequestCount';
  count: Scalars['Int'];
  data: Array<AdminPartneringRequest>;
};

export enum PartneringRequestState {
  AllStarted = 'all_started',
  Available = 'available',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmStarted = 'confirm_started',
  GamerStarted = 'gamer_started',
  Idled = 'idled',
  Ignored = 'ignored',
  Matched = 'matched',
  PaymentOnHold = 'payment_on_hold',
  Rated = 'rated',
  Refunded = 'refunded',
  Reported = 'reported',
  Reviewed = 'reviewed',
  Started = 'started'
}

export enum PartnerRate {
  Normal = 'normal',
  Pro = 'pro'
}

/** Payout */
export type Payout = {
  __typename?: 'Payout';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  approve: Scalars['Boolean'];
  bank: Scalars['String'];
  bankAccountNo: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  currency: Scalars['String'];
  email: Scalars['String'];
  ic: Scalars['String'];
  id: Scalars['ID'];
  phone: Scalars['String'];
  pilot: Pilot;
  recipient?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  state?: Maybe<PayoutState>;
};

/** Payouts include counter */
export type PayoutCount = {
  __typename?: 'PayoutCount';
  count: Scalars['Int'];
  data: Array<PayoutIndex>;
};

/** Payout Index */
export type PayoutIndex = {
  __typename?: 'PayoutIndex';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  approve: Scalars['Boolean'];
  bank: Scalars['String'];
  bankAccountNo: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  ic: Scalars['String'];
  id: Scalars['ID'];
  phone: Scalars['String'];
  recipient?: Maybe<Scalars['String']>;
  state?: Maybe<PayoutState>;
};

export enum PayoutState {
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected',
  TransactionFailed = 'transaction_failed',
  TransactionSuccess = 'transaction_success'
}

/** Pilot */
export type Pilot = {
  __typename?: 'Pilot';
  acceptedPartneringRequests: Array<PartneringRequest>;
  adminNote?: Maybe<Scalars['String']>;
  aggregateRating: Scalars['Float'];
  allReports: Array<MonthlyReport>;
  availableGameBundles: Array<GameBundle>;
  avatarUrl?: Maybe<Scalars['String']>;
  averageRespondTime?: Maybe<Scalars['Int']>;
  bankInfo?: Maybe<BankInfo>;
  beKol: Scalars['Boolean'];
  bePro: Scalars['Boolean'];
  beStar: Scalars['Boolean'];
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  chatroom?: Maybe<Chatroom>;
  completeGameBundlesCount: Scalars['Int'];
  completePartneringRequestsCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gameAccount?: Maybe<GameAccount>;
  gameAccounts: Array<GameAccount>;
  gameBundles: Array<GameBundle>;
  gender?: Maybe<Gender>;
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Language;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  mlbbAccount?: Maybe<GameAccount>;
  nickname?: Maybe<Scalars['String']>;
  notifications: Array<Notification>;
  ongoingPartneringRequests: Array<PartneringRequest>;
  ongoingPilotServices: Array<PartneringRequest>;
  online: Scalars['Boolean'];
  partneringRequest?: Maybe<PartneringRequest>;
  partneringRequests: Array<PartneringRequest>;
  pendingPing?: Maybe<PartneringRequest>;
  pendingReward?: Maybe<PilotReward>;
  phoneNumber?: Maybe<Scalars['String']>;
  pilotAcceptedPartneringRequests: PilotPartneringRequestCount;
  pilotChatroom?: Maybe<Chatroom>;
  pilotCreditTransactions: PilotCreditTransactionCount;
  pilotGameBundles: PilotGameBundleCount;
  pilotRewards?: Maybe<Array<PilotReward>>;
  playerIdsProficientRoles?: Maybe<Array<ProficientRole>>;
  referralCode: Scalars['String'];
  rewardsCount: Scalars['Int'];
  signInCount: Scalars['Int'];
  streamer: Scalars['Boolean'];
  suspend: Scalars['Boolean'];
  suspendRequests?: Maybe<Array<SuspendRequest>>;
  team?: Maybe<Team>;
  teamRole?: Maybe<TeamRole>;
  totalRaterCount?: Maybe<Scalars['Int']>;
  trained: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
  userGameAccounts: Array<UserGameAccount>;
  userIdApple?: Maybe<Scalars['String']>;
  userIdGoogle?: Maybe<Scalars['String']>;
  userIdInstagram?: Maybe<Scalars['String']>;
  userIdTiktok?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};


/** Pilot */
export type PilotAvailableGameBundlesArgs = {
  gameId?: Maybe<Scalars['ID']>;
};


/** Pilot */
export type PilotChatroomArgs = {
  id: Scalars['ID'];
};


/** Pilot */
export type PilotGameAccountArgs = {
  gameId: Scalars['ID'];
};


/** Pilot */
export type PilotGameBundlesArgs = {
  states?: Maybe<Array<GameBundleState>>;
};


/** Pilot */
export type PilotNotificationsArgs = {
  gameId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Pilot */
export type PilotPartneringRequestArgs = {
  id: Scalars['ID'];
};


/** Pilot */
export type PilotPilotAcceptedPartneringRequestsArgs = {
  page?: Maybe<Scalars['Int']>;
};


/** Pilot */
export type PilotPilotChatroomArgs = {
  id: Scalars['ID'];
};


/** Pilot */
export type PilotPilotCreditTransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
};


/** Pilot */
export type PilotPilotGameBundlesArgs = {
  page?: Maybe<Scalars['Int']>;
};


/** Pilot */
export type PilotPilotRewardsArgs = {
  page?: Maybe<Scalars['Int']>;
};

/** Pilot Banner */
export type PilotBanner = {
  __typename?: 'PilotBanner';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  endTime?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  image: Scalars['String'];
  poster: Scalars['Boolean'];
  screen?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** Pilot Credit Transaction include counter */
export type PilotCreditTransactionCount = {
  __typename?: 'PilotCreditTransactionCount';
  count: Scalars['Int'];
  data: Array<CreditTransaction>;
};

/** Pilot Game bundles include counter */
export type PilotGameBundleCount = {
  __typename?: 'PilotGameBundleCount';
  count: Scalars['Int'];
  data: Array<GameBundle>;
};

/** Pilot Index */
export type PilotIndex = {
  __typename?: 'PilotIndex';
  adminNote?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bankInfo?: Maybe<BankInfo>;
  beKol: Scalars['Boolean'];
  bePro: Scalars['Boolean'];
  beStar: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Language;
  nickname?: Maybe<Scalars['String']>;
  online: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  signInCount: Scalars['Int'];
  streamer: Scalars['Boolean'];
  suspend: Scalars['Boolean'];
  trained: Scalars['Boolean'];
  verified: Scalars['Boolean'];
};

/** Pilot Level */
export type PilotLevel = {
  __typename?: 'PilotLevel';
  commission: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  maxCancelCount: Scalars['Int'];
  maxIdleCount: Scalars['Int'];
  maxMicOffCount: Scalars['Int'];
  maxRespondTime: Scalars['Float'];
  minRating: Scalars['Float'];
  minRealtimeCount: Scalars['Int'];
  minRealtimeHour: Scalars['Float'];
  minScheduleCount: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['Int'];
  rewards?: Maybe<Array<Reward>>;
  rewardValue: Scalars['Int'];
};

/** Pilot Partnering Request include counter */
export type PilotPartneringRequestCount = {
  __typename?: 'PilotPartneringRequestCount';
  count: Scalars['Int'];
  data: Array<PartneringRequest>;
};

/** Pilot Reward */
export type PilotReward = {
  __typename?: 'PilotReward';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  fullName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inGameId?: Maybe<Scalars['String']>;
  inGameName?: Maybe<Scalars['String']>;
  monthlyReport: MonthlyReport;
  pilot: Pilot;
  pilotLevel?: Maybe<PilotLevel>;
  remark?: Maybe<Scalars['String']>;
  reward?: Maybe<Reward>;
  state: PilotRewardState;
};

/** Pilot Rewards include counter */
export type PilotRewardsCount = {
  __typename?: 'PilotRewardsCount';
  count: Scalars['Int'];
  data: Array<PilotReward>;
};

export enum PilotRewardState {
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  PendingInfo = 'pending_info',
  PendingReward = 'pending_reward',
  Processing = 'processing'
}

/** Pilot Service */
export type PilotService = {
  __typename?: 'PilotService';
  commission: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  partneringRequestId: Scalars['ID'];
  pilot: SimpleUser;
  reason?: Maybe<Scalars['String']>;
  state: PilotServiceState;
};

export enum PilotServiceState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Pending = 'pending',
  Readied = 'readied',
  Rejected = 'rejected'
}

/** Pilot Tier */
export type PilotTier = {
  __typename?: 'PilotTier';
  aggregateRating: Scalars['Float'];
  completeGameBundlesCount: Scalars['Int'];
  completePartneringRequestsCount: Scalars['Int'];
  game: Game;
  gameAccount?: Maybe<GameAccount>;
  gameAccounts: Array<GameAccount>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pilots?: Maybe<Array<Pilot>>;
};


/** Pilot Tier */
export type PilotTierGameAccountArgs = {
  gameId: Scalars['ID'];
};

/** Pilot Tiers Game Account include counter */
export type PilotTiersCount = {
  __typename?: 'PilotTiersCount';
  count: Scalars['Int'];
  data: Array<GameAccountIndex>;
};

/** Pool Prize */
export type PoolPrize = {
  __typename?: 'PoolPrize';
  amount?: Maybe<Scalars['Float']>;
  combatTeam?: Maybe<CombatTeam>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leaderboard?: Maybe<Leaderboard>;
  maximumTeamCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  ordering: Scalars['Int'];
  template?: Maybe<Scalars['Boolean']>;
  tournament?: Maybe<Tournament>;
  tournamentTeam?: Maybe<TournamentTeam>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Post */
export type Post = {
  __typename?: 'Post';
  active?: Maybe<Scalars['Boolean']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  assetId?: Maybe<Scalars['ID']>;
  author?: Maybe<User>;
  body?: Maybe<Scalars['String']>;
  commentsCount: Scalars['Int'];
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  favCount: Scalars['Int'];
  faved: Scalars['Boolean'];
  followed: Scalars['Boolean'];
  id: Scalars['ID'];
  imageRatio?: Maybe<Scalars['Float']>;
  isYoutube?: Maybe<Scalars['Boolean']>;
  likeCount: Scalars['Int'];
  liked?: Maybe<Scalars['Boolean']>;
  matchMaking?: Maybe<MatchMaking>;
  merchandises?: Maybe<Array<Merchandise>>;
  newsImage?: Maybe<Scalars['String']>;
  ogData?: Maybe<OgData>;
  playbackId?: Maybe<Scalars['ID']>;
  postComments?: Maybe<Array<PostComment>>;
  postImages?: Maybe<Array<Scalars['String']>>;
  postType?: Maybe<Scalars['String']>;
  postVideos?: Maybe<Array<Scalars['String']>>;
  proTip?: Maybe<ProTip>;
  reported: Scalars['Boolean'];
  reportRequests: Array<ReportRequest>;
  reportRequestsCount?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Topic>;
  uploadId?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** Post Comment */
export type PostComment = {
  __typename?: 'PostComment';
  body: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  post: Post;
  postId: Scalars['ID'];
  postTitle?: Maybe<Scalars['String']>;
  user: User;
};

/** Posts include counter */
export type PostCount = {
  __typename?: 'PostCount';
  count: Scalars['Int'];
  data: Array<Post>;
};

export enum PostType {
  News = 'news',
  NewsContent = 'news_content'
}

/** Game Bundle Preview Amount */
export type PreviewAmount = {
  __typename?: 'PreviewAmount';
  priceBreakdown?: Maybe<Array<PriceBreakdown>>;
  totalAmount: Scalars['Float'];
};

export type PreviewAmountGameBundleInput = {
  currentGameRankId: Scalars['ID'];
  currentRankPoint: Scalars['String'];
  currentRankStar: Scalars['String'];
  currentRankTier: Scalars['String'];
  gameId: Scalars['ID'];
  pilotId: Scalars['ID'];
  promoCode?: Maybe<Scalars['String']>;
  targetGameRankId: Scalars['ID'];
  targetRankPoint: Scalars['String'];
  targetRankStar: Scalars['String'];
  targetRankTier: Scalars['String'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of PreviewAmountGameBundle */
export type PreviewAmountGameBundlePayload = {
  __typename?: 'PreviewAmountGameBundlePayload';
  amount?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<Error>>;
  inCooldown: Scalars['Boolean'];
  previewAmount?: Maybe<PreviewAmount>;
  promoCodeApply?: Maybe<PromoCodeApply>;
  unlockAt?: Maybe<Scalars['ISO8601DateTime']>;
  userCreditWallet?: Maybe<Scalars['Float']>;
};

/** Game Bundle Price Breakdown */
export type PriceBreakdown = {
  __typename?: 'PriceBreakdown';
  higherPoint?: Maybe<Scalars['Int']>;
  higherRank: Scalars['String'];
  higherStar?: Maybe<Scalars['Int']>;
  higherTier: Scalars['Int'];
  lowerPoint?: Maybe<Scalars['Int']>;
  lowerRank: Scalars['String'];
  lowerStar?: Maybe<Scalars['Int']>;
  lowerTier: Scalars['Int'];
  subTotal: Scalars['Float'];
};

/** Prize */
export type Prize = {
  __typename?: 'Prize';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  icon: Scalars['String'];
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  prizeName: Scalars['String'];
  prizeType?: Maybe<PrizeType>;
  value: Scalars['Float'];
};

/** Proficient role include counter */
export type PrizeCount = {
  __typename?: 'PrizeCount';
  count: Scalars['Int'];
  data: Array<Prize>;
};

export enum PrizeType {
  CustomJob = 'custom_job',
  EliteSkin = 'elite_skin',
  EpicSkin = 'epic_skin',
  FirstPrize = 'first_prize',
  GameCredit = 'game_credit',
  GameItem = 'game_item',
  GrandPrize = 'grand_prize',
  PromoCode = 'promo_code',
  SecondPrize = 'second_prize',
  ThirdPrize = 'third_prize',
  Tshirt = 'tshirt'
}

/** Proficient Hero */
export type ProficientHero = {
  __typename?: 'ProficientHero';
  game: Game;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  proficientRole: ProficientRole;
};

/** Proficient Role */
export type ProficientRole = {
  __typename?: 'ProficientRole';
  game: Game;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  kind?: Maybe<ProficientRoleKind>;
  name: Scalars['String'];
  proficientHeros?: Maybe<Array<ProficientHero>>;
  selectedImageUrl?: Maybe<Scalars['String']>;
};

/** Proficient role include counter */
export type ProficientRoleCount = {
  __typename?: 'ProficientRoleCount';
  count: Scalars['Int'];
  data: Array<ProficientRole>;
};

export enum ProficientRoleKind {
  HeroRole = 'hero_role',
  Lane = 'lane',
  Perspective = 'perspective',
  Queue = 'queue'
}

/** Promo Code */
export type PromoCode = {
  __typename?: 'PromoCode';
  active: Scalars['Boolean'];
  amount: Scalars['Int'];
  availableFor: Scalars['String'];
  code: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  gameType: Scalars['String'];
  id: Scalars['ID'];
  minimumAmount: Scalars['Int'];
  prosOnly?: Maybe<Scalars['Boolean']>;
  redeemLimit?: Maybe<Scalars['Int']>;
  specifiedClub?: Maybe<Club>;
  specifiedTier?: Maybe<PilotTier>;
  userIds?: Maybe<Array<Scalars['String']>>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  whitelistsOnly?: Maybe<Scalars['Boolean']>;
};

/** Promo Code Apply */
export type PromoCodeApply = {
  __typename?: 'PromoCodeApply';
  amount?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Promo codes include counter */
export type PromoCodeCount = {
  __typename?: 'PromoCodeCount';
  count: Scalars['Int'];
  data: Array<PromoCode>;
};

export type ProTip = {
  __typename?: 'ProTip';
  id: Scalars['ID'];
  image: Scalars['String'];
  prosOnly: Scalars['Boolean'];
  text: Scalars['String'];
  video: Scalars['String'];
};

/** Publisher */
export type Publisher = {
  __typename?: 'Publisher';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activeUsersAnalytic?: Maybe<ActiveUsersAnalytic>;
  adminLog?: Maybe<AdminLog>;
  adminLogs?: Maybe<AdminLogsCount>;
  adminLogsCsv?: Maybe<Scalars['String']>;
  adminTransferLogs?: Maybe<AdminLogsCount>;
  analytics?: Maybe<AdminAnalytic>;
  bankVerifications?: Maybe<GameAccountCount>;
  booking: Booking;
  caiLeaderboards?: Maybe<Array<CaiLeaderboard>>;
  careerHighlight: CareerHighlight;
  categories?: Maybe<Array<Category>>;
  category: Category;
  checkLocation: CheckLocation;
  clan: Clan;
  clanAccounts: ClansCount;
  club: Club;
  clubCounts?: Maybe<ClubCounts>;
  clubMemberAccounts?: Maybe<ClubsCount>;
  clubs: Array<Club>;
  combatRoom: CombatRoom;
  combatRoomAccounts?: Maybe<CombatRoomsCount>;
  combatRoomAccountsCsv?: Maybe<Scalars['String']>;
  combatRooms: Array<CombatRoom>;
  combatTeam: CombatTeam;
  creditTransactionAccounts?: Maybe<CreditTransactionsCount>;
  creditTransactionCsv?: Maybe<Scalars['String']>;
  creditTransactions: Array<CreditTransaction>;
  creditWallet: CreditWallet;
  faq: Faq;
  faqAccounts: FaqCount;
  game: Game;
  gameAccount?: Maybe<GameAccount>;
  gameAccounts?: Maybe<GameAccountCount>;
  gameBundle: AdminGameBundle;
  gameBundles?: Maybe<Array<AdminGameBundle>>;
  gameBundlesIndex?: Maybe<GameBundleCount>;
  gameChatrooms?: Maybe<Array<GameChatroom>>;
  gameCountAccounts: GameCount;
  gameMessageAccounts?: Maybe<GameMessageCount>;
  gameRank?: Maybe<GameRank>;
  gamerBanner: Banner;
  gamerBanners?: Maybe<GamerBannerCount>;
  games?: Maybe<Array<Game>>;
  interest: Interest;
  interestAccounts: InterestsCount;
  leaderboard?: Maybe<CombatTeam>;
  leaderboardAccounts?: Maybe<LeaderboardCount>;
  leaderboardCsv?: Maybe<Scalars['String']>;
  leaderboardPoolPrize: Leaderboard;
  leaderboardPoolPrizeAccounts: LeaderboardPoolPrizeCount;
  leaderboards: Array<Leaderboard>;
  listOfProducts: Array<VocaProduct>;
  manager?: Maybe<Manager>;
  markupPricing: MarkupPricing;
  markupPricingAccounts: MarkupPricingCount;
  matchMaking: MatchMaking;
  matchMakingAccounts: MatchMakingCount;
  matchResult?: Maybe<MatchResult>;
  me?: Maybe<Admin>;
  merchandise: Merchandise;
  merchandiseAccounts: MerchandiseCount;
  merchandiseVariant: MerchandiseVariant;
  monthlyReportHistories?: Maybe<MonthlyReportHistoriesCount>;
  monthlyReports?: Maybe<MonthlyReportsCount>;
  nextLevelCount: NextLevelCount;
  officialChannel: OfficialChannel;
  officialChannels: Array<OfficialChannel>;
  order: Order;
  orderAccounts: OrderCount;
  paginatedPosts: PostCount;
  partneringRequest: AdminPartneringRequest;
  partneringRequests?: Maybe<Array<AdminPartneringRequest>>;
  partneringRequestsIndex?: Maybe<PartneringRequestCount>;
  payout: Payout;
  payouts?: Maybe<PayoutCount>;
  payoutsCsv?: Maybe<Scalars['String']>;
  pilot?: Maybe<Pilot>;
  pilotBanner: PilotBanner;
  pilotBanners: Array<PilotBanner>;
  pilotLevel?: Maybe<PilotLevel>;
  pilotLevels: Array<PilotLevel>;
  pilotReward?: Maybe<PilotReward>;
  pilotRewardAccounts?: Maybe<PilotRewardsCount>;
  pilotsCsv?: Maybe<Scalars['String']>;
  pilotTier?: Maybe<PilotTier>;
  pilotTierAccountCsv?: Maybe<Scalars['String']>;
  pilotTierAccounts?: Maybe<PilotTiersCount>;
  pilotTiers: Array<PilotTier>;
  poolPrize: PoolPrize;
  post: Post;
  posts: Array<Post>;
  prize: Prize;
  prizeAccounts: PrizeCount;
  prizes: Array<Prize>;
  proficientRole: ProficientRole;
  proficientRoleAccounts: ProficientRoleCount;
  promoCode: PromoCode;
  promoCodes?: Maybe<PromoCodeCount>;
  publisher: Publisher;
  publishers?: Maybe<Array<Publisher>>;
  rejectReasons: Array<RejectReason>;
  reportRequest: ReportRequest;
  reportRequestAccounts: ReportRequestCount;
  scrim?: Maybe<Scrim>;
  scrimAccounts?: Maybe<ScrimsCount>;
  searchGameRanks?: Maybe<Array<GameRank>>;
  searchPilots?: Maybe<Array<GameAccount>>;
  searchPoolPrizes?: Maybe<Array<PoolPrize>>;
  searchPoolPrizeTeams?: Maybe<Array<CombatTeam>>;
  sponsorAd: SponsorAd;
  sponsorAdAccounts: SponsorAdCount;
  starPlayerAccounts: StarPlayerCount;
  starTeamAccounts: StarTeamsCount;
  suspendGamerRequest: SuspendGamerRequest;
  suspendRequests: SuspendRequest;
  team?: Maybe<Team>;
  teamAccounts?: Maybe<TeamCount>;
  teamBankInfo?: Maybe<BankInfo>;
  teamBankInfoAccounts?: Maybe<TeamBankInfoCount>;
  teamInvitation?: Maybe<TeamInvitation>;
  teamPayout?: Maybe<TeamPayout>;
  teamPayoutAccounts?: Maybe<TeamPayoutCount>;
  teamPayouts?: Maybe<PayoutCount>;
  teamPayoutsCsv?: Maybe<Scalars['String']>;
  teamsCsv?: Maybe<Scalars['String']>;
  topUpProduct: TopupProduct;
  topUpProductAccounts: TopUpProductCount;
  topUpProductItem: TopupProductItem;
  topUpProductItems: Array<TopupProductItem>;
  totalAdminLogs: Scalars['Int'];
  tournament: Tournament;
  tournamentAccounts: TournamentCount;
  tournamentEvent: TournamentEvent;
  tournaments?: Maybe<Array<Tournament>>;
  tournamentTeam: TournamentTeam;
  tournamentTeamAccounts?: Maybe<TournamentTeamCount>;
  tournamentTeamsCsv?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userBlastSchedule: UserBlastSchedule;
  userBlastScheduleAccounts?: Maybe<UserBlastScheduleCount>;
  userPrize: UserPrize;
  userPrizes?: Maybe<UserPrizeCount>;
  userPromoCode?: Maybe<UserPromoCode>;
  userPromoCodes?: Maybe<UserPromoCodeCount>;
  users?: Maybe<UserCount>;
  usersCsv?: Maybe<Scalars['String']>;
  vendor: Vendor;
  vendors?: Maybe<Array<Vendor>>;
};


export type QueryAdminLogArgs = {
  id: Scalars['ID'];
};


export type QueryAdminLogsArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryAdminTransferLogsArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryBankVerificationsArgs = {
  bankInfoState?: Maybe<BankInfoState>;
  gameName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  suspended?: Maybe<Scalars['Boolean']>;
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
};


export type QueryCaiLeaderboardsArgs = {
  fromDate?: Maybe<Scalars['ISO8601DateTime']>;
  toDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryCareerHighlightArgs = {
  id: Scalars['ID'];
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryCheckLocationArgs = {
  ipAddress: Scalars['String'];
};


export type QueryClanArgs = {
  id: Scalars['ID'];
};


export type QueryClanAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryClubArgs = {
  id: Scalars['ID'];
};


export type QueryClubCountsArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryClubMemberAccountsArgs = {
  clubId: Scalars['ID'];
  gameId: Scalars['String'];
  gameName?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
};


export type QueryCombatRoomArgs = {
  id: Scalars['ID'];
};


export type QueryCombatRoomAccountsArgs = {
  leaderboardId?: Maybe<Scalars['ID']>;
  month?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryCombatRoomAccountsCsvArgs = {
  fromDate?: Maybe<Scalars['ISO8601DateTime']>;
  toDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryCombatTeamArgs = {
  id: Scalars['ID'];
};


export type QueryCreditTransactionAccountsArgs = {
  category?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type QueryCreditTransactionsArgs = {
  type: Transaction;
};


export type QueryCreditWalletArgs = {
  id: Scalars['ID'];
};


export type QueryFaqArgs = {
  id: Scalars['ID'];
};


export type QueryFaqAccountsArgs = {
  categoryColumns?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryGameArgs = {
  id: Scalars['ID'];
};


export type QueryGameAccountArgs = {
  id: Scalars['ID'];
};


export type QueryGameAccountsArgs = {
  approve?: Maybe<Scalars['Boolean']>;
  bankInfoState?: Maybe<BankInfoState>;
  filterWhere?: Maybe<FilterQuery>;
  gameName?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  rankName?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  streamer?: Maybe<Scalars['Boolean']>;
  suspended?: Maybe<Scalars['Boolean']>;
  trained?: Maybe<Scalars['Boolean']>;
};


export type QueryGameBundleArgs = {
  id: Scalars['ID'];
};


export type QueryGameBundlesArgs = {
  state?: Maybe<GameBundleState>;
};


export type QueryGameBundlesIndexArgs = {
  filterWhere?: Maybe<FilterPartneringQuery>;
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  suspicious?: Maybe<Scalars['Boolean']>;
};


export type QueryGameCountAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryGameMessageAccountsArgs = {
  gameChatroomId: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryGameRankArgs = {
  id: Scalars['ID'];
};


export type QueryGamerBannerArgs = {
  id: Scalars['ID'];
};


export type QueryGamerBannersArgs = {
  active?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryInterestArgs = {
  id: Scalars['ID'];
};


export type QueryInterestAccountsArgs = {
  active?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryLeaderboardArgs = {
  id: Scalars['ID'];
};


export type QueryLeaderboardAccountsArgs = {
  isCombatTeam?: Maybe<Scalars['Boolean']>;
  leaderboardId?: Maybe<Scalars['ID']>;
  month?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryLeaderboardCsvArgs = {
  id: Scalars['ID'];
};


export type QueryLeaderboardPoolPrizeArgs = {
  id: Scalars['ID'];
};


export type QueryLeaderboardPoolPrizeAccountsArgs = {
  gameId: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryListOfProductsArgs = {
  searchString?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
};


export type QueryManagerArgs = {
  id: Scalars['ID'];
};


export type QueryMarkupPricingArgs = {
  id: Scalars['ID'];
};


export type QueryMarkupPricingAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryMatchMakingArgs = {
  id: Scalars['ID'];
};


export type QueryMatchMakingAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryMatchResultArgs = {
  id: Scalars['ID'];
};


export type QueryMerchandiseArgs = {
  id: Scalars['ID'];
};


export type QueryMerchandiseAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryMerchandiseVariantArgs = {
  id: Scalars['ID'];
};


export type QueryMonthlyReportHistoriesArgs = {
  level?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  months?: Maybe<Month>;
  page?: Maybe<Scalars['Int']>;
  searchString: Scalars['String'];
  year?: Maybe<Scalars['String']>;
};


export type QueryMonthlyReportsArgs = {
  level?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryOfficialChannelArgs = {
  id: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryPaginatedPostsArgs = {
  page?: Maybe<Scalars['Int']>;
  scope?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryPartneringRequestArgs = {
  id: Scalars['ID'];
};


export type QueryPartneringRequestsArgs = {
  state?: Maybe<PartneringRequestState>;
};


export type QueryPartneringRequestsIndexArgs = {
  filterWhere?: Maybe<FilterPartneringQuery>;
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  suspicious?: Maybe<Scalars['Boolean']>;
};


export type QueryPayoutArgs = {
  id: Scalars['ID'];
};


export type QueryPayoutsArgs = {
  adminNote?: Maybe<Scalars['String']>;
  approve?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryPilotArgs = {
  id: Scalars['ID'];
};


export type QueryPilotBannerArgs = {
  id: Scalars['ID'];
};


export type QueryPilotLevelArgs = {
  id: Scalars['ID'];
};


export type QueryPilotRewardArgs = {
  id: Scalars['ID'];
};


export type QueryPilotRewardAccountsArgs = {
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
};


export type QueryPilotTierArgs = {
  id: Scalars['ID'];
};


export type QueryPilotTierAccountsArgs = {
  gameId: Scalars['String'];
  gameName?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  pilotTierId: Scalars['ID'];
  searchString?: Maybe<Scalars['String']>;
};


export type QueryPoolPrizeArgs = {
  id: Scalars['ID'];
};


export type QueryPostArgs = {
  id: Scalars['ID'];
};


export type QueryPrizeArgs = {
  id: Scalars['ID'];
};


export type QueryPrizeAccountsArgs = {
  active?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


export type QueryProficientRoleArgs = {
  id: Scalars['ID'];
};


export type QueryProficientRoleAccountsArgs = {
  gameName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryPromoCodeArgs = {
  id: Scalars['ID'];
};


export type QueryPromoCodesArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type QueryPublisherArgs = {
  id: Scalars['ID'];
};


export type QueryRejectReasonsArgs = {
  kind: RejectReasonKind;
};


export type QueryReportRequestArgs = {
  id: Scalars['ID'];
};


export type QueryReportRequestAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryScrimArgs = {
  id: Scalars['ID'];
};


export type QueryScrimAccountsArgs = {
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type QuerySearchGameRanksArgs = {
  gameId: Scalars['String'];
};


export type QuerySearchPilotsArgs = {
  gameId: Scalars['String'];
  searchString: Scalars['String'];
};


export type QuerySearchPoolPrizeTeamsArgs = {
  leaderboardId?: Maybe<Scalars['String']>;
};


export type QuerySponsorAdArgs = {
  id: Scalars['ID'];
};


export type QuerySponsorAdAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryStarPlayerAccountsArgs = {
  countryCode?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['String']>;
};


export type QueryStarTeamAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['String']>;
};


export type QuerySuspendGamerRequestArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySuspendRequestsArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamAccountsArgs = {
  lanePlayer?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  teamMember?: Maybe<Scalars['String']>;
  totalMatch?: Maybe<Scalars['String']>;
};


export type QueryTeamBankInfoArgs = {
  id: Scalars['ID'];
};


export type QueryTeamBankInfoAccountsArgs = {
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryTeamInvitationArgs = {
  id: Scalars['ID'];
};


export type QueryTeamPayoutArgs = {
  id: Scalars['ID'];
};


export type QueryTeamPayoutAccountsArgs = {
  adminNote?: Maybe<Scalars['String']>;
  approve?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryTeamPayoutsArgs = {
  adminNote?: Maybe<Scalars['String']>;
  approve?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryTopUpProductArgs = {
  id: Scalars['ID'];
};


export type QueryTopUpProductAccountsArgs = {
  categoryId?: Maybe<Scalars['ID']>;
  featured?: Maybe<Scalars['String']>;
  genre?: Maybe<GameGenre>;
  isActive?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  publisherId?: Maybe<Scalars['ID']>;
  searchString?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['ID']>;
};


export type QueryTopUpProductItemArgs = {
  id: Scalars['ID'];
  productId: Scalars['ID'];
  vendorName: Scalars['String'];
};


export type QueryTopUpProductItemsArgs = {
  productId?: Maybe<Scalars['ID']>;
  searchString?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
};


export type QueryTournamentArgs = {
  id: Scalars['ID'];
};


export type QueryTournamentAccountsArgs = {
  gameId: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryTournamentEventArgs = {
  id: Scalars['ID'];
};


export type QueryTournamentTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTournamentTeamAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryTournamentTeamsCsvArgs = {
  tournamentId: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserBlastScheduleArgs = {
  id: Scalars['ID'];
};


export type QueryUserBlastScheduleAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryUserPrizeArgs = {
  id: Scalars['ID'];
};


export type QueryUserPrizesArgs = {
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryUserPromoCodeArgs = {
  id: Scalars['ID'];
};


export type QueryUserPromoCodesArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  adminNote?: Maybe<Scalars['String']>;
  filterWhere?: Maybe<FilterQuery>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  suspended?: Maybe<Scalars['Boolean']>;
};


export type QueryVendorArgs = {
  id: Scalars['ID'];
};

/** Referrer */
export type Referrer = {
  __typename?: 'Referrer';
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type RefundBookingInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RefundBooking */
export type RefundBookingPayload = {
  __typename?: 'RefundBookingPayload';
  booking?: Maybe<Booking>;
  errors?: Maybe<Array<Error>>;
};

export type RefundBundleInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RefundBundle */
export type RefundBundlePayload = {
  __typename?: 'RefundBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type RefundPartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RefundPartneringRequest */
export type RefundPartneringRequestPayload = {
  __typename?: 'RefundPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

export type RejectBankInfoInput = {
  pilotId: Scalars['ID'];
  rejectReason: Scalars['String'];
};

/** Autogenerated return type of RejectBankInfo */
export type RejectBankInfoPayload = {
  __typename?: 'RejectBankInfoPayload';
  bankInfo?: Maybe<BankInfo>;
  errors?: Maybe<Array<Error>>;
};

export type RejectInput = {
  id: Scalars['ID'];
  rejectReason: Scalars['String'];
};

/** Autogenerated return type of Reject */
export type RejectPayload = {
  __typename?: 'RejectPayload';
  errors?: Maybe<Array<Error>>;
  payout?: Maybe<Payout>;
};

/** Reject Reason */
export type RejectReason = {
  __typename?: 'RejectReason';
  id: Scalars['ID'];
  kind: RejectReasonKind;
  text: Scalars['String'];
};

export enum RejectReasonKind {
  BankInfo = 'bank_info',
  Code = 'code',
  GameBundle = 'game_bundle',
  PartneringRequest = 'partnering_request',
  PilotTest = 'pilot_test',
  Screenshot = 'screenshot',
  Suspend = 'suspend'
}

export type RejectScreenshotInput = {
  id: Scalars['ID'];
  rejectReason: Scalars['String'];
};

/** Autogenerated return type of RejectScreenshot */
export type RejectScreenshotPayload = {
  __typename?: 'RejectScreenshotPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type RejectSuspendGamerRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RejectSuspendGamerRequest */
export type RejectSuspendGamerRequestPayload = {
  __typename?: 'RejectSuspendGamerRequestPayload';
  errors?: Maybe<Array<Error>>;
  suspendRequest?: Maybe<SuspendGamerRequest>;
};

export type RejectSuspendRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RejectSuspendRequest */
export type RejectSuspendRequestPayload = {
  __typename?: 'RejectSuspendRequestPayload';
  errors?: Maybe<Array<Error>>;
  suspendRequest?: Maybe<SuspendRequest>;
};

export type RejectTeamInvitationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RejectTeamInvitation */
export type RejectTeamInvitationPayload = {
  __typename?: 'RejectTeamInvitationPayload';
  errors?: Maybe<Array<Error>>;
  teamInvitation?: Maybe<TeamInvitation>;
};

export type RejectVerificationCodeInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RejectVerificationCode */
export type RejectVerificationCodePayload = {
  __typename?: 'RejectVerificationCodePayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type RemindPilotTestInput = {
  gameAccountId: Scalars['ID'];
};

/** Autogenerated return type of RemindPilotTest */
export type RemindPilotTestPayload = {
  __typename?: 'RemindPilotTestPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type RemoveClubMemberInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveClubMember */
export type RemoveClubMemberPayload = {
  __typename?: 'RemoveClubMemberPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type RemoveCreditTransactionInput = {
  creditTransactionId: Scalars['ID'];
};

/** Autogenerated return type of RemoveCreditTransaction */
export type RemoveCreditTransactionPayload = {
  __typename?: 'RemoveCreditTransactionPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RemoveGameRankInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveGameRank */
export type RemoveGameRankPayload = {
  __typename?: 'RemoveGameRankPayload';
  errors?: Maybe<Array<Error>>;
  gameRank?: Maybe<GameRank>;
};

export type RemoveMerchandiseVariantsInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveMerchandiseVariants */
export type RemoveMerchandiseVariantsPayload = {
  __typename?: 'RemoveMerchandiseVariantsPayload';
  errors?: Maybe<Array<Error>>;
  merchandiseVariants?: Maybe<MerchandiseVariant>;
};

export type RemovePilotCreditTransactionInput = {
  creditTransactionId: Scalars['ID'];
};

/** Autogenerated return type of RemovePilotCreditTransaction */
export type RemovePilotCreditTransactionPayload = {
  __typename?: 'RemovePilotCreditTransactionPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RemovePilotTierInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RemovePilotTier */
export type RemovePilotTierPayload = {
  __typename?: 'RemovePilotTierPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type RemoveTeamMemberInput = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of RemoveTeamMember */
export type RemoveTeamMemberPayload = {
  __typename?: 'RemoveTeamMemberPayload';
  errors?: Maybe<Array<Error>>;
  team?: Maybe<Team>;
};

export type ReportGameBundleInput = {
  id: Scalars['ID'];
  reportReason: Scalars['String'];
};

/** Autogenerated return type of ReportGameBundle */
export type ReportGameBundlePayload = {
  __typename?: 'ReportGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type ReportPartneringRequestInput = {
  id: Scalars['ID'];
  reportReason: Scalars['String'];
};

/** Autogenerated return type of ReportPartneringRequest */
export type ReportPartneringRequestPayload = {
  __typename?: 'ReportPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

/** Report Request */
export type ReportRequest = {
  __typename?: 'ReportRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  post?: Maybe<Post>;
  reason: Scalars['String'];
  state: ReportRequestState;
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Report request include counter */
export type ReportRequestCount = {
  __typename?: 'ReportRequestCount';
  count: Scalars['Int'];
  data: Array<Post>;
};

export enum ReportRequestState {
  Approved = 'approved',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type RescheduleBlastInput = {
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of RescheduleBlast */
export type RescheduleBlastPayload = {
  __typename?: 'RescheduleBlastPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ReviewBookingInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ReviewBooking */
export type ReviewBookingPayload = {
  __typename?: 'ReviewBookingPayload';
  booking?: Maybe<Booking>;
  errors?: Maybe<Array<Error>>;
};

export type ReviewBundleInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ReviewBundle */
export type ReviewBundlePayload = {
  __typename?: 'ReviewBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type ReviewPartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ReviewPartneringRequest */
export type ReviewPartneringRequestPayload = {
  __typename?: 'ReviewPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

export type RevokeSuspendCurrentCombatTeamInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RevokeSuspendCurrentCombatTeam */
export type RevokeSuspendCurrentCombatTeamPayload = {
  __typename?: 'RevokeSuspendCurrentCombatTeamPayload';
  combatTeam?: Maybe<CombatTeam>;
  errors?: Maybe<Array<Error>>;
};

export type RevokeTeamSuspensionInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RevokeTeamSuspension */
export type RevokeTeamSuspensionPayload = {
  __typename?: 'RevokeTeamSuspensionPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Reward */
export type Reward = {
  __typename?: 'Reward';
  id: Scalars['ID'];
  name: Scalars['String'];
  pilotLevel: PilotLevel;
  rewardType: RewardType;
};

export enum RewardType {
  Cash = 'cash',
  GameCredit = 'game_credit',
  GameItem = 'game_item',
  PromoCode = 'promo_code'
}

export enum ScreenshotState {
  PendingScreenshotResubmission = 'pending_screenshot_resubmission',
  ScreenshotApproved = 'screenshot_approved',
  ScreenshotSubmitted = 'screenshot_submitted',
  ScreenshotUnverified = 'screenshot_unverified'
}

export type Scrim = {
  __typename?: 'Scrim';
  chatroom?: Maybe<Chatroom>;
  completeTime?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  guest?: Maybe<Team>;
  guestPlayers: Array<Pilot>;
  host: Team;
  hostPlayers: Array<Pilot>;
  id: Scalars['ID'];
  matchCount?: Maybe<Scalars['Int']>;
  matchResults: Array<MatchResult>;
  realtime: Scalars['Boolean'];
  roles: Array<Scalars['String']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: ScrimState;
};

/** Scrims include counter */
export type ScrimsCount = {
  __typename?: 'ScrimsCount';
  count: Scalars['Int'];
  data: Array<Scrim>;
};

export enum ScrimState {
  Available = 'available',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmCompleted = 'confirm_completed',
  ConfirmStarted = 'confirm_started',
  Grabbed = 'grabbed',
  Matched = 'matched',
  Reported = 'reported',
  Reviewed = 'reviewed',
  Started = 'started'
}

export type SetPartneringRateInput = {
  id: Scalars['ID'];
  partneringPenaltyRate: Scalars['Int'];
  partneringRate: Scalars['Int'];
};

/** Autogenerated return type of SetPartneringRate */
export type SetPartneringRatePayload = {
  __typename?: 'SetPartneringRatePayload';
  errors?: Maybe<Array<Error>>;
  game?: Maybe<Game>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  addressName: Scalars['String'];
  country: Scalars['String'];
  defaultAddress: Scalars['Boolean'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  orders: Array<Order>;
  postalCode: Scalars['String'];
  recipientName?: Maybe<Scalars['String']>;
  recipientPhoneNumber?: Maybe<Scalars['String']>;
  state: ShippingAddressState;
  town: Scalars['String'];
  user: User;
};

export enum ShippingAddressState {
  Johor = 'Johor',
  Kedah = 'Kedah',
  Kelantan = 'Kelantan',
  /** Kuala Lumpur */
  KualaLumpur = 'Kuala_Lumpur',
  Labuan = 'Labuan',
  Melaka = 'Melaka',
  /** Negeri Sembilan */
  NegeriSembilan = 'Negeri_Sembilan',
  Pahang = 'Pahang',
  Penang = 'Penang',
  Perak = 'Perak',
  Perlis = 'Perlis',
  Putrajaya = 'Putrajaya',
  Sabah = 'Sabah',
  Sarawak = 'Sarawak',
  Selangor = 'Selangor',
  Terengganu = 'Terengganu'
}

/** Simple Game Account */
export type SimpleGameAccount = {
  __typename?: 'SimpleGameAccount';
  accountId: Scalars['String'];
  club?: Maybe<Club>;
  gameRank: SimpleGameRank;
  id: Scalars['ID'];
  kd?: Maybe<Scalars['Float']>;
  nickname: Scalars['String'];
  proficientLanes?: Maybe<Array<ProficientRole>>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  winRate?: Maybe<Scalars['Float']>;
};

/** Simple Game Rank */
export type SimpleGameRank = {
  __typename?: 'SimpleGameRank';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Simple User */
export type SimpleUser = {
  __typename?: 'SimpleUser';
  _id: Scalars['ID'];
  aggregateRating?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  averageRespondTime?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  completeGameBundlesCount?: Maybe<Scalars['Int']>;
  completePartneringRequestsCount?: Maybe<Scalars['Int']>;
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Language;
  mlbbAccount?: Maybe<GameAccount>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  suspend: Scalars['Boolean'];
  suspendGamerRequests?: Maybe<Array<SuspendGamerRequest>>;
  totalRaterCount?: Maybe<Scalars['Int']>;
  userPrizes?: Maybe<Array<UserPrize>>;
  verified: Scalars['Boolean'];
};

/** Sponsor Ads */
export type SponsorAd = {
  __typename?: 'SponsorAd';
  active: Scalars['Boolean'];
  adClicks?: Maybe<Array<AdClick>>;
  adClicksCount?: Maybe<Scalars['Int']>;
  adsName: Scalars['String'];
  assetType: SponsorAdAsset;
  createdAt: Scalars['ISO8601DateTime'];
  gamerBanner?: Maybe<GamerBanner>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  layoutType: SponsorAdLayout;
  position: Scalars['Int'];
  reaches?: Maybe<Array<User>>;
  reachesCount?: Maybe<Scalars['Int']>;
  screenRoute?: Maybe<Scalars['String']>;
  sponsorName: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  url: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
};

export enum SponsorAdAsset {
  Image = 'image',
  Video = 'video'
}

/** Sponsor Ad include counter */
export type SponsorAdCount = {
  __typename?: 'SponsorAdCount';
  count: Scalars['Int'];
  data: Array<SponsorAd>;
};

export enum SponsorAdLayout {
  Banner = 'banner',
  Poster = 'poster'
}

/** Star Player include counter */
export type StarPlayerCount = {
  __typename?: 'StarPlayerCount';
  count: Scalars['Int'];
  data: Array<User>;
};

/** Club include counter */
export type StarTeamsCount = {
  __typename?: 'StarTeamsCount';
  count: Scalars['Int'];
  data: Array<Club>;
};

export type SuspendGamerRequest = {
  __typename?: 'SuspendGamerRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  state: SuspendRequestState;
};

export type SuspendRequest = {
  __typename?: 'SuspendRequest';
  admin: Admin;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  state: SuspendRequestState;
};

export enum SuspendRequestState {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected'
}

/** Target */
export type Target = GameBundle | PartneringRequest;

export type Team = {
  __typename?: 'Team';
  allChatrooms: Array<Chatroom>;
  allCombatRooms: TeamCombatRoomCount;
  allCombatRoomSize: Scalars['Int'];
  allScrims: Array<Scrim>;
  availableRealtimes: Array<Scrim>;
  availableSchedules: Array<Scrim>;
  availableTimeValid: Scalars['Boolean'];
  bankInfo?: Maybe<BankInfo>;
  careerHighlights?: Maybe<Array<CareerHighlight>>;
  chatroomId: Scalars['ID'];
  club?: Maybe<Club>;
  clubId: Scalars['ID'];
  combatStartTime: Scalars['ISO8601DateTime'];
  combatTeams?: Maybe<Array<CombatTeam>>;
  country: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  currentCombat?: Maybe<CombatTeam>;
  currentCombatId?: Maybe<Scalars['ID']>;
  currentCombatRoom?: Maybe<CombatRoom>;
  deleted: Scalars['Boolean'];
  founded?: Maybe<Scalars['ISO8601DateTime']>;
  game: Game;
  gameId: Scalars['ID'];
  guestScrims?: Maybe<Array<Scrim>>;
  hostScrims?: Maybe<Array<Scrim>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isManager: Scalars['Boolean'];
  isPlayer: Scalars['Boolean'];
  joinRequests: Array<JoinRequest>;
  lastCombatTeamScore: Scalars['Int'];
  lastMessage?: Maybe<Message>;
  location?: Maybe<Scalars['String']>;
  loseMatches?: Maybe<Array<MatchResult>>;
  manager: User;
  matchCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  onlineCount: Scalars['Int'];
  overview?: Maybe<Scalars['String']>;
  paginatedHistoryLeaderboard: Array<CombatRoom>;
  paginatedRealtimes: Array<Scrim>;
  paginatedSchedules: Array<Scrim>;
  pendingInvitationId?: Maybe<Scalars['ID']>;
  pendingRequestId?: Maybe<Scalars['ID']>;
  pilots: Array<Pilot>;
  reason?: Maybe<Scalars['String']>;
  recentCombats?: Maybe<Array<CombatRoom>>;
  registeredTournament: Scalars['Boolean'];
  scrim?: Maybe<Scrim>;
  scrimable: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  suspend?: Maybe<Scalars['Boolean']>;
  teamChatroom: Chatroom;
  teamCombatTeams?: Maybe<Array<CombatTeam>>;
  teamInvitations: Array<TeamInvitation>;
  teamMembers: Scalars['Int'];
  teamRolePlayers: Array<User>;
  teamRoles: Array<TeamRole>;
  teamWallet?: Maybe<TeamWallet>;
  themeColor?: Maybe<Scalars['String']>;
  themeColors?: Maybe<Array<Scalars['String']>>;
  tier?: Maybe<Scalars['String']>;
  tournamentTeam?: Maybe<TournamentTeam>;
  tournamentTeams?: Maybe<Array<TournamentTeam>>;
  town?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  users: Array<User>;
  usersCount: Scalars['Int'];
  verified: Scalars['Boolean'];
  winCount?: Maybe<Scalars['Int']>;
  winMatches?: Maybe<Array<MatchResult>>;
};


export type TeamAllCombatRoomsArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type TeamPaginatedHistoryLeaderboardArgs = {
  leaderboardId?: Maybe<Scalars['ID']>;
};


export type TeamPaginatedRealtimesArgs = {
  page?: Maybe<Scalars['Int']>;
  states: Array<ScrimState>;
};


export type TeamPaginatedSchedulesArgs = {
  page?: Maybe<Scalars['Int']>;
  states: Array<ScrimState>;
};


export type TeamScrimArgs = {
  scrimId: Scalars['ID'];
};


export type TeamUsersArgs = {
  all?: Maybe<Scalars['Boolean']>;
  searchUser?: Maybe<Scalars['String']>;
};

export type TeamApproveInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of TeamApprove */
export type TeamApprovePayload = {
  __typename?: 'TeamApprovePayload';
  errors?: Maybe<Array<Error>>;
  teamPayout?: Maybe<TeamPayout>;
};

/** Team Bank Info include counter */
export type TeamBankInfoCount = {
  __typename?: 'TeamBankInfoCount';
  count: Scalars['Int'];
  data: Array<BankInfo>;
};

/** Team Combat Rooms include counter */
export type TeamCombatRoomCount = {
  __typename?: 'TeamCombatRoomCount';
  count: Scalars['Int'];
  data: Array<CombatRoom>;
};

export type TeamCompleteInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of TeamComplete */
export type TeamCompletePayload = {
  __typename?: 'TeamCompletePayload';
  errors?: Maybe<Array<Error>>;
  teamPayout?: Maybe<TeamPayout>;
};

/** Teams include counter */
export type TeamCount = {
  __typename?: 'TeamCount';
  count: Scalars['Int'];
  data: Array<Team>;
};

export type TeamFailedInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of TeamFailed */
export type TeamFailedPayload = {
  __typename?: 'TeamFailedPayload';
  errors?: Maybe<Array<Error>>;
  teamPayout?: Maybe<TeamPayout>;
};

export type TeamInvitation = {
  __typename?: 'TeamInvitation';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  pilot?: Maybe<Pilot>;
  state: TeamInvitationState;
  team: Team;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export enum TeamInvitationState {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Rejected = 'rejected'
}

/** TeamPayout */
export type TeamPayout = {
  __typename?: 'TeamPayout';
  adminNote?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  approve: Scalars['Boolean'];
  bank: Scalars['String'];
  bankAccountNo: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ic: Scalars['String'];
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  pilot: Pilot;
  recipient?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  state: TeamPayoutState;
  teamWallet: TeamWallet;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Team Payout include counter */
export type TeamPayoutCount = {
  __typename?: 'TeamPayoutCount';
  count: Scalars['Int'];
  data: Array<TeamPayout>;
};

export enum TeamPayoutState {
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected',
  TransactionFailed = 'transaction_failed',
  TransactionSuccess = 'transaction_success'
}

export type TeamRejectInput = {
  id: Scalars['ID'];
  rejectReason: Scalars['String'];
};

/** Autogenerated return type of TeamReject */
export type TeamRejectPayload = {
  __typename?: 'TeamRejectPayload';
  errors?: Maybe<Array<Error>>;
  teamPayout?: Maybe<TeamPayout>;
};

export type TeamRole = {
  __typename?: 'TeamRole';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  online: Scalars['Boolean'];
  pilot?: Maybe<Pilot>;
  proficientRole?: Maybe<ProficientRole>;
  team: Team;
  user?: Maybe<User>;
};

/** Team Wallet */
export type TeamWallet = {
  __typename?: 'TeamWallet';
  amount: Scalars['Float'];
  countryCode: CountryCode;
  credits: Array<CreditTransaction>;
  creditTransactions: Array<CreditTransaction>;
  currency: Scalars['String'];
  debits: Array<CreditTransaction>;
  id: Scalars['ID'];
  team: Team;
  teamCreditTransactions: CreditTransactionsCount;
  teamPayouts: Array<TeamPayout>;
  transactionHistory: Array<CreditTransaction>;
};


/** Team Wallet */
export type TeamWalletTeamCreditTransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
};

export enum TestState {
  TestFailed = 'test_failed',
  TestNonRetryable = 'test_non_retryable',
  TestPassed = 'test_passed',
  TestPending = 'test_pending'
}

export type Topic = {
  __typename?: 'Topic';
  id: Scalars['ID'];
  image: Scalars['String'];
  prosOnly: Scalars['Boolean'];
  text: Scalars['String'];
  video: Scalars['String'];
};

/** Topup Product Type */
export type TopupProduct = {
  __typename?: 'TopupProduct';
  avatarUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  code?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
  coverUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  genre?: Maybe<GameGenre>;
  helperUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<TopupProductItem>>;
  logoUrl?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['Int']>;
  originId: Scalars['Int'];
  publisher?: Maybe<Publisher>;
  publisherLogoUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userInput?: Maybe<TopupProductUserInput>;
  vendor?: Maybe<Vendor>;
  vocaId?: Maybe<Scalars['Int']>;
};

/** Product Attribute Data Type */
export type TopupProductAttrData = {
  __typename?: 'TopupProductAttrData';
  id: Scalars['ID'];
  text: Scalars['String'];
  value: Scalars['String'];
};

/** Topup Product include counter */
export type TopUpProductCount = {
  __typename?: 'TopUpProductCount';
  count: Scalars['Int'];
  data: Array<TopupProduct>;
};

/** Topup Product Item Type */
export type TopupProductItem = {
  __typename?: 'TopupProductItem';
  discount?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isPromo: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceAsMoney?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Topup Product User input Type */
export type TopupProductUserInput = {
  __typename?: 'TopupProductUserInput';
  fields: Array<TopupProductUserInputFields>;
  id: Scalars['ID'];
  instructionText?: Maybe<Scalars['String']>;
  isHideData: Scalars['Boolean'];
};

/** Topup Product User input Fields Type */
export type TopupProductUserInputFields = {
  __typename?: 'TopupProductUserInputFields';
  attrs?: Maybe<TopupProductUserInputFieldsAttrs>;
  id?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
};

/** Topup User input Field Attrs Type */
export type TopupProductUserInputFieldsAttrs = {
  __typename?: 'TopupProductUserInputFieldsAttrs';
  datas?: Maybe<Array<TopupProductAttrData>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Tournament = {
  __typename?: 'Tournament';
  apiKey?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  clan?: Maybe<Clan>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  featured?: Maybe<Scalars['Boolean']>;
  game: Game;
  id: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  isOrganizer: Scalars['Boolean'];
  judges?: Maybe<Array<Judge>>;
  logo?: Maybe<Scalars['String']>;
  mode: TournamentMode;
  name: Scalars['String'];
  organizer?: Maybe<User>;
  participantsCount?: Maybe<Scalars['Int']>;
  poolPrizes?: Maybe<Array<PoolPrize>>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  state: TournamentState;
  totalPrize?: Maybe<Scalars['Float']>;
  tournamentEvents?: Maybe<Array<TournamentEvent>>;
  tournamentSettings?: Maybe<Array<TournamentSetting>>;
  tournamentTeams?: Maybe<Array<TournamentTeam>>;
  tournamentUsers?: Maybe<Array<TournamentUser>>;
  updatedAt: Scalars['ISO8601DateTime'];
  verified?: Maybe<Scalars['Boolean']>;
};

/** Tournament include counter */
export type TournamentCount = {
  __typename?: 'TournamentCount';
  count: Scalars['Int'];
  data: Array<Tournament>;
};

export type TournamentEvent = {
  __typename?: 'TournamentEvent';
  createdAt: Scalars['ISO8601DateTime'];
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  eventType?: Maybe<TournamentEventEvent>;
  id: Scalars['ID'];
  isEnded: Scalars['Boolean'];
  isOngoing: Scalars['Boolean'];
  judge?: Maybe<User>;
  name: Scalars['String'];
  position: Scalars['Int'];
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  state?: Maybe<Scalars['String']>;
  tournament: Tournament;
  tournamentRooms?: Maybe<Array<TournamentRoom>>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export enum TournamentEventEvent {
  DoubleElimination = 'double_elimination',
  Final = 'final',
  PooledRoundRobin = 'pooled_round_robin',
  RandomPairings = 'random_pairings',
  Registration = 'registration',
  RoundRobin = 'round_robin',
  SemiFinal = 'semi_final',
  SingleElimination = 'single_elimination'
}

export enum TournamentEventState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Pending = 'pending',
  Started = 'started'
}

export enum TournamentMode {
  Solo = 'solo',
  TeamMatch = 'team_match'
}

/** Tournament Room */
export type TournamentRoom = {
  __typename?: 'TournamentRoom';
  battles?: Maybe<Array<Battle>>;
  battleType: TournamentRoomBattle;
  chatroom?: Maybe<Chatroom>;
  checkIn?: Maybe<Scalars['ISO8601DateTime']>;
  checkInMembers?: Maybe<Array<TournamentTeam>>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  judge?: Maybe<Judge>;
  loseTeam?: Maybe<TournamentTeam>;
  roomName?: Maybe<Scalars['String']>;
  state: TournamentRoomState;
  tournamentEvent: TournamentEvent;
  tournamentTeams?: Maybe<Array<TournamentTeam>>;
  updatedAt: Scalars['ISO8601DateTime'];
  winTeam?: Maybe<TournamentTeam>;
  winTeamId?: Maybe<Scalars['Int']>;
};


/** Tournament Room */
export type TournamentRoomChatroomArgs = {
  id: Scalars['ID'];
};

export enum TournamentRoomBattle {
  B03 = 'B03',
  B05 = 'B05',
  B07 = 'B07',
  SingleElimination = 'single_elimination'
}

export enum TournamentRoomState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Expired = 'expired',
  Initiating = 'initiating',
  Started = 'started'
}

/** Tournament Settings */
export type TournamentSetting = {
  __typename?: 'TournamentSetting';
  name: Scalars['String'];
  tournament: Tournament;
  value: Scalars['String'];
};

export enum TournamentState {
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Expired = 'expired',
  Started = 'started',
  Suspended = 'suspended'
}

export type TournamentTeam = {
  __typename?: 'TournamentTeam';
  camps?: Maybe<Array<Camp>>;
  captain: User;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isManager: Scalars['Boolean'];
  loseCount?: Maybe<Scalars['Int']>;
  players: Array<User>;
  state: TournamentTeamState;
  subPlayers: Array<User>;
  team: Team;
  teamName?: Maybe<Scalars['String']>;
  tournament: Tournament;
  tournamentRooms?: Maybe<Array<TournamentRoom>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Tournament Teams include counter */
export type TournamentTeamCount = {
  __typename?: 'TournamentTeamCount';
  count: Scalars['Int'];
  data: Array<TournamentTeam>;
};

export type TournamentTeamInput = {
  id: Scalars['ID'];
  stateRequest: Scalars['String'];
};

/** Autogenerated return type of TournamentTeam */
export type TournamentTeamPayload = {
  __typename?: 'TournamentTeamPayload';
  errors?: Maybe<Array<Error>>;
  tournamentTeam?: Maybe<TournamentTeam>;
};

export enum TournamentTeamState {
  Cancelled = 'cancelled',
  Disqualified = 'disqualified',
  Pending = 'pending',
  Qualified = 'qualified'
}

export type TournamentUser = {
  __typename?: 'TournamentUser';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  state: TournamentUserState;
  tournament: Tournament;
  tournamentRooms?: Maybe<Array<TournamentRoom>>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

export enum TournamentUserState {
  Cancelled = 'cancelled',
  Disqualified = 'disqualified',
  Pending = 'pending',
  Qualified = 'qualified'
}

export enum Transaction {
  Bonus = 'bonus',
  CancelFee = 'cancel_fee',
  ContestBonus = 'contest_bonus',
  DepositDeduction = 'deposit_deduction',
  Earn = 'earn',
  GameCredit = 'game_credit',
  Giveaway = 'giveaway',
  Merchandise = 'merchandise',
  Payout = 'payout',
  Penalty = 'penalty',
  PoolPrize = 'pool_prize',
  RankReward = 'rank_reward',
  ReceiveCredit = 'receive_credit',
  Referral = 'referral',
  Refund = 'refund',
  RefundDeduction = 'refund_deduction',
  RegistrationFees = 'registration_fees',
  RegistrationRefund = 'registration_refund',
  SendCredit = 'send_credit',
  Spend = 'spend',
  TeamPayout = 'team_payout',
  Topup = 'topup',
  TransactionFee = 'transaction_fee',
  Transfer = 'transfer'
}

export type UpdateAdminNoteCombatRoomInput = {
  adminNote: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateAdminNoteCombatRoom */
export type UpdateAdminNoteCombatRoomPayload = {
  __typename?: 'UpdateAdminNoteCombatRoomPayload';
  combatRoom?: Maybe<CombatRoom>;
  errors?: Maybe<Array<Error>>;
};

export type UpdateAdminNoteGameBundleInput = {
  adminNote: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateAdminNoteGameBundle */
export type UpdateAdminNoteGameBundlePayload = {
  __typename?: 'UpdateAdminNoteGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type UpdateAdminNotePartneringRequestInput = {
  adminNote: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateAdminNotePartneringRequest */
export type UpdateAdminNotePartneringRequestPayload = {
  __typename?: 'UpdateAdminNotePartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

export type UpdateAdminNotePayoutInput = {
  adminNote: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateAdminNotePayout */
export type UpdateAdminNotePayoutPayload = {
  __typename?: 'UpdateAdminNotePayoutPayload';
  errors?: Maybe<Array<Error>>;
  payout?: Maybe<Payout>;
};

export type UpdateAdminNoteTeamPayoutInput = {
  adminNote: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateAdminNoteTeamPayout */
export type UpdateAdminNoteTeamPayoutPayload = {
  __typename?: 'UpdateAdminNoteTeamPayoutPayload';
  errors?: Maybe<Array<Error>>;
  teamPayout?: Maybe<TeamPayout>;
};

export type UpdateAdminNoteUserInput = {
  adminNote: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateAdminNoteUser */
export type UpdateAdminNoteUserPayload = {
  __typename?: 'UpdateAdminNoteUserPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type UpdateCareerHighlightInput = {
  endYear?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
  startYear?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateCareerHighlight */
export type UpdateCareerHighlightPayload = {
  __typename?: 'UpdateCareerHighlightPayload';
  careerHighlight?: Maybe<CareerHighlight>;
  errors?: Maybe<Array<Error>>;
};

export type UpdateClubInput = {
  beStar?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  founded?: Maybe<Scalars['ISO8601DateTime']>;
  icon?: Maybe<Scalars['File']>;
  id: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  themeColor: Scalars['String'];
  tier: ClubTier;
  verified?: Maybe<Scalars['Boolean']>;
};

export type UpdateClubMemberInput = {
  clubId: Scalars['String'];
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of UpdateClubMember */
export type UpdateClubMemberPayload = {
  __typename?: 'UpdateClubMemberPayload';
  errors?: Maybe<Array<Error>>;
  gameAccounts?: Maybe<Array<GameAccount>>;
};

/** Autogenerated return type of UpdateClub */
export type UpdateClubPayload = {
  __typename?: 'UpdateClubPayload';
  club?: Maybe<Club>;
  errors?: Maybe<Array<Error>>;
};

export type UpdateFaqInput = {
  answer: Scalars['String'];
  apps: FaqApps;
  categoryColumns: CategoryColumns;
  id: Scalars['ID'];
  language: Language;
  position: Scalars['Int'];
  question: Scalars['String'];
};

/** Autogenerated return type of UpdateFaq */
export type UpdateFaqPayload = {
  __typename?: 'UpdateFaqPayload';
  errors?: Maybe<Array<Error>>;
  faq?: Maybe<Faq>;
};

export type UpdateGameInput = {
  active?: Maybe<Scalars['Boolean']>;
  combat?: Maybe<Scalars['Boolean']>;
  developerName?: Maybe<Scalars['String']>;
  genre?: Maybe<GameGenre>;
  icon?: Maybe<Scalars['File']>;
  id: Scalars['ID'];
  interestIds?: Maybe<Array<Scalars['ID']>>;
  logo?: Maybe<Scalars['File']>;
  mascot?: Maybe<Scalars['File']>;
  matchmaking?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['Int']>;
  platform?: Maybe<GamePlatform>;
  realTime?: Maybe<Scalars['Boolean']>;
  schedule?: Maybe<Scalars['Boolean']>;
  screenshotSample?: Maybe<Scalars['File']>;
  scrim?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  themeColor?: Maybe<Scalars['String']>;
  tournament?: Maybe<Scalars['Boolean']>;
  vocaCode?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateGame */
export type UpdateGamePayload = {
  __typename?: 'UpdateGamePayload';
  errors?: Maybe<Array<Error>>;
  game?: Maybe<Game>;
};

export type UpdateGameRankInput = {
  gameId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['File']>;
  name?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['Int']>;
  rankPointMaxCount?: Maybe<Array<Scalars['Int']>>;
  rankStarMaxCount?: Maybe<Scalars['Int']>;
  rankTierMaxCount?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateGameRank */
export type UpdateGameRankPayload = {
  __typename?: 'UpdateGameRankPayload';
  errors?: Maybe<Array<Error>>;
  gameRank?: Maybe<GameRank>;
};

export type UpdateGamerBannerInput = {
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['File']>;
  poster?: Maybe<Scalars['Boolean']>;
  screen?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateGamerBanner */
export type UpdateGamerBannerPayload = {
  __typename?: 'UpdateGamerBannerPayload';
  errors?: Maybe<Array<Error>>;
  gamerBanner?: Maybe<Banner>;
};

export type UpdateGiftInput = {
  amount: Scalars['Int'];
  icon: Scalars['File'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateGift */
export type UpdateGiftPayload = {
  __typename?: 'UpdateGiftPayload';
  errors?: Maybe<Array<Error>>;
  gift?: Maybe<Gift>;
};

export type UpdateInterestInput = {
  active: Scalars['Boolean'];
  iconOff?: Maybe<Scalars['File']>;
  iconOn?: Maybe<Scalars['File']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateInterest */
export type UpdateInterestPayload = {
  __typename?: 'UpdateInterestPayload';
  errors?: Maybe<Array<Error>>;
  interest?: Maybe<Interest>;
};

export type UpdateMarkupPricingInput = {
  active?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<CountryCode>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  invoiceId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['ID']>;
  merchantId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  uniqueId?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateMarkupPricing */
export type UpdateMarkupPricingPayload = {
  __typename?: 'UpdateMarkupPricingPayload';
  errors?: Maybe<Array<Error>>;
  markupPricing?: Maybe<MarkupPricing>;
};

export type UpdateMatchResultAdminInput = {
  combatRoomId: Scalars['ID'];
  combatTeamId: Scalars['ID'];
  resultScreenshots: Array<Scalars['File']>;
};

/** Autogenerated return type of UpdateMatchResultAdmin */
export type UpdateMatchResultAdminPayload = {
  __typename?: 'UpdateMatchResultAdminPayload';
  errors?: Maybe<Array<Error>>;
  matchResult?: Maybe<MatchResult>;
};

export type UpdateMatchResultInput = {
  id: Scalars['ID'];
  loseTeamId: Scalars['ID'];
  resultScreenshots?: Maybe<Array<Scalars['File']>>;
  winTeamId: Scalars['ID'];
};

/** Autogenerated return type of UpdateMatchResult */
export type UpdateMatchResultPayload = {
  __typename?: 'UpdateMatchResultPayload';
  currentUser?: Maybe<UserSession>;
  errors?: Maybe<Array<Error>>;
  matchResult?: Maybe<MatchResult>;
};

export type UpdateMerchandiseImagesInput = {
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['File']>>;
  isUpdate: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateMerchandiseImages */
export type UpdateMerchandiseImagesPayload = {
  __typename?: 'UpdateMerchandiseImagesPayload';
  errors?: Maybe<Array<Error>>;
  merchandise?: Maybe<Merchandise>;
};

export type UpdateMerchandiseInput = {
  active: Scalars['Boolean'];
  categoryId: Scalars['ID'];
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  postId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateMerchandise */
export type UpdateMerchandisePayload = {
  __typename?: 'UpdateMerchandisePayload';
  errors?: Maybe<Array<Error>>;
  merchandise?: Maybe<Merchandise>;
};

export type UpdateMerchandiseVariantInput = {
  id: Scalars['ID'];
  merchandiseId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  stock?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateMerchandiseVariant */
export type UpdateMerchandiseVariantPayload = {
  __typename?: 'UpdateMerchandiseVariantPayload';
  errors?: Maybe<Array<Error>>;
  merchandiseVariant?: Maybe<MerchandiseVariant>;
};

export type UpdateOfficialChannelInput = {
  accountId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOfficialChannel */
export type UpdateOfficialChannelPayload = {
  __typename?: 'UpdateOfficialChannelPayload';
  errors?: Maybe<Array<Error>>;
  officialChannel?: Maybe<OfficialChannel>;
};

export type UpdateOrderStatusInput = {
  id: Scalars['ID'];
  statusType: Scalars['String'];
};

/** Autogenerated return type of UpdateOrderStatus */
export type UpdateOrderStatusPayload = {
  __typename?: 'UpdateOrderStatusPayload';
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type UpdatePilotBannerInput = {
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['File']>;
  poster?: Maybe<Scalars['Boolean']>;
  screen?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePilotBanner */
export type UpdatePilotBannerPayload = {
  __typename?: 'UpdatePilotBannerPayload';
  errors?: Maybe<Array<Error>>;
  pilotBanner?: Maybe<PilotBanner>;
};

export type UpdatePilotTierInput = {
  ids: Array<Scalars['ID']>;
  pilotTierId: Scalars['String'];
};

/** Autogenerated return type of UpdatePilotTier */
export type UpdatePilotTierPayload = {
  __typename?: 'UpdatePilotTierPayload';
  errors?: Maybe<Array<Error>>;
  gameAccounts?: Maybe<Array<GameAccount>>;
};

export type UpdatePoolPrizeInput = {
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  leaderboardId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdatePoolPrize */
export type UpdatePoolPrizePayload = {
  __typename?: 'UpdatePoolPrizePayload';
  errors?: Maybe<Array<Error>>;
  poolPrize?: Maybe<PoolPrize>;
};

export type UpdatePostInput = {
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isYoutube?: Maybe<Scalars['Boolean']>;
  newsImage?: Maybe<Scalars['File']>;
  postType?: Maybe<PostType>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePost */
export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  errors?: Maybe<Array<Error>>;
  post?: Maybe<Post>;
};

export type UpdatePrizeInput = {
  active?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['File']>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  prizeName?: Maybe<Scalars['String']>;
  prizeType?: Maybe<PrizeType>;
  value?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of UpdatePrize */
export type UpdatePrizePayload = {
  __typename?: 'UpdatePrizePayload';
  errors?: Maybe<Array<Error>>;
  prize?: Maybe<Prize>;
};

export type UpdateProficientRoleInput = {
  gameId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['File']>;
  kind?: Maybe<ProficientRoleKind>;
  name?: Maybe<Scalars['String']>;
  selectedImage?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of UpdateProficientRole */
export type UpdateProficientRolePayload = {
  __typename?: 'UpdateProficientRolePayload';
  errors?: Maybe<Array<Error>>;
  proficientRole?: Maybe<ProficientRole>;
};

export type UpdatePromoCodeInput = {
  active?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Int']>;
  availableFor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  gameType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  minimumAmount?: Maybe<Scalars['Int']>;
  redeemLimit?: Maybe<Scalars['Int']>;
  userIds?: Maybe<Array<Scalars['String']>>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of UpdatePromoCode */
export type UpdatePromoCodePayload = {
  __typename?: 'UpdatePromoCodePayload';
  errors?: Maybe<Array<Error>>;
  promoCode?: Maybe<PromoCode>;
};

export type UpdatePublisherInput = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePublisher */
export type UpdatePublisherPayload = {
  __typename?: 'UpdatePublisherPayload';
  errors?: Maybe<Array<Error>>;
  publisher?: Maybe<Publisher>;
};

export type UpdateRatingGameBundleInput = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
};

/** Autogenerated return type of UpdateRatingGameBundle */
export type UpdateRatingGameBundlePayload = {
  __typename?: 'UpdateRatingGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type UpdateRatingPartneringRequestInput = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
};

/** Autogenerated return type of UpdateRatingPartneringRequest */
export type UpdateRatingPartneringRequestPayload = {
  __typename?: 'UpdateRatingPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

export type UpdateSponsorAdInput = {
  active?: Maybe<Scalars['Boolean']>;
  adsName?: Maybe<Scalars['String']>;
  assetType?: Maybe<SponsorAdAsset>;
  gamerBannerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['File']>;
  layoutType?: Maybe<SponsorAdLayout>;
  position?: Maybe<Scalars['Int']>;
  screenRoute?: Maybe<Scalars['String']>;
  sponsorName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of UpdateSponsorAd */
export type UpdateSponsorAdPayload = {
  __typename?: 'UpdateSponsorAdPayload';
  errors?: Maybe<Array<Error>>;
  sponsorAd?: Maybe<SponsorAd>;
};

export type UpdateTeamWalletInput = {
  combatTeamId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateTeamWallet */
export type UpdateTeamWalletPayload = {
  __typename?: 'UpdateTeamWalletPayload';
  errors?: Maybe<Array<Error>>;
  poolPrize?: Maybe<PoolPrize>;
};

export type UpdateTopUpProductInput = {
  avatar?: Maybe<Scalars['File']>;
  categoryId?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
  cover?: Maybe<Scalars['File']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  genre?: Maybe<GameGenre>;
  helper?: Maybe<Scalars['File']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['File']>;
  ordering?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['ID']>;
  publisherId?: Maybe<Scalars['ID']>;
  publisherLogo?: Maybe<Scalars['File']>;
  title?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['ID']>;
  vocaId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateTopUpProduct */
export type UpdateTopUpProductPayload = {
  __typename?: 'UpdateTopUpProductPayload';
  errors?: Maybe<Array<Error>>;
  topUpProduct?: Maybe<TopupProduct>;
};

export type UpdateTournamentEventInput = {
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  startTime?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTournamentEvent */
export type UpdateTournamentEventPayload = {
  __typename?: 'UpdateTournamentEventPayload';
  errors?: Maybe<Array<Error>>;
  tournamentEvent?: Maybe<TournamentEvent>;
};

export type UpdateTournamentInput = {
  backgroundImage?: Maybe<Scalars['File']>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['File']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTournament */
export type UpdateTournamentPayload = {
  __typename?: 'UpdateTournamentPayload';
  errors?: Maybe<Array<Error>>;
  tournament?: Maybe<Tournament>;
};

export type UpdateTrackingOrderInput = {
  id: Scalars['ID'];
  trackingNumber: Scalars['String'];
  trackingUrl: Scalars['String'];
};

/** Autogenerated return type of UpdateTrackingOrder */
export type UpdateTrackingOrderPayload = {
  __typename?: 'UpdateTrackingOrderPayload';
  errors?: Maybe<Array<Error>>;
  order?: Maybe<Order>;
};

export type UpdateTrainedPilotInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateTrainedPilot */
export type UpdateTrainedPilotPayload = {
  __typename?: 'UpdateTrainedPilotPayload';
  errors?: Maybe<Array<Error>>;
  pilot?: Maybe<Pilot>;
};

export type UpdateUserBlastScheduleInput = {
  body: Scalars['String'];
  id: Scalars['ID'];
  isScheduled?: Maybe<Scalars['Boolean']>;
  scheduleDate?: Maybe<Scalars['ISO8601DateTime']>;
  scheduleDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  scheduleTime?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateUserBlastSchedule */
export type UpdateUserBlastSchedulePayload = {
  __typename?: 'UpdateUserBlastSchedulePayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
  userBlastSchedule?: Maybe<UserBlastSchedule>;
};

export type UpdateUserInput = {
  avatar?: Maybe<Scalars['File']>;
  beKol: Scalars['Boolean'];
  bePro: Scalars['Boolean'];
  beStar: Scalars['Boolean'];
  coverPicture?: Maybe<Scalars['File']>;
  id: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  officialName?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  proPhoto?: Maybe<Scalars['File']>;
  verified: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type UpdateVendorInput = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateVendor */
export type UpdateVendorPayload = {
  __typename?: 'UpdateVendorPayload';
  errors?: Maybe<Array<Error>>;
  vendor?: Maybe<Vendor>;
};

/** User */
export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  adminNote?: Maybe<Scalars['String']>;
  allPrivateChatrooms: Array<Chatroom>;
  avatar?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bankInfo?: Maybe<BankInfo>;
  beKol: Scalars['Boolean'];
  bePro: Scalars['Boolean'];
  beStar: Scalars['Boolean'];
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  blockedUsers?: Maybe<Array<User>>;
  blockUsers?: Maybe<Array<BlockUser>>;
  bookings?: Maybe<Array<Booking>>;
  bookingsRatingCount?: Maybe<Scalars['Float']>;
  career?: Maybe<Scalars['String']>;
  careerHighlights?: Maybe<Array<CareerHighlight>>;
  cartOrders?: Maybe<Cart>;
  chatroom?: Maybe<Chatroom>;
  chatroomId?: Maybe<Scalars['ID']>;
  clansCount: Scalars['Int'];
  club?: Maybe<Club>;
  combatRooms?: Maybe<Array<CombatRoom>>;
  commission?: Maybe<Scalars['Float']>;
  completedBookingsCount: Scalars['Int'];
  countryCode: CountryCode;
  coverPictureUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  currentJoinRequest?: Maybe<JoinRequest>;
  currentRealtime?: Maybe<PartneringRequest>;
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  followed: Scalars['Boolean'];
  followersCount: Scalars['Int'];
  followingCount: Scalars['Int'];
  fullName?: Maybe<Scalars['String']>;
  gameAccounts: Array<GameAccount>;
  gameBundles: Array<GameBundle>;
  gender?: Maybe<Gender>;
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indicators?: Maybe<Indicator>;
  introText?: Maybe<Scalars['String']>;
  isBlocked: Scalars['Boolean'];
  isManager: Scalars['Boolean'];
  language: Language;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  liked: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  matchMakingBookings?: Maybe<Array<Booking>>;
  matchMakings?: Maybe<Array<MatchMaking>>;
  merchandises?: Maybe<Array<Merchandise>>;
  mlbbAccount?: Maybe<GameAccount>;
  name: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  notifications: Array<Notification>;
  officialName?: Maybe<Scalars['String']>;
  online: Scalars['Boolean'];
  orderHistory?: Maybe<Array<Order>>;
  overview?: Maybe<Scalars['String']>;
  partneringRequests: Array<PartneringRequest>;
  pendingJoinRequests?: Maybe<Array<JoinRequest>>;
  pendingPrize: Scalars['Int'];
  phoneCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postsCount: Scalars['Int'];
  privateChatroom?: Maybe<Chatroom>;
  promoCodes?: Maybe<Array<PromoCode>>;
  proPhotoUrl?: Maybe<Scalars['String']>;
  recentCombats?: Maybe<Array<CombatRoom>>;
  referralCode: Scalars['String'];
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<Referrer>;
  referrerId?: Maybe<Scalars['String']>;
  shippingAddresses?: Maybe<Array<ShippingAddress>>;
  signInCount: Scalars['Int'];
  suspend: Scalars['Boolean'];
  suspendGamerRequests?: Maybe<Array<SuspendGamerRequest>>;
  team?: Maybe<Team>;
  teamChatrooms?: Maybe<Array<Chatroom>>;
  teamInvitation?: Maybe<TeamInvitation>;
  teamInvitations: Array<TeamInvitation>;
  teamName?: Maybe<Scalars['String']>;
  teamRole?: Maybe<TeamRole>;
  themeColors?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['ISO8601DateTime'];
  userCreditTransaction: UserCreditTransactionCount;
  userIdApple?: Maybe<Scalars['String']>;
  userIdGoogle?: Maybe<Scalars['String']>;
  userIdInstagram?: Maybe<Scalars['String']>;
  userIdTiktok?: Maybe<Scalars['String']>;
  userMatchCount?: Maybe<Scalars['Int']>;
  userPrizes?: Maybe<Array<UserPrize>>;
  userPrizesCount: Scalars['Int'];
  userWinCount?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};


/** User */
export type UserChatroomArgs = {
  id: Scalars['ID'];
};


/** User */
export type UserMatchMakingBookingsArgs = {
  matchMakingId?: Maybe<Scalars['ID']>;
};


/** User */
export type UserMatchMakingsArgs = {
  page?: Maybe<Scalars['Int']>;
  state: Array<MatchMakingState>;
};


/** User */
export type UserNotificationsArgs = {
  gameId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** User */
export type UserOrderHistoryArgs = {
  status: Array<OrderStatus>;
};


/** User */
export type UserPrivateChatroomArgs = {
  userId: Scalars['ID'];
};


/** User */
export type UserUserCreditTransactionArgs = {
  page?: Maybe<Scalars['Int']>;
};


/** User */
export type UserUserPrizesArgs = {
  page?: Maybe<Scalars['Int']>;
};

/** User Blast Schedule */
export type UserBlastSchedule = {
  __typename?: 'UserBlastSchedule';
  body: Scalars['String'];
  id: Scalars['ID'];
  isScheduled: Scalars['Boolean'];
  scheduleDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  state?: Maybe<UserBlastScheduleState>;
  title: Scalars['String'];
  userIds?: Maybe<Array<User>>;
};

/** User Blast Schedule include counter */
export type UserBlastScheduleCount = {
  __typename?: 'UserBlastScheduleCount';
  count: Scalars['Int'];
  data: Array<UserBlastSchedule>;
};

export enum UserBlastScheduleState {
  Failed = 'failed',
  Pending = 'pending',
  Sent = 'sent'
}

/** User include counter */
export type UserCount = {
  __typename?: 'UserCount';
  count: Scalars['Int'];
  data: Array<UserIndex>;
};

/** User Credit Transaction include counter */
export type UserCreditTransactionCount = {
  __typename?: 'UserCreditTransactionCount';
  count: Scalars['Int'];
  data: Array<CreditTransaction>;
};

/** User Game Account */
export type UserGameAccount = {
  __typename?: 'UserGameAccount';
  game: Game;
  id: Scalars['ID'];
  proficientHeros: Array<ProficientHero>;
};

/** User Index */
export type UserIndex = {
  __typename?: 'UserIndex';
  adminNote?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditWalletAmount: Scalars['Float'];
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  suspend: Scalars['Boolean'];
};

/** User Prize */
export type UserPrize = {
  __typename?: 'UserPrize';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditTransactionId: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inGameId?: Maybe<Scalars['String']>;
  inGameName?: Maybe<Scalars['String']>;
  printName?: Maybe<Scalars['String']>;
  prize?: Maybe<Prize>;
  probabilities: Array<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  skinName?: Maybe<Scalars['String']>;
  state: UserPrizeState;
  userId: Scalars['ID'];
  userNickname: Scalars['String'];
};

/** UserPrize include counter */
export type UserPrizeCount = {
  __typename?: 'UserPrizeCount';
  count: Scalars['Int'];
  data: Array<UserPrize>;
};

export enum UserPrizeState {
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  PendingInfo = 'pending_info',
  PendingSpin = 'pending_spin',
  Processing = 'processing'
}

/** UserPromoCode */
export type UserPromoCode = {
  __typename?: 'UserPromoCode';
  id: Scalars['ID'];
  promoCode: PromoCode;
  target: Target;
  user: User;
};

/** User Promo Code include counter */
export type UserPromoCodeCount = {
  __typename?: 'UserPromoCodeCount';
  count: Scalars['Int'];
  data: Array<UserPromoCode>;
};

/** User Session */
export type UserSession = {
  __typename?: 'UserSession';
  avatarUrl?: Maybe<Scalars['String']>;
  banWeek: Scalars['Int'];
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  clubId?: Maybe<Scalars['ID']>;
  cooldownTime?: Maybe<Scalars['ISO8601DateTime']>;
  countryCode: CountryCode;
  coverPictureUrl?: Maybe<Scalars['String']>;
  creditWalletBalance: Scalars['Float'];
  creditWalletId: Scalars['ID'];
  currentCombatId?: Maybe<Scalars['ID']>;
  currentCombatTeam?: Maybe<CombatTeam>;
  currentRealtime?: Maybe<PartneringRequest>;
  currentRealtimeId?: Maybe<Scalars['ID']>;
  discordId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  followersCount: Scalars['Int'];
  followingCount: Scalars['Int'];
  gender?: Maybe<Gender>;
  hasSecurityCode: Scalars['Boolean'];
  icName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  introText?: Maybe<Scalars['String']>;
  isBanned: Scalars['Boolean'];
  isManager: Scalars['Boolean'];
  isPlayer: Scalars['Boolean'];
  joinRequestIds?: Maybe<Array<Scalars['ID']>>;
  language: Language;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  likesCount: Scalars['Int'];
  managerName?: Maybe<Scalars['String']>;
  mlbbAccountId?: Maybe<Scalars['ID']>;
  mlbbTeamId?: Maybe<Scalars['ID']>;
  nickname?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postsCount: Scalars['Int'];
  referralCode: Scalars['String'];
  referrerId?: Maybe<Scalars['String']>;
  securityCode?: Maybe<Scalars['String']>;
  signInCount: Scalars['Int'];
  suspend: Scalars['Boolean'];
  teamIconUrl?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['ID']>;
  teamInvitationIds?: Maybe<Array<Scalars['ID']>>;
  teamName?: Maybe<Scalars['String']>;
  teamWalletId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
  verified: Scalars['Boolean'];
};

/** Vendor */
export type Vendor = {
  __typename?: 'Vendor';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Voca Product Type */
export type VocaProduct = {
  __typename?: 'VocaProduct';
  category?: Maybe<Category>;
  code?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  isPromo: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  title?: Maybe<Scalars['String']>;
  typeOrder?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type BasicAdminFragment = (
  { __typename?: 'Admin' }
  & Pick<Admin, 'id' | 'email' | 'supervisor' | 'role' | 'isSuper' | 'canManage' | 'canEdit' | 'canViewFinance' | 'canViewJobs' | 'canViewUsers'>
);

export type AdminLogFragment = (
  { __typename?: 'AdminLog' }
  & Pick<AdminLog, 'id' | 'description' | 'targetType' | 'targetId' | 'createdAt'>
  & { admin: (
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'email'>
  ) }
);

export type BankInfoFragment = (
  { __typename?: 'BankInfo' }
  & Pick<BankInfo, 'id' | 'name' | 'bankName' | 'bankAccountNo' | 'country' | 'ic' | 'icImage' | 'state' | 'updatedAt' | 'rejectReason' | 'selfie'>
);

export type CategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name'>
);

export type ClubFragment = (
  { __typename?: 'Club' }
  & Pick<Club, 'id' | 'name' | 'icon' | 'description' | 'tier' | 'beStar' | 'verified' | 'themeColor' | 'founded' | 'origin' | 'introText' | 'overview'>
  & { teams: Array<(
    { __typename?: 'Team' }
    & TeamFragment
  )> }
);

export type CreditTransactionFragment = (
  { __typename?: 'CreditTransaction' }
  & Pick<CreditTransaction, 'id' | 'amount' | 'state' | 'transactionType' | 'createdAt' | 'targetId' | 'targetType'>
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'amount' | 'status'>
  )> }
);

export type CreditWalletFragment = (
  { __typename?: 'CreditWallet' }
  & Pick<CreditWallet, 'amount' | 'id' | 'currency'>
  & { creditTransactions: Array<(
    { __typename?: 'CreditTransaction' }
    & CreditTransactionFragment
  )>, payouts: Array<(
    { __typename?: 'Payout' }
    & PayoutFragment
  )>, user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  ) }
);

export type ErrorFragment = (
  { __typename?: 'Error' }
  & Pick<Error, 'path' | 'detail'>
);

export type GameFragment = (
  { __typename?: 'Game' }
  & Pick<Game, 'id' | 'name' | 'iconUrl' | 'developerName' | 'teamsCount' | 'gameAccountsCount' | 'shortname' | 'genre' | 'platform' | 'combat' | 'scrim' | 'realTime' | 'schedule' | 'matchmaking' | 'active' | 'themeColor' | 'mascotUrl' | 'logoUrl' | 'screenshotSampleUrl' | 'ordering' | 'vocaCode'>
  & { gameRanks: Array<(
    { __typename?: 'GameRank' }
    & GameRankFragment
  )>, interests?: Maybe<Array<(
    { __typename?: 'Interest' }
    & Pick<Interest, 'id' | 'name' | 'iconOnUrl' | 'iconOffUrl' | 'active'>
  )>>, proficientRoles: Array<(
    { __typename?: 'ProficientRole' }
    & Pick<ProficientRole, 'id' | 'name' | 'imageUrl' | 'selectedImageUrl' | 'kind'>
    & { proficientHeros?: Maybe<Array<(
      { __typename?: 'ProficientHero' }
      & Pick<ProficientHero, 'id' | 'name' | 'imageUrl'>
    )>> }
  )> }
);

export type GameAccountFragment = (
  { __typename?: 'GameAccount' }
  & Pick<GameAccount, 'id' | 'accountId' | 'verificationCode' | 'codeState' | 'screenshotState' | 'testState' | 'screenshotUrl' | 'createdAt' | 'updatedAt' | 'approve' | 'testScore'>
  & { gameRank: (
    { __typename?: 'GameRank' }
    & GameRankFragment
  ), game: (
    { __typename?: 'Game' }
    & GameFragment
  ), pilot: (
    { __typename?: 'Pilot' }
    & PilotFragment
  ), pairableGameRanks: Array<(
    { __typename?: 'GameRank' }
    & Pick<GameRank, 'id' | 'name'>
  )>, pilotTier?: Maybe<(
    { __typename?: 'PilotTier' }
    & PilotTierFragment
  )> }
);

export type GameChatroomFragment = (
  { __typename?: 'GameChatroom' }
  & Pick<GameChatroom, 'id' | 'state' | 'createdAt'>
  & { game: (
    { __typename?: 'Game' }
    & GameFragment
  ) }
);

export type GameMessageFragment = (
  { __typename?: 'GameMessage' }
  & Pick<GameMessage, 'id' | 'text' | 'messageType' | 'createdAt'>
  & { gameChatroom: (
    { __typename?: 'GameChatroom' }
    & GameChatroomFragment
  ), user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'phoneNumber' | 'email' | 'avatarUrl'>
  )>, admin?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'email'>
  )>, team?: Maybe<(
    { __typename?: 'Team' }
    & TeamFragment
  )>, scrim?: Maybe<(
    { __typename?: 'Scrim' }
    & Pick<Scrim, 'id' | 'state' | 'description'>
    & { host: (
      { __typename?: 'Team' }
      & TeamFragment
    ), guest?: Maybe<(
      { __typename?: 'Team' }
      & TeamFragment
    )> }
  )> }
);

export type GameRankFragment = (
  { __typename?: 'GameRank' }
  & Pick<GameRank, 'id' | 'name' | 'ordering' | 'rankTierMaxCount' | 'rankStarMaxCount' | 'rankPointMaxCount' | 'imageUrl'>
  & { game: (
    { __typename?: 'Game' }
    & Pick<Game, 'id'>
  ) }
);

export type LeaderboardFragment = (
  { __typename?: 'Leaderboard' }
  & Pick<Leaderboard, 'id' | 'year' | 'month' | 'beginningWeek' | 'endWeek' | 'numberOfWeek' | 'createdAt' | 'updatedAt' | 'totalPrize' | 'totalRegisteredTeamCounts' | 'state'>
  & { poolPrizes?: Maybe<Array<(
    { __typename?: 'PoolPrize' }
    & Pick<PoolPrize, 'id' | 'amount' | 'name' | 'currency' | 'ordering'>
    & { combatTeam?: Maybe<(
      { __typename?: 'CombatTeam' }
      & Pick<CombatTeam, 'id' | 'score'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      ) }
    )> }
  )>> }
);

export type MarkupPricingFragment = (
  { __typename?: 'MarkupPricing' }
  & Pick<MarkupPricing, 'id' | 'percentage' | 'name' | 'description' | 'active' | 'uniqueId' | 'merchantId' | 'productId' | 'invoiceId' | 'createdAt' | 'countryCode' | 'itemId' | 'startDate' | 'endDate'>
  & { vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & VendorFragment
  )> }
);

export type MatchMakingFragment = (
  { __typename?: 'MatchMaking' }
  & Pick<MatchMaking, 'id' | 'matchCount' | 'state' | 'currency' | 'price' | 'minimumRankId' | 'maximumRankId' | 'queueLimit' | 'queueCount' | 'startTime' | 'endTime' | 'prosOnly'>
  & { maximumRank?: Maybe<(
    { __typename?: 'GameRank' }
    & GameRankFragment
  )>, minimumRank?: Maybe<(
    { __typename?: 'GameRank' }
    & GameRankFragment
  )>, post: (
    { __typename?: 'Post' }
    & PostFragment
  ), user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
  ), game: (
    { __typename?: 'Game' }
    & Pick<Game, 'id' | 'name'>
  ) }
);

export type MerchandiseFragment = (
  { __typename?: 'Merchandise' }
  & Pick<Merchandise, 'id' | 'name' | 'startDate' | 'endDate' | 'active' | 'images' | 'position' | 'createdAt'>
  & { club?: Maybe<(
    { __typename?: 'Club' }
    & Pick<Club, 'id' | 'name'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
  )>, category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  ), post: (
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'url' | 'title'>
  ), merchandiseVariants: Array<(
    { __typename?: 'MerchandiseVariant' }
    & Pick<MerchandiseVariant, 'id' | 'name' | 'price' | 'position' | 'sku' | 'stock'>
  )> }
);

export type MonthlyReportFragment = (
  { __typename?: 'MonthlyReport' }
  & Pick<MonthlyReport, 'id' | 'month' | 'year' | 'createdAt' | 'scheduleCount' | 'realtimeHours' | 'cancelCount' | 'rating' | 'respondTime'>
  & { nextLevel?: Maybe<(
    { __typename?: 'PilotLevel' }
    & PilotLevelFragment
  )>, pilotLevel: (
    { __typename?: 'PilotLevel' }
    & PilotLevelFragment
  ), gameAccount: (
    { __typename?: 'GameAccount' }
    & GameAccountFragment
  ) }
);

export type OfficialChannelFragment = (
  { __typename?: 'OfficialChannel' }
  & Pick<OfficialChannel, 'id' | 'name' | 'url' | 'phoneNumber' | 'accountId' | 'active'>
);

export type PayoutFragment = (
  { __typename?: 'Payout' }
  & Pick<Payout, 'id' | 'amount' | 'recipient' | 'bank' | 'bankAccountNo' | 'approve' | 'createdAt'>
);

export type PilotFragment = (
  { __typename?: 'Pilot' }
  & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname' | 'gender' | 'email' | 'avatarUrl' | 'language' | 'updatedAt'>
);

export type PilotLevelFragment = (
  { __typename?: 'PilotLevel' }
  & Pick<PilotLevel, 'id' | 'name' | 'position' | 'minRealtimeHour' | 'minScheduleCount' | 'minRating' | 'maxCancelCount' | 'maxIdleCount' | 'maxRespondTime' | 'rewardValue'>
);

export type PilotTierFragment = (
  { __typename?: 'PilotTier' }
  & Pick<PilotTier, 'id' | 'name' | 'aggregateRating' | 'completeGameBundlesCount' | 'completePartneringRequestsCount'>
);

export type PoolPrizeFragment = (
  { __typename?: 'PoolPrize' }
  & Pick<PoolPrize, 'id' | 'name' | 'amount' | 'ordering' | 'createdAt' | 'updatedAt'>
  & { leaderboard?: Maybe<(
    { __typename?: 'Leaderboard' }
    & Pick<Leaderboard, 'id' | 'beginningWeek' | 'endWeek' | 'month' | 'year' | 'totalPrize'>
  )>, combatTeam?: Maybe<(
    { __typename?: 'CombatTeam' }
    & Pick<CombatTeam, 'id'>
  )> }
);

export type PostFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'id' | 'url' | 'title' | 'text' | 'active' | 'isYoutube' | 'views' | 'createdAt' | 'likeCount' | 'targetType' | 'targetId' | 'postImages' | 'postVideos' | 'body' | 'favCount' | 'commentsCount' | 'imageRatio' | 'assetId' | 'playbackId' | 'aspectRatio' | 'uploadId' | 'postType' | 'newsImage'>
  & { postComments?: Maybe<Array<(
    { __typename?: 'PostComment' }
    & Pick<PostComment, 'id' | 'body' | 'createdAt' | 'postId' | 'postTitle'>
  )>>, ogData?: Maybe<(
    { __typename?: 'OgData' }
    & Pick<OgData, 'title' | 'image'>
  )>, proTip?: Maybe<(
    { __typename?: 'ProTip' }
    & Pick<ProTip, 'id' | 'image' | 'text' | 'video' | 'prosOnly'>
  )>, topic?: Maybe<(
    { __typename?: 'Topic' }
    & Pick<Topic, 'id' | 'image' | 'text' | 'video' | 'prosOnly'>
  )>, merchandises?: Maybe<Array<(
    { __typename?: 'Merchandise' }
    & MerchandiseFragment
  )>>, author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  )> }
);

export type PrizeFragment = (
  { __typename?: 'Prize' }
  & Pick<Prize, 'id' | 'prizeName' | 'value' | 'icon' | 'prizeType' | 'createdAt' | 'position' | 'active' | 'limit'>
);

export type PromoCodeFragment = (
  { __typename?: 'PromoCode' }
  & Pick<PromoCode, 'active' | 'amount' | 'availableFor' | 'code' | 'gameType' | 'id' | 'minimumAmount' | 'validUntil' | 'redeemLimit' | 'userIds'>
);

export type PublisherFragment = (
  { __typename?: 'Publisher' }
  & Pick<Publisher, 'id' | 'name' | 'description'>
);

export type RejectReasonFragment = (
  { __typename?: 'RejectReason' }
  & Pick<RejectReason, 'id' | 'text' | 'kind'>
);

export type ReportRequestFragment = (
  { __typename?: 'ReportRequest' }
  & Pick<ReportRequest, 'id' | 'reason' | 'targetType' | 'targetId' | 'state' | 'createdAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'phoneNumber' | 'email'>
  )>, post?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'reportRequestsCount'>
  )> }
);

export type SuspendGamerRequestFragment = (
  { __typename?: 'SuspendGamerRequest' }
  & Pick<SuspendGamerRequest, 'id' | 'state' | 'reason' | 'createdAt'>
);

export type SuspendRequestFragment = (
  { __typename?: 'SuspendRequest' }
  & Pick<SuspendRequest, 'id' | 'state' | 'reason' | 'createdAt'>
);

export type TeamFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'icon' | 'name' | 'createdAt' | 'suspend' | 'reason' | 'themeColors' | 'overview' | 'location' | 'founded'>
  & { manager: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname'>
  ), teamWallet?: Maybe<(
    { __typename?: 'TeamWallet' }
    & Pick<TeamWallet, 'id' | 'amount'>
    & { creditTransactions: Array<(
      { __typename?: 'CreditTransaction' }
      & Pick<CreditTransaction, 'id' | 'amount' | 'state' | 'transactionType' | 'createdAt' | 'targetType' | 'targetId' | 'spinnable' | 'token' | 'isDebit' | 'isCredit' | 'transactionCategory'>
      & { leaderboardPoolPrize?: Maybe<(
        { __typename?: 'Leaderboard' }
        & LeaderboardFragment
      )> }
    )> }
  )>, teamRoles: Array<(
    { __typename?: 'TeamRole' }
    & Pick<TeamRole, 'id'>
    & { proficientRole?: Maybe<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name'>
    )>, pilot?: Maybe<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'email' | 'nickname' | 'phoneNumber'>
    )> }
  )>, pilots: Array<(
    { __typename?: 'Pilot' }
    & Pick<Pilot, 'id' | 'email' | 'nickname' | 'phoneNumber'>
  )>, game: (
    { __typename?: 'Game' }
    & Pick<Game, 'id' | 'name'>
  ), currentCombat?: Maybe<(
    { __typename?: 'CombatTeam' }
    & Pick<CombatTeam, 'id' | 'reason' | 'suspended'>
  )>, teamCombatTeams?: Maybe<Array<(
    { __typename?: 'CombatTeam' }
    & Pick<CombatTeam, 'id' | 'teamId' | 'leaderboardId' | 'score' | 'position' | 'endWeek'>
  )>>, careerHighlights?: Maybe<Array<(
    { __typename?: 'CareerHighlight' }
    & Pick<CareerHighlight, 'id' | 'title' | 'startYear' | 'endYear' | 'position'>
  )>>, bankInfo?: Maybe<(
    { __typename?: 'BankInfo' }
    & BankInfoFragment
  )> }
);

export type TeamPayoutFragment = (
  { __typename?: 'TeamPayout' }
  & Pick<TeamPayout, 'id' | 'amount' | 'recipient' | 'bank' | 'bankAccountNo' | 'approve' | 'createdAt' | 'updatedAt'>
);

export type TeamWalletFragment = (
  { __typename?: 'TeamWallet' }
  & Pick<TeamWallet, 'amount' | 'id' | 'currency'>
  & { creditTransactions: Array<(
    { __typename?: 'CreditTransaction' }
    & CreditTransactionFragment
  )>, teamPayouts: Array<(
    { __typename?: 'TeamPayout' }
    & TeamPayoutFragment
  )> }
);

export type TopUpProductFragment = (
  { __typename?: 'TopupProduct' }
  & Pick<TopupProduct, 'id' | 'originId' | 'title' | 'description' | 'code' | 'countryCodes' | 'genre' | 'featured' | 'ordering' | 'isActive' | 'logoUrl' | 'coverUrl' | 'avatarUrl' | 'helperUrl' | 'publisherLogoUrl' | 'vocaId'>
  & { vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id' | 'name' | 'description'>
  )>, publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & Pick<Publisher, 'id' | 'name' | 'description'>
  )>, category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )> }
);

export type UserPrizeFragment = (
  { __typename?: 'UserPrize' }
  & Pick<UserPrize, 'address' | 'createdAt' | 'creditTransactionId' | 'fullName' | 'icNumber' | 'id' | 'inGameId' | 'inGameName' | 'probabilities' | 'skinName' | 'state' | 'userId' | 'userNickname' | 'remark' | 'printName'>
);

export type VendorFragment = (
  { __typename?: 'Vendor' }
  & Pick<Vendor, 'id' | 'name' | 'description'>
);

export type AdminAuthorizationMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  ipAddress: Scalars['String'];
}>;


export type AdminAuthorizationMutation = (
  { __typename?: 'Mutation' }
  & { adminAuthorization?: Maybe<(
    { __typename?: 'AdminAuthorizationPayload' }
    & Pick<AdminAuthorizationPayload, 'jwt'>
    & { admin?: Maybe<(
      { __typename?: 'Admin' }
      & BasicAdminFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type BlastPartnersMutationVariables = Exact<{
  title: Scalars['String'];
  body: Scalars['String'];
  userIds?: Maybe<Array<Scalars['ID']>>;
}>;


export type BlastPartnersMutation = (
  { __typename?: 'Mutation' }
  & { blastPartners?: Maybe<(
    { __typename?: 'BlastPartnersPayload' }
    & Pick<BlastPartnersPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateStarPlayerMutationVariables = Exact<{
  userId: Scalars['ID'];
  proPhoto: Scalars['File'];
  themeColors: Array<Scalars['String']>;
  fullName: Scalars['String'];
  officialName: Scalars['String'];
  nationality: Scalars['String'];
  career: Scalars['String'];
  overview: Scalars['String'];
}>;


export type CreateStarPlayerMutation = (
  { __typename?: 'Mutation' }
  & { createStarPlayer?: Maybe<(
    { __typename?: 'CreateStarPlayerPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateStarTeamMutationVariables = Exact<{
  teamId: Scalars['ID'];
  themeColors: Array<Scalars['String']>;
  location: Scalars['String'];
  overview: Scalars['String'];
  founded: Scalars['ISO8601DateTime'];
}>;


export type CreateStarTeamMutation = (
  { __typename?: 'Mutation' }
  & { createStarTeam?: Maybe<(
    { __typename?: 'CreateStarTeamPayload' }
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AdminTransferMutationVariables = Exact<{
  userId: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
}>;


export type AdminTransferMutation = (
  { __typename?: 'Mutation' }
  & { adminTransfer?: Maybe<(
    { __typename?: 'AdminTransferPayload' }
    & Pick<AdminTransferPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreditWalletQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CreditWalletQuery = (
  { __typename?: 'Query' }
  & { creditWallet: (
    { __typename?: 'CreditWallet' }
    & Pick<CreditWallet, 'id' | 'kohaiCredit' | 'currency' | 'kohaiSendCreditTotal' | 'kohaiReceiveCreditTotal'>
  ) }
);

export type AdminTransferLogsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
}>;


export type AdminTransferLogsQuery = (
  { __typename?: 'Query' }
  & { adminTransferLogs?: Maybe<(
    { __typename?: 'AdminLogsCount' }
    & Pick<AdminLogsCount, 'count'>
    & { data: Array<(
      { __typename?: 'AdminLog' }
      & AdminLogFragment
    )> }
  )> }
);

export type RefundBookingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RefundBookingMutation = (
  { __typename?: 'Mutation' }
  & { refundMatchMaking?: Maybe<(
    { __typename?: 'RefundBookingPayload' }
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ReviewBookingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReviewBookingMutation = (
  { __typename?: 'Mutation' }
  & { reviewMatchMaking?: Maybe<(
    { __typename?: 'ReviewBookingPayload' }
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateCareerHighlightMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  teamId?: Maybe<Scalars['ID']>;
  startYear?: Maybe<Scalars['Int']>;
  endYear?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
}>;


export type CreateCareerHighlightMutation = (
  { __typename?: 'Mutation' }
  & { createCareerHighlight?: Maybe<(
    { __typename?: 'CreateCareerHighlightPayload' }
    & { careerHighlight?: Maybe<(
      { __typename?: 'CareerHighlight' }
      & Pick<CareerHighlight, 'id' | 'title'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateCareerHighlightMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  teamId?: Maybe<Scalars['ID']>;
  startYear?: Maybe<Scalars['Int']>;
  endYear?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
}>;


export type UpdateCareerHighlightMutation = (
  { __typename?: 'Mutation' }
  & { updateCareerHighlight?: Maybe<(
    { __typename?: 'UpdateCareerHighlightPayload' }
    & { careerHighlight?: Maybe<(
      { __typename?: 'CareerHighlight' }
      & Pick<CareerHighlight, 'id' | 'title'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveClubMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveClubMemberMutation = (
  { __typename?: 'Mutation' }
  & { removeClubMember?: Maybe<(
    { __typename?: 'RemoveClubMemberPayload' }
    & { gameAccount?: Maybe<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & ClubFragment
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateClubMemberMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
  clubId: Scalars['String'];
}>;


export type UpdateClubMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateClubMember?: Maybe<(
    { __typename?: 'UpdateClubMemberPayload' }
    & { gameAccounts?: Maybe<Array<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & ClubFragment
      )> }
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateClubMutationVariables = Exact<{
  id: Scalars['ID'];
  icon?: Maybe<Scalars['File']>;
  themeColor: Scalars['String'];
  tier: ClubTier;
  description?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  beStar?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  founded?: Maybe<Scalars['ISO8601DateTime']>;
  origin?: Maybe<Scalars['String']>;
  introText?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
}>;


export type UpdateClubMutation = (
  { __typename?: 'Mutation' }
  & { updateClub?: Maybe<(
    { __typename?: 'UpdateClubPayload' }
    & { club?: Maybe<(
      { __typename?: 'Club' }
      & Pick<Club, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ChangeCombatRoomStateMutationVariables = Exact<{
  id: Scalars['ID'];
  action: Scalars['String'];
}>;


export type ChangeCombatRoomStateMutation = (
  { __typename?: 'Mutation' }
  & { changeCombatRoomState?: Maybe<(
    { __typename?: 'ChangeCombatRoomStatePayload' }
    & Pick<ChangeCombatRoomStatePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ChooseWinnerCombatRoomMutationVariables = Exact<{
  combatTeamAccepterId: Scalars['ID'];
  combatTeamChallengerId: Scalars['ID'];
  combatRoomId: Scalars['ID'];
  additionScore: Scalars['Int'];
  deductionScore: Scalars['Int'];
  combatTeamType: Scalars['String'];
}>;


export type ChooseWinnerCombatRoomMutation = (
  { __typename?: 'Mutation' }
  & { chooseWinnerCombatRoom?: Maybe<(
    { __typename?: 'ChooseWinnerCombatRoomPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateAddPointForFlaggedTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  combatRoomId: Scalars['ID'];
  score: Scalars['Int'];
}>;


export type CreateAddPointForFlaggedTeamMutation = (
  { __typename?: 'Mutation' }
  & { createAddPointForFlaggedTeam?: Maybe<(
    { __typename?: 'CreateAddPointForFlaggedTeamPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateSuspendCombatTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  reason: Scalars['String'];
}>;


export type CreateSuspendCombatTeamMutation = (
  { __typename?: 'Mutation' }
  & { createSuspendCombatTeam?: Maybe<(
    { __typename?: 'CreateSuspendCombatTeamPayload' }
    & { combatTeam?: Maybe<(
      { __typename?: 'CombatTeam' }
      & Pick<CombatTeam, 'id' | 'score' | 'suspended' | 'reason'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeductPointTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  combatRoomId: Scalars['ID'];
  deductScore: Scalars['Int'];
}>;


export type DeductPointTeamMutation = (
  { __typename?: 'Mutation' }
  & { deductPointTeam?: Maybe<(
    { __typename?: 'DeductPointTeamPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateAdminNoteCombatRoomMutationVariables = Exact<{
  id: Scalars['ID'];
  adminNote: Scalars['String'];
}>;


export type UpdateAdminNoteCombatRoomMutation = (
  { __typename?: 'Mutation' }
  & { updateAdminNoteCombatRoom?: Maybe<(
    { __typename?: 'UpdateAdminNoteCombatRoomPayload' }
    & { combatRoom?: Maybe<(
      { __typename?: 'CombatRoom' }
      & Pick<CombatRoom, 'id' | 'adminNote'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateMatchResultAdminMutationVariables = Exact<{
  combatRoomId: Scalars['ID'];
  combatTeamId: Scalars['ID'];
  resultScreenshots: Array<Scalars['File']>;
}>;


export type UpdateMatchResultAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateMatchResultAdmin?: Maybe<(
    { __typename?: 'UpdateMatchResultAdminPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateFaqMutationVariables = Exact<{
  question: Scalars['String'];
  answer: Scalars['String'];
  language: Language;
  position: Scalars['Int'];
  categoryColumns: CategoryColumns;
  apps: FaqApps;
}>;


export type CreateFaqMutation = (
  { __typename?: 'Mutation' }
  & { createFaq?: Maybe<(
    { __typename?: 'CreateFaqPayload' }
    & { faq?: Maybe<(
      { __typename?: 'Faq' }
      & Pick<Faq, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateFaqMutationVariables = Exact<{
  id: Scalars['ID'];
  question: Scalars['String'];
  answer: Scalars['String'];
  language: Language;
  position: Scalars['Int'];
  categoryColumns: CategoryColumns;
  apps: FaqApps;
}>;


export type UpdateFaqMutation = (
  { __typename?: 'Mutation' }
  & { updateFaq?: Maybe<(
    { __typename?: 'UpdateFaqPayload' }
    & { faq?: Maybe<(
      { __typename?: 'Faq' }
      & Pick<Faq, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateGameMessageMutationVariables = Exact<{
  gameChatroomId: Scalars['ID'];
  text: Scalars['String'];
  messageType: ChatroomMessage;
}>;


export type CreateGameMessageMutation = (
  { __typename?: 'Mutation' }
  & { createGameMessage?: Maybe<(
    { __typename?: 'CreateGameMessagePayload' }
    & { gameMessage?: Maybe<(
      { __typename?: 'GameMessage' }
      & Pick<GameMessage, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CheckPilotGameBundleMutationVariables = Exact<{
  nickname: Scalars['String'];
}>;


export type CheckPilotGameBundleMutation = (
  { __typename?: 'Mutation' }
  & { checkPilotGameBundle?: Maybe<(
    { __typename?: 'CheckPilotGameBundlePayload' }
    & { pilots?: Maybe<Array<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'nickname' | 'phoneNumber' | 'avatarUrl' | 'email'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CheckUserGameBundleMutationVariables = Exact<{
  nickname: Scalars['String'];
}>;


export type CheckUserGameBundleMutation = (
  { __typename?: 'Mutation' }
  & { checkUserGameBundle?: Maybe<(
    { __typename?: 'CheckUserGameBundlePayload' }
    & { users?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'phoneNumber' | 'avatarUrl'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ConfirmCompletedGameBundleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConfirmCompletedGameBundleMutation = (
  { __typename?: 'Mutation' }
  & { confirmCompletedGameBundle?: Maybe<(
    { __typename?: 'ConfirmCompletedGameBundlePayload' }
    & { gameBundle?: Maybe<(
      { __typename?: 'GameBundle' }
      & Pick<GameBundle, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateGameBundleMutationVariables = Exact<{
  userId: Scalars['ID'];
  pilotId: Scalars['ID'];
  currentGameRankId: Scalars['ID'];
  currentRankTier: Scalars['String'];
  currentRankStar: Scalars['String'];
  currentRankPoint: Scalars['String'];
  targetGameRankId: Scalars['ID'];
  targetRankTier: Scalars['String'];
  targetRankStar: Scalars['String'];
  targetRankPoint: Scalars['String'];
  gameId: Scalars['ID'];
  promoCode: Scalars['String'];
}>;


export type CreateGameBundleMutation = (
  { __typename?: 'Mutation' }
  & { createGameBundle?: Maybe<(
    { __typename?: 'CreateGameBundlePayload' }
    & { gameBundle?: Maybe<(
      { __typename?: 'GameBundle' }
      & Pick<GameBundle, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type PreviewAmountGameBundleMutationVariables = Exact<{
  userId: Scalars['ID'];
  pilotId: Scalars['ID'];
  currentGameRankId: Scalars['ID'];
  currentRankTier: Scalars['String'];
  currentRankStar: Scalars['String'];
  currentRankPoint: Scalars['String'];
  targetGameRankId: Scalars['ID'];
  targetRankTier: Scalars['String'];
  targetRankStar: Scalars['String'];
  targetRankPoint: Scalars['String'];
  gameId: Scalars['ID'];
  promoCode: Scalars['String'];
}>;


export type PreviewAmountGameBundleMutation = (
  { __typename?: 'Mutation' }
  & { previewAmountGameBundle?: Maybe<(
    { __typename?: 'PreviewAmountGameBundlePayload' }
    & Pick<PreviewAmountGameBundlePayload, 'amount' | 'userCreditWallet' | 'unlockAt'>
    & { previewAmount?: Maybe<(
      { __typename?: 'PreviewAmount' }
      & Pick<PreviewAmount, 'totalAmount'>
      & { priceBreakdown?: Maybe<Array<(
        { __typename?: 'PriceBreakdown' }
        & Pick<PriceBreakdown, 'lowerRank' | 'lowerTier' | 'lowerStar' | 'lowerPoint' | 'higherRank' | 'higherTier' | 'higherStar' | 'higherPoint'>
      )>> }
    )>, promoCodeApply?: Maybe<(
      { __typename?: 'PromoCodeApply' }
      & Pick<PromoCodeApply, 'success' | 'amount' | 'error'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RefundBundleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RefundBundleMutation = (
  { __typename?: 'Mutation' }
  & { refundBundle?: Maybe<(
    { __typename?: 'RefundBundlePayload' }
    & { gameBundle?: Maybe<(
      { __typename?: 'GameBundle' }
      & Pick<GameBundle, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type ReportGameBundleMutationVariables = Exact<{
  id: Scalars['ID'];
  reportReason: Scalars['String'];
}>;


export type ReportGameBundleMutation = (
  { __typename?: 'Mutation' }
  & { reportGameBundle?: Maybe<(
    { __typename?: 'ReportGameBundlePayload' }
    & { gameBundle?: Maybe<(
      { __typename?: 'GameBundle' }
      & Pick<GameBundle, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type ReviewBundleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReviewBundleMutation = (
  { __typename?: 'Mutation' }
  & { reviewBundle?: Maybe<(
    { __typename?: 'ReviewBundlePayload' }
    & { gameBundle?: Maybe<(
      { __typename?: 'GameBundle' }
      & Pick<GameBundle, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateAdminNoteGameBundleMutationVariables = Exact<{
  id: Scalars['ID'];
  adminNote: Scalars['String'];
}>;


export type UpdateAdminNoteGameBundleMutation = (
  { __typename?: 'Mutation' }
  & { updateAdminNoteGameBundle?: Maybe<(
    { __typename?: 'UpdateAdminNoteGameBundlePayload' }
    & { gameBundle?: Maybe<(
      { __typename?: 'GameBundle' }
      & Pick<GameBundle, 'id' | 'adminNote'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateRatingGameBundleMutationVariables = Exact<{
  id: Scalars['ID'];
  rating: Scalars['Int'];
}>;


export type UpdateRatingGameBundleMutation = (
  { __typename?: 'Mutation' }
  & { updateRatingGameBundle?: Maybe<(
    { __typename?: 'UpdateRatingGameBundlePayload' }
    & { gameBundle?: Maybe<(
      { __typename?: 'GameBundle' }
      & Pick<GameBundle, 'id' | 'rating'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AddGameRankMutationVariables = Exact<{
  gameId: Scalars['ID'];
  name: Scalars['String'];
  ordering: Scalars['Int'];
  rankTierMaxCount: Scalars['Int'];
  rankStarMaxCount: Scalars['Int'];
  rankPointMaxCount?: Maybe<Array<Scalars['Int']>>;
  image: Scalars['File'];
}>;


export type AddGameRankMutation = (
  { __typename?: 'Mutation' }
  & { addGameRank?: Maybe<(
    { __typename?: 'AddGameRankPayload' }
    & { gameRank?: Maybe<(
      { __typename?: 'GameRank' }
      & Pick<GameRank, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateGameMutationVariables = Exact<{
  name: Scalars['String'];
  shortname: Scalars['String'];
  developerName: Scalars['String'];
  genre: GameGenre;
  platform: GamePlatform;
  combat: Scalars['Boolean'];
  scrim: Scalars['Boolean'];
  realTime: Scalars['Boolean'];
  schedule: Scalars['Boolean'];
  matchmaking: Scalars['Boolean'];
  active: Scalars['Boolean'];
  icon: Scalars['File'];
  mascot: Scalars['File'];
  logo: Scalars['File'];
  screenshotSample: Scalars['File'];
  ordering?: Maybe<Scalars['Int']>;
  interestIds?: Maybe<Array<Scalars['ID']>>;
  tournament?: Maybe<Scalars['Boolean']>;
  themeColor: Scalars['String'];
  vocaCode?: Maybe<Scalars['String']>;
}>;


export type CreateGameMutation = (
  { __typename?: 'Mutation' }
  & { createGame?: Maybe<(
    { __typename?: 'CreateGamePayload' }
    & { game?: Maybe<(
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type RemoveGameRankMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveGameRankMutation = (
  { __typename?: 'Mutation' }
  & { removeGameRank?: Maybe<(
    { __typename?: 'RemoveGameRankPayload' }
    & { gameRank?: Maybe<(
      { __typename?: 'GameRank' }
      & Pick<GameRank, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateGameMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  shortname: Scalars['String'];
  developerName: Scalars['String'];
  genre: GameGenre;
  platform: GamePlatform;
  combat: Scalars['Boolean'];
  scrim: Scalars['Boolean'];
  realTime: Scalars['Boolean'];
  schedule: Scalars['Boolean'];
  matchmaking: Scalars['Boolean'];
  active: Scalars['Boolean'];
  icon?: Maybe<Scalars['File']>;
  mascot?: Maybe<Scalars['File']>;
  logo?: Maybe<Scalars['File']>;
  screenshotSample?: Maybe<Scalars['File']>;
  ordering?: Maybe<Scalars['Int']>;
  interestIds?: Maybe<Array<Scalars['ID']>>;
  tournament?: Maybe<Scalars['Boolean']>;
  themeColor?: Maybe<Scalars['String']>;
  vocaCode?: Maybe<Scalars['String']>;
}>;


export type UpdateGameMutation = (
  { __typename?: 'Mutation' }
  & { updateGame?: Maybe<(
    { __typename?: 'UpdateGamePayload' }
    & { game?: Maybe<(
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateGameRankMutationVariables = Exact<{
  id: Scalars['ID'];
  gameId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['Int']>;
  rankTierMaxCount?: Maybe<Scalars['Int']>;
  rankStarMaxCount?: Maybe<Scalars['Int']>;
  rankPointMaxCount?: Maybe<Array<Scalars['Int']>>;
  image?: Maybe<Scalars['File']>;
}>;


export type UpdateGameRankMutation = (
  { __typename?: 'Mutation' }
  & { updateGameRank?: Maybe<(
    { __typename?: 'UpdateGameRankPayload' }
    & { gameRank?: Maybe<(
      { __typename?: 'GameRank' }
      & Pick<GameRank, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateGamerBannerMutationVariables = Exact<{
  active: Scalars['Boolean'];
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  image: Scalars['File'];
  poster: Scalars['Boolean'];
  screen?: Maybe<Scalars['String']>;
}>;


export type CreateGamerBannerMutation = (
  { __typename?: 'Mutation' }
  & { createGamerBanner?: Maybe<(
    { __typename?: 'CreateGamerBannerPayload' }
    & { gamerBanner?: Maybe<(
      { __typename?: 'Banner' }
      & Pick<Banner, 'id' | 'title'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateGamerBannerMutationVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['File']>;
  poster: Scalars['Boolean'];
  screen?: Maybe<Scalars['String']>;
}>;


export type UpdateGamerBannerMutation = (
  { __typename?: 'Mutation' }
  & { updateGamerBanner?: Maybe<(
    { __typename?: 'UpdateGamerBannerPayload' }
    & { gamerBanner?: Maybe<(
      { __typename?: 'Banner' }
      & Pick<Banner, 'id' | 'title'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateInterestMutationVariables = Exact<{
  name: Scalars['String'];
  iconOn: Scalars['File'];
  iconOff: Scalars['File'];
  active: Scalars['Boolean'];
}>;


export type CreateInterestMutation = (
  { __typename?: 'Mutation' }
  & { createInterest?: Maybe<(
    { __typename?: 'CreateInterestPayload' }
    & { interest?: Maybe<(
      { __typename?: 'Interest' }
      & Pick<Interest, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateInterestMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  iconOn?: Maybe<Scalars['File']>;
  iconOff?: Maybe<Scalars['File']>;
  active: Scalars['Boolean'];
}>;


export type UpdateInterestMutation = (
  { __typename?: 'Mutation' }
  & { updateInterest?: Maybe<(
    { __typename?: 'UpdateInterestPayload' }
    & { interest?: Maybe<(
      { __typename?: 'Interest' }
      & Pick<Interest, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type GenerateLeaderboardAdminMutationVariables = Exact<{
  string: Scalars['String'];
}>;


export type GenerateLeaderboardAdminMutation = (
  { __typename?: 'Mutation' }
  & { generateLeaderboardAdmin?: Maybe<(
    { __typename?: 'GenerateLeaderboardAdminPayload' }
    & Pick<GenerateLeaderboardAdminPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateMarkupPricingMutationVariables = Exact<{
  countryCode: CountryCode;
  active: Scalars['Boolean'];
  uniqueId: Scalars['String'];
  percentage?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['ID']>;
  invoiceId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  vendorId: Scalars['ID'];
}>;


export type CreateMarkupPricingMutation = (
  { __typename?: 'Mutation' }
  & { createMarkupPricing?: Maybe<(
    { __typename?: 'CreateMarkupPricingPayload' }
    & { markupPricing?: Maybe<(
      { __typename?: 'MarkupPricing' }
      & Pick<MarkupPricing, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateMarkupPricingMutationVariables = Exact<{
  id: Scalars['ID'];
  percentage?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  uniqueId?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['ID']>;
  invoiceId?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCode>;
  itemId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  vendorId?: Maybe<Scalars['ID']>;
}>;


export type UpdateMarkupPricingMutation = (
  { __typename?: 'Mutation' }
  & { updateMarkupPricing?: Maybe<(
    { __typename?: 'UpdateMarkupPricingPayload' }
    & { markupPricing?: Maybe<(
      { __typename?: 'MarkupPricing' }
      & Pick<MarkupPricing, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CheckCategoryMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CheckCategoryMutation = (
  { __typename?: 'Mutation' }
  & { checkCategory?: Maybe<(
    { __typename?: 'CheckCategoryPayload' }
    & { categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CheckMerchandiseVariantMutationVariables = Exact<{
  sku: Scalars['String'];
}>;


export type CheckMerchandiseVariantMutation = (
  { __typename?: 'Mutation' }
  & { checkMerchandiseVariant?: Maybe<(
    { __typename?: 'CheckMerchandiseVariantPayload' }
    & { merchandiseVariants?: Maybe<Array<(
      { __typename?: 'MerchandiseVariant' }
      & Pick<MerchandiseVariant, 'id' | 'name' | 'sku' | 'price' | 'stock'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CheckPostMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CheckPostMutation = (
  { __typename?: 'Mutation' }
  & { checkPost?: Maybe<(
    { __typename?: 'CheckPostPayload' }
    & { posts?: Maybe<Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'url' | 'isYoutube' | 'views' | 'active' | 'title'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateCategoryMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory?: Maybe<(
    { __typename?: 'CreateCategoryPayload' }
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateMerchandiseMutationVariables = Exact<{
  name: Scalars['String'];
  active: Scalars['Boolean'];
  images: Array<Scalars['File']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  postId: Scalars['ID'];
  categoryId: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
}>;


export type CreateMerchandiseMutation = (
  { __typename?: 'Mutation' }
  & { createMerchandise?: Maybe<(
    { __typename?: 'CreateMerchandisePayload' }
    & { merchandise?: Maybe<(
      { __typename?: 'Merchandise' }
      & Pick<Merchandise, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateMerchandiseVariantMutationVariables = Exact<{
  merchandiseId: Scalars['ID'];
  name: Scalars['String'];
  sku: Scalars['String'];
  price: Scalars['Float'];
  stock: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
}>;


export type CreateMerchandiseVariantMutation = (
  { __typename?: 'Mutation' }
  & { createMerchandiseVariant?: Maybe<(
    { __typename?: 'CreateMerchandiseVariantPayload' }
    & { merchandiseVariant?: Maybe<(
      { __typename?: 'MerchandiseVariant' }
      & Pick<MerchandiseVariant, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateMerchandiseMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  postId: Scalars['ID'];
  categoryId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
}>;


export type UpdateMerchandiseMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchandise?: Maybe<(
    { __typename?: 'UpdateMerchandisePayload' }
    & { merchandise?: Maybe<(
      { __typename?: 'Merchandise' }
      & Pick<Merchandise, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateMerchandiseImagesMutationVariables = Exact<{
  id: Scalars['ID'];
  isUpdate: Scalars['Boolean'];
  images?: Maybe<Array<Scalars['File']>>;
}>;


export type UpdateMerchandiseImagesMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchandiseImages?: Maybe<(
    { __typename?: 'UpdateMerchandiseImagesPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateMerchandiseVariantMutationVariables = Exact<{
  id: Scalars['ID'];
  merchandiseId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
}>;


export type UpdateMerchandiseVariantMutation = (
  { __typename?: 'Mutation' }
  & { updateMerchandiseVariant?: Maybe<(
    { __typename?: 'UpdateMerchandiseVariantPayload' }
    & { merchandiseVariant?: Maybe<(
      { __typename?: 'MerchandiseVariant' }
      & Pick<MerchandiseVariant, 'id' | 'name'>
      & { merchandise: (
        { __typename?: 'Merchandise' }
        & Pick<Merchandise, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateOfficialChannelMutationVariables = Exact<{
  active: Scalars['Boolean'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
}>;


export type CreateOfficialChannelMutation = (
  { __typename?: 'Mutation' }
  & { createOfficialChannel?: Maybe<(
    { __typename?: 'CreateOfficialChannelPayload' }
    & { officialChannel?: Maybe<(
      { __typename?: 'OfficialChannel' }
      & OfficialChannelFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateOfficialChannelMutationVariables = Exact<{
  id: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
}>;


export type UpdateOfficialChannelMutation = (
  { __typename?: 'Mutation' }
  & { updateOfficialChannel?: Maybe<(
    { __typename?: 'UpdateOfficialChannelPayload' }
    & { officialChannel?: Maybe<(
      { __typename?: 'OfficialChannel' }
      & OfficialChannelFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateOrderStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  statusType: Scalars['String'];
}>;


export type UpdateOrderStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderStatus?: Maybe<(
    { __typename?: 'UpdateOrderStatusPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateTrackingOrderMutationVariables = Exact<{
  id: Scalars['ID'];
  trackingNumber: Scalars['String'];
  trackingUrl: Scalars['String'];
}>;


export type UpdateTrackingOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateTrackingOrder?: Maybe<(
    { __typename?: 'UpdateTrackingOrderPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CompletePartneringRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompletePartneringRequestMutation = (
  { __typename?: 'Mutation' }
  & { completePartneringRequest?: Maybe<(
    { __typename?: 'CompletePartneringRequestPayload' }
    & { partneringRequest?: Maybe<(
      { __typename?: 'PartneringRequest' }
      & Pick<PartneringRequest, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type RefundPartneringRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RefundPartneringRequestMutation = (
  { __typename?: 'Mutation' }
  & { refundPartneringRequest?: Maybe<(
    { __typename?: 'RefundPartneringRequestPayload' }
    & { partneringRequest?: Maybe<(
      { __typename?: 'PartneringRequest' }
      & Pick<PartneringRequest, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type ReportPartneringRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  reportReason: Scalars['String'];
}>;


export type ReportPartneringRequestMutation = (
  { __typename?: 'Mutation' }
  & { reportPartneringRequest?: Maybe<(
    { __typename?: 'ReportPartneringRequestPayload' }
    & { partneringRequest?: Maybe<(
      { __typename?: 'PartneringRequest' }
      & Pick<PartneringRequest, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type ReviewPartneringRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReviewPartneringRequestMutation = (
  { __typename?: 'Mutation' }
  & { reviewPartneringRequest?: Maybe<(
    { __typename?: 'ReviewPartneringRequestPayload' }
    & { partneringRequest?: Maybe<(
      { __typename?: 'PartneringRequest' }
      & Pick<PartneringRequest, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateAdminNotePartneringRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  adminNote: Scalars['String'];
}>;


export type UpdateAdminNotePartneringRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateAdminNotePartneringRequest?: Maybe<(
    { __typename?: 'UpdateAdminNotePartneringRequestPayload' }
    & { partneringRequest?: Maybe<(
      { __typename?: 'PartneringRequest' }
      & Pick<PartneringRequest, 'id' | 'adminNote'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateRatingPartneringRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  rating: Scalars['Int'];
}>;


export type UpdateRatingPartneringRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateRatingPartneringRequest?: Maybe<(
    { __typename?: 'UpdateRatingPartneringRequestPayload' }
    & { partneringRequest?: Maybe<(
      { __typename?: 'PartneringRequest' }
      & Pick<PartneringRequest, 'id' | 'rating'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ApprovePayoutTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApprovePayoutTransactionMutation = (
  { __typename?: 'Mutation' }
  & { approvePayoutTransaction?: Maybe<(
    { __typename?: 'ApprovePayload' }
    & { payout?: Maybe<(
      { __typename?: 'Payout' }
      & PayoutFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CompletePayoutTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompletePayoutTransactionMutation = (
  { __typename?: 'Mutation' }
  & { completePayoutTransaction?: Maybe<(
    { __typename?: 'CompletePayload' }
    & { payout?: Maybe<(
      { __typename?: 'Payout' }
      & PayoutFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePayoutMutationVariables = Exact<{
  amount: Scalars['Int'];
  pilotId: Scalars['ID'];
}>;


export type CreatePayoutMutation = (
  { __typename?: 'Mutation' }
  & { createPayout?: Maybe<(
    { __typename?: 'CreatePayoutPayload' }
    & { payout?: Maybe<(
      { __typename?: 'Payout' }
      & PayoutFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type FailedPayoutTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FailedPayoutTransactionMutation = (
  { __typename?: 'Mutation' }
  & { failedPayoutTransaction?: Maybe<(
    { __typename?: 'FailedPayload' }
    & { payout?: Maybe<(
      { __typename?: 'Payout' }
      & PayoutFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RejectPayoutTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
  rejectReason: Scalars['String'];
}>;


export type RejectPayoutTransactionMutation = (
  { __typename?: 'Mutation' }
  & { rejectPayoutTransaction?: Maybe<(
    { __typename?: 'RejectPayload' }
    & { payout?: Maybe<(
      { __typename?: 'Payout' }
      & PayoutFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateAdminNotePayoutMutationVariables = Exact<{
  id: Scalars['ID'];
  adminNote: Scalars['String'];
}>;


export type UpdateAdminNotePayoutMutation = (
  { __typename?: 'Mutation' }
  & { updateAdminNotePayout?: Maybe<(
    { __typename?: 'UpdateAdminNotePayoutPayload' }
    & { payout?: Maybe<(
      { __typename?: 'Payout' }
      & Pick<Payout, 'id' | 'adminNote'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePilotBannerMutationVariables = Exact<{
  active: Scalars['Boolean'];
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  image: Scalars['File'];
  poster: Scalars['Boolean'];
  screen?: Maybe<Scalars['String']>;
}>;


export type CreatePilotBannerMutation = (
  { __typename?: 'Mutation' }
  & { createPilotBanner?: Maybe<(
    { __typename?: 'CreatePilotBannerPayload' }
    & { pilotBanner?: Maybe<(
      { __typename?: 'PilotBanner' }
      & Pick<PilotBanner, 'id' | 'title'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdatePilotBannerMutationVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['File']>;
  poster: Scalars['Boolean'];
  screen?: Maybe<Scalars['String']>;
}>;


export type UpdatePilotBannerMutation = (
  { __typename?: 'Mutation' }
  & { updatePilotBanner?: Maybe<(
    { __typename?: 'UpdatePilotBannerPayload' }
    & { pilotBanner?: Maybe<(
      { __typename?: 'PilotBanner' }
      & Pick<PilotBanner, 'id' | 'title'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type DeliverPilotRewardMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeliverPilotRewardMutation = (
  { __typename?: 'Mutation' }
  & { deliverPilotReward?: Maybe<(
    { __typename?: 'DeliverPilotRewardPayload' }
    & { pilotReward?: Maybe<(
      { __typename?: 'PilotReward' }
      & Pick<PilotReward, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type RemovePilotTierMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemovePilotTierMutation = (
  { __typename?: 'Mutation' }
  & { removePilotTier?: Maybe<(
    { __typename?: 'RemovePilotTierPayload' }
    & { gameAccount?: Maybe<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id'>
      & { pilotTier?: Maybe<(
        { __typename?: 'PilotTier' }
        & PilotTierFragment
      )> }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdatePilotTierMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
  pilotTierId: Scalars['String'];
}>;


export type UpdatePilotTierMutation = (
  { __typename?: 'Mutation' }
  & { updatePilotTier?: Maybe<(
    { __typename?: 'UpdatePilotTierPayload' }
    & { gameAccounts?: Maybe<Array<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id'>
      & { pilotTier?: Maybe<(
        { __typename?: 'PilotTier' }
        & PilotTierFragment
      )> }
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ActivatePilotMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivatePilotMutation = (
  { __typename?: 'Mutation' }
  & { activatePilot?: Maybe<(
    { __typename?: 'ActivatePilotPayload' }
    & { pilot?: Maybe<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'suspend'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ApproveBankInfoMutationVariables = Exact<{
  pilotId: Scalars['ID'];
}>;


export type ApproveBankInfoMutation = (
  { __typename?: 'Mutation' }
  & { approveBankInfo?: Maybe<(
    { __typename?: 'ApproveBankInfoPayload' }
    & { bankInfo?: Maybe<(
      { __typename?: 'BankInfo' }
      & BankInfoFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ApproveScreenshotMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveScreenshotMutation = (
  { __typename?: 'Mutation' }
  & { approveScreenshot?: Maybe<(
    { __typename?: 'ApproveScreenshotPayload' }
    & { gameAccount?: Maybe<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'screenshotState' | 'codeState'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ApproveSuspendRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveSuspendRequestMutation = (
  { __typename?: 'Mutation' }
  & { approveSuspendRequest?: Maybe<(
    { __typename?: 'ApproveSuspendRequestPayload' }
    & { suspendRequest?: Maybe<(
      { __typename?: 'SuspendRequest' }
      & SuspendRequestFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ApproveVerificationCodeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveVerificationCodeMutation = (
  { __typename?: 'Mutation' }
  & { approveVerificationCode?: Maybe<(
    { __typename?: 'ApproveVerificationCodePayload' }
    & { gameAccount?: Maybe<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'screenshotState' | 'codeState'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePilotMutationVariables = Exact<{
  phoneNumber?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  email: Scalars['String'];
  birthday: Scalars['ISO8601DateTime'];
  accountId: Scalars['String'];
  gameId: Scalars['ID'];
  gameRankId: Scalars['ID'];
  rankTier: Scalars['Int'];
  rankStar: Scalars['Int'];
  rankPoint: Scalars['Int'];
  screenshots: Array<Scalars['File']>;
  image1: Scalars['File'];
  image2: Scalars['File'];
  image3: Scalars['File'];
  name: Scalars['String'];
  bankName: Scalars['String'];
  bankAccountNo: Scalars['String'];
  country: Scalars['String'];
  ic: Scalars['String'];
  icImage: Scalars['File'];
  selfie: Scalars['File'];
}>;


export type CreatePilotMutation = (
  { __typename?: 'Mutation' }
  & { createPilot?: Maybe<(
    { __typename?: 'CreatePilotPayload' }
    & { pilot?: Maybe<(
      { __typename?: 'Pilot' }
      & PilotFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateSuspendRequestMutationVariables = Exact<{
  pilotId: Scalars['ID'];
  reason: Scalars['String'];
}>;


export type CreateSuspendRequestMutation = (
  { __typename?: 'Mutation' }
  & { createSuspendRequest?: Maybe<(
    { __typename?: 'CreateSuspendRequestPayload' }
    & { suspendRequest?: Maybe<(
      { __typename?: 'SuspendRequest' }
      & SuspendRequestFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RejectBankInfoMutationVariables = Exact<{
  pilotId: Scalars['ID'];
  rejectReason: Scalars['String'];
}>;


export type RejectBankInfoMutation = (
  { __typename?: 'Mutation' }
  & { rejectBankInfo?: Maybe<(
    { __typename?: 'RejectBankInfoPayload' }
    & { bankInfo?: Maybe<(
      { __typename?: 'BankInfo' }
      & BankInfoFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RejectScreenshotMutationVariables = Exact<{
  id: Scalars['ID'];
  rejectReason: Scalars['String'];
}>;


export type RejectScreenshotMutation = (
  { __typename?: 'Mutation' }
  & { rejectScreenshot?: Maybe<(
    { __typename?: 'RejectScreenshotPayload' }
    & { gameAccount?: Maybe<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'screenshotState' | 'codeState'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RejectSuspendRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RejectSuspendRequestMutation = (
  { __typename?: 'Mutation' }
  & { rejectSuspendRequest?: Maybe<(
    { __typename?: 'RejectSuspendRequestPayload' }
    & { suspendRequest?: Maybe<(
      { __typename?: 'SuspendRequest' }
      & SuspendRequestFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RejectVerificationCodeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RejectVerificationCodeMutation = (
  { __typename?: 'Mutation' }
  & { rejectVerificationCode?: Maybe<(
    { __typename?: 'RejectVerificationCodePayload' }
    & { gameAccount?: Maybe<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'screenshotState' | 'codeState'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemindPilotTestMutationVariables = Exact<{
  gameAccountId: Scalars['ID'];
}>;


export type RemindPilotTestMutation = (
  { __typename?: 'Mutation' }
  & { remindPilotTest?: Maybe<(
    { __typename?: 'RemindPilotTestPayload' }
    & { gameAccount?: Maybe<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'remindAt' | 'remindCount'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemovePilotCreditTransactionMutationVariables = Exact<{
  creditTransactionId: Scalars['ID'];
}>;


export type RemovePilotCreditTransactionMutation = (
  { __typename?: 'Mutation' }
  & { removePilotCreditTransaction?: Maybe<(
    { __typename?: 'RemovePilotCreditTransactionPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateTrainedPilotMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateTrainedPilotMutation = (
  { __typename?: 'Mutation' }
  & { updateTrainedPilot?: Maybe<(
    { __typename?: 'UpdateTrainedPilotPayload' }
    & { pilot?: Maybe<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'trained'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CancelTeamWalletMutationVariables = Exact<{
  id: Scalars['ID'];
  combatTeamId?: Maybe<Scalars['ID']>;
}>;


export type CancelTeamWalletMutation = (
  { __typename?: 'Mutation' }
  & { cancelTeamWallet?: Maybe<(
    { __typename?: 'CancelTeamWalletPayload' }
    & { poolPrize?: Maybe<(
      { __typename?: 'PoolPrize' }
      & PoolPrizeFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdatePoolPrizeMutationVariables = Exact<{
  id: Scalars['ID'];
  leaderboardId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  ordering?: Maybe<Scalars['Int']>;
}>;


export type UpdatePoolPrizeMutation = (
  { __typename?: 'Mutation' }
  & { updatePoolPrize?: Maybe<(
    { __typename?: 'UpdatePoolPrizePayload' }
    & { poolPrize?: Maybe<(
      { __typename?: 'PoolPrize' }
      & PoolPrizeFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateTeamWalletMutationVariables = Exact<{
  id: Scalars['ID'];
  combatTeamId?: Maybe<Scalars['ID']>;
}>;


export type UpdateTeamWalletMutation = (
  { __typename?: 'Mutation' }
  & { updateTeamWallet?: Maybe<(
    { __typename?: 'UpdateTeamWalletPayload' }
    & { poolPrize?: Maybe<(
      { __typename?: 'PoolPrize' }
      & PoolPrizeFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreatePostMutationVariables = Exact<{
  url?: Maybe<Scalars['String']>;
  isYoutube?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  postType?: Maybe<PostType>;
  newsImage?: Maybe<Scalars['File']>;
}>;


export type CreatePostMutation = (
  { __typename?: 'Mutation' }
  & { createPost?: Maybe<(
    { __typename?: 'CreatePostPayload' }
    & { post?: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdatePostMutationVariables = Exact<{
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  isYoutube?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  postType?: Maybe<PostType>;
  newsImage?: Maybe<Scalars['File']>;
}>;


export type UpdatePostMutation = (
  { __typename?: 'Mutation' }
  & { updatePost?: Maybe<(
    { __typename?: 'UpdatePostPayload' }
    & { post?: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreatePrizeMutationVariables = Exact<{
  value: Scalars['Float'];
  prizeName: Scalars['String'];
  prizeType: PrizeType;
  icon: Scalars['File'];
  position: Scalars['Int'];
  active: Scalars['Boolean'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type CreatePrizeMutation = (
  { __typename?: 'Mutation' }
  & { createPrize?: Maybe<(
    { __typename?: 'CreatePrizePayload' }
    & { prize?: Maybe<(
      { __typename?: 'Prize' }
      & Pick<Prize, 'id' | 'prizeName'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdatePrizeMutationVariables = Exact<{
  id: Scalars['ID'];
  value?: Maybe<Scalars['Float']>;
  prizeName?: Maybe<Scalars['String']>;
  prizeType?: Maybe<PrizeType>;
  icon?: Maybe<Scalars['File']>;
  position?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type UpdatePrizeMutation = (
  { __typename?: 'Mutation' }
  & { updatePrize?: Maybe<(
    { __typename?: 'UpdatePrizePayload' }
    & { prize?: Maybe<(
      { __typename?: 'Prize' }
      & Pick<Prize, 'id' | 'prizeName'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreateProficientRoleMutationVariables = Exact<{
  name: Scalars['String'];
  kind: ProficientRoleKind;
  image: Scalars['File'];
  selectedImage: Scalars['File'];
  gameId: Scalars['ID'];
}>;


export type CreateProficientRoleMutation = (
  { __typename?: 'Mutation' }
  & { createProficientRole?: Maybe<(
    { __typename?: 'CreateProficientRolePayload' }
    & { proficientRole?: Maybe<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateProficientRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<ProficientRoleKind>;
  image?: Maybe<Scalars['File']>;
  selectedImage?: Maybe<Scalars['File']>;
  gameId?: Maybe<Scalars['ID']>;
}>;


export type UpdateProficientRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateProficientRole?: Maybe<(
    { __typename?: 'UpdateProficientRolePayload' }
    & { proficientRole?: Maybe<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreatePromoCodeMutationVariables = Exact<{
  active: Scalars['Boolean'];
  amount: Scalars['Int'];
  minimumAmount: Scalars['Int'];
  availableFor: Scalars['String'];
  code: Scalars['String'];
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  gameType: Scalars['String'];
  redeemLimit?: Maybe<Scalars['Int']>;
  userIds?: Maybe<Array<Scalars['String']>>;
  prosOnly?: Maybe<Scalars['Boolean']>;
  whitelistsOnly?: Maybe<Scalars['Boolean']>;
}>;


export type CreatePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { createPromoCode?: Maybe<(
    { __typename?: 'CreatePromoCodePayload' }
    & { promoCode?: Maybe<(
      { __typename?: 'PromoCode' }
      & PromoCodeFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdatePromoCodeMutationVariables = Exact<{
  id: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Int']>;
  minimumAmount?: Maybe<Scalars['Int']>;
  availableFor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  gameType?: Maybe<Scalars['String']>;
  redeemLimit?: Maybe<Scalars['Int']>;
  userIds?: Maybe<Array<Scalars['String']>>;
}>;


export type UpdatePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { updatePromoCode?: Maybe<(
    { __typename?: 'UpdatePromoCodePayload' }
    & { promoCode?: Maybe<(
      { __typename?: 'PromoCode' }
      & Pick<PromoCode, 'id' | 'active' | 'amount' | 'minimumAmount' | 'code' | 'availableFor' | 'validUntil'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CreatePublisherMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  publisherLogo?: Maybe<Scalars['File']>;
}>;


export type CreatePublisherMutation = (
  { __typename?: 'Mutation' }
  & { createPublisher?: Maybe<(
    { __typename?: 'CreatePublisherPayload' }
    & { publisher?: Maybe<(
      { __typename?: 'Publisher' }
      & PublisherFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdatePublisherMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdatePublisherMutation = (
  { __typename?: 'Mutation' }
  & { updatePublisher?: Maybe<(
    { __typename?: 'UpdatePublisherPayload' }
    & { publisher?: Maybe<(
      { __typename?: 'Publisher' }
      & PublisherFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type ApproveOrRejectReportRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
}>;


export type ApproveOrRejectReportRequestMutation = (
  { __typename?: 'Mutation' }
  & { approveOrRejectReportRequest?: Maybe<(
    { __typename?: 'ApproveOrRejectReportRequestPayload' }
    & { reportRequests?: Maybe<Array<(
      { __typename?: 'ReportRequest' }
      & Pick<ReportRequest, 'id' | 'reason'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type CheckGamerBannerMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CheckGamerBannerMutation = (
  { __typename?: 'Mutation' }
  & { checkGamerBanner?: Maybe<(
    { __typename?: 'CheckGamerBannerPayload' }
    & { gamerBanners?: Maybe<Array<(
      { __typename?: 'GamerBanner' }
      & Pick<GamerBanner, 'id' | 'url' | 'title'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateSponsorAdMutationVariables = Exact<{
  adsName: Scalars['String'];
  sponsorName: Scalars['String'];
  url: Scalars['String'];
  assetType?: Maybe<SponsorAdAsset>;
  layoutType?: Maybe<SponsorAdLayout>;
  image?: Maybe<Scalars['File']>;
  video?: Maybe<Scalars['File']>;
  active?: Maybe<Scalars['Boolean']>;
  gamerBannerId?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['Int']>;
  screenRoute?: Maybe<Scalars['String']>;
}>;


export type CreateSponsorAdMutation = (
  { __typename?: 'Mutation' }
  & { createSponsorAd?: Maybe<(
    { __typename?: 'CreateSponsorAdPayload' }
    & { sponsorAd?: Maybe<(
      { __typename?: 'SponsorAd' }
      & Pick<SponsorAd, 'id' | 'adsName'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateSponsorAdMutationVariables = Exact<{
  id: Scalars['ID'];
  adsName?: Maybe<Scalars['String']>;
  sponsorName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  assetType?: Maybe<SponsorAdAsset>;
  layoutType?: Maybe<SponsorAdLayout>;
  image?: Maybe<Scalars['File']>;
  video?: Maybe<Scalars['File']>;
  active?: Maybe<Scalars['Boolean']>;
  gamerBannerId?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['Int']>;
  screenRoute?: Maybe<Scalars['String']>;
}>;


export type UpdateSponsorAdMutation = (
  { __typename?: 'Mutation' }
  & { updateSponsorAd?: Maybe<(
    { __typename?: 'UpdateSponsorAdPayload' }
    & { sponsorAd?: Maybe<(
      { __typename?: 'SponsorAd' }
      & Pick<SponsorAd, 'id' | 'adsName'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type ApproveTeamPayoutTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveTeamPayoutTransactionMutation = (
  { __typename?: 'Mutation' }
  & { approveTeamPayoutTransaction?: Maybe<(
    { __typename?: 'TeamApprovePayload' }
    & { teamPayout?: Maybe<(
      { __typename?: 'TeamPayout' }
      & TeamPayoutFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CompleteTeamPayoutTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompleteTeamPayoutTransactionMutation = (
  { __typename?: 'Mutation' }
  & { completeTeamPayoutTransaction?: Maybe<(
    { __typename?: 'TeamCompletePayload' }
    & { teamPayout?: Maybe<(
      { __typename?: 'TeamPayout' }
      & TeamPayoutFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type FailedTeamPayoutTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FailedTeamPayoutTransactionMutation = (
  { __typename?: 'Mutation' }
  & { failedTeamPayoutTransaction?: Maybe<(
    { __typename?: 'TeamFailedPayload' }
    & { teamPayout?: Maybe<(
      { __typename?: 'TeamPayout' }
      & TeamPayoutFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RejectTeamPayoutTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
  rejectReason: Scalars['String'];
}>;


export type RejectTeamPayoutTransactionMutation = (
  { __typename?: 'Mutation' }
  & { rejectTeamPayoutTransaction?: Maybe<(
    { __typename?: 'TeamRejectPayload' }
    & { teamPayout?: Maybe<(
      { __typename?: 'TeamPayout' }
      & TeamPayoutFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateAdminNoteTeamPayoutMutationVariables = Exact<{
  id: Scalars['ID'];
  adminNote: Scalars['String'];
}>;


export type UpdateAdminNoteTeamPayoutMutation = (
  { __typename?: 'Mutation' }
  & { updateAdminNoteTeamPayout?: Maybe<(
    { __typename?: 'UpdateAdminNoteTeamPayoutPayload' }
    & { teamPayout?: Maybe<(
      { __typename?: 'TeamPayout' }
      & Pick<TeamPayout, 'id' | 'adminNote'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AddMemberMutationVariables = Exact<{
  userId: Scalars['ID'];
  teamId: Scalars['ID'];
}>;


export type AddMemberMutation = (
  { __typename?: 'Mutation' }
  & { addMember?: Maybe<(
    { __typename?: 'AddMemberPayload' }
    & Pick<AddMemberPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CheckPilotMutationVariables = Exact<{
  nickname: Scalars['String'];
}>;


export type CheckPilotMutation = (
  { __typename?: 'Mutation' }
  & { checkPilot?: Maybe<(
    { __typename?: 'CheckPilotPayload' }
    & { pilots?: Maybe<Array<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'nickname' | 'phoneNumber' | 'avatarUrl'>
    )>>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateSuspendTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  reason: Scalars['String'];
}>;


export type CreateSuspendTeamMutation = (
  { __typename?: 'Mutation' }
  & { createSuspendTeam?: Maybe<(
    { __typename?: 'CreateSuspendTeamPayload' }
    & { combatTeam?: Maybe<(
      { __typename?: 'CombatTeam' }
      & Pick<CombatTeam, 'id' | 'suspended' | 'reason'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateSuspendTeamAndMembersMutationVariables = Exact<{
  id: Scalars['ID'];
  reason: Scalars['String'];
}>;


export type CreateSuspendTeamAndMembersMutation = (
  { __typename?: 'Mutation' }
  & { createSuspendTeamAndMembers?: Maybe<(
    { __typename?: 'CreateSuspendTeamAndMembersPayload' }
    & Pick<CreateSuspendTeamAndMembersPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateTeamMutationVariables = Exact<{
  name: Scalars['String'];
  icon: Scalars['File'];
  themeColor: Scalars['String'];
  tier: ClubTier;
  description?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam?: Maybe<(
    { __typename?: 'CreateTeamPayload' }
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateClubMutationVariables = Exact<{
  name: Scalars['String'];
  icon: Scalars['File'];
  themeColor: Scalars['String'];
  tier: ClubTier;
  description?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
}>;


export type CreateClubMutation = (
  { __typename?: 'Mutation' }
  & { createClub?: Maybe<(
    { __typename?: 'CreateClubPayload' }
    & { club?: Maybe<(
      { __typename?: 'Club' }
      & Pick<Club, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveTeamMemberMutationVariables = Exact<{
  userId: Scalars['ID'];
  teamId: Scalars['ID'];
}>;


export type RemoveTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & { removeTeamMember?: Maybe<(
    { __typename?: 'RemoveTeamMemberPayload' }
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RevokeSuspendCurrentCombatTeamMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RevokeSuspendCurrentCombatTeamMutation = (
  { __typename?: 'Mutation' }
  & { revokeSuspendCurrentCombatTeam?: Maybe<(
    { __typename?: 'RevokeSuspendCurrentCombatTeamPayload' }
    & { combatTeam?: Maybe<(
      { __typename?: 'CombatTeam' }
      & Pick<CombatTeam, 'id' | 'suspended' | 'reason'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RevokeTeamSuspensionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RevokeTeamSuspensionMutation = (
  { __typename?: 'Mutation' }
  & { revokeTeamSuspension?: Maybe<(
    { __typename?: 'RevokeTeamSuspensionPayload' }
    & Pick<RevokeTeamSuspensionPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateTopUpProductMutationVariables = Exact<{
  originId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  vendorId: Scalars['ID'];
  vocaId?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
  categoryId: Scalars['ID'];
  genre?: Maybe<GameGenre>;
  featured?: Maybe<Scalars['Boolean']>;
  ordering?: Maybe<Scalars['Int']>;
  publisherId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['File']>;
  cover?: Maybe<Scalars['File']>;
  avatar?: Maybe<Scalars['File']>;
  helper?: Maybe<Scalars['File']>;
}>;


export type CreateTopUpProductMutation = (
  { __typename?: 'Mutation' }
  & { createTopUpProduct?: Maybe<(
    { __typename?: 'CreateTopUpProductPayload' }
    & { topUpProduct?: Maybe<(
      { __typename?: 'TopupProduct' }
      & TopUpProductFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateTopUpProductMutationVariables = Exact<{
  id: Scalars['ID'];
  originId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['ID']>;
  vocaId?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
  categoryId?: Maybe<Scalars['ID']>;
  genre?: Maybe<GameGenre>;
  featured?: Maybe<Scalars['Boolean']>;
  ordering?: Maybe<Scalars['Int']>;
  publisherId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['File']>;
  cover?: Maybe<Scalars['File']>;
  avatar?: Maybe<Scalars['File']>;
  helper?: Maybe<Scalars['File']>;
  publisherLogo?: Maybe<Scalars['File']>;
}>;


export type UpdateTopUpProductMutation = (
  { __typename?: 'Mutation' }
  & { updateTopUpProduct?: Maybe<(
    { __typename?: 'UpdateTopUpProductPayload' }
    & { topUpProduct?: Maybe<(
      { __typename?: 'TopupProduct' }
      & TopUpProductFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateTournamentEventMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateTournamentEventMutation = (
  { __typename?: 'Mutation' }
  & { updateTournamentEvent?: Maybe<(
    { __typename?: 'UpdateTournamentEventPayload' }
    & { tournamentEvent?: Maybe<(
      { __typename?: 'TournamentEvent' }
      & Pick<TournamentEvent, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type TournamentTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  stateRequest: Scalars['String'];
}>;


export type TournamentTeamMutation = (
  { __typename?: 'Mutation' }
  & { tournamentTeam?: Maybe<(
    { __typename?: 'TournamentTeamPayload' }
    & { tournamentTeam?: Maybe<(
      { __typename?: 'TournamentTeam' }
      & Pick<TournamentTeam, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateTournamentMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['File']>;
  backgroundImage?: Maybe<Scalars['File']>;
}>;


export type UpdateTournamentMutation = (
  { __typename?: 'Mutation' }
  & { updateTournament?: Maybe<(
    { __typename?: 'UpdateTournamentPayload' }
    & { tournament?: Maybe<(
      { __typename?: 'Tournament' }
      & Pick<Tournament, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type BlastGamersMutationVariables = Exact<{
  title: Scalars['String'];
  body: Scalars['String'];
  userIds?: Maybe<Array<Scalars['ID']>>;
  isScheduled?: Maybe<Scalars['Boolean']>;
  scheduleDate?: Maybe<Scalars['ISO8601DateTime']>;
  scheduleTime?: Maybe<Scalars['String']>;
  scheduleDatetime?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type BlastGamersMutation = (
  { __typename?: 'Mutation' }
  & { blastGamers?: Maybe<(
    { __typename?: 'BlastGamersPayload' }
    & Pick<BlastGamersPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteUserBlastScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserBlastScheduleMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserBlastSchedule?: Maybe<(
    { __typename?: 'DeleteUserBlastSchedulePayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RescheduleBlastMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RescheduleBlastMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleBlast?: Maybe<(
    { __typename?: 'RescheduleBlastPayload' }
    & Pick<RescheduleBlastPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateUserBlastScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  userIds?: Maybe<Array<Scalars['ID']>>;
  isScheduled?: Maybe<Scalars['Boolean']>;
  scheduleDate?: Maybe<Scalars['ISO8601DateTime']>;
  scheduleTime?: Maybe<Scalars['String']>;
  scheduleDatetime?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type UpdateUserBlastScheduleMutation = (
  { __typename?: 'Mutation' }
  & { updateUserBlastSchedule?: Maybe<(
    { __typename?: 'UpdateUserBlastSchedulePayload' }
    & Pick<UpdateUserBlastSchedulePayload, 'success'>
    & { userBlastSchedule?: Maybe<(
      { __typename?: 'UserBlastSchedule' }
      & Pick<UserBlastSchedule, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeliverUserPrizeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeliverUserPrizeMutation = (
  { __typename?: 'Mutation' }
  & { deliverUserPrize?: Maybe<(
    { __typename?: 'DeliverUserPrizePayload' }
    & { userPrize?: Maybe<(
      { __typename?: 'UserPrize' }
      & Pick<UserPrize, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type ActivateUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & { activateUser?: Maybe<(
    { __typename?: 'ActivateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'suspend'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ApproveSuspendGamerRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveSuspendGamerRequestMutation = (
  { __typename?: 'Mutation' }
  & { approveSuspendGamerRequest?: Maybe<(
    { __typename?: 'ApproveSuspendGamerRequestPayload' }
    & { suspendRequest?: Maybe<(
      { __typename?: 'SuspendGamerRequest' }
      & SuspendGamerRequestFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateSuspendGamerRequestMutationVariables = Exact<{
  userId: Scalars['ID'];
  reason: Scalars['String'];
}>;


export type CreateSuspendGamerRequestMutation = (
  { __typename?: 'Mutation' }
  & { createSuspendGamerRequest?: Maybe<(
    { __typename?: 'CreateSuspendGamerRequestPayload' }
    & { suspendRequest?: Maybe<(
      { __typename?: 'SuspendGamerRequest' }
      & SuspendGamerRequestFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RejectSuspendGamerRequestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RejectSuspendGamerRequestMutation = (
  { __typename?: 'Mutation' }
  & { rejectSuspendGamerRequest?: Maybe<(
    { __typename?: 'RejectSuspendGamerRequestPayload' }
    & { suspendRequest?: Maybe<(
      { __typename?: 'SuspendGamerRequest' }
      & SuspendGamerRequestFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveUserCreditTransactionMutationVariables = Exact<{
  creditTransactionId: Scalars['ID'];
}>;


export type RemoveUserCreditTransactionMutation = (
  { __typename?: 'Mutation' }
  & { removeUserCreditTransaction?: Maybe<(
    { __typename?: 'RemoveCreditTransactionPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateAdminNoteUserMutationVariables = Exact<{
  id: Scalars['ID'];
  adminNote: Scalars['String'];
}>;


export type UpdateAdminNoteUserMutation = (
  { __typename?: 'Mutation' }
  & { updateAdminNoteUser?: Maybe<(
    { __typename?: 'UpdateAdminNoteUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'adminNote'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  beStar: Scalars['Boolean'];
  bePro: Scalars['Boolean'];
  beKol: Scalars['Boolean'];
  verified: Scalars['Boolean'];
  avatar?: Maybe<Scalars['File']>;
  proPhoto?: Maybe<Scalars['File']>;
  coverPicture?: Maybe<Scalars['File']>;
  nationality?: Maybe<Scalars['String']>;
  officialName?: Maybe<Scalars['String']>;
  introText?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateVendorMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateVendorMutation = (
  { __typename?: 'Mutation' }
  & { createVendor?: Maybe<(
    { __typename?: 'CreateVendorPayload' }
    & { vendor?: Maybe<(
      { __typename?: 'Vendor' }
      & VendorFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type UpdateVendorMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateVendorMutation = (
  { __typename?: 'Mutation' }
  & { updateVendor?: Maybe<(
    { __typename?: 'UpdateVendorPayload' }
    & { vendor?: Maybe<(
      { __typename?: 'Vendor' }
      & VendorFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'detail'>
    )>> }
  )> }
);

export type ActiveUsersAnalyticQueryVariables = Exact<{
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type ActiveUsersAnalyticQuery = (
  { __typename?: 'Query' }
  & { activeUsersAnalytic?: Maybe<(
    { __typename?: 'ActiveUsersAnalytic' }
    & { activeUsersCombatArena?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, activeUsersPartneringRequest?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, activeUsersBuyGameCredit?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, activeUsersTopUp?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, activeUsersGameBundles?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, activeUsersCreatePost?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, activeUsersOnPostLike?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, activeUsersOnPostComment?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, activeUsersCreateTeam?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, activeUsersJoinTeam?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, registeredUsers?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )>, uniqueUsers?: Maybe<(
      { __typename?: 'ActiveUsersMetric' }
      & Pick<ActiveUsersMetric, 'size' | 'label'>
    )> }
  )> }
);

export type BankVerificationsPageQueryVariables = Exact<{
  gameName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  suspended?: Maybe<Scalars['Boolean']>;
}>;


export type BankVerificationsPageQuery = (
  { __typename?: 'Query' }
  & { bankVerifications?: Maybe<(
    { __typename?: 'GameAccountCount' }
    & Pick<GameAccountCount, 'count'>
    & { data: Array<(
      { __typename?: 'GameAccountIndex' }
      & Pick<GameAccountIndex, 'id' | 'accountId' | 'screenshotState'>
      & { gameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      ), game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), pilot: (
        { __typename?: 'PilotIndex' }
        & Pick<PilotIndex, 'id' | 'email' | 'phoneNumber' | 'nickname' | 'online' | 'trained'>
        & { creditWallet: (
          { __typename?: 'CreditWallet' }
          & Pick<CreditWallet, 'amount'>
        ), bankInfo?: Maybe<(
          { __typename?: 'BankInfo' }
          & BankInfoFragment
        )> }
      ) }
    )> }
  )> }
);

export type BookingIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BookingIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'state' | 'reportReason' | 'rating' | 'ratingRemark'>
    & { reporter?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
    ) }
  ) }
);

export type BookingChatroomQueryVariables = Exact<{
  id: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type BookingChatroomQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'state' | 'reportReason' | 'rating' | 'ratingRemark'>
    & { reporter?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
      & { privateChatroom?: Maybe<(
        { __typename?: 'Chatroom' }
        & Pick<Chatroom, 'id' | 'createdAt'>
        & { messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'id' | 'imageUrl' | 'createdAt' | 'text'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type AllCaiLeaderboardQueryVariables = Exact<{
  fromDate: Scalars['ISO8601DateTime'];
  toDate: Scalars['ISO8601DateTime'];
}>;


export type AllCaiLeaderboardQuery = (
  { __typename?: 'Query' }
  & { caiLeaderboards?: Maybe<Array<(
    { __typename?: 'CaiLeaderboard' }
    & Pick<CaiLeaderboard, 'id' | 'country' | 'state' | 'town' | 'name' | 'tier' | 'totalMatches' | 'score'>
  )>> }
);

export type CareerHighlightIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CareerHighlightIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, careerHighlight: (
    { __typename?: 'CareerHighlight' }
    & Pick<CareerHighlight, 'id' | 'startYear' | 'endYear' | 'title' | 'position'>
  ) }
);

export type ClanIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ClanIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, clan: (
    { __typename?: 'Clan' }
    & Pick<Clan, 'id' | 'avatarUrl' | 'coverPictureUrl' | 'name' | 'introText' | 'description' | 'membersCount' | 'postsCount' | 'likesCount' | 'followersCount' | 'targetType'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'phoneNumber' | 'email'>
    ) }
  ) }
);

export type ClansIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
}>;


export type ClansIndexPageQuery = (
  { __typename?: 'Query' }
  & { clanAccounts: (
    { __typename?: 'ClansCount' }
    & Pick<ClansCount, 'count'>
    & { data: Array<(
      { __typename?: 'Clan' }
      & Pick<Clan, 'id' | 'avatarUrl' | 'coverPictureUrl' | 'name' | 'membersCount' | 'postsCount' | 'likesCount' | 'followersCount' | 'targetType'>
    )> }
  ) }
);

export type ClubMemberAccountsIndexPageQueryVariables = Exact<{
  clubId: Scalars['ID'];
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  gameId: Scalars['String'];
}>;


export type ClubMemberAccountsIndexPageQuery = (
  { __typename?: 'Query' }
  & { clubMemberAccounts?: Maybe<(
    { __typename?: 'ClubsCount' }
    & Pick<ClubsCount, 'count'>
    & { data: Array<(
      { __typename?: 'GameAccountIndex' }
      & Pick<GameAccountIndex, 'id' | 'accountId' | 'screenshotState' | 'aggregateRating' | 'completeGameBundlesCount' | 'completePartneringRequestsCount'>
      & { gameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      ), game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), pilot: (
        { __typename?: 'PilotIndex' }
        & Pick<PilotIndex, 'id' | 'email' | 'phoneNumber' | 'nickname' | 'trained' | 'online'>
        & { creditWallet: (
          { __typename?: 'CreditWallet' }
          & Pick<CreditWallet, 'amount'>
        ), bankInfo?: Maybe<(
          { __typename?: 'BankInfo' }
          & BankInfoFragment
        )> }
      ), club?: Maybe<(
        { __typename?: 'Club' }
        & ClubFragment
      )> }
    )> }
  )> }
);

export type ClubIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ClubIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, club: (
    { __typename?: 'Club' }
    & ClubFragment
  ) }
);

export type ClubIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
}>;


export type ClubIndexPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, clubCounts?: Maybe<(
    { __typename?: 'ClubCounts' }
    & Pick<ClubCounts, 'count'>
    & { data: Array<(
      { __typename?: 'Club' }
      & Pick<Club, 'id' | 'name' | 'icon' | 'description' | 'tier' | 'beStar' | 'verified' | 'themeColor' | 'founded' | 'origin' | 'introText' | 'overview'>
    )> }
  )> }
);

export type ClubsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClubsQuery = (
  { __typename?: 'Query' }
  & { clubs: Array<(
    { __typename?: 'Club' }
    & ClubFragment
  )> }
);

export type CombatRoomIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CombatRoomIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, combatRoom: (
    { __typename?: 'CombatRoom' }
    & Pick<CombatRoom, 'id' | 'state' | 'duration' | 'createdAt' | 'startTime' | 'completeTime' | 'adminNote' | 'accepterWinScores' | 'challengerWinScores' | 'updatedScores' | 'roomUrl' | 'validationRemark'>
    & { winTeam?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & Pick<Club, 'id' | 'name'>
      )> }
    )>, loseTeam?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & Pick<Club, 'id' | 'name'>
      )> }
    )>, accepter?: Maybe<(
      { __typename?: 'CombatTeam' }
      & Pick<CombatTeam, 'id' | 'score' | 'suspended' | 'reason'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      ) }
    )>, challenger: (
      { __typename?: 'CombatTeam' }
      & Pick<CombatTeam, 'id' | 'score' | 'suspended' | 'reason'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      ) }
    ), accepterPlayerIds?: Maybe<Array<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'nickname' | 'email' | 'phoneNumber'>
      & { teamRole?: Maybe<(
        { __typename?: 'TeamRole' }
        & Pick<TeamRole, 'id'>
        & { proficientRole?: Maybe<(
          { __typename?: 'ProficientRole' }
          & Pick<ProficientRole, 'id' | 'name'>
        )> }
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      )>, playerIdsProficientRoles?: Maybe<Array<(
        { __typename?: 'ProficientRole' }
        & Pick<ProficientRole, 'id' | 'name'>
      )>>, gameAccounts: Array<(
        { __typename?: 'GameAccount' }
        & Pick<GameAccount, 'id' | 'inGameName'>
      )> }
    )>>, challengerPlayerIds?: Maybe<Array<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'nickname' | 'email' | 'phoneNumber'>
      & { teamRole?: Maybe<(
        { __typename?: 'TeamRole' }
        & Pick<TeamRole, 'id'>
        & { proficientRole?: Maybe<(
          { __typename?: 'ProficientRole' }
          & Pick<ProficientRole, 'id' | 'name'>
        )> }
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      )>, playerIdsProficientRoles?: Maybe<Array<(
        { __typename?: 'ProficientRole' }
        & Pick<ProficientRole, 'id' | 'name'>
      )>>, gameAccounts: Array<(
        { __typename?: 'GameAccount' }
        & Pick<GameAccount, 'id' | 'inGameName'>
      )> }
    )>>, matchResults?: Maybe<Array<(
      { __typename?: 'MatchResult' }
      & Pick<MatchResult, 'id' | 'resultScreenshots' | 'createdAt' | 'updatedAt'>
      & { combatTeam?: Maybe<(
        { __typename?: 'CombatTeam' }
        & Pick<CombatTeam, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, winTeam?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )>, loseTeam?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )> }
    )>>, chatroom?: Maybe<(
      { __typename?: 'Chatroom' }
      & Pick<Chatroom, 'id'>
      & { messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'createdAt' | 'text'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname'>
        ) }
      )> }
    )>, combatQueues?: Maybe<Array<(
      { __typename?: 'CombatQueue' }
      & Pick<CombatQueue, 'id' | 'score'>
      & { combatTeam: (
        { __typename?: 'CombatTeam' }
        & Pick<CombatTeam, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      ) }
    )>> }
  ) }
);

export type CombatRoomsIndexPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['String']>;
  leaderboardId?: Maybe<Scalars['ID']>;
}>;


export type CombatRoomsIndexPageQuery = (
  { __typename?: 'Query' }
  & { combatRoomAccounts?: Maybe<(
    { __typename?: 'CombatRoomsCount' }
    & Pick<CombatRoomsCount, 'count'>
    & { data: Array<(
      { __typename?: 'CombatRoom' }
      & Pick<CombatRoom, 'id' | 'state' | 'createdAt' | 'startTime' | 'completeTime'>
      & { accepter?: Maybe<(
        { __typename?: 'CombatTeam' }
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, challenger: (
        { __typename?: 'CombatTeam' }
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      ), matchResults?: Maybe<Array<(
        { __typename?: 'MatchResult' }
        & Pick<MatchResult, 'id'>
        & { winTeam?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        )>, loseTeam?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        )> }
      )>> }
    )> }
  )> }
);

export type CombatRoomsCsvQueryVariables = Exact<{
  fromDate?: Maybe<Scalars['ISO8601DateTime']>;
  toDate?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type CombatRoomsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'combatRoomAccountsCsv'>
);

export type CombatTeamIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CombatTeamIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, combatTeam: (
    { __typename?: 'CombatTeam' }
    & Pick<CombatTeam, 'id' | 'score' | 'totalMatches' | 'state'>
    & { team: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'icon' | 'tier'>
    ), players: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber' | 'icName' | 'icNumber' | 'discordId'>
      & { gameAccounts: Array<(
        { __typename?: 'GameAccount' }
        & Pick<GameAccount, 'id' | 'accountId' | 'inGameName' | 'serverId'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )>, teamRole?: Maybe<(
        { __typename?: 'TeamRole' }
        & Pick<TeamRole, 'id'>
        & { proficientRole?: Maybe<(
          { __typename?: 'ProficientRole' }
          & Pick<ProficientRole, 'id' | 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type FaqIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FaqIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, faq: (
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answer' | 'language' | 'categoryColumns' | 'position' | 'apps'>
  ) }
);

export type FaqIndexPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
}>;


export type FaqIndexPageQuery = (
  { __typename?: 'Query' }
  & { faqAccounts: (
    { __typename?: 'FaqCount' }
    & Pick<FaqCount, 'count'>
    & { data: Array<(
      { __typename?: 'Faq' }
      & Pick<Faq, 'id' | 'question' | 'answer' | 'language' | 'categoryColumns' | 'apps'>
    )> }
  ) }
);

export type GameAccountsIndexPageQueryVariables = Exact<{
  approve?: Maybe<Scalars['Boolean']>;
  gameName?: Maybe<Scalars['String']>;
  rankName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  filterWhere?: Maybe<FilterQuery>;
  suspended?: Maybe<Scalars['Boolean']>;
  trained?: Maybe<Scalars['Boolean']>;
  streamer?: Maybe<Scalars['Boolean']>;
  online?: Maybe<Scalars['Boolean']>;
}>;


export type GameAccountsIndexPageQuery = (
  { __typename?: 'Query' }
  & { gameAccounts?: Maybe<(
    { __typename?: 'GameAccountCount' }
    & Pick<GameAccountCount, 'count'>
    & { data: Array<(
      { __typename?: 'GameAccountIndex' }
      & Pick<GameAccountIndex, 'id' | 'accountId' | 'screenshotState'>
      & { gameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      ), game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), pilot: (
        { __typename?: 'PilotIndex' }
        & Pick<PilotIndex, 'id' | 'email' | 'phoneNumber' | 'nickname' | 'trained' | 'online'>
        & { creditWallet: (
          { __typename?: 'CreditWallet' }
          & Pick<CreditWallet, 'amount'>
        ), bankInfo?: Maybe<(
          { __typename?: 'BankInfo' }
          & BankInfoFragment
        )> }
      ), pilotTier?: Maybe<(
        { __typename?: 'PilotTier' }
        & PilotTierFragment
      )> }
    )> }
  )> }
);

export type GameMessagesQueryVariables = Exact<{
  gameChatroomId: Scalars['String'];
  searchString?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type GameMessagesQuery = (
  { __typename?: 'Query' }
  & { gameMessageAccounts?: Maybe<(
    { __typename?: 'GameMessageCount' }
    & Pick<GameMessageCount, 'count'>
    & { messages: Array<(
      { __typename?: 'GameMessage' }
      & GameMessageFragment
    )> }
  )> }
);

export type GameRankIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GameRankIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, gameRank?: Maybe<(
    { __typename?: 'GameRank' }
    & GameRankFragment
  )> }
);

export type BundleIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BundleIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, gameBundle: (
    { __typename?: 'AdminGameBundle' }
    & Pick<AdminGameBundle, 'id' | 'promoCode' | 'originalAmount' | 'remark' | 'rating' | 'ratingRemark' | 'amount' | 'currentRankStar' | 'currentRankTier' | 'targetRankTier' | 'targetRankStar' | 'currentRankPoint' | 'targetRankPoint' | 'completionScreenshotUrl' | 'country' | 'startTime' | 'completionTime' | 'state' | 'createdAt' | 'reportReason' | 'communicationRating' | 'skillRating' | 'adminNote'>
    & { pilot?: Maybe<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'email' | 'nickname' | 'phoneNumber'>
      & { creditWallet: (
        { __typename?: 'CreditWallet' }
        & Pick<CreditWallet, 'amount'>
      ) }
    )>, user?: Maybe<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'email' | 'nickname' | 'phoneNumber'>
    )>, reporter?: Maybe<(
      { __typename?: 'SimpleUser' }
      & Pick<SimpleUser, 'id' | 'nickname'>
    )>, game: (
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'name'>
    ), currentGameRank: (
      { __typename?: 'GameRank' }
      & Pick<GameRank, 'id' | 'name' | 'imageUrl' | 'rankStarMaxCount'>
    ), targetGameRank: (
      { __typename?: 'GameRank' }
      & Pick<GameRank, 'id' | 'name' | 'imageUrl' | 'rankStarMaxCount'>
    ), chatroom?: Maybe<(
      { __typename?: 'Chatroom' }
      & Pick<Chatroom, 'id'>
      & { messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'createdAt' | 'text' | 'imageUrl'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname'>
        ) }
      )> }
    )>, perspectives?: Maybe<Array<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name'>
    )>>, queues?: Maybe<Array<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name'>
    )>>, proficientRoles?: Maybe<Array<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name'>
    )>> }
  ) }
);

export type AllGameBundlesPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  filterWhere?: Maybe<FilterPartneringQuery>;
  state?: Maybe<Scalars['String']>;
  suspicious?: Maybe<Scalars['Boolean']>;
}>;


export type AllGameBundlesPageQuery = (
  { __typename?: 'Query' }
  & { gameBundlesIndex?: Maybe<(
    { __typename?: 'GameBundleCount' }
    & Pick<GameBundleCount, 'count'>
    & { data: Array<(
      { __typename?: 'AdminGameBundle' }
      & Pick<AdminGameBundle, 'id' | 'state' | 'createdAt'>
      & { pilot?: Maybe<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname'>
      )>, user?: Maybe<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname'>
      )>, game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GamerBannerIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GamerBannerIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, gamerBanner: (
    { __typename?: 'Banner' }
    & Pick<Banner, 'id' | 'title' | 'createdAt' | 'url' | 'image' | 'active' | 'screen' | 'poster'>
  ) }
);

export type GamerBannerIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
}>;


export type GamerBannerIndexPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, gamerBanners?: Maybe<(
    { __typename?: 'GamerBannerCount' }
    & Pick<GamerBannerCount, 'count'>
    & { data: Array<(
      { __typename?: 'Banner' }
      & Pick<Banner, 'id' | 'title' | 'createdAt' | 'url' | 'image' | 'active' | 'screen' | 'poster'>
    )> }
  )> }
);

export type GameIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GameIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, game: (
    { __typename?: 'Game' }
    & GameFragment
  ) }
);

export type GamesIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
}>;


export type GamesIndexPageQuery = (
  { __typename?: 'Query' }
  & { gameCountAccounts: (
    { __typename?: 'GameCount' }
    & Pick<GameCount, 'count'>
    & { data: Array<(
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'iconUrl' | 'name' | 'developerName' | 'teamsCount' | 'gameAccountsCount' | 'ordering'>
    )> }
  ) }
);

export type DashboardPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
}>;


export type DashboardPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, analytics?: Maybe<(
    { __typename?: 'AdminAnalytic' }
    & Pick<AdminAnalytic, 'totalUsers' | 'totalApprovePilots' | 'pendingPilots' | 'pendingPayouts' | 'reportsPartneringRequest' | 'reportsGameBundle' | 'flaggedCombatRooms' | 'pendingReportedRequests' | 'vocaCreditBalance'>
  )>, adminLogs?: Maybe<(
    { __typename?: 'AdminLogsCount' }
    & Pick<AdminLogsCount, 'count'>
    & { data: Array<(
      { __typename?: 'AdminLog' }
      & AdminLogFragment
    )> }
  )> }
);

export type InterestIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InterestIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, interest: (
    { __typename?: 'Interest' }
    & Pick<Interest, 'id' | 'name' | 'iconOnUrl' | 'iconOffUrl' | 'active'>
  ) }
);

export type InterestsIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['String']>;
}>;


export type InterestsIndexPageQuery = (
  { __typename?: 'Query' }
  & { interestAccounts: (
    { __typename?: 'InterestsCount' }
    & Pick<InterestsCount, 'count'>
    & { data: Array<(
      { __typename?: 'Interest' }
      & Pick<Interest, 'id' | 'name' | 'iconOnUrl' | 'iconOffUrl' | 'active'>
    )> }
  ) }
);

export type LeaderboardPoolPrizeIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LeaderboardPoolPrizeIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, leaderboardPoolPrize: (
    { __typename?: 'Leaderboard' }
    & LeaderboardFragment
  ) }
);

export type LeaderboardPoolPrizeIndexPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  gameId: Scalars['String'];
}>;


export type LeaderboardPoolPrizeIndexPageQuery = (
  { __typename?: 'Query' }
  & { leaderboardPoolPrizeAccounts: (
    { __typename?: 'LeaderboardPoolPrizeCount' }
    & Pick<LeaderboardPoolPrizeCount, 'count'>
    & { data: Array<(
      { __typename?: 'Leaderboard' }
      & LeaderboardFragment
    )> }
  ) }
);

export type CombatTeamsQueryVariables = Exact<{
  leaderboardId?: Maybe<Scalars['String']>;
}>;


export type CombatTeamsQuery = (
  { __typename?: 'Query' }
  & { searchPoolPrizeTeams?: Maybe<Array<(
    { __typename?: 'CombatTeam' }
    & Pick<CombatTeam, 'id' | 'score'>
    & { team: (
      { __typename?: 'Team' }
      & Pick<Team, 'id'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & Pick<Club, 'id' | 'name'>
      )> }
    ) }
  )>> }
);

export type LeaderboardIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LeaderboardIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, leaderboard?: Maybe<(
    { __typename?: 'CombatTeam' }
    & Pick<CombatTeam, 'id'>
    & { team: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    ), combatRooms: Array<(
      { __typename?: 'CombatRoom' }
      & Pick<CombatRoom, 'id' | 'state' | 'createdAt' | 'accepterWinScores' | 'challengerWinScores'>
      & { accepter?: Maybe<(
        { __typename?: 'CombatTeam' }
        & Pick<CombatTeam, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, challenger: (
        { __typename?: 'CombatTeam' }
        & Pick<CombatTeam, 'id'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      ), matchResults?: Maybe<Array<(
        { __typename?: 'MatchResult' }
        & Pick<MatchResult, 'id' | 'createdAt' | 'updatedAt'>
        & { winTeam?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        )>, loseTeam?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        )> }
      )>>, winTeam?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )>, loseTeam?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )> }
    )>, combatRoomAdminLogs: Array<(
      { __typename?: 'AdminLog' }
      & AdminLogFragment
    )> }
  )> }
);

export type LeaderboardIndexPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  week?: Maybe<Scalars['String']>;
  leaderboardId?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  isCombatTeam?: Maybe<Scalars['Boolean']>;
}>;


export type LeaderboardIndexPageQuery = (
  { __typename?: 'Query' }
  & { leaderboardAccounts?: Maybe<(
    { __typename?: 'LeaderboardCount' }
    & Pick<LeaderboardCount, 'count'>
    & { data: Array<(
      { __typename?: 'CombatTeam' }
      & Pick<CombatTeam, 'id' | 'score' | 'totalMatches' | 'state'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name' | 'icon' | 'tier'>
      ), leaderboard: (
        { __typename?: 'Leaderboard' }
        & Pick<Leaderboard, 'beginningWeek' | 'endWeek' | 'month' | 'year' | 'totalPrize' | 'totalRegisteredTeamCounts' | 'state'>
      ) }
    )> }
  )> }
);

export type LeaderboardCsvQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LeaderboardCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'leaderboardCsv'>
);

export type WeekLeaderboardQueryVariables = Exact<{ [key: string]: never; }>;


export type WeekLeaderboardQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, leaderboards: Array<(
    { __typename?: 'Leaderboard' }
    & Pick<Leaderboard, 'id' | 'beginningWeek' | 'endWeek' | 'numberOfWeek' | 'month' | 'year'>
  )> }
);

export type LogsPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
}>;


export type LogsPageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalAdminLogs'>
  & { adminLogs?: Maybe<(
    { __typename?: 'AdminLogsCount' }
    & Pick<AdminLogsCount, 'count'>
    & { data: Array<(
      { __typename?: 'AdminLog' }
      & AdminLogFragment
    )> }
  )> }
);

export type AdminLogsCsvQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminLogsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'adminLogsCsv'>
);

export type MarkupPricingIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MarkupPricingIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, markupPricing: (
    { __typename?: 'MarkupPricing' }
    & MarkupPricingFragment
  ) }
);

export type MarkupPricingAccountsIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
}>;


export type MarkupPricingAccountsIndexPageQuery = (
  { __typename?: 'Query' }
  & { markupPricingAccounts: (
    { __typename?: 'MarkupPricingCount' }
    & Pick<MarkupPricingCount, 'count'>
    & { data: Array<(
      { __typename?: 'MarkupPricing' }
      & MarkupPricingFragment
    )> }
  ) }
);

export type MatchMakingIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
}>;


export type MatchMakingIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, matchMaking: (
    { __typename?: 'MatchMaking' }
    & Pick<MatchMaking, 'id' | 'matchCount' | 'state' | 'currency' | 'price' | 'minimumRankId' | 'maximumRankId' | 'queueLimit' | 'queueCount' | 'startTime' | 'endTime' | 'prosOnly'>
    & { maximumRank?: Maybe<(
      { __typename?: 'GameRank' }
      & GameRankFragment
    )>, minimumRank?: Maybe<(
      { __typename?: 'GameRank' }
      & GameRankFragment
    )>, post: (
      { __typename?: 'Post' }
      & PostFragment
    ), user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
    ), game: (
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'name'>
    ), bookingAccounts: (
      { __typename?: 'BookingCount' }
      & Pick<BookingCount, 'count'>
      & { data: Array<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'state' | 'reportReason' | 'rating' | 'ratingRemark'>
        & { reporter?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
        )>, user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
        ) }
      )> }
    ) }
  ) }
);

export type MatchMakingIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
}>;


export type MatchMakingIndexPageQuery = (
  { __typename?: 'Query' }
  & { matchMakingAccounts: (
    { __typename?: 'MatchMakingCount' }
    & Pick<MatchMakingCount, 'count'>
    & { data: Array<(
      { __typename?: 'MatchMaking' }
      & MatchMakingFragment
    )> }
  ) }
);

export type MerchandiseVariantIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MerchandiseVariantIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, merchandiseVariant: (
    { __typename?: 'MerchandiseVariant' }
    & Pick<MerchandiseVariant, 'id' | 'name' | 'price' | 'position' | 'sku' | 'stock'>
    & { merchandise: (
      { __typename?: 'Merchandise' }
      & Pick<Merchandise, 'id' | 'name'>
    ) }
  ) }
);

export type MerchandiseIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MerchandiseIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, merchandise: (
    { __typename?: 'Merchandise' }
    & MerchandiseFragment
  ) }
);

export type MerchandiseAccountsIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
}>;


export type MerchandiseAccountsIndexPageQuery = (
  { __typename?: 'Query' }
  & { merchandiseAccounts: (
    { __typename?: 'MerchandiseCount' }
    & Pick<MerchandiseCount, 'count'>
    & { data: Array<(
      { __typename?: 'Merchandise' }
      & MerchandiseFragment
    )> }
  ) }
);

export type MonthlyReportHistoriesIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  searchString: Scalars['String'];
}>;


export type MonthlyReportHistoriesIndexPageQuery = (
  { __typename?: 'Query' }
  & { monthlyReportHistories?: Maybe<(
    { __typename?: 'MonthlyReportHistoriesCount' }
    & Pick<MonthlyReportHistoriesCount, 'count'>
    & { data: Array<(
      { __typename?: 'MonthlyReport' }
      & MonthlyReportFragment
    )> }
  )> }
);

export type MonthlyReportsIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
}>;


export type MonthlyReportsIndexPageQuery = (
  { __typename?: 'Query' }
  & { monthlyReports?: Maybe<(
    { __typename?: 'MonthlyReportsCount' }
    & Pick<MonthlyReportsCount, 'count'>
    & { data: Array<(
      { __typename?: 'MonthlyReport' }
      & MonthlyReportFragment
    )> }
  )> }
);

export type NextLevelCountQueryVariables = Exact<{ [key: string]: never; }>;


export type NextLevelCountQuery = (
  { __typename?: 'Query' }
  & { nextLevelCount: (
    { __typename?: 'NextLevelCount' }
    & Pick<NextLevelCount, 'creepCount' | 'survivorCount' | 'conquerorCount' | 'dominatorCount' | 'savageCount'>
  ) }
);

export type OfficialChannelIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OfficialChannelIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, officialChannel: (
    { __typename?: 'OfficialChannel' }
    & OfficialChannelFragment
  ) }
);

export type OfficialChannelIndexPageQueryVariables = Exact<{ [key: string]: never; }>;


export type OfficialChannelIndexPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, officialChannels: Array<(
    { __typename?: 'OfficialChannel' }
    & OfficialChannelFragment
  )> }
);

export type OrderIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, order: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'amount' | 'status' | 'currency' | 'channel' | 'quantity' | 'trackingNumber' | 'trackingUrl' | 'createdAt'>
    & { creditTransaction?: Maybe<(
      { __typename?: 'CreditTransaction' }
      & CreditTransactionFragment
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'phoneNumber' | 'email'>
    )>, merchandiseVariant?: Maybe<(
      { __typename?: 'MerchandiseVariant' }
      & Pick<MerchandiseVariant, 'id' | 'name' | 'price' | 'sku' | 'stock' | 'position'>
      & { merchandise: (
        { __typename?: 'Merchandise' }
        & Pick<Merchandise, 'id' | 'name'>
        & { post: (
          { __typename?: 'Post' }
          & Pick<Post, 'id' | 'url' | 'title'>
        ) }
      ) }
    )>, shippingAddress?: Maybe<(
      { __typename?: 'ShippingAddress' }
      & Pick<ShippingAddress, 'id' | 'addressName' | 'defaultAddress' | 'recipientName' | 'recipientPhoneNumber' | 'address1' | 'address2' | 'state' | 'town' | 'country' | 'postalCode' | 'longitude' | 'latitude'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname' | 'phoneNumber' | 'email'>
      ) }
    )> }
  ) }
);

export type OrderIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}>;


export type OrderIndexPageQuery = (
  { __typename?: 'Query' }
  & { orderAccounts: (
    { __typename?: 'OrderCount' }
    & Pick<OrderCount, 'count'>
    & { data: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'amount' | 'status' | 'currency' | 'channel' | 'quantity' | 'orderNumber' | 'trackingNumber' | 'trackingUrl' | 'createdAt'>
      & { creditTransaction?: Maybe<(
        { __typename?: 'CreditTransaction' }
        & CreditTransactionFragment
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname' | 'phoneNumber' | 'email'>
      )>, merchandiseVariant?: Maybe<(
        { __typename?: 'MerchandiseVariant' }
        & Pick<MerchandiseVariant, 'id' | 'name' | 'price' | 'sku' | 'stock'>
      )>, shippingAddress?: Maybe<(
        { __typename?: 'ShippingAddress' }
        & Pick<ShippingAddress, 'id' | 'addressName' | 'defaultAddress' | 'recipientName' | 'recipientPhoneNumber' | 'address1' | 'address2' | 'state' | 'town' | 'country' | 'postalCode' | 'longitude' | 'latitude'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname' | 'phoneNumber' | 'email'>
        ) }
      )> }
    )> }
  ) }
);

export type RequestIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RequestIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, partneringRequest: (
    { __typename?: 'AdminPartneringRequest' }
    & Pick<AdminPartneringRequest, 'id' | 'promoCode' | 'totalOriginalAmount' | 'createdAt' | 'totalAmount' | 'rating' | 'ratingRemark' | 'rankTier' | 'rankStar' | 'rankPoint' | 'totalDurationInMinutes' | 'startTime' | 'completeTime' | 'state' | 'reportReason' | 'communicationRating' | 'skillRating' | 'durationHour' | 'adminNote' | 'pilotCount' | 'matchCount' | 'pilotNicknames'>
    & { pilot?: Maybe<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'email' | 'nickname' | 'phoneNumber'>
      & { creditWallet: (
        { __typename?: 'CreditWallet' }
        & Pick<CreditWallet, 'amount'>
      ) }
    )>, user?: Maybe<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'email' | 'nickname' | 'phoneNumber'>
    )>, reporter?: Maybe<(
      { __typename?: 'SimpleUser' }
      & Pick<SimpleUser, 'id' | 'nickname'>
    )>, game: (
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'name'>
    ), gameRank: (
      { __typename?: 'GameRank' }
      & Pick<GameRank, 'id' | 'name' | 'imageUrl' | 'rankStarMaxCount'>
    ), requestedPilots?: Maybe<Array<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'email' | 'nickname' | 'phoneNumber'>
    )>>, chatroom?: Maybe<(
      { __typename?: 'Chatroom' }
      & Pick<Chatroom, 'id'>
      & { messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'createdAt' | 'text' | 'imageUrl'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname'>
        ) }
      )> }
    )>, perspectives?: Maybe<Array<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name'>
    )>>, queues?: Maybe<Array<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name'>
    )>>, proficientRoles?: Maybe<Array<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name'>
    )>>, pilotServices?: Maybe<Array<(
      { __typename?: 'PilotService' }
      & Pick<PilotService, 'state' | 'createdAt'>
      & { pilot: (
        { __typename?: 'SimpleUser' }
        & Pick<SimpleUser, 'id' | 'nickname'>
      ) }
    )>> }
  ) }
);

export type AllPartneringRequestsPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  filterWhere?: Maybe<FilterPartneringQuery>;
  state?: Maybe<Scalars['String']>;
  suspicious?: Maybe<Scalars['Boolean']>;
}>;


export type AllPartneringRequestsPageQuery = (
  { __typename?: 'Query' }
  & { partneringRequestsIndex?: Maybe<(
    { __typename?: 'PartneringRequestCount' }
    & Pick<PartneringRequestCount, 'count'>
    & { data: Array<(
      { __typename?: 'AdminPartneringRequest' }
      & Pick<AdminPartneringRequest, 'id' | 'adminNote' | 'state' | 'createdAt' | 'pilotCount' | 'matchCount' | 'pilotNicknames'>
      & { pilot?: Maybe<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname'>
      )>, user?: Maybe<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname'>
      )>, game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), requestedPilots?: Maybe<Array<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber'>
      )>>, pilotServices?: Maybe<Array<(
        { __typename?: 'PilotService' }
        & { pilot: (
          { __typename?: 'SimpleUser' }
          & Pick<SimpleUser, 'id' | 'nickname'>
        ) }
      )>> }
    )> }
  )> }
);

export type PayoutIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PayoutIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, payout: (
    { __typename?: 'Payout' }
    & Pick<Payout, 'id' | 'amount' | 'recipient' | 'bank' | 'bankAccountNo' | 'approve' | 'createdAt' | 'state' | 'rejectReason' | 'adminNote'>
    & { pilot: (
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname' | 'email' | 'avatarUrl' | 'language' | 'gender' | 'trained' | 'adminNote'>
      & { creditWallet: (
        { __typename?: 'CreditWallet' }
        & CreditWalletFragment
      ), bankInfo?: Maybe<(
        { __typename?: 'BankInfo' }
        & BankInfoFragment
      )> }
    ) }
  ) }
);

export type AllPayoutsPageQueryVariables = Exact<{
  approve?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
}>;


export type AllPayoutsPageQuery = (
  { __typename?: 'Query' }
  & { payouts?: Maybe<(
    { __typename?: 'PayoutCount' }
    & Pick<PayoutCount, 'count'>
    & { data: Array<(
      { __typename?: 'PayoutIndex' }
      & Pick<PayoutIndex, 'id' | 'recipient' | 'email' | 'phone' | 'bank' | 'bankAccountNo' | 'amount' | 'approve' | 'createdAt' | 'state' | 'adminNote'>
    )> }
  )> }
);

export type PayoutsCsvQueryVariables = Exact<{ [key: string]: never; }>;


export type PayoutsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'payoutsCsv'>
);

export type PilotBannerIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PilotBannerIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, pilotBanner: (
    { __typename?: 'PilotBanner' }
    & Pick<PilotBanner, 'id' | 'title' | 'createdAt' | 'url' | 'image' | 'active' | 'screen' | 'poster'>
  ) }
);

export type PilotBannerIndexPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PilotBannerIndexPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, pilotBanners: Array<(
    { __typename?: 'PilotBanner' }
    & Pick<PilotBanner, 'id' | 'title' | 'createdAt' | 'url' | 'image' | 'active' | 'screen' | 'poster'>
  )> }
);

export type PilotLevelIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PilotLevelIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, pilotLevel?: Maybe<(
    { __typename?: 'PilotLevel' }
    & PilotLevelFragment
  )> }
);

export type PilotLevelIndexPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PilotLevelIndexPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, pilotLevels: Array<(
    { __typename?: 'PilotLevel' }
    & PilotLevelFragment
  )> }
);

export type PilotRewardIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PilotRewardIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, pilotReward?: Maybe<(
    { __typename?: 'PilotReward' }
    & Pick<PilotReward, 'id' | 'state'>
    & { reward?: Maybe<(
      { __typename?: 'Reward' }
      & Pick<Reward, 'id' | 'name' | 'rewardType'>
      & { pilotLevel: (
        { __typename?: 'PilotLevel' }
        & PilotLevelFragment
      ) }
    )>, monthlyReport: (
      { __typename?: 'MonthlyReport' }
      & MonthlyReportFragment
    ) }
  )> }
);

export type PilotRewardIndexPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
}>;


export type PilotRewardIndexPageQuery = (
  { __typename?: 'Query' }
  & { pilotRewardAccounts?: Maybe<(
    { __typename?: 'PilotRewardsCount' }
    & Pick<PilotRewardsCount, 'count'>
    & { data: Array<(
      { __typename?: 'PilotReward' }
      & Pick<PilotReward, 'id' | 'state' | 'fullName' | 'address' | 'icNumber' | 'inGameName' | 'inGameId' | 'remark' | 'createdAt'>
      & { reward?: Maybe<(
        { __typename?: 'Reward' }
        & Pick<Reward, 'id' | 'name' | 'rewardType'>
        & { pilotLevel: (
          { __typename?: 'PilotLevel' }
          & PilotLevelFragment
        ) }
      )>, monthlyReport: (
        { __typename?: 'MonthlyReport' }
        & MonthlyReportFragment
      ), pilotLevel?: Maybe<(
        { __typename?: 'PilotLevel' }
        & PilotLevelFragment
      )>, pilot: (
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'email' | 'phoneNumber' | 'nickname'>
      ) }
    )> }
  )> }
);

export type PilotTierAccountsIndexPageQueryVariables = Exact<{
  pilotTierId: Scalars['ID'];
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  gameId: Scalars['String'];
}>;


export type PilotTierAccountsIndexPageQuery = (
  { __typename?: 'Query' }
  & { pilotTierAccounts?: Maybe<(
    { __typename?: 'PilotTiersCount' }
    & Pick<PilotTiersCount, 'count'>
    & { data: Array<(
      { __typename?: 'GameAccountIndex' }
      & Pick<GameAccountIndex, 'id' | 'accountId' | 'screenshotState' | 'aggregateRating' | 'completeGameBundlesCount' | 'completePartneringRequestsCount'>
      & { gameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      ), game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), pilot: (
        { __typename?: 'PilotIndex' }
        & Pick<PilotIndex, 'id' | 'email' | 'phoneNumber' | 'nickname' | 'trained' | 'online'>
        & { creditWallet: (
          { __typename?: 'CreditWallet' }
          & Pick<CreditWallet, 'amount'>
        ), bankInfo?: Maybe<(
          { __typename?: 'BankInfo' }
          & BankInfoFragment
        )> }
      ), pilotTier?: Maybe<(
        { __typename?: 'PilotTier' }
        & PilotTierFragment
      )> }
    )> }
  )> }
);

export type PilotTierAccountCsvQueryVariables = Exact<{ [key: string]: never; }>;


export type PilotTierAccountCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pilotTierAccountCsv'>
);

export type AllPilotTierQueryVariables = Exact<{ [key: string]: never; }>;


export type AllPilotTierQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, pilotTiers: Array<(
    { __typename?: 'PilotTier' }
    & Pick<PilotTier, 'id' | 'name'>
  )> }
);

export type PilotsVerificationPageQueryVariables = Exact<{
  filterWhere?: Maybe<FilterQuery>;
  searchString?: Maybe<Scalars['String']>;
  approve?: Maybe<Scalars['Boolean']>;
  gameName?: Maybe<Scalars['String']>;
  rankName?: Maybe<Scalars['String']>;
  bankInfoState?: Maybe<BankInfoState>;
  page?: Maybe<Scalars['Int']>;
}>;


export type PilotsVerificationPageQuery = (
  { __typename?: 'Query' }
  & { gameAccounts?: Maybe<(
    { __typename?: 'GameAccountCount' }
    & Pick<GameAccountCount, 'count'>
    & { data: Array<(
      { __typename?: 'GameAccountIndex' }
      & Pick<GameAccountIndex, 'id' | 'codeState' | 'screenshotState' | 'testState' | 'approve' | 'createdAt' | 'updatedAt'>
      & { gameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      ), game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), pilot: (
        { __typename?: 'PilotIndex' }
        & Pick<PilotIndex, 'id' | 'nickname'>
        & { bankInfo?: Maybe<(
          { __typename?: 'BankInfo' }
          & Pick<BankInfo, 'id' | 'state'>
        )> }
      ) }
    )> }
  )> }
);

export type PilotsIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PilotsIdPageQuery = (
  { __typename?: 'Query' }
  & { pilot?: Maybe<(
    { __typename?: 'Pilot' }
    & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname' | 'email' | 'gender' | 'avatarUrl' | 'language' | 'updatedAt' | 'createdAt' | 'aggregateRating' | 'averageRespondTime' | 'completeGameBundlesCount' | 'completePartneringRequestsCount' | 'suspend' | 'trained' | 'beStar' | 'bePro' | 'beKol' | 'verified' | 'adminNote'>
    & { bankInfo?: Maybe<(
      { __typename?: 'BankInfo' }
      & BankInfoFragment
    )>, gameAccounts: Array<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'accountId' | 'verificationCode' | 'codeState' | 'screenshotState' | 'testState' | 'screenshotUrl' | 'createdAt' | 'approve' | 'updatedAt' | 'testScore' | 'remindAt' | 'remindCount' | 'rejectReason' | 'rankTier' | 'rankStar' | 'rankPoint'>
      & { gameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name' | 'rankStarMaxCount' | 'rankPointMaxCount'>
      ), game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name' | 'developerName' | 'testPassingScore'>
      ), pairableGameRanks: Array<(
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      )> }
    )>, creditWallet: (
      { __typename?: 'CreditWallet' }
      & CreditWalletFragment
    ), suspendRequests?: Maybe<Array<(
      { __typename?: 'SuspendRequest' }
      & Pick<SuspendRequest, 'id' | 'reason' | 'state' | 'createdAt'>
    )>> }
  )> }
);

export type PilotsIndexPageQueryVariables = Exact<{
  approve?: Maybe<Scalars['Boolean']>;
  gameName?: Maybe<Scalars['String']>;
  rankName?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  filterWhere?: Maybe<FilterQuery>;
  suspended?: Maybe<Scalars['Boolean']>;
  trained?: Maybe<Scalars['Boolean']>;
  streamer?: Maybe<Scalars['Boolean']>;
  online?: Maybe<Scalars['Boolean']>;
}>;


export type PilotsIndexPageQuery = (
  { __typename?: 'Query' }
  & { gameAccounts?: Maybe<(
    { __typename?: 'GameAccountCount' }
    & Pick<GameAccountCount, 'count'>
    & { data: Array<(
      { __typename?: 'GameAccountIndex' }
      & Pick<GameAccountIndex, 'id' | 'accountId' | 'screenshotState'>
      & { gameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      ), game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), pilot: (
        { __typename?: 'PilotIndex' }
        & Pick<PilotIndex, 'id' | 'email' | 'phoneNumber' | 'nickname' | 'trained' | 'adminNote' | 'online'>
        & { creditWallet: (
          { __typename?: 'CreditWallet' }
          & Pick<CreditWallet, 'amount'>
        ), bankInfo?: Maybe<(
          { __typename?: 'BankInfo' }
          & BankInfoFragment
        )> }
      ) }
    )> }
  )> }
);

export type PilotsCsvQueryVariables = Exact<{ [key: string]: never; }>;


export type PilotsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pilotsCsv'>
);

export type PilotRecordsQueryVariables = Exact<{
  id: Scalars['ID'];
  page: Scalars['Int'];
}>;


export type PilotRecordsQuery = (
  { __typename?: 'Query' }
  & { pilot?: Maybe<(
    { __typename?: 'Pilot' }
    & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname' | 'email'>
    & { creditWallet: (
      { __typename?: 'CreditWallet' }
      & Pick<CreditWallet, 'amount' | 'id'>
    ), pilotGameBundles: (
      { __typename?: 'PilotGameBundleCount' }
      & Pick<PilotGameBundleCount, 'count'>
      & { data: Array<(
        { __typename?: 'GameBundle' }
        & Pick<GameBundle, 'id' | 'state' | 'createdAt'>
        & { pilot?: Maybe<(
          { __typename?: 'SimpleUser' }
          & Pick<SimpleUser, 'id' | 'phoneNumber' | 'nickname'>
        )>, user: (
          { __typename?: 'SimpleUser' }
          & Pick<SimpleUser, 'id' | 'phoneNumber' | 'nickname'>
        ), game: (
          { __typename?: 'Game' }
          & Pick<Game, 'id' | 'name'>
        ) }
      )> }
    ), pilotAcceptedPartneringRequests: (
      { __typename?: 'PilotPartneringRequestCount' }
      & Pick<PilotPartneringRequestCount, 'count'>
      & { data: Array<(
        { __typename?: 'PartneringRequest' }
        & Pick<PartneringRequest, 'id' | 'adminNote' | 'state' | 'createdAt'>
        & { pilot?: Maybe<(
          { __typename?: 'SimpleUser' }
          & Pick<SimpleUser, 'id' | 'phoneNumber' | 'nickname'>
        )>, user: (
          { __typename?: 'SimpleUser' }
          & Pick<SimpleUser, 'id' | 'phoneNumber' | 'nickname'>
        ), game: (
          { __typename?: 'Game' }
          & Pick<Game, 'id' | 'name'>
        ) }
      )> }
    ), pilotCreditTransactions: (
      { __typename?: 'PilotCreditTransactionCount' }
      & Pick<PilotCreditTransactionCount, 'count'>
      & { data: Array<(
        { __typename?: 'CreditTransaction' }
        & CreditTransactionFragment
      )> }
    ) }
  )> }
);

export type PoolPrizeIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PoolPrizeIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, poolPrize: (
    { __typename?: 'PoolPrize' }
    & PoolPrizeFragment
  ) }
);

export type PostIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PostIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, post: (
    { __typename?: 'Post' }
    & PostFragment
  ) }
);

export type PostIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
}>;


export type PostIndexPageQuery = (
  { __typename?: 'Query' }
  & { paginatedPosts: (
    { __typename?: 'PostCount' }
    & Pick<PostCount, 'count'>
    & { data: Array<(
      { __typename?: 'Post' }
      & PostFragment
    )> }
  ) }
);

export type PrizeIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PrizeIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, prize: (
    { __typename?: 'Prize' }
    & PrizeFragment
  ) }
);

export type PrizeIndexPageQueryVariables = Exact<{
  active?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type PrizeIndexPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, prizeAccounts: (
    { __typename?: 'PrizeCount' }
    & Pick<PrizeCount, 'count'>
    & { data: Array<(
      { __typename?: 'Prize' }
      & PrizeFragment
    )> }
  ) }
);

export type ProficientRoleIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProficientRoleIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, proficientRole: (
    { __typename?: 'ProficientRole' }
    & Pick<ProficientRole, 'id' | 'name' | 'kind' | 'imageUrl' | 'selectedImageUrl'>
    & { game: (
      { __typename?: 'Game' }
      & GameFragment
    ), proficientHeros?: Maybe<Array<(
      { __typename?: 'ProficientHero' }
      & Pick<ProficientHero, 'id' | 'name' | 'imageUrl'>
    )>> }
  ) }
);

export type ProficientRolesIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
}>;


export type ProficientRolesIndexPageQuery = (
  { __typename?: 'Query' }
  & { proficientRoleAccounts: (
    { __typename?: 'ProficientRoleCount' }
    & Pick<ProficientRoleCount, 'count'>
    & { data: Array<(
      { __typename?: 'ProficientRole' }
      & Pick<ProficientRole, 'id' | 'name' | 'imageUrl' | 'selectedImageUrl' | 'kind'>
      & { game: (
        { __typename?: 'Game' }
        & GameFragment
      ), proficientHeros?: Maybe<Array<(
        { __typename?: 'ProficientHero' }
        & Pick<ProficientHero, 'id' | 'name' | 'imageUrl'>
      )>> }
    )> }
  ) }
);

export type PromoCodeIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
  searchString?: Maybe<Scalars['String']>;
}>;


export type PromoCodeIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, promoCode: (
    { __typename?: 'PromoCode' }
    & PromoCodeFragment
  ), users?: Maybe<(
    { __typename?: 'UserCount' }
    & { data: Array<(
      { __typename?: 'UserIndex' }
      & Pick<UserIndex, 'id' | 'nickname'>
    )> }
  )> }
);

export type PromoCodeIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}>;


export type PromoCodeIndexPageQuery = (
  { __typename?: 'Query' }
  & { promoCodes?: Maybe<(
    { __typename?: 'PromoCodeCount' }
    & Pick<PromoCodeCount, 'count'>
    & { data: Array<(
      { __typename?: 'PromoCode' }
      & PromoCodeFragment
    )> }
  )> }
);

export type ReportRequestIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportRequestIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, post: (
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'url' | 'title' | 'text' | 'reportRequestsCount' | 'postImages' | 'postVideos'>
    & { reportRequests: Array<(
      { __typename?: 'ReportRequest' }
      & ReportRequestFragment
    )> }
  ) }
);

export type ReportRequestsIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
}>;


export type ReportRequestsIndexPageQuery = (
  { __typename?: 'Query' }
  & { reportRequestAccounts: (
    { __typename?: 'ReportRequestCount' }
    & Pick<ReportRequestCount, 'count'>
    & { data: Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'url' | 'title' | 'text' | 'reportRequestsCount'>
      & { reportRequests: Array<(
        { __typename?: 'ReportRequest' }
        & ReportRequestFragment
      )> }
    )> }
  ) }
);

export type ReportRequestPageQueryVariables = Exact<{
  state?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type ReportRequestPageQuery = (
  { __typename?: 'Query' }
  & { partneringRequestsIndex?: Maybe<(
    { __typename?: 'PartneringRequestCount' }
    & Pick<PartneringRequestCount, 'count'>
    & { data: Array<(
      { __typename?: 'AdminPartneringRequest' }
      & Pick<AdminPartneringRequest, 'id' | 'state' | 'createdAt' | 'reportReason' | 'updatedAt'>
      & { pilot?: Maybe<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname'>
      )>, user?: Maybe<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname'>
      )>, game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), requestedPilots?: Maybe<Array<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber'>
      )>> }
    )> }
  )> }
);

export type ReportBundlePageQueryVariables = Exact<{
  state?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type ReportBundlePageQuery = (
  { __typename?: 'Query' }
  & { gameBundlesIndex?: Maybe<(
    { __typename?: 'GameBundleCount' }
    & Pick<GameBundleCount, 'count'>
    & { data: Array<(
      { __typename?: 'AdminGameBundle' }
      & Pick<AdminGameBundle, 'id' | 'state' | 'createdAt' | 'reportReason' | 'updatedAt'>
      & { pilot?: Maybe<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname'>
      )>, user?: Maybe<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname'>
      )>, game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type ScrimIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ScrimIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, scrim?: Maybe<(
    { __typename?: 'Scrim' }
    & Pick<Scrim, 'id' | 'state' | 'description' | 'matchCount' | 'duration' | 'startTime' | 'completeTime'>
    & { matchResults: Array<(
      { __typename?: 'MatchResult' }
      & Pick<MatchResult, 'id' | 'resultScreenshots'>
      & { winTeam?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )>, loseTeam?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )> }
    )>, host: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    ), hostPlayers: Array<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'email' | 'phoneNumber' | 'nickname'>
      & { teamRole?: Maybe<(
        { __typename?: 'TeamRole' }
        & Pick<TeamRole, 'id'>
        & { proficientRole?: Maybe<(
          { __typename?: 'ProficientRole' }
          & Pick<ProficientRole, 'id' | 'name'>
        )> }
      )> }
    )>, guest?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>, guestPlayers: Array<(
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'email' | 'phoneNumber' | 'nickname'>
      & { teamRole?: Maybe<(
        { __typename?: 'TeamRole' }
        & Pick<TeamRole, 'id'>
        & { proficientRole?: Maybe<(
          { __typename?: 'ProficientRole' }
          & Pick<ProficientRole, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type ScrimsIndexPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
}>;


export type ScrimsIndexPageQuery = (
  { __typename?: 'Query' }
  & { scrimAccounts?: Maybe<(
    { __typename?: 'ScrimsCount' }
    & Pick<ScrimsCount, 'count'>
    & { data: Array<(
      { __typename?: 'Scrim' }
      & Pick<Scrim, 'id' | 'state' | 'matchCount' | 'duration' | 'realtime' | 'startTime' | 'completeTime' | 'roles'>
      & { host: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      ), guest?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      )>, hostPlayers: Array<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'email' | 'phoneNumber' | 'nickname'>
        & { teamRole?: Maybe<(
          { __typename?: 'TeamRole' }
          & { proficientRole?: Maybe<(
            { __typename?: 'ProficientRole' }
            & Pick<ProficientRole, 'id' | 'name'>
          )> }
        )> }
      )>, guestPlayers: Array<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'email' | 'phoneNumber' | 'nickname'>
        & { teamRole?: Maybe<(
          { __typename?: 'TeamRole' }
          & { proficientRole?: Maybe<(
            { __typename?: 'ProficientRole' }
            & Pick<ProficientRole, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type SponsorIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SponsorIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, sponsorAd: (
    { __typename?: 'SponsorAd' }
    & Pick<SponsorAd, 'id' | 'adsName' | 'sponsorName' | 'url' | 'assetType' | 'layoutType' | 'imageUrl' | 'videoUrl' | 'active' | 'reachesCount' | 'adClicksCount' | 'position' | 'screenRoute'>
    & { gamerBanner?: Maybe<(
      { __typename?: 'GamerBanner' }
      & Pick<GamerBanner, 'id' | 'title' | 'url'>
    )> }
  ) }
);

export type SponsorAdsIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
}>;


export type SponsorAdsIndexPageQuery = (
  { __typename?: 'Query' }
  & { sponsorAdAccounts: (
    { __typename?: 'SponsorAdCount' }
    & Pick<SponsorAdCount, 'count'>
    & { data: Array<(
      { __typename?: 'SponsorAd' }
      & Pick<SponsorAd, 'id' | 'adsName' | 'sponsorName' | 'url' | 'active' | 'reachesCount' | 'adClicksCount' | 'position'>
    )> }
  ) }
);

export type StarPlayerIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
}>;


export type StarPlayerIndexPageQuery = (
  { __typename?: 'Query' }
  & { starPlayerAccounts: (
    { __typename?: 'StarPlayerCount' }
    & Pick<StarPlayerCount, 'count'>
    & { data: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'officialName' | 'fullName' | 'verified' | 'countryCode'>
      & { team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type StarTeamsIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['String']>;
}>;


export type StarTeamsIndexPageQuery = (
  { __typename?: 'Query' }
  & { starTeamAccounts: (
    { __typename?: 'StarTeamsCount' }
    & Pick<StarTeamsCount, 'count'>
    & { data: Array<(
      { __typename?: 'Club' }
      & Pick<Club, 'id' | 'name' | 'verified'>
      & { teams: Array<(
        { __typename?: 'Team' }
        & Pick<Team, 'teamMembers'>
      )> }
    )> }
  ) }
);

export type TeamBankInfoIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamBankInfoIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, teamBankInfo?: Maybe<(
    { __typename?: 'BankInfo' }
    & Pick<BankInfo, 'id' | 'name' | 'bankName' | 'bankAccountNo' | 'country' | 'ic' | 'icImage' | 'selfie' | 'state' | 'rejectReason'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'avatarUrl' | 'nickname' | 'phoneNumber' | 'email' | 'language' | 'gender'>
      & { team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
        & { teamWallet?: Maybe<(
          { __typename?: 'TeamWallet' }
          & Pick<TeamWallet, 'id' | 'amount'>
        )> }
      )>, gameAccounts: Array<(
        { __typename?: 'GameAccount' }
        & Pick<GameAccount, 'id'>
        & { game: (
          { __typename?: 'Game' }
          & Pick<Game, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type TeamBankInfosPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
}>;


export type TeamBankInfosPageQuery = (
  { __typename?: 'Query' }
  & { teamBankInfoAccounts?: Maybe<(
    { __typename?: 'TeamBankInfoCount' }
    & Pick<TeamBankInfoCount, 'count'>
    & { data: Array<(
      { __typename?: 'BankInfo' }
      & Pick<BankInfo, 'id' | 'name' | 'bankName' | 'bankAccountNo' | 'ic' | 'state'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname'>
        & { team?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type TeamPayoutIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamPayoutIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, teamPayout?: Maybe<(
    { __typename?: 'TeamPayout' }
    & Pick<TeamPayout, 'id' | 'amount' | 'recipient' | 'bank' | 'bankAccountNo' | 'approve' | 'createdAt' | 'updatedAt' | 'state' | 'rejectReason' | 'adminNote' | 'ic'>
    & { teamWallet: (
      { __typename?: 'TeamWallet' }
      & TeamWalletFragment
    ), pilot: (
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'phoneNumber' | 'nickname' | 'email' | 'avatarUrl' | 'language' | 'gender' | 'trained' | 'adminNote'>
      & { bankInfo?: Maybe<(
        { __typename?: 'BankInfo' }
        & BankInfoFragment
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )> }
    ) }
  )> }
);

export type AllTeamPayoutsPageQueryVariables = Exact<{
  approve?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
}>;


export type AllTeamPayoutsPageQuery = (
  { __typename?: 'Query' }
  & { teamPayoutAccounts?: Maybe<(
    { __typename?: 'TeamPayoutCount' }
    & Pick<TeamPayoutCount, 'count'>
    & { data: Array<(
      { __typename?: 'TeamPayout' }
      & Pick<TeamPayout, 'id' | 'recipient' | 'email' | 'phone' | 'bank' | 'bankAccountNo' | 'amount' | 'approve' | 'createdAt' | 'state' | 'adminNote'>
    )> }
  )> }
);

export type TeamPayoutsCsvQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamPayoutsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'teamPayoutsCsv'>
);

export type TeamIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, team?: Maybe<(
    { __typename?: 'Team' }
    & TeamFragment
  )> }
);

export type TeamAccountsIndexPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  teamMember?: Maybe<Scalars['String']>;
  lanePlayer?: Maybe<Scalars['String']>;
  totalMatch?: Maybe<Scalars['String']>;
}>;


export type TeamAccountsIndexPageQuery = (
  { __typename?: 'Query' }
  & { teamAccounts?: Maybe<(
    { __typename?: 'TeamCount' }
    & Pick<TeamCount, 'count'>
    & { data: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'icon' | 'name' | 'gameId' | 'allCombatRoomSize'>
      & { manager: (
        { __typename?: 'User' }
        & Pick<User, 'nickname'>
      ), teamRolePlayers: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'nickname'>
      )>, pilots: Array<(
        { __typename?: 'Pilot' }
        & Pick<Pilot, 'id' | 'email' | 'phoneNumber' | 'nickname' | 'trained' | 'online'>
        & { bankInfo?: Maybe<(
          { __typename?: 'BankInfo' }
          & BankInfoFragment
        )> }
      )>, teamRoles: Array<(
        { __typename?: 'TeamRole' }
        & Pick<TeamRole, 'id'>
        & { proficientRole?: Maybe<(
          { __typename?: 'ProficientRole' }
          & Pick<ProficientRole, 'id' | 'name'>
        )>, pilot?: Maybe<(
          { __typename?: 'Pilot' }
          & Pick<Pilot, 'id' | 'email' | 'nickname'>
        )> }
      )> }
    )> }
  )> }
);

export type TeamsCsvQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'teamsCsv'>
);

export type TeamCombatRoomsQueryVariables = Exact<{
  id: Scalars['ID'];
  page: Scalars['Int'];
}>;


export type TeamCombatRoomsQuery = (
  { __typename?: 'Query' }
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
    & { allCombatRooms: (
      { __typename?: 'TeamCombatRoomCount' }
      & Pick<TeamCombatRoomCount, 'count'>
      & { data: Array<(
        { __typename?: 'CombatRoom' }
        & Pick<CombatRoom, 'id' | 'state' | 'createdAt'>
        & { accepter?: Maybe<(
          { __typename?: 'CombatTeam' }
          & Pick<CombatTeam, 'id'>
          & { team: (
            { __typename?: 'Team' }
            & Pick<Team, 'id' | 'name'>
          ) }
        )>, challenger: (
          { __typename?: 'CombatTeam' }
          & Pick<CombatTeam, 'id'>
          & { team: (
            { __typename?: 'Team' }
            & Pick<Team, 'id' | 'name'>
          ) }
        ), matchResults?: Maybe<Array<(
          { __typename?: 'MatchResult' }
          & Pick<MatchResult, 'id' | 'createdAt' | 'updatedAt'>
          & { winTeam?: Maybe<(
            { __typename?: 'Team' }
            & Pick<Team, 'id' | 'name'>
          )>, loseTeam?: Maybe<(
            { __typename?: 'Team' }
            & Pick<Team, 'id' | 'name'>
          )> }
        )>>, winTeam?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id'>
          & { club?: Maybe<(
            { __typename?: 'Club' }
            & Pick<Club, 'id' | 'name'>
          )> }
        )>, loseTeam?: Maybe<(
          { __typename?: 'Team' }
          & Pick<Team, 'id'>
          & { club?: Maybe<(
            { __typename?: 'Club' }
            & Pick<Club, 'id' | 'name'>
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type TeamCreditTransactionQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
}>;


export type TeamCreditTransactionQuery = (
  { __typename?: 'Query' }
  & { team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'icon' | 'name'>
    & { manager: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname'>
    ), teamWallet?: Maybe<(
      { __typename?: 'TeamWallet' }
      & Pick<TeamWallet, 'id' | 'amount'>
      & { teamCreditTransactions: (
        { __typename?: 'CreditTransactionsCount' }
        & Pick<CreditTransactionsCount, 'count'>
        & { data: Array<(
          { __typename?: 'CreditTransaction' }
          & Pick<CreditTransaction, 'id' | 'amount' | 'state' | 'transactionType' | 'createdAt' | 'targetType' | 'targetId' | 'spinnable' | 'token' | 'isDebit' | 'isCredit' | 'transactionCategory'>
          & { leaderboardPoolPrize?: Maybe<(
            { __typename?: 'Leaderboard' }
            & LeaderboardFragment
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type TopUpProductIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TopUpProductIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, topUpProduct: (
    { __typename?: 'TopupProduct' }
    & TopUpProductFragment
  ) }
);

export type TopUpProductIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  searchString?: Maybe<Scalars['String']>;
  genre?: Maybe<GameGenre>;
  categoryId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['ID']>;
  publisherId?: Maybe<Scalars['ID']>;
}>;


export type TopUpProductIndexPageQuery = (
  { __typename?: 'Query' }
  & { topUpProductAccounts: (
    { __typename?: 'TopUpProductCount' }
    & Pick<TopUpProductCount, 'count'>
    & { data: Array<(
      { __typename?: 'TopupProduct' }
      & TopUpProductFragment
    )> }
  ) }
);

export type TournamentEventIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentEventIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, tournamentEvent: (
    { __typename?: 'TournamentEvent' }
    & Pick<TournamentEvent, 'id' | 'name' | 'startDate' | 'endDate'>
    & { tournament: (
      { __typename?: 'Tournament' }
      & Pick<Tournament, 'id' | 'name'>
    ) }
  ) }
);

export type TournamentTeamIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentTeamIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, tournamentTeam: (
    { __typename?: 'TournamentTeam' }
    & Pick<TournamentTeam, 'id' | 'state'>
    & { team: (
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & Pick<Club, 'id' | 'name'>
        & { manager: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber' | 'icName' | 'icNumber' | 'discordId'>
          & { gameAccounts: Array<(
            { __typename?: 'GameAccount' }
            & Pick<GameAccount, 'id' | 'accountId' | 'inGameName' | 'serverId'>
          )> }
        ) }
      )> }
    ), captain: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'phoneNumber' | 'nickname' | 'icName' | 'icNumber' | 'discordId'>
      & { gameAccounts: Array<(
        { __typename?: 'GameAccount' }
        & Pick<GameAccount, 'id' | 'accountId' | 'inGameName' | 'serverId'>
      )> }
    ), players: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber' | 'icName' | 'icNumber' | 'discordId'>
      & { gameAccounts: Array<(
        { __typename?: 'GameAccount' }
        & Pick<GameAccount, 'id' | 'accountId' | 'inGameName' | 'serverId'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )>, teamRole?: Maybe<(
        { __typename?: 'TeamRole' }
        & Pick<TeamRole, 'id'>
        & { proficientRole?: Maybe<(
          { __typename?: 'ProficientRole' }
          & Pick<ProficientRole, 'id' | 'name'>
        )> }
      )> }
    )>, subPlayers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber' | 'icName' | 'icNumber' | 'discordId'>
      & { gameAccounts: Array<(
        { __typename?: 'GameAccount' }
        & Pick<GameAccount, 'id' | 'accountId' | 'inGameName' | 'serverId'>
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      )>, teamRole?: Maybe<(
        { __typename?: 'TeamRole' }
        & Pick<TeamRole, 'id'>
        & { proficientRole?: Maybe<(
          { __typename?: 'ProficientRole' }
          & Pick<ProficientRole, 'id' | 'name'>
        )> }
      )> }
    )>, tournament: (
      { __typename?: 'Tournament' }
      & Pick<Tournament, 'id' | 'state' | 'name'>
    ) }
  ) }
);

export type TournamentTeamsIndexPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
}>;


export type TournamentTeamsIndexPageQuery = (
  { __typename?: 'Query' }
  & { tournamentTeamAccounts?: Maybe<(
    { __typename?: 'TournamentTeamCount' }
    & Pick<TournamentTeamCount, 'count'>
    & { data: Array<(
      { __typename?: 'TournamentTeam' }
      & Pick<TournamentTeam, 'id' | 'createdAt' | 'updatedAt' | 'state'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
        & { club?: Maybe<(
          { __typename?: 'Club' }
          & Pick<Club, 'id' | 'name'>
        )> }
      ), captain: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'phoneNumber' | 'nickname'>
      ), players: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
      )>, subPlayers: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
      )>, tournament: (
        { __typename?: 'Tournament' }
        & Pick<Tournament, 'id' | 'state' | 'name'>
      ) }
    )> }
  )> }
);

export type TournamentTeamsCsvQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;


export type TournamentTeamsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'tournamentTeamsCsv'>
);

export type TournamentIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, tournament: (
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'name' | 'state' | 'logo' | 'backgroundImage'>
    & { tournamentEvents?: Maybe<Array<(
      { __typename?: 'TournamentEvent' }
      & Pick<TournamentEvent, 'id' | 'name' | 'startDate' | 'endDate'>
    )>> }
  ) }
);

export type TournamentsIndexPageQueryVariables = Exact<{
  page: Scalars['Int'];
  searchString?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  gameId: Scalars['String'];
}>;


export type TournamentsIndexPageQuery = (
  { __typename?: 'Query' }
  & { tournamentAccounts: (
    { __typename?: 'TournamentCount' }
    & Pick<TournamentCount, 'count'>
    & { data: Array<(
      { __typename?: 'Tournament' }
      & Pick<Tournament, 'id' | 'name' | 'state'>
      & { game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type TournamentTournamentEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TournamentTournamentEventQuery = (
  { __typename?: 'Query' }
  & { tournament: (
    { __typename?: 'Tournament' }
    & { tournamentEvents?: Maybe<Array<(
      { __typename?: 'TournamentEvent' }
      & Pick<TournamentEvent, 'id' | 'name' | 'startDate' | 'endDate'>
    )>> }
  ) }
);

export type UserPrizeIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserPrizeIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, userPrize: (
    { __typename?: 'UserPrize' }
    & { prize?: Maybe<(
      { __typename?: 'Prize' }
      & PrizeFragment
    )> }
    & UserPrizeFragment
  ) }
);

export type AllUserPrizesPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
}>;


export type AllUserPrizesPageQuery = (
  { __typename?: 'Query' }
  & { userPrizes?: Maybe<(
    { __typename?: 'UserPrizeCount' }
    & Pick<UserPrizeCount, 'count'>
    & { data: Array<(
      { __typename?: 'UserPrize' }
      & UserPrizeFragment
    )> }
  )> }
);

export type UserBlastScheduleIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserBlastScheduleIdPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )>, userBlastSchedule: (
    { __typename?: 'UserBlastSchedule' }
    & Pick<UserBlastSchedule, 'id' | 'title' | 'body' | 'isScheduled' | 'scheduleDatetime' | 'state'>
  ) }
);

export type UserBlastSchedulesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
}>;


export type UserBlastSchedulesQuery = (
  { __typename?: 'Query' }
  & { userBlastScheduleAccounts?: Maybe<(
    { __typename?: 'UserBlastScheduleCount' }
    & Pick<UserBlastScheduleCount, 'count'>
    & { data: Array<(
      { __typename?: 'UserBlastSchedule' }
      & Pick<UserBlastSchedule, 'id' | 'title' | 'body' | 'isScheduled' | 'scheduleDatetime' | 'state'>
    )> }
  )> }
);

export type UsersIdPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UsersIdPageQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'avatarUrl' | 'proPhotoUrl' | 'coverPictureUrl' | 'createdAt' | 'email' | 'adminNote' | 'gender' | 'id' | 'language' | 'lastSignInAt' | 'nickname' | 'phoneNumber' | 'referralCode' | 'signInCount' | 'updatedAt' | 'suspend' | 'beStar' | 'bePro' | 'beKol' | 'verified' | 'themeColors' | 'fullName' | 'officialName' | 'nationality' | 'career' | 'overview' | 'introText'>
    & { creditWallet: (
      { __typename?: 'CreditWallet' }
      & Pick<CreditWallet, 'amount' | 'currency' | 'id'>
      & { creditTransactions: Array<(
        { __typename?: 'CreditTransaction' }
        & CreditTransactionFragment
      )> }
    ), gameBundles: Array<(
      { __typename?: 'GameBundle' }
      & Pick<GameBundle, 'id' | 'amount' | 'completionScreenshotUrl' | 'completionTime' | 'createdAt' | 'currentRankPoint' | 'currentRankStar' | 'currentRankTier' | 'rating' | 'ratingRemark' | 'remark' | 'reportReason' | 'startTime' | 'state' | 'targetRankPoint' | 'targetRankStar' | 'targetRankTier'>
      & { currentGameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      ), game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), pilot?: Maybe<(
        { __typename?: 'SimpleUser' }
        & Pick<SimpleUser, 'id' | 'nickname'>
      )>, reporter?: Maybe<(
        { __typename?: 'SimpleUser' }
        & Pick<SimpleUser, 'id' | 'nickname'>
      )>, targetGameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      ) }
    )>, partneringRequests: Array<(
      { __typename?: 'PartneringRequest' }
      & Pick<PartneringRequest, 'amount' | 'completeTime' | 'createdAt' | 'id' | 'originalAmount' | 'promoApplied' | 'rankPoint' | 'rankStar' | 'rankTier' | 'rating' | 'ratingRemark' | 'reportReason' | 'startTime' | 'state' | 'totalAmount' | 'totalDurationInMinutes' | 'totalOriginalAmount'>
      & { game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ), gameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      ), pilot?: Maybe<(
        { __typename?: 'SimpleUser' }
        & Pick<SimpleUser, 'id' | 'nickname'>
      )>, reporter?: Maybe<(
        { __typename?: 'SimpleUser' }
        & Pick<SimpleUser, 'id' | 'nickname'>
      )> }
    )>, suspendGamerRequests?: Maybe<Array<(
      { __typename?: 'SuspendGamerRequest' }
      & Pick<SuspendGamerRequest, 'id' | 'reason' | 'state' | 'createdAt'>
    )>>, gameAccounts: Array<(
      { __typename?: 'GameAccount' }
      & Pick<GameAccount, 'id' | 'accountId' | 'verificationCode' | 'codeState' | 'screenshotState' | 'testState' | 'screenshotUrl' | 'createdAt' | 'approve' | 'updatedAt' | 'testScore' | 'remindAt' | 'remindCount' | 'rejectReason' | 'rankTier' | 'rankStar' | 'rankPoint'>
      & { gameRank: (
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name' | 'rankStarMaxCount' | 'rankPointMaxCount'>
      ), game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name' | 'developerName' | 'testPassingScore'>
      ), pairableGameRanks: Array<(
        { __typename?: 'GameRank' }
        & Pick<GameRank, 'id' | 'name'>
      )>, proficientRoles: Array<(
        { __typename?: 'ProficientRole' }
        & Pick<ProficientRole, 'id' | 'name'>
      )> }
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'country' | 'state'>
      & { club?: Maybe<(
        { __typename?: 'Club' }
        & Pick<Club, 'id' | 'name' | 'icon' | 'tier'>
      )>, game: (
        { __typename?: 'Game' }
        & Pick<Game, 'id' | 'name'>
      ) }
    )>, careerHighlights?: Maybe<Array<(
      { __typename?: 'CareerHighlight' }
      & Pick<CareerHighlight, 'id' | 'title' | 'startYear' | 'endYear' | 'position'>
    )>> }
  )> }
);

export type UsersIndexPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  suspended?: Maybe<Scalars['Boolean']>;
  searchString?: Maybe<Scalars['String']>;
}>;


export type UsersIndexPageQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserCount' }
    & Pick<UserCount, 'count'>
    & { data: Array<(
      { __typename?: 'UserIndex' }
      & Pick<UserIndex, 'id' | 'nickname' | 'phoneNumber' | 'email' | 'creditWalletAmount' | 'suspend' | 'adminNote'>
    )> }
  )> }
);

export type UsersCsvQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'usersCsv'>
);

export type TransactionsQueryVariables = Exact<{
  id: Scalars['ID'];
  page: Scalars['Int'];
}>;


export type TransactionsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'nickname' | 'createdAt'>
    & { creditWallet: (
      { __typename?: 'CreditWallet' }
      & Pick<CreditWallet, 'amount' | 'id'>
      & { transactionHistory: Array<(
        { __typename?: 'CreditTransaction' }
        & CreditTransactionFragment
      )> }
    ), userCreditTransaction: (
      { __typename?: 'UserCreditTransactionCount' }
      & Pick<UserCreditTransactionCount, 'count'>
      & { data: Array<(
        { __typename?: 'CreditTransaction' }
        & CreditTransactionFragment
      )> }
    ) }
  )> }
);

export type CheckLocationQueryVariables = Exact<{
  ipAddress: Scalars['String'];
}>;


export type CheckLocationQuery = (
  { __typename?: 'Query' }
  & { checkLocation: (
    { __typename?: 'CheckLocation' }
    & Pick<CheckLocation, 'valid'>
  ) }
);

export type GameChatroomsQueryVariables = Exact<{ [key: string]: never; }>;


export type GameChatroomsQuery = (
  { __typename?: 'Query' }
  & { gameChatrooms?: Maybe<Array<(
    { __typename?: 'GameChatroom' }
    & GameChatroomFragment
  )>> }
);

export type GamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GamesQuery = (
  { __typename?: 'Query' }
  & { games?: Maybe<Array<(
    { __typename?: 'Game' }
    & GameFragment
  )>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & BasicAdminFragment
  )> }
);

export type RejectReasonsQueryVariables = Exact<{
  kind: RejectReasonKind;
}>;


export type RejectReasonsQuery = (
  { __typename?: 'Query' }
  & { rejectReasons: Array<(
    { __typename?: 'RejectReason' }
    & RejectReasonFragment
  )> }
);

export type SearchGameRanksQueryVariables = Exact<{
  gameId: Scalars['String'];
}>;


export type SearchGameRanksQuery = (
  { __typename?: 'Query' }
  & { searchGameRanks?: Maybe<Array<(
    { __typename?: 'GameRank' }
    & Pick<GameRank, 'id' | 'name' | 'rankTierMaxCount' | 'rankStarMaxCount' | 'rankPointMaxCount' | 'ordering'>
    & { game: (
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'name'>
    ) }
  )>> }
);

export type SearchPilotsQueryVariables = Exact<{
  searchString: Scalars['String'];
  gameId: Scalars['String'];
}>;


export type SearchPilotsQuery = (
  { __typename?: 'Query' }
  & { searchPilots?: Maybe<Array<(
    { __typename?: 'GameAccount' }
    & Pick<GameAccount, 'id' | 'accountId'>
    & { pilot: (
      { __typename?: 'Pilot' }
      & Pick<Pilot, 'id' | 'email' | 'phoneNumber' | 'nickname'>
    ), game: (
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'name'>
    ) }
  )>> }
);

export type SearchPoolPrizesQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchPoolPrizesQuery = (
  { __typename?: 'Query' }
  & { searchPoolPrizes?: Maybe<Array<(
    { __typename?: 'PoolPrize' }
    & PoolPrizeFragment
  )>> }
);

export type TournamentsQueryVariables = Exact<{ [key: string]: never; }>;


export type TournamentsQuery = (
  { __typename?: 'Query' }
  & { tournaments?: Maybe<Array<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'id' | 'name' | 'state'>
  )>> }
);

export type UserPromoCodesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
}>;


export type UserPromoCodesQuery = (
  { __typename?: 'Query' }
  & { userPromoCodes?: Maybe<(
    { __typename?: 'UserPromoCodeCount' }
    & Pick<UserPromoCodeCount, 'count'>
    & { data: Array<(
      { __typename?: 'UserPromoCode' }
      & Pick<UserPromoCode, 'id'>
      & { promoCode: (
        { __typename?: 'PromoCode' }
        & Pick<PromoCode, 'id'>
      ), user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname' | 'email' | 'phoneNumber'>
      ) }
    )> }
  )> }
);

export type ListOfProductsQueryVariables = Exact<{
  searchString?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
}>;


export type ListOfProductsQuery = (
  { __typename?: 'Query' }
  & { listOfProducts: Array<(
    { __typename?: 'VocaProduct' }
    & Pick<VocaProduct, 'id' | 'title' | 'code' | 'typeOrder' | 'vendor'>
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )> }
  )> }
);

export type VendorsQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorsQuery = (
  { __typename?: 'Query' }
  & { vendors?: Maybe<Array<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id' | 'name' | 'description'>
  )>> }
);

export type PublishersQueryVariables = Exact<{ [key: string]: never; }>;


export type PublishersQuery = (
  { __typename?: 'Query' }
  & { publishers?: Maybe<Array<(
    { __typename?: 'Publisher' }
    & Pick<Publisher, 'id' | 'name' | 'description'>
  )>> }
);

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>> }
);

export type TopUpProductItemsQueryVariables = Exact<{
  searchString?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  vendorName?: Maybe<Scalars['String']>;
}>;


export type TopUpProductItemsQuery = (
  { __typename?: 'Query' }
  & { topUpProductItems: Array<(
    { __typename?: 'TopupProductItem' }
    & Pick<TopupProductItem, 'id' | 'name' | 'price'>
  )> }
);

export type TopUpProductItemQueryVariables = Exact<{
  id: Scalars['ID'];
  vendorName: Scalars['String'];
  productId: Scalars['ID'];
}>;


export type TopUpProductItemQuery = (
  { __typename?: 'Query' }
  & { topUpProductItem: (
    { __typename?: 'TopupProductItem' }
    & Pick<TopupProductItem, 'id' | 'name' | 'price'>
  ) }
);

export const BasicAdminFragmentDoc = gql`
    fragment BasicAdmin on Admin {
  id
  email
  supervisor
  role
  isSuper
  canManage
  canEdit
  canViewFinance
  canViewJobs
  canViewUsers
}
    `;
export const AdminLogFragmentDoc = gql`
    fragment AdminLog on AdminLog {
  id
  description
  targetType
  targetId
  createdAt
  admin {
    id
    email
  }
}
    `;
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  name
}
    `;
export const LeaderboardFragmentDoc = gql`
    fragment Leaderboard on Leaderboard {
  id
  year
  month
  beginningWeek
  endWeek
  numberOfWeek
  createdAt
  updatedAt
  totalPrize
  totalPrize
  totalRegisteredTeamCounts
  state
  poolPrizes {
    id
    amount
    name
    currency
    ordering
    combatTeam {
      id
      score
      team {
        id
        club {
          id
          name
        }
      }
    }
  }
}
    `;
export const BankInfoFragmentDoc = gql`
    fragment BankInfo on BankInfo {
  id
  name
  bankName
  bankAccountNo
  country
  ic
  icImage
  state
  updatedAt
  rejectReason
  selfie
}
    `;
export const TeamFragmentDoc = gql`
    fragment Team on Team {
  id
  icon
  name
  manager {
    id
    nickname
  }
  teamWallet {
    id
    amount
    creditTransactions {
      id
      amount
      state
      transactionType
      createdAt
      targetType
      targetId
      spinnable
      token
      isDebit
      isCredit
      transactionCategory
      leaderboardPoolPrize {
        ...Leaderboard
      }
    }
  }
  teamRoles {
    id
    proficientRole {
      id
      name
    }
    pilot {
      id
      email
      nickname
      phoneNumber
    }
  }
  pilots {
    id
    email
    nickname
    phoneNumber
  }
  createdAt
  game {
    id
    name
  }
  currentCombat {
    id
    reason
    suspended
  }
  suspend
  reason
  teamCombatTeams {
    id
    teamId
    leaderboardId
    score
    position
    endWeek
  }
  themeColors
  overview
  location
  founded
  careerHighlights {
    id
    title
    startYear
    endYear
    position
  }
  bankInfo {
    ...BankInfo
  }
}
    ${LeaderboardFragmentDoc}
${BankInfoFragmentDoc}`;
export const ClubFragmentDoc = gql`
    fragment Club on Club {
  id
  name
  icon
  description
  tier
  teams {
    ...Team
  }
  beStar
  verified
  themeColor
  tier
  founded
  origin
  introText
  overview
}
    ${TeamFragmentDoc}`;
export const CreditTransactionFragmentDoc = gql`
    fragment CreditTransaction on CreditTransaction {
  id
  amount
  state
  transactionType
  createdAt
  targetId
  targetType
  order {
    id
    amount
    status
  }
}
    `;
export const PayoutFragmentDoc = gql`
    fragment Payout on Payout {
  id
  amount
  recipient
  bank
  bankAccountNo
  approve
  createdAt
}
    `;
export const CreditWalletFragmentDoc = gql`
    fragment CreditWallet on CreditWallet {
  amount
  currency
  creditTransactions {
    ...CreditTransaction
  }
  id
  payouts {
    ...Payout
  }
  user {
    id
    nickname
  }
}
    ${CreditTransactionFragmentDoc}
${PayoutFragmentDoc}`;
export const ErrorFragmentDoc = gql`
    fragment Error on Error {
  path
  detail
}
    `;
export const GameRankFragmentDoc = gql`
    fragment GameRank on GameRank {
  id
  name
  ordering
  rankTierMaxCount
  rankStarMaxCount
  rankPointMaxCount
  imageUrl
  game {
    id
  }
}
    `;
export const GameFragmentDoc = gql`
    fragment Game on Game {
  id
  name
  iconUrl
  developerName
  teamsCount
  gameAccountsCount
  shortname
  genre
  platform
  combat
  scrim
  realTime
  schedule
  matchmaking
  active
  themeColor
  gameRanks {
    ...GameRank
  }
  iconUrl
  mascotUrl
  logoUrl
  screenshotSampleUrl
  ordering
  interests {
    id
    name
    iconOnUrl
    iconOffUrl
    active
  }
  proficientRoles {
    id
    name
    imageUrl
    selectedImageUrl
    proficientHeros {
      id
      name
      imageUrl
    }
    kind
  }
  vocaCode
}
    ${GameRankFragmentDoc}`;
export const GameChatroomFragmentDoc = gql`
    fragment GameChatroom on GameChatroom {
  id
  game {
    ...Game
  }
  state
  createdAt
}
    ${GameFragmentDoc}`;
export const GameMessageFragmentDoc = gql`
    fragment GameMessage on GameMessage {
  id
  text
  gameChatroom {
    ...GameChatroom
  }
  messageType
  createdAt
  user {
    id
    nickname
    phoneNumber
    email
    avatarUrl
  }
  admin {
    id
    email
  }
  team {
    ...Team
  }
  scrim {
    id
    state
    description
    host {
      ...Team
    }
    guest {
      ...Team
    }
  }
}
    ${GameChatroomFragmentDoc}
${TeamFragmentDoc}`;
export const VendorFragmentDoc = gql`
    fragment Vendor on Vendor {
  id
  name
  description
}
    `;
export const MarkupPricingFragmentDoc = gql`
    fragment MarkupPricing on MarkupPricing {
  id
  percentage
  name
  description
  active
  uniqueId
  merchantId
  productId
  invoiceId
  createdAt
  countryCode
  itemId
  startDate
  endDate
  vendor {
    ...Vendor
  }
}
    ${VendorFragmentDoc}`;
export const MerchandiseFragmentDoc = gql`
    fragment Merchandise on Merchandise {
  id
  name
  startDate
  endDate
  club {
    id
    name
  }
  user {
    id
    nickname
    email
    phoneNumber
  }
  active
  category {
    id
    name
  }
  post {
    id
    url
    title
  }
  images
  position
  merchandiseVariants {
    id
    name
    price
    position
    sku
    stock
  }
  createdAt
}
    `;
export const PostFragmentDoc = gql`
    fragment Post on Post {
  id
  url
  title
  text
  active
  isYoutube
  views
  createdAt
  likeCount
  postComments {
    id
    body
    createdAt
    postId
    postTitle
  }
  ogData {
    title
    image
  }
  targetType
  targetId
  proTip {
    id
    image
    text
    video
    prosOnly
  }
  topic {
    id
    image
    text
    video
    prosOnly
  }
  merchandises {
    ...Merchandise
  }
  postImages
  postVideos
  author {
    id
    nickname
  }
  body
  favCount
  commentsCount
  imageRatio
  assetId
  playbackId
  aspectRatio
  uploadId
  postType
  newsImage
}
    ${MerchandiseFragmentDoc}`;
export const MatchMakingFragmentDoc = gql`
    fragment MatchMaking on MatchMaking {
  id
  matchCount
  state
  currency
  price
  minimumRankId
  maximumRankId
  maximumRank {
    ...GameRank
  }
  minimumRank {
    ...GameRank
  }
  queueLimit
  queueCount
  startTime
  endTime
  prosOnly
  post {
    ...Post
  }
  user {
    id
    nickname
    email
    phoneNumber
  }
  game {
    id
    name
  }
}
    ${GameRankFragmentDoc}
${PostFragmentDoc}`;
export const PilotLevelFragmentDoc = gql`
    fragment PilotLevel on PilotLevel {
  id
  name
  position
  minRealtimeHour
  minScheduleCount
  minRating
  maxCancelCount
  maxIdleCount
  maxRespondTime
  rewardValue
}
    `;
export const PilotFragmentDoc = gql`
    fragment Pilot on Pilot {
  id
  phoneNumber
  nickname
  gender
  email
  avatarUrl
  language
  updatedAt
}
    `;
export const PilotTierFragmentDoc = gql`
    fragment PilotTier on PilotTier {
  id
  name
  aggregateRating
  completeGameBundlesCount
  completePartneringRequestsCount
}
    `;
export const GameAccountFragmentDoc = gql`
    fragment GameAccount on GameAccount {
  id
  accountId
  verificationCode
  codeState
  screenshotState
  testState
  screenshotUrl
  createdAt
  updatedAt
  approve
  testScore
  gameRank {
    ...GameRank
  }
  game {
    ...Game
  }
  pilot {
    ...Pilot
  }
  pairableGameRanks {
    id
    name
  }
  pilotTier {
    ...PilotTier
  }
}
    ${GameRankFragmentDoc}
${GameFragmentDoc}
${PilotFragmentDoc}
${PilotTierFragmentDoc}`;
export const MonthlyReportFragmentDoc = gql`
    fragment MonthlyReport on MonthlyReport {
  id
  month
  year
  createdAt
  scheduleCount
  realtimeHours
  cancelCount
  rating
  respondTime
  nextLevel {
    ...PilotLevel
  }
  pilotLevel {
    ...PilotLevel
  }
  gameAccount {
    ...GameAccount
  }
}
    ${PilotLevelFragmentDoc}
${GameAccountFragmentDoc}`;
export const OfficialChannelFragmentDoc = gql`
    fragment OfficialChannel on OfficialChannel {
  id
  name
  url
  phoneNumber
  accountId
  active
}
    `;
export const PoolPrizeFragmentDoc = gql`
    fragment PoolPrize on PoolPrize {
  id
  name
  amount
  ordering
  leaderboard {
    id
    beginningWeek
    endWeek
    month
    year
    totalPrize
  }
  combatTeam {
    id
  }
  createdAt
  updatedAt
}
    `;
export const PrizeFragmentDoc = gql`
    fragment Prize on Prize {
  id
  prizeName
  value
  icon
  prizeType
  createdAt
  position
  active
  limit
}
    `;
export const PromoCodeFragmentDoc = gql`
    fragment PromoCode on PromoCode {
  active
  amount
  availableFor
  code
  gameType
  id
  minimumAmount
  validUntil
  redeemLimit
  userIds
}
    `;
export const PublisherFragmentDoc = gql`
    fragment Publisher on Publisher {
  id
  name
  description
}
    `;
export const RejectReasonFragmentDoc = gql`
    fragment RejectReason on RejectReason {
  id
  text
  kind
}
    `;
export const ReportRequestFragmentDoc = gql`
    fragment ReportRequest on ReportRequest {
  id
  reason
  user {
    id
    nickname
    phoneNumber
    email
  }
  targetType
  targetId
  state
  createdAt
  post {
    id
    reportRequestsCount
  }
}
    `;
export const SuspendGamerRequestFragmentDoc = gql`
    fragment SuspendGamerRequest on SuspendGamerRequest {
  id
  state
  reason
  createdAt
}
    `;
export const SuspendRequestFragmentDoc = gql`
    fragment SuspendRequest on SuspendRequest {
  id
  state
  reason
  createdAt
}
    `;
export const TeamPayoutFragmentDoc = gql`
    fragment TeamPayout on TeamPayout {
  id
  amount
  recipient
  bank
  bankAccountNo
  approve
  createdAt
  updatedAt
}
    `;
export const TeamWalletFragmentDoc = gql`
    fragment TeamWallet on TeamWallet {
  amount
  creditTransactions {
    ...CreditTransaction
  }
  id
  teamPayouts {
    ...TeamPayout
  }
  currency
}
    ${CreditTransactionFragmentDoc}
${TeamPayoutFragmentDoc}`;
export const TopUpProductFragmentDoc = gql`
    fragment TopUpProduct on TopupProduct {
  id
  originId
  title
  description
  vendor {
    id
    name
    description
  }
  code
  countryCodes
  genre
  featured
  ordering
  publisher {
    id
    name
    description
  }
  category {
    id
    name
  }
  isActive
  logoUrl
  coverUrl
  avatarUrl
  helperUrl
  publisherLogoUrl
  vocaId
}
    `;
export const UserPrizeFragmentDoc = gql`
    fragment UserPrize on UserPrize {
  address
  createdAt
  creditTransactionId
  fullName
  icNumber
  id
  inGameId
  inGameName
  probabilities
  skinName
  state
  userId
  userNickname
  remark
  printName
}
    `;
export const AdminAuthorizationDocument = gql`
    mutation AdminAuthorization($email: String!, $password: String!, $ipAddress: String!) {
  adminAuthorization(input: {email: $email, password: $password, ipAddress: $ipAddress}) {
    jwt
    admin {
      ...BasicAdmin
    }
    errors {
      ...Error
    }
  }
}
    ${BasicAdminFragmentDoc}
${ErrorFragmentDoc}`;
export type AdminAuthorizationMutationFn = Apollo.MutationFunction<AdminAuthorizationMutation, AdminAuthorizationMutationVariables>;

/**
 * __useAdminAuthorizationMutation__
 *
 * To run a mutation, you first call `useAdminAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAuthorizationMutation, { data, loading, error }] = useAdminAuthorizationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      ipAddress: // value for 'ipAddress'
 *   },
 * });
 */
export function useAdminAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<AdminAuthorizationMutation, AdminAuthorizationMutationVariables>) {
        return Apollo.useMutation<AdminAuthorizationMutation, AdminAuthorizationMutationVariables>(AdminAuthorizationDocument, baseOptions);
      }
export type AdminAuthorizationMutationHookResult = ReturnType<typeof useAdminAuthorizationMutation>;
export type AdminAuthorizationMutationResult = Apollo.MutationResult<AdminAuthorizationMutation>;
export type AdminAuthorizationMutationOptions = Apollo.BaseMutationOptions<AdminAuthorizationMutation, AdminAuthorizationMutationVariables>;
export const BlastPartnersDocument = gql`
    mutation BlastPartners($title: String!, $body: String!, $userIds: [ID!]) {
  blastPartners(input: {title: $title, body: $body, userIds: $userIds}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type BlastPartnersMutationFn = Apollo.MutationFunction<BlastPartnersMutation, BlastPartnersMutationVariables>;

/**
 * __useBlastPartnersMutation__
 *
 * To run a mutation, you first call `useBlastPartnersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlastPartnersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blastPartnersMutation, { data, loading, error }] = useBlastPartnersMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useBlastPartnersMutation(baseOptions?: Apollo.MutationHookOptions<BlastPartnersMutation, BlastPartnersMutationVariables>) {
        return Apollo.useMutation<BlastPartnersMutation, BlastPartnersMutationVariables>(BlastPartnersDocument, baseOptions);
      }
export type BlastPartnersMutationHookResult = ReturnType<typeof useBlastPartnersMutation>;
export type BlastPartnersMutationResult = Apollo.MutationResult<BlastPartnersMutation>;
export type BlastPartnersMutationOptions = Apollo.BaseMutationOptions<BlastPartnersMutation, BlastPartnersMutationVariables>;
export const CreateStarPlayerDocument = gql`
    mutation CreateStarPlayer($userId: ID!, $proPhoto: File!, $themeColors: [String!]!, $fullName: String!, $officialName: String!, $nationality: String!, $career: String!, $overview: String!) {
  createStarPlayer(input: {userId: $userId, proPhoto: $proPhoto, themeColors: $themeColors, fullName: $fullName, officialName: $officialName, nationality: $nationality, career: $career, overview: $overview}) {
    user {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateStarPlayerMutationFn = Apollo.MutationFunction<CreateStarPlayerMutation, CreateStarPlayerMutationVariables>;

/**
 * __useCreateStarPlayerMutation__
 *
 * To run a mutation, you first call `useCreateStarPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStarPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStarPlayerMutation, { data, loading, error }] = useCreateStarPlayerMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      proPhoto: // value for 'proPhoto'
 *      themeColors: // value for 'themeColors'
 *      fullName: // value for 'fullName'
 *      officialName: // value for 'officialName'
 *      nationality: // value for 'nationality'
 *      career: // value for 'career'
 *      overview: // value for 'overview'
 *   },
 * });
 */
export function useCreateStarPlayerMutation(baseOptions?: Apollo.MutationHookOptions<CreateStarPlayerMutation, CreateStarPlayerMutationVariables>) {
        return Apollo.useMutation<CreateStarPlayerMutation, CreateStarPlayerMutationVariables>(CreateStarPlayerDocument, baseOptions);
      }
export type CreateStarPlayerMutationHookResult = ReturnType<typeof useCreateStarPlayerMutation>;
export type CreateStarPlayerMutationResult = Apollo.MutationResult<CreateStarPlayerMutation>;
export type CreateStarPlayerMutationOptions = Apollo.BaseMutationOptions<CreateStarPlayerMutation, CreateStarPlayerMutationVariables>;
export const CreateStarTeamDocument = gql`
    mutation CreateStarTeam($teamId: ID!, $themeColors: [String!]!, $location: String!, $overview: String!, $founded: ISO8601DateTime!) {
  createStarTeam(input: {teamId: $teamId, themeColors: $themeColors, location: $location, founded: $founded, overview: $overview}) {
    team {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateStarTeamMutationFn = Apollo.MutationFunction<CreateStarTeamMutation, CreateStarTeamMutationVariables>;

/**
 * __useCreateStarTeamMutation__
 *
 * To run a mutation, you first call `useCreateStarTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStarTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStarTeamMutation, { data, loading, error }] = useCreateStarTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      themeColors: // value for 'themeColors'
 *      location: // value for 'location'
 *      overview: // value for 'overview'
 *      founded: // value for 'founded'
 *   },
 * });
 */
export function useCreateStarTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateStarTeamMutation, CreateStarTeamMutationVariables>) {
        return Apollo.useMutation<CreateStarTeamMutation, CreateStarTeamMutationVariables>(CreateStarTeamDocument, baseOptions);
      }
export type CreateStarTeamMutationHookResult = ReturnType<typeof useCreateStarTeamMutation>;
export type CreateStarTeamMutationResult = Apollo.MutationResult<CreateStarTeamMutation>;
export type CreateStarTeamMutationOptions = Apollo.BaseMutationOptions<CreateStarTeamMutation, CreateStarTeamMutationVariables>;
export const AdminTransferDocument = gql`
    mutation AdminTransfer($userId: ID!, $amount: Float) {
  adminTransfer(input: {userId: $userId, amount: $amount}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type AdminTransferMutationFn = Apollo.MutationFunction<AdminTransferMutation, AdminTransferMutationVariables>;

/**
 * __useAdminTransferMutation__
 *
 * To run a mutation, you first call `useAdminTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTransferMutation, { data, loading, error }] = useAdminTransferMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAdminTransferMutation(baseOptions?: Apollo.MutationHookOptions<AdminTransferMutation, AdminTransferMutationVariables>) {
        return Apollo.useMutation<AdminTransferMutation, AdminTransferMutationVariables>(AdminTransferDocument, baseOptions);
      }
export type AdminTransferMutationHookResult = ReturnType<typeof useAdminTransferMutation>;
export type AdminTransferMutationResult = Apollo.MutationResult<AdminTransferMutation>;
export type AdminTransferMutationOptions = Apollo.BaseMutationOptions<AdminTransferMutation, AdminTransferMutationVariables>;
export const CreditWalletDocument = gql`
    query CreditWallet($id: ID!) {
  creditWallet(id: $id) {
    id
    kohaiCredit
    currency
    kohaiSendCreditTotal
    kohaiReceiveCreditTotal
  }
}
    `;

/**
 * __useCreditWalletQuery__
 *
 * To run a query within a React component, call `useCreditWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditWalletQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreditWalletQuery(baseOptions?: Apollo.QueryHookOptions<CreditWalletQuery, CreditWalletQueryVariables>) {
        return Apollo.useQuery<CreditWalletQuery, CreditWalletQueryVariables>(CreditWalletDocument, baseOptions);
      }
export function useCreditWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditWalletQuery, CreditWalletQueryVariables>) {
          return Apollo.useLazyQuery<CreditWalletQuery, CreditWalletQueryVariables>(CreditWalletDocument, baseOptions);
        }
export type CreditWalletQueryHookResult = ReturnType<typeof useCreditWalletQuery>;
export type CreditWalletLazyQueryHookResult = ReturnType<typeof useCreditWalletLazyQuery>;
export type CreditWalletQueryResult = Apollo.QueryResult<CreditWalletQuery, CreditWalletQueryVariables>;
export const AdminTransferLogsDocument = gql`
    query AdminTransferLogs($page: Int) {
  adminTransferLogs(page: $page) {
    count
    data {
      ...AdminLog
    }
  }
}
    ${AdminLogFragmentDoc}`;

/**
 * __useAdminTransferLogsQuery__
 *
 * To run a query within a React component, call `useAdminTransferLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTransferLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTransferLogsQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAdminTransferLogsQuery(baseOptions?: Apollo.QueryHookOptions<AdminTransferLogsQuery, AdminTransferLogsQueryVariables>) {
        return Apollo.useQuery<AdminTransferLogsQuery, AdminTransferLogsQueryVariables>(AdminTransferLogsDocument, baseOptions);
      }
export function useAdminTransferLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminTransferLogsQuery, AdminTransferLogsQueryVariables>) {
          return Apollo.useLazyQuery<AdminTransferLogsQuery, AdminTransferLogsQueryVariables>(AdminTransferLogsDocument, baseOptions);
        }
export type AdminTransferLogsQueryHookResult = ReturnType<typeof useAdminTransferLogsQuery>;
export type AdminTransferLogsLazyQueryHookResult = ReturnType<typeof useAdminTransferLogsLazyQuery>;
export type AdminTransferLogsQueryResult = Apollo.QueryResult<AdminTransferLogsQuery, AdminTransferLogsQueryVariables>;
export const RefundBookingDocument = gql`
    mutation RefundBooking($id: ID!) {
  refundMatchMaking(input: {id: $id}) {
    booking {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RefundBookingMutationFn = Apollo.MutationFunction<RefundBookingMutation, RefundBookingMutationVariables>;

/**
 * __useRefundBookingMutation__
 *
 * To run a mutation, you first call `useRefundBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundBookingMutation, { data, loading, error }] = useRefundBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefundBookingMutation(baseOptions?: Apollo.MutationHookOptions<RefundBookingMutation, RefundBookingMutationVariables>) {
        return Apollo.useMutation<RefundBookingMutation, RefundBookingMutationVariables>(RefundBookingDocument, baseOptions);
      }
export type RefundBookingMutationHookResult = ReturnType<typeof useRefundBookingMutation>;
export type RefundBookingMutationResult = Apollo.MutationResult<RefundBookingMutation>;
export type RefundBookingMutationOptions = Apollo.BaseMutationOptions<RefundBookingMutation, RefundBookingMutationVariables>;
export const ReviewBookingDocument = gql`
    mutation ReviewBooking($id: ID!) {
  reviewMatchMaking(input: {id: $id}) {
    booking {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ReviewBookingMutationFn = Apollo.MutationFunction<ReviewBookingMutation, ReviewBookingMutationVariables>;

/**
 * __useReviewBookingMutation__
 *
 * To run a mutation, you first call `useReviewBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewBookingMutation, { data, loading, error }] = useReviewBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewBookingMutation(baseOptions?: Apollo.MutationHookOptions<ReviewBookingMutation, ReviewBookingMutationVariables>) {
        return Apollo.useMutation<ReviewBookingMutation, ReviewBookingMutationVariables>(ReviewBookingDocument, baseOptions);
      }
export type ReviewBookingMutationHookResult = ReturnType<typeof useReviewBookingMutation>;
export type ReviewBookingMutationResult = Apollo.MutationResult<ReviewBookingMutation>;
export type ReviewBookingMutationOptions = Apollo.BaseMutationOptions<ReviewBookingMutation, ReviewBookingMutationVariables>;
export const CreateCareerHighlightDocument = gql`
    mutation CreateCareerHighlight($id: ID, $type: String!, $title: String, $userId: ID, $teamId: ID, $startYear: Int, $endYear: Int, $position: Int) {
  createCareerHighlight(input: {id: $id, type: $type, title: $title, userId: $userId, teamId: $teamId, startYear: $startYear, endYear: $endYear, position: $position}) {
    careerHighlight {
      id
      title
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateCareerHighlightMutationFn = Apollo.MutationFunction<CreateCareerHighlightMutation, CreateCareerHighlightMutationVariables>;

/**
 * __useCreateCareerHighlightMutation__
 *
 * To run a mutation, you first call `useCreateCareerHighlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareerHighlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareerHighlightMutation, { data, loading, error }] = useCreateCareerHighlightMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *      startYear: // value for 'startYear'
 *      endYear: // value for 'endYear'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useCreateCareerHighlightMutation(baseOptions?: Apollo.MutationHookOptions<CreateCareerHighlightMutation, CreateCareerHighlightMutationVariables>) {
        return Apollo.useMutation<CreateCareerHighlightMutation, CreateCareerHighlightMutationVariables>(CreateCareerHighlightDocument, baseOptions);
      }
export type CreateCareerHighlightMutationHookResult = ReturnType<typeof useCreateCareerHighlightMutation>;
export type CreateCareerHighlightMutationResult = Apollo.MutationResult<CreateCareerHighlightMutation>;
export type CreateCareerHighlightMutationOptions = Apollo.BaseMutationOptions<CreateCareerHighlightMutation, CreateCareerHighlightMutationVariables>;
export const UpdateCareerHighlightDocument = gql`
    mutation UpdateCareerHighlight($id: ID!, $title: String, $userId: ID, $teamId: ID, $startYear: Int, $endYear: Int, $position: Int) {
  updateCareerHighlight(input: {id: $id, title: $title, userId: $userId, teamId: $teamId, startYear: $startYear, endYear: $endYear, position: $position}) {
    careerHighlight {
      id
      title
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateCareerHighlightMutationFn = Apollo.MutationFunction<UpdateCareerHighlightMutation, UpdateCareerHighlightMutationVariables>;

/**
 * __useUpdateCareerHighlightMutation__
 *
 * To run a mutation, you first call `useUpdateCareerHighlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareerHighlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareerHighlightMutation, { data, loading, error }] = useUpdateCareerHighlightMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *      startYear: // value for 'startYear'
 *      endYear: // value for 'endYear'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdateCareerHighlightMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCareerHighlightMutation, UpdateCareerHighlightMutationVariables>) {
        return Apollo.useMutation<UpdateCareerHighlightMutation, UpdateCareerHighlightMutationVariables>(UpdateCareerHighlightDocument, baseOptions);
      }
export type UpdateCareerHighlightMutationHookResult = ReturnType<typeof useUpdateCareerHighlightMutation>;
export type UpdateCareerHighlightMutationResult = Apollo.MutationResult<UpdateCareerHighlightMutation>;
export type UpdateCareerHighlightMutationOptions = Apollo.BaseMutationOptions<UpdateCareerHighlightMutation, UpdateCareerHighlightMutationVariables>;
export const RemoveClubMemberDocument = gql`
    mutation RemoveClubMember($id: ID!) {
  removeClubMember(input: {id: $id}) {
    gameAccount {
      id
      club {
        ...Club
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ClubFragmentDoc}
${ErrorFragmentDoc}`;
export type RemoveClubMemberMutationFn = Apollo.MutationFunction<RemoveClubMemberMutation, RemoveClubMemberMutationVariables>;

/**
 * __useRemoveClubMemberMutation__
 *
 * To run a mutation, you first call `useRemoveClubMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClubMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClubMemberMutation, { data, loading, error }] = useRemoveClubMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClubMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClubMemberMutation, RemoveClubMemberMutationVariables>) {
        return Apollo.useMutation<RemoveClubMemberMutation, RemoveClubMemberMutationVariables>(RemoveClubMemberDocument, baseOptions);
      }
export type RemoveClubMemberMutationHookResult = ReturnType<typeof useRemoveClubMemberMutation>;
export type RemoveClubMemberMutationResult = Apollo.MutationResult<RemoveClubMemberMutation>;
export type RemoveClubMemberMutationOptions = Apollo.BaseMutationOptions<RemoveClubMemberMutation, RemoveClubMemberMutationVariables>;
export const UpdateClubMemberDocument = gql`
    mutation UpdateClubMember($ids: [ID!]!, $clubId: String!) {
  updateClubMember(input: {ids: $ids, clubId: $clubId}) {
    gameAccounts {
      id
      club {
        ...Club
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ClubFragmentDoc}
${ErrorFragmentDoc}`;
export type UpdateClubMemberMutationFn = Apollo.MutationFunction<UpdateClubMemberMutation, UpdateClubMemberMutationVariables>;

/**
 * __useUpdateClubMemberMutation__
 *
 * To run a mutation, you first call `useUpdateClubMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubMemberMutation, { data, loading, error }] = useUpdateClubMemberMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useUpdateClubMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClubMemberMutation, UpdateClubMemberMutationVariables>) {
        return Apollo.useMutation<UpdateClubMemberMutation, UpdateClubMemberMutationVariables>(UpdateClubMemberDocument, baseOptions);
      }
export type UpdateClubMemberMutationHookResult = ReturnType<typeof useUpdateClubMemberMutation>;
export type UpdateClubMemberMutationResult = Apollo.MutationResult<UpdateClubMemberMutation>;
export type UpdateClubMemberMutationOptions = Apollo.BaseMutationOptions<UpdateClubMemberMutation, UpdateClubMemberMutationVariables>;
export const UpdateClubDocument = gql`
    mutation UpdateClub($id: ID!, $icon: File, $themeColor: String!, $tier: ClubTier!, $description: String, $tagName: String, $name: String, $beStar: Boolean, $verified: Boolean, $founded: ISO8601DateTime, $origin: String, $introText: String, $overview: String) {
  updateClub(input: {id: $id, icon: $icon, themeColor: $themeColor, tier: $tier, description: $description, tagName: $tagName, name: $name, beStar: $beStar, verified: $verified, founded: $founded, origin: $origin, introText: $introText, overview: $overview}) {
    club {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateClubMutationFn = Apollo.MutationFunction<UpdateClubMutation, UpdateClubMutationVariables>;

/**
 * __useUpdateClubMutation__
 *
 * To run a mutation, you first call `useUpdateClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClubMutation, { data, loading, error }] = useUpdateClubMutation({
 *   variables: {
 *      id: // value for 'id'
 *      icon: // value for 'icon'
 *      themeColor: // value for 'themeColor'
 *      tier: // value for 'tier'
 *      description: // value for 'description'
 *      tagName: // value for 'tagName'
 *      name: // value for 'name'
 *      beStar: // value for 'beStar'
 *      verified: // value for 'verified'
 *      founded: // value for 'founded'
 *      origin: // value for 'origin'
 *      introText: // value for 'introText'
 *      overview: // value for 'overview'
 *   },
 * });
 */
export function useUpdateClubMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClubMutation, UpdateClubMutationVariables>) {
        return Apollo.useMutation<UpdateClubMutation, UpdateClubMutationVariables>(UpdateClubDocument, baseOptions);
      }
export type UpdateClubMutationHookResult = ReturnType<typeof useUpdateClubMutation>;
export type UpdateClubMutationResult = Apollo.MutationResult<UpdateClubMutation>;
export type UpdateClubMutationOptions = Apollo.BaseMutationOptions<UpdateClubMutation, UpdateClubMutationVariables>;
export const ChangeCombatRoomStateDocument = gql`
    mutation ChangeCombatRoomState($id: ID!, $action: String!) {
  changeCombatRoomState(input: {id: $id, action: $action}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ChangeCombatRoomStateMutationFn = Apollo.MutationFunction<ChangeCombatRoomStateMutation, ChangeCombatRoomStateMutationVariables>;

/**
 * __useChangeCombatRoomStateMutation__
 *
 * To run a mutation, you first call `useChangeCombatRoomStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCombatRoomStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCombatRoomStateMutation, { data, loading, error }] = useChangeCombatRoomStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useChangeCombatRoomStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCombatRoomStateMutation, ChangeCombatRoomStateMutationVariables>) {
        return Apollo.useMutation<ChangeCombatRoomStateMutation, ChangeCombatRoomStateMutationVariables>(ChangeCombatRoomStateDocument, baseOptions);
      }
export type ChangeCombatRoomStateMutationHookResult = ReturnType<typeof useChangeCombatRoomStateMutation>;
export type ChangeCombatRoomStateMutationResult = Apollo.MutationResult<ChangeCombatRoomStateMutation>;
export type ChangeCombatRoomStateMutationOptions = Apollo.BaseMutationOptions<ChangeCombatRoomStateMutation, ChangeCombatRoomStateMutationVariables>;
export const ChooseWinnerCombatRoomDocument = gql`
    mutation ChooseWinnerCombatRoom($combatTeamAccepterId: ID!, $combatTeamChallengerId: ID!, $combatRoomId: ID!, $additionScore: Int!, $deductionScore: Int!, $combatTeamType: String!) {
  chooseWinnerCombatRoom(input: {combatTeamAccepterId: $combatTeamAccepterId, combatTeamChallengerId: $combatTeamChallengerId, combatRoomId: $combatRoomId, additionScore: $additionScore, deductionScore: $deductionScore, combatTeamType: $combatTeamType}) {
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ChooseWinnerCombatRoomMutationFn = Apollo.MutationFunction<ChooseWinnerCombatRoomMutation, ChooseWinnerCombatRoomMutationVariables>;

/**
 * __useChooseWinnerCombatRoomMutation__
 *
 * To run a mutation, you first call `useChooseWinnerCombatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseWinnerCombatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseWinnerCombatRoomMutation, { data, loading, error }] = useChooseWinnerCombatRoomMutation({
 *   variables: {
 *      combatTeamAccepterId: // value for 'combatTeamAccepterId'
 *      combatTeamChallengerId: // value for 'combatTeamChallengerId'
 *      combatRoomId: // value for 'combatRoomId'
 *      additionScore: // value for 'additionScore'
 *      deductionScore: // value for 'deductionScore'
 *      combatTeamType: // value for 'combatTeamType'
 *   },
 * });
 */
export function useChooseWinnerCombatRoomMutation(baseOptions?: Apollo.MutationHookOptions<ChooseWinnerCombatRoomMutation, ChooseWinnerCombatRoomMutationVariables>) {
        return Apollo.useMutation<ChooseWinnerCombatRoomMutation, ChooseWinnerCombatRoomMutationVariables>(ChooseWinnerCombatRoomDocument, baseOptions);
      }
export type ChooseWinnerCombatRoomMutationHookResult = ReturnType<typeof useChooseWinnerCombatRoomMutation>;
export type ChooseWinnerCombatRoomMutationResult = Apollo.MutationResult<ChooseWinnerCombatRoomMutation>;
export type ChooseWinnerCombatRoomMutationOptions = Apollo.BaseMutationOptions<ChooseWinnerCombatRoomMutation, ChooseWinnerCombatRoomMutationVariables>;
export const CreateAddPointForFlaggedTeamDocument = gql`
    mutation CreateAddPointForFlaggedTeam($id: ID!, $combatRoomId: ID!, $score: Int!) {
  createAddPointForFlaggedTeam(input: {id: $id, combatRoomId: $combatRoomId, score: $score}) {
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateAddPointForFlaggedTeamMutationFn = Apollo.MutationFunction<CreateAddPointForFlaggedTeamMutation, CreateAddPointForFlaggedTeamMutationVariables>;

/**
 * __useCreateAddPointForFlaggedTeamMutation__
 *
 * To run a mutation, you first call `useCreateAddPointForFlaggedTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddPointForFlaggedTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddPointForFlaggedTeamMutation, { data, loading, error }] = useCreateAddPointForFlaggedTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      combatRoomId: // value for 'combatRoomId'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useCreateAddPointForFlaggedTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateAddPointForFlaggedTeamMutation, CreateAddPointForFlaggedTeamMutationVariables>) {
        return Apollo.useMutation<CreateAddPointForFlaggedTeamMutation, CreateAddPointForFlaggedTeamMutationVariables>(CreateAddPointForFlaggedTeamDocument, baseOptions);
      }
export type CreateAddPointForFlaggedTeamMutationHookResult = ReturnType<typeof useCreateAddPointForFlaggedTeamMutation>;
export type CreateAddPointForFlaggedTeamMutationResult = Apollo.MutationResult<CreateAddPointForFlaggedTeamMutation>;
export type CreateAddPointForFlaggedTeamMutationOptions = Apollo.BaseMutationOptions<CreateAddPointForFlaggedTeamMutation, CreateAddPointForFlaggedTeamMutationVariables>;
export const CreateSuspendCombatTeamDocument = gql`
    mutation CreateSuspendCombatTeam($id: ID!, $reason: String!) {
  createSuspendCombatTeam(input: {id: $id, reason: $reason}) {
    combatTeam {
      id
      score
      team {
        id
        name
      }
      suspended
      reason
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateSuspendCombatTeamMutationFn = Apollo.MutationFunction<CreateSuspendCombatTeamMutation, CreateSuspendCombatTeamMutationVariables>;

/**
 * __useCreateSuspendCombatTeamMutation__
 *
 * To run a mutation, you first call `useCreateSuspendCombatTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuspendCombatTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuspendCombatTeamMutation, { data, loading, error }] = useCreateSuspendCombatTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateSuspendCombatTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateSuspendCombatTeamMutation, CreateSuspendCombatTeamMutationVariables>) {
        return Apollo.useMutation<CreateSuspendCombatTeamMutation, CreateSuspendCombatTeamMutationVariables>(CreateSuspendCombatTeamDocument, baseOptions);
      }
export type CreateSuspendCombatTeamMutationHookResult = ReturnType<typeof useCreateSuspendCombatTeamMutation>;
export type CreateSuspendCombatTeamMutationResult = Apollo.MutationResult<CreateSuspendCombatTeamMutation>;
export type CreateSuspendCombatTeamMutationOptions = Apollo.BaseMutationOptions<CreateSuspendCombatTeamMutation, CreateSuspendCombatTeamMutationVariables>;
export const DeductPointTeamDocument = gql`
    mutation DeductPointTeam($id: ID!, $combatRoomId: ID!, $deductScore: Int!) {
  deductPointTeam(input: {id: $id, combatRoomId: $combatRoomId, deductScore: $deductScore}) {
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeductPointTeamMutationFn = Apollo.MutationFunction<DeductPointTeamMutation, DeductPointTeamMutationVariables>;

/**
 * __useDeductPointTeamMutation__
 *
 * To run a mutation, you first call `useDeductPointTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeductPointTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deductPointTeamMutation, { data, loading, error }] = useDeductPointTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      combatRoomId: // value for 'combatRoomId'
 *      deductScore: // value for 'deductScore'
 *   },
 * });
 */
export function useDeductPointTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeductPointTeamMutation, DeductPointTeamMutationVariables>) {
        return Apollo.useMutation<DeductPointTeamMutation, DeductPointTeamMutationVariables>(DeductPointTeamDocument, baseOptions);
      }
export type DeductPointTeamMutationHookResult = ReturnType<typeof useDeductPointTeamMutation>;
export type DeductPointTeamMutationResult = Apollo.MutationResult<DeductPointTeamMutation>;
export type DeductPointTeamMutationOptions = Apollo.BaseMutationOptions<DeductPointTeamMutation, DeductPointTeamMutationVariables>;
export const UpdateAdminNoteCombatRoomDocument = gql`
    mutation UpdateAdminNoteCombatRoom($id: ID!, $adminNote: String!) {
  updateAdminNoteCombatRoom(input: {id: $id, adminNote: $adminNote}) {
    combatRoom {
      id
      adminNote
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateAdminNoteCombatRoomMutationFn = Apollo.MutationFunction<UpdateAdminNoteCombatRoomMutation, UpdateAdminNoteCombatRoomMutationVariables>;

/**
 * __useUpdateAdminNoteCombatRoomMutation__
 *
 * To run a mutation, you first call `useUpdateAdminNoteCombatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminNoteCombatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminNoteCombatRoomMutation, { data, loading, error }] = useUpdateAdminNoteCombatRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      adminNote: // value for 'adminNote'
 *   },
 * });
 */
export function useUpdateAdminNoteCombatRoomMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminNoteCombatRoomMutation, UpdateAdminNoteCombatRoomMutationVariables>) {
        return Apollo.useMutation<UpdateAdminNoteCombatRoomMutation, UpdateAdminNoteCombatRoomMutationVariables>(UpdateAdminNoteCombatRoomDocument, baseOptions);
      }
export type UpdateAdminNoteCombatRoomMutationHookResult = ReturnType<typeof useUpdateAdminNoteCombatRoomMutation>;
export type UpdateAdminNoteCombatRoomMutationResult = Apollo.MutationResult<UpdateAdminNoteCombatRoomMutation>;
export type UpdateAdminNoteCombatRoomMutationOptions = Apollo.BaseMutationOptions<UpdateAdminNoteCombatRoomMutation, UpdateAdminNoteCombatRoomMutationVariables>;
export const UpdateMatchResultAdminDocument = gql`
    mutation UpdateMatchResultAdmin($combatRoomId: ID!, $combatTeamId: ID!, $resultScreenshots: [File!]!) {
  updateMatchResultAdmin(input: {combatRoomId: $combatRoomId, combatTeamId: $combatTeamId, resultScreenshots: $resultScreenshots}) {
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateMatchResultAdminMutationFn = Apollo.MutationFunction<UpdateMatchResultAdminMutation, UpdateMatchResultAdminMutationVariables>;

/**
 * __useUpdateMatchResultAdminMutation__
 *
 * To run a mutation, you first call `useUpdateMatchResultAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchResultAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchResultAdminMutation, { data, loading, error }] = useUpdateMatchResultAdminMutation({
 *   variables: {
 *      combatRoomId: // value for 'combatRoomId'
 *      combatTeamId: // value for 'combatTeamId'
 *      resultScreenshots: // value for 'resultScreenshots'
 *   },
 * });
 */
export function useUpdateMatchResultAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMatchResultAdminMutation, UpdateMatchResultAdminMutationVariables>) {
        return Apollo.useMutation<UpdateMatchResultAdminMutation, UpdateMatchResultAdminMutationVariables>(UpdateMatchResultAdminDocument, baseOptions);
      }
export type UpdateMatchResultAdminMutationHookResult = ReturnType<typeof useUpdateMatchResultAdminMutation>;
export type UpdateMatchResultAdminMutationResult = Apollo.MutationResult<UpdateMatchResultAdminMutation>;
export type UpdateMatchResultAdminMutationOptions = Apollo.BaseMutationOptions<UpdateMatchResultAdminMutation, UpdateMatchResultAdminMutationVariables>;
export const CreateFaqDocument = gql`
    mutation CreateFaq($question: String!, $answer: String!, $language: Language!, $position: Int!, $categoryColumns: CategoryColumns!, $apps: FaqApps!) {
  createFaq(input: {question: $question, answer: $answer, language: $language, position: $position, categoryColumns: $categoryColumns, apps: $apps}) {
    faq {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateFaqMutationFn = Apollo.MutationFunction<CreateFaqMutation, CreateFaqMutationVariables>;

/**
 * __useCreateFaqMutation__
 *
 * To run a mutation, you first call `useCreateFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaqMutation, { data, loading, error }] = useCreateFaqMutation({
 *   variables: {
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *      language: // value for 'language'
 *      position: // value for 'position'
 *      categoryColumns: // value for 'categoryColumns'
 *      apps: // value for 'apps'
 *   },
 * });
 */
export function useCreateFaqMutation(baseOptions?: Apollo.MutationHookOptions<CreateFaqMutation, CreateFaqMutationVariables>) {
        return Apollo.useMutation<CreateFaqMutation, CreateFaqMutationVariables>(CreateFaqDocument, baseOptions);
      }
export type CreateFaqMutationHookResult = ReturnType<typeof useCreateFaqMutation>;
export type CreateFaqMutationResult = Apollo.MutationResult<CreateFaqMutation>;
export type CreateFaqMutationOptions = Apollo.BaseMutationOptions<CreateFaqMutation, CreateFaqMutationVariables>;
export const UpdateFaqDocument = gql`
    mutation UpdateFaq($id: ID!, $question: String!, $answer: String!, $language: Language!, $position: Int!, $categoryColumns: CategoryColumns!, $apps: FaqApps!) {
  updateFaq(input: {id: $id, question: $question, answer: $answer, language: $language, position: $position, categoryColumns: $categoryColumns, apps: $apps}) {
    faq {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateFaqMutationFn = Apollo.MutationFunction<UpdateFaqMutation, UpdateFaqMutationVariables>;

/**
 * __useUpdateFaqMutation__
 *
 * To run a mutation, you first call `useUpdateFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaqMutation, { data, loading, error }] = useUpdateFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *      question: // value for 'question'
 *      answer: // value for 'answer'
 *      language: // value for 'language'
 *      position: // value for 'position'
 *      categoryColumns: // value for 'categoryColumns'
 *      apps: // value for 'apps'
 *   },
 * });
 */
export function useUpdateFaqMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFaqMutation, UpdateFaqMutationVariables>) {
        return Apollo.useMutation<UpdateFaqMutation, UpdateFaqMutationVariables>(UpdateFaqDocument, baseOptions);
      }
export type UpdateFaqMutationHookResult = ReturnType<typeof useUpdateFaqMutation>;
export type UpdateFaqMutationResult = Apollo.MutationResult<UpdateFaqMutation>;
export type UpdateFaqMutationOptions = Apollo.BaseMutationOptions<UpdateFaqMutation, UpdateFaqMutationVariables>;
export const CreateGameMessageDocument = gql`
    mutation CreateGameMessage($gameChatroomId: ID!, $text: String!, $messageType: ChatroomMessage!) {
  createGameMessage(input: {gameChatroomId: $gameChatroomId, text: $text, messageType: $messageType}) {
    gameMessage {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateGameMessageMutationFn = Apollo.MutationFunction<CreateGameMessageMutation, CreateGameMessageMutationVariables>;

/**
 * __useCreateGameMessageMutation__
 *
 * To run a mutation, you first call `useCreateGameMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameMessageMutation, { data, loading, error }] = useCreateGameMessageMutation({
 *   variables: {
 *      gameChatroomId: // value for 'gameChatroomId'
 *      text: // value for 'text'
 *      messageType: // value for 'messageType'
 *   },
 * });
 */
export function useCreateGameMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateGameMessageMutation, CreateGameMessageMutationVariables>) {
        return Apollo.useMutation<CreateGameMessageMutation, CreateGameMessageMutationVariables>(CreateGameMessageDocument, baseOptions);
      }
export type CreateGameMessageMutationHookResult = ReturnType<typeof useCreateGameMessageMutation>;
export type CreateGameMessageMutationResult = Apollo.MutationResult<CreateGameMessageMutation>;
export type CreateGameMessageMutationOptions = Apollo.BaseMutationOptions<CreateGameMessageMutation, CreateGameMessageMutationVariables>;
export const CheckPilotGameBundleDocument = gql`
    mutation CheckPilotGameBundle($nickname: String!) {
  checkPilotGameBundle(input: {nickname: $nickname}) {
    pilots {
      id
      nickname
      phoneNumber
      avatarUrl
      email
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CheckPilotGameBundleMutationFn = Apollo.MutationFunction<CheckPilotGameBundleMutation, CheckPilotGameBundleMutationVariables>;

/**
 * __useCheckPilotGameBundleMutation__
 *
 * To run a mutation, you first call `useCheckPilotGameBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPilotGameBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPilotGameBundleMutation, { data, loading, error }] = useCheckPilotGameBundleMutation({
 *   variables: {
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useCheckPilotGameBundleMutation(baseOptions?: Apollo.MutationHookOptions<CheckPilotGameBundleMutation, CheckPilotGameBundleMutationVariables>) {
        return Apollo.useMutation<CheckPilotGameBundleMutation, CheckPilotGameBundleMutationVariables>(CheckPilotGameBundleDocument, baseOptions);
      }
export type CheckPilotGameBundleMutationHookResult = ReturnType<typeof useCheckPilotGameBundleMutation>;
export type CheckPilotGameBundleMutationResult = Apollo.MutationResult<CheckPilotGameBundleMutation>;
export type CheckPilotGameBundleMutationOptions = Apollo.BaseMutationOptions<CheckPilotGameBundleMutation, CheckPilotGameBundleMutationVariables>;
export const CheckUserGameBundleDocument = gql`
    mutation CheckUserGameBundle($nickname: String!) {
  checkUserGameBundle(input: {nickname: $nickname}) {
    users {
      id
      nickname
      phoneNumber
      avatarUrl
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CheckUserGameBundleMutationFn = Apollo.MutationFunction<CheckUserGameBundleMutation, CheckUserGameBundleMutationVariables>;

/**
 * __useCheckUserGameBundleMutation__
 *
 * To run a mutation, you first call `useCheckUserGameBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckUserGameBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkUserGameBundleMutation, { data, loading, error }] = useCheckUserGameBundleMutation({
 *   variables: {
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useCheckUserGameBundleMutation(baseOptions?: Apollo.MutationHookOptions<CheckUserGameBundleMutation, CheckUserGameBundleMutationVariables>) {
        return Apollo.useMutation<CheckUserGameBundleMutation, CheckUserGameBundleMutationVariables>(CheckUserGameBundleDocument, baseOptions);
      }
export type CheckUserGameBundleMutationHookResult = ReturnType<typeof useCheckUserGameBundleMutation>;
export type CheckUserGameBundleMutationResult = Apollo.MutationResult<CheckUserGameBundleMutation>;
export type CheckUserGameBundleMutationOptions = Apollo.BaseMutationOptions<CheckUserGameBundleMutation, CheckUserGameBundleMutationVariables>;
export const ConfirmCompletedGameBundleDocument = gql`
    mutation ConfirmCompletedGameBundle($id: ID!) {
  confirmCompletedGameBundle(input: {id: $id}) {
    gameBundle {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type ConfirmCompletedGameBundleMutationFn = Apollo.MutationFunction<ConfirmCompletedGameBundleMutation, ConfirmCompletedGameBundleMutationVariables>;

/**
 * __useConfirmCompletedGameBundleMutation__
 *
 * To run a mutation, you first call `useConfirmCompletedGameBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCompletedGameBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCompletedGameBundleMutation, { data, loading, error }] = useConfirmCompletedGameBundleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmCompletedGameBundleMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmCompletedGameBundleMutation, ConfirmCompletedGameBundleMutationVariables>) {
        return Apollo.useMutation<ConfirmCompletedGameBundleMutation, ConfirmCompletedGameBundleMutationVariables>(ConfirmCompletedGameBundleDocument, baseOptions);
      }
export type ConfirmCompletedGameBundleMutationHookResult = ReturnType<typeof useConfirmCompletedGameBundleMutation>;
export type ConfirmCompletedGameBundleMutationResult = Apollo.MutationResult<ConfirmCompletedGameBundleMutation>;
export type ConfirmCompletedGameBundleMutationOptions = Apollo.BaseMutationOptions<ConfirmCompletedGameBundleMutation, ConfirmCompletedGameBundleMutationVariables>;
export const CreateGameBundleDocument = gql`
    mutation CreateGameBundle($userId: ID!, $pilotId: ID!, $currentGameRankId: ID!, $currentRankTier: String!, $currentRankStar: String!, $currentRankPoint: String!, $targetGameRankId: ID!, $targetRankTier: String!, $targetRankStar: String!, $targetRankPoint: String!, $gameId: ID!, $promoCode: String!) {
  createGameBundle(input: {userId: $userId, pilotId: $pilotId, currentGameRankId: $currentGameRankId, currentRankTier: $currentRankTier, currentRankStar: $currentRankStar, currentRankPoint: $currentRankPoint, targetGameRankId: $targetGameRankId, targetRankTier: $targetRankTier, targetRankStar: $targetRankStar, targetRankPoint: $targetRankPoint, gameId: $gameId, promoCode: $promoCode}) {
    gameBundle {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateGameBundleMutationFn = Apollo.MutationFunction<CreateGameBundleMutation, CreateGameBundleMutationVariables>;

/**
 * __useCreateGameBundleMutation__
 *
 * To run a mutation, you first call `useCreateGameBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameBundleMutation, { data, loading, error }] = useCreateGameBundleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      pilotId: // value for 'pilotId'
 *      currentGameRankId: // value for 'currentGameRankId'
 *      currentRankTier: // value for 'currentRankTier'
 *      currentRankStar: // value for 'currentRankStar'
 *      currentRankPoint: // value for 'currentRankPoint'
 *      targetGameRankId: // value for 'targetGameRankId'
 *      targetRankTier: // value for 'targetRankTier'
 *      targetRankStar: // value for 'targetRankStar'
 *      targetRankPoint: // value for 'targetRankPoint'
 *      gameId: // value for 'gameId'
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useCreateGameBundleMutation(baseOptions?: Apollo.MutationHookOptions<CreateGameBundleMutation, CreateGameBundleMutationVariables>) {
        return Apollo.useMutation<CreateGameBundleMutation, CreateGameBundleMutationVariables>(CreateGameBundleDocument, baseOptions);
      }
export type CreateGameBundleMutationHookResult = ReturnType<typeof useCreateGameBundleMutation>;
export type CreateGameBundleMutationResult = Apollo.MutationResult<CreateGameBundleMutation>;
export type CreateGameBundleMutationOptions = Apollo.BaseMutationOptions<CreateGameBundleMutation, CreateGameBundleMutationVariables>;
export const PreviewAmountGameBundleDocument = gql`
    mutation PreviewAmountGameBundle($userId: ID!, $pilotId: ID!, $currentGameRankId: ID!, $currentRankTier: String!, $currentRankStar: String!, $currentRankPoint: String!, $targetGameRankId: ID!, $targetRankTier: String!, $targetRankStar: String!, $targetRankPoint: String!, $gameId: ID!, $promoCode: String!) {
  previewAmountGameBundle(input: {userId: $userId, pilotId: $pilotId, currentGameRankId: $currentGameRankId, currentRankTier: $currentRankTier, currentRankStar: $currentRankStar, currentRankPoint: $currentRankPoint, targetGameRankId: $targetGameRankId, targetRankTier: $targetRankTier, targetRankStar: $targetRankStar, targetRankPoint: $targetRankPoint, gameId: $gameId, promoCode: $promoCode}) {
    previewAmount {
      totalAmount
      priceBreakdown {
        lowerRank
        lowerTier
        lowerStar
        lowerPoint
        higherRank
        higherTier
        higherStar
        higherPoint
      }
    }
    amount
    userCreditWallet
    promoCodeApply {
      success
      amount
      error
    }
    unlockAt
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type PreviewAmountGameBundleMutationFn = Apollo.MutationFunction<PreviewAmountGameBundleMutation, PreviewAmountGameBundleMutationVariables>;

/**
 * __usePreviewAmountGameBundleMutation__
 *
 * To run a mutation, you first call `usePreviewAmountGameBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewAmountGameBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewAmountGameBundleMutation, { data, loading, error }] = usePreviewAmountGameBundleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      pilotId: // value for 'pilotId'
 *      currentGameRankId: // value for 'currentGameRankId'
 *      currentRankTier: // value for 'currentRankTier'
 *      currentRankStar: // value for 'currentRankStar'
 *      currentRankPoint: // value for 'currentRankPoint'
 *      targetGameRankId: // value for 'targetGameRankId'
 *      targetRankTier: // value for 'targetRankTier'
 *      targetRankStar: // value for 'targetRankStar'
 *      targetRankPoint: // value for 'targetRankPoint'
 *      gameId: // value for 'gameId'
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function usePreviewAmountGameBundleMutation(baseOptions?: Apollo.MutationHookOptions<PreviewAmountGameBundleMutation, PreviewAmountGameBundleMutationVariables>) {
        return Apollo.useMutation<PreviewAmountGameBundleMutation, PreviewAmountGameBundleMutationVariables>(PreviewAmountGameBundleDocument, baseOptions);
      }
export type PreviewAmountGameBundleMutationHookResult = ReturnType<typeof usePreviewAmountGameBundleMutation>;
export type PreviewAmountGameBundleMutationResult = Apollo.MutationResult<PreviewAmountGameBundleMutation>;
export type PreviewAmountGameBundleMutationOptions = Apollo.BaseMutationOptions<PreviewAmountGameBundleMutation, PreviewAmountGameBundleMutationVariables>;
export const RefundBundleDocument = gql`
    mutation RefundBundle($id: ID!) {
  refundBundle(input: {id: $id}) {
    gameBundle {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type RefundBundleMutationFn = Apollo.MutationFunction<RefundBundleMutation, RefundBundleMutationVariables>;

/**
 * __useRefundBundleMutation__
 *
 * To run a mutation, you first call `useRefundBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundBundleMutation, { data, loading, error }] = useRefundBundleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefundBundleMutation(baseOptions?: Apollo.MutationHookOptions<RefundBundleMutation, RefundBundleMutationVariables>) {
        return Apollo.useMutation<RefundBundleMutation, RefundBundleMutationVariables>(RefundBundleDocument, baseOptions);
      }
export type RefundBundleMutationHookResult = ReturnType<typeof useRefundBundleMutation>;
export type RefundBundleMutationResult = Apollo.MutationResult<RefundBundleMutation>;
export type RefundBundleMutationOptions = Apollo.BaseMutationOptions<RefundBundleMutation, RefundBundleMutationVariables>;
export const ReportGameBundleDocument = gql`
    mutation ReportGameBundle($id: ID!, $reportReason: String!) {
  reportGameBundle(input: {id: $id, reportReason: $reportReason}) {
    gameBundle {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type ReportGameBundleMutationFn = Apollo.MutationFunction<ReportGameBundleMutation, ReportGameBundleMutationVariables>;

/**
 * __useReportGameBundleMutation__
 *
 * To run a mutation, you first call `useReportGameBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportGameBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportGameBundleMutation, { data, loading, error }] = useReportGameBundleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reportReason: // value for 'reportReason'
 *   },
 * });
 */
export function useReportGameBundleMutation(baseOptions?: Apollo.MutationHookOptions<ReportGameBundleMutation, ReportGameBundleMutationVariables>) {
        return Apollo.useMutation<ReportGameBundleMutation, ReportGameBundleMutationVariables>(ReportGameBundleDocument, baseOptions);
      }
export type ReportGameBundleMutationHookResult = ReturnType<typeof useReportGameBundleMutation>;
export type ReportGameBundleMutationResult = Apollo.MutationResult<ReportGameBundleMutation>;
export type ReportGameBundleMutationOptions = Apollo.BaseMutationOptions<ReportGameBundleMutation, ReportGameBundleMutationVariables>;
export const ReviewBundleDocument = gql`
    mutation ReviewBundle($id: ID!) {
  reviewBundle(input: {id: $id}) {
    gameBundle {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type ReviewBundleMutationFn = Apollo.MutationFunction<ReviewBundleMutation, ReviewBundleMutationVariables>;

/**
 * __useReviewBundleMutation__
 *
 * To run a mutation, you first call `useReviewBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewBundleMutation, { data, loading, error }] = useReviewBundleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewBundleMutation(baseOptions?: Apollo.MutationHookOptions<ReviewBundleMutation, ReviewBundleMutationVariables>) {
        return Apollo.useMutation<ReviewBundleMutation, ReviewBundleMutationVariables>(ReviewBundleDocument, baseOptions);
      }
export type ReviewBundleMutationHookResult = ReturnType<typeof useReviewBundleMutation>;
export type ReviewBundleMutationResult = Apollo.MutationResult<ReviewBundleMutation>;
export type ReviewBundleMutationOptions = Apollo.BaseMutationOptions<ReviewBundleMutation, ReviewBundleMutationVariables>;
export const UpdateAdminNoteGameBundleDocument = gql`
    mutation UpdateAdminNoteGameBundle($id: ID!, $adminNote: String!) {
  updateAdminNoteGameBundle(input: {id: $id, adminNote: $adminNote}) {
    gameBundle {
      id
      adminNote
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateAdminNoteGameBundleMutationFn = Apollo.MutationFunction<UpdateAdminNoteGameBundleMutation, UpdateAdminNoteGameBundleMutationVariables>;

/**
 * __useUpdateAdminNoteGameBundleMutation__
 *
 * To run a mutation, you first call `useUpdateAdminNoteGameBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminNoteGameBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminNoteGameBundleMutation, { data, loading, error }] = useUpdateAdminNoteGameBundleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      adminNote: // value for 'adminNote'
 *   },
 * });
 */
export function useUpdateAdminNoteGameBundleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminNoteGameBundleMutation, UpdateAdminNoteGameBundleMutationVariables>) {
        return Apollo.useMutation<UpdateAdminNoteGameBundleMutation, UpdateAdminNoteGameBundleMutationVariables>(UpdateAdminNoteGameBundleDocument, baseOptions);
      }
export type UpdateAdminNoteGameBundleMutationHookResult = ReturnType<typeof useUpdateAdminNoteGameBundleMutation>;
export type UpdateAdminNoteGameBundleMutationResult = Apollo.MutationResult<UpdateAdminNoteGameBundleMutation>;
export type UpdateAdminNoteGameBundleMutationOptions = Apollo.BaseMutationOptions<UpdateAdminNoteGameBundleMutation, UpdateAdminNoteGameBundleMutationVariables>;
export const UpdateRatingGameBundleDocument = gql`
    mutation UpdateRatingGameBundle($id: ID!, $rating: Int!) {
  updateRatingGameBundle(input: {id: $id, rating: $rating}) {
    gameBundle {
      id
      rating
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateRatingGameBundleMutationFn = Apollo.MutationFunction<UpdateRatingGameBundleMutation, UpdateRatingGameBundleMutationVariables>;

/**
 * __useUpdateRatingGameBundleMutation__
 *
 * To run a mutation, you first call `useUpdateRatingGameBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRatingGameBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRatingGameBundleMutation, { data, loading, error }] = useUpdateRatingGameBundleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useUpdateRatingGameBundleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRatingGameBundleMutation, UpdateRatingGameBundleMutationVariables>) {
        return Apollo.useMutation<UpdateRatingGameBundleMutation, UpdateRatingGameBundleMutationVariables>(UpdateRatingGameBundleDocument, baseOptions);
      }
export type UpdateRatingGameBundleMutationHookResult = ReturnType<typeof useUpdateRatingGameBundleMutation>;
export type UpdateRatingGameBundleMutationResult = Apollo.MutationResult<UpdateRatingGameBundleMutation>;
export type UpdateRatingGameBundleMutationOptions = Apollo.BaseMutationOptions<UpdateRatingGameBundleMutation, UpdateRatingGameBundleMutationVariables>;
export const AddGameRankDocument = gql`
    mutation AddGameRank($gameId: ID!, $name: String!, $ordering: Int!, $rankTierMaxCount: Int!, $rankStarMaxCount: Int!, $rankPointMaxCount: [Int!], $image: File!) {
  addGameRank(input: {gameId: $gameId, name: $name, ordering: $ordering, rankTierMaxCount: $rankTierMaxCount, rankStarMaxCount: $rankStarMaxCount, rankPointMaxCount: $rankPointMaxCount, image: $image}) {
    gameRank {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type AddGameRankMutationFn = Apollo.MutationFunction<AddGameRankMutation, AddGameRankMutationVariables>;

/**
 * __useAddGameRankMutation__
 *
 * To run a mutation, you first call `useAddGameRankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGameRankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGameRankMutation, { data, loading, error }] = useAddGameRankMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      name: // value for 'name'
 *      ordering: // value for 'ordering'
 *      rankTierMaxCount: // value for 'rankTierMaxCount'
 *      rankStarMaxCount: // value for 'rankStarMaxCount'
 *      rankPointMaxCount: // value for 'rankPointMaxCount'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddGameRankMutation(baseOptions?: Apollo.MutationHookOptions<AddGameRankMutation, AddGameRankMutationVariables>) {
        return Apollo.useMutation<AddGameRankMutation, AddGameRankMutationVariables>(AddGameRankDocument, baseOptions);
      }
export type AddGameRankMutationHookResult = ReturnType<typeof useAddGameRankMutation>;
export type AddGameRankMutationResult = Apollo.MutationResult<AddGameRankMutation>;
export type AddGameRankMutationOptions = Apollo.BaseMutationOptions<AddGameRankMutation, AddGameRankMutationVariables>;
export const CreateGameDocument = gql`
    mutation CreateGame($name: String!, $shortname: String!, $developerName: String!, $genre: GameGenre!, $platform: GamePlatform!, $combat: Boolean!, $scrim: Boolean!, $realTime: Boolean!, $schedule: Boolean!, $matchmaking: Boolean!, $active: Boolean!, $icon: File!, $mascot: File!, $logo: File!, $screenshotSample: File!, $ordering: Int, $interestIds: [ID!], $tournament: Boolean, $themeColor: String!, $vocaCode: String) {
  createGame(input: {name: $name, shortname: $shortname, developerName: $developerName, genre: $genre, platform: $platform, combat: $combat, scrim: $scrim, realTime: $realTime, schedule: $schedule, matchmaking: $matchmaking, active: $active, icon: $icon, mascot: $mascot, logo: $logo, screenshotSample: $screenshotSample, ordering: $ordering, interestIds: $interestIds, tournament: $tournament, themeColor: $themeColor, vocaCode: $vocaCode}) {
    game {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateGameMutationFn = Apollo.MutationFunction<CreateGameMutation, CreateGameMutationVariables>;

/**
 * __useCreateGameMutation__
 *
 * To run a mutation, you first call `useCreateGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameMutation, { data, loading, error }] = useCreateGameMutation({
 *   variables: {
 *      name: // value for 'name'
 *      shortname: // value for 'shortname'
 *      developerName: // value for 'developerName'
 *      genre: // value for 'genre'
 *      platform: // value for 'platform'
 *      combat: // value for 'combat'
 *      scrim: // value for 'scrim'
 *      realTime: // value for 'realTime'
 *      schedule: // value for 'schedule'
 *      matchmaking: // value for 'matchmaking'
 *      active: // value for 'active'
 *      icon: // value for 'icon'
 *      mascot: // value for 'mascot'
 *      logo: // value for 'logo'
 *      screenshotSample: // value for 'screenshotSample'
 *      ordering: // value for 'ordering'
 *      interestIds: // value for 'interestIds'
 *      tournament: // value for 'tournament'
 *      themeColor: // value for 'themeColor'
 *      vocaCode: // value for 'vocaCode'
 *   },
 * });
 */
export function useCreateGameMutation(baseOptions?: Apollo.MutationHookOptions<CreateGameMutation, CreateGameMutationVariables>) {
        return Apollo.useMutation<CreateGameMutation, CreateGameMutationVariables>(CreateGameDocument, baseOptions);
      }
export type CreateGameMutationHookResult = ReturnType<typeof useCreateGameMutation>;
export type CreateGameMutationResult = Apollo.MutationResult<CreateGameMutation>;
export type CreateGameMutationOptions = Apollo.BaseMutationOptions<CreateGameMutation, CreateGameMutationVariables>;
export const RemoveGameRankDocument = gql`
    mutation RemoveGameRank($id: ID!) {
  removeGameRank(input: {id: $id}) {
    gameRank {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type RemoveGameRankMutationFn = Apollo.MutationFunction<RemoveGameRankMutation, RemoveGameRankMutationVariables>;

/**
 * __useRemoveGameRankMutation__
 *
 * To run a mutation, you first call `useRemoveGameRankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGameRankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGameRankMutation, { data, loading, error }] = useRemoveGameRankMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveGameRankMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGameRankMutation, RemoveGameRankMutationVariables>) {
        return Apollo.useMutation<RemoveGameRankMutation, RemoveGameRankMutationVariables>(RemoveGameRankDocument, baseOptions);
      }
export type RemoveGameRankMutationHookResult = ReturnType<typeof useRemoveGameRankMutation>;
export type RemoveGameRankMutationResult = Apollo.MutationResult<RemoveGameRankMutation>;
export type RemoveGameRankMutationOptions = Apollo.BaseMutationOptions<RemoveGameRankMutation, RemoveGameRankMutationVariables>;
export const UpdateGameDocument = gql`
    mutation UpdateGame($id: ID!, $name: String!, $shortname: String!, $developerName: String!, $genre: GameGenre!, $platform: GamePlatform!, $combat: Boolean!, $scrim: Boolean!, $realTime: Boolean!, $schedule: Boolean!, $matchmaking: Boolean!, $active: Boolean!, $icon: File, $mascot: File, $logo: File, $screenshotSample: File, $ordering: Int, $interestIds: [ID!], $tournament: Boolean, $themeColor: String, $vocaCode: String) {
  updateGame(input: {id: $id, name: $name, shortname: $shortname, developerName: $developerName, genre: $genre, platform: $platform, combat: $combat, scrim: $scrim, realTime: $realTime, schedule: $schedule, matchmaking: $matchmaking, active: $active, icon: $icon, mascot: $mascot, logo: $logo, screenshotSample: $screenshotSample, ordering: $ordering, interestIds: $interestIds, tournament: $tournament, themeColor: $themeColor, vocaCode: $vocaCode}) {
    game {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateGameMutationFn = Apollo.MutationFunction<UpdateGameMutation, UpdateGameMutationVariables>;

/**
 * __useUpdateGameMutation__
 *
 * To run a mutation, you first call `useUpdateGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameMutation, { data, loading, error }] = useUpdateGameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      shortname: // value for 'shortname'
 *      developerName: // value for 'developerName'
 *      genre: // value for 'genre'
 *      platform: // value for 'platform'
 *      combat: // value for 'combat'
 *      scrim: // value for 'scrim'
 *      realTime: // value for 'realTime'
 *      schedule: // value for 'schedule'
 *      matchmaking: // value for 'matchmaking'
 *      active: // value for 'active'
 *      icon: // value for 'icon'
 *      mascot: // value for 'mascot'
 *      logo: // value for 'logo'
 *      screenshotSample: // value for 'screenshotSample'
 *      ordering: // value for 'ordering'
 *      interestIds: // value for 'interestIds'
 *      tournament: // value for 'tournament'
 *      themeColor: // value for 'themeColor'
 *      vocaCode: // value for 'vocaCode'
 *   },
 * });
 */
export function useUpdateGameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGameMutation, UpdateGameMutationVariables>) {
        return Apollo.useMutation<UpdateGameMutation, UpdateGameMutationVariables>(UpdateGameDocument, baseOptions);
      }
export type UpdateGameMutationHookResult = ReturnType<typeof useUpdateGameMutation>;
export type UpdateGameMutationResult = Apollo.MutationResult<UpdateGameMutation>;
export type UpdateGameMutationOptions = Apollo.BaseMutationOptions<UpdateGameMutation, UpdateGameMutationVariables>;
export const UpdateGameRankDocument = gql`
    mutation UpdateGameRank($id: ID!, $gameId: ID, $name: String, $ordering: Int, $rankTierMaxCount: Int, $rankStarMaxCount: Int, $rankPointMaxCount: [Int!], $image: File) {
  updateGameRank(input: {id: $id, gameId: $gameId, name: $name, ordering: $ordering, rankTierMaxCount: $rankTierMaxCount, rankStarMaxCount: $rankStarMaxCount, rankPointMaxCount: $rankPointMaxCount, image: $image}) {
    gameRank {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateGameRankMutationFn = Apollo.MutationFunction<UpdateGameRankMutation, UpdateGameRankMutationVariables>;

/**
 * __useUpdateGameRankMutation__
 *
 * To run a mutation, you first call `useUpdateGameRankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameRankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameRankMutation, { data, loading, error }] = useUpdateGameRankMutation({
 *   variables: {
 *      id: // value for 'id'
 *      gameId: // value for 'gameId'
 *      name: // value for 'name'
 *      ordering: // value for 'ordering'
 *      rankTierMaxCount: // value for 'rankTierMaxCount'
 *      rankStarMaxCount: // value for 'rankStarMaxCount'
 *      rankPointMaxCount: // value for 'rankPointMaxCount'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateGameRankMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGameRankMutation, UpdateGameRankMutationVariables>) {
        return Apollo.useMutation<UpdateGameRankMutation, UpdateGameRankMutationVariables>(UpdateGameRankDocument, baseOptions);
      }
export type UpdateGameRankMutationHookResult = ReturnType<typeof useUpdateGameRankMutation>;
export type UpdateGameRankMutationResult = Apollo.MutationResult<UpdateGameRankMutation>;
export type UpdateGameRankMutationOptions = Apollo.BaseMutationOptions<UpdateGameRankMutation, UpdateGameRankMutationVariables>;
export const CreateGamerBannerDocument = gql`
    mutation CreateGamerBanner($active: Boolean!, $title: String!, $url: String, $image: File!, $poster: Boolean!, $screen: String) {
  createGamerBanner(input: {active: $active, title: $title, url: $url, image: $image, poster: $poster, screen: $screen}) {
    gamerBanner {
      id
      title
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateGamerBannerMutationFn = Apollo.MutationFunction<CreateGamerBannerMutation, CreateGamerBannerMutationVariables>;

/**
 * __useCreateGamerBannerMutation__
 *
 * To run a mutation, you first call `useCreateGamerBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGamerBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGamerBannerMutation, { data, loading, error }] = useCreateGamerBannerMutation({
 *   variables: {
 *      active: // value for 'active'
 *      title: // value for 'title'
 *      url: // value for 'url'
 *      image: // value for 'image'
 *      poster: // value for 'poster'
 *      screen: // value for 'screen'
 *   },
 * });
 */
export function useCreateGamerBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateGamerBannerMutation, CreateGamerBannerMutationVariables>) {
        return Apollo.useMutation<CreateGamerBannerMutation, CreateGamerBannerMutationVariables>(CreateGamerBannerDocument, baseOptions);
      }
export type CreateGamerBannerMutationHookResult = ReturnType<typeof useCreateGamerBannerMutation>;
export type CreateGamerBannerMutationResult = Apollo.MutationResult<CreateGamerBannerMutation>;
export type CreateGamerBannerMutationOptions = Apollo.BaseMutationOptions<CreateGamerBannerMutation, CreateGamerBannerMutationVariables>;
export const UpdateGamerBannerDocument = gql`
    mutation UpdateGamerBanner($id: ID!, $active: Boolean!, $title: String, $url: String, $image: File, $poster: Boolean!, $screen: String) {
  updateGamerBanner(input: {id: $id, active: $active, title: $title, url: $url, image: $image, poster: $poster, screen: $screen}) {
    gamerBanner {
      id
      title
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateGamerBannerMutationFn = Apollo.MutationFunction<UpdateGamerBannerMutation, UpdateGamerBannerMutationVariables>;

/**
 * __useUpdateGamerBannerMutation__
 *
 * To run a mutation, you first call `useUpdateGamerBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGamerBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGamerBannerMutation, { data, loading, error }] = useUpdateGamerBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *      title: // value for 'title'
 *      url: // value for 'url'
 *      image: // value for 'image'
 *      poster: // value for 'poster'
 *      screen: // value for 'screen'
 *   },
 * });
 */
export function useUpdateGamerBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGamerBannerMutation, UpdateGamerBannerMutationVariables>) {
        return Apollo.useMutation<UpdateGamerBannerMutation, UpdateGamerBannerMutationVariables>(UpdateGamerBannerDocument, baseOptions);
      }
export type UpdateGamerBannerMutationHookResult = ReturnType<typeof useUpdateGamerBannerMutation>;
export type UpdateGamerBannerMutationResult = Apollo.MutationResult<UpdateGamerBannerMutation>;
export type UpdateGamerBannerMutationOptions = Apollo.BaseMutationOptions<UpdateGamerBannerMutation, UpdateGamerBannerMutationVariables>;
export const CreateInterestDocument = gql`
    mutation CreateInterest($name: String!, $iconOn: File!, $iconOff: File!, $active: Boolean!) {
  createInterest(input: {name: $name, iconOn: $iconOn, iconOff: $iconOff, active: $active}) {
    interest {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateInterestMutationFn = Apollo.MutationFunction<CreateInterestMutation, CreateInterestMutationVariables>;

/**
 * __useCreateInterestMutation__
 *
 * To run a mutation, you first call `useCreateInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterestMutation, { data, loading, error }] = useCreateInterestMutation({
 *   variables: {
 *      name: // value for 'name'
 *      iconOn: // value for 'iconOn'
 *      iconOff: // value for 'iconOff'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCreateInterestMutation(baseOptions?: Apollo.MutationHookOptions<CreateInterestMutation, CreateInterestMutationVariables>) {
        return Apollo.useMutation<CreateInterestMutation, CreateInterestMutationVariables>(CreateInterestDocument, baseOptions);
      }
export type CreateInterestMutationHookResult = ReturnType<typeof useCreateInterestMutation>;
export type CreateInterestMutationResult = Apollo.MutationResult<CreateInterestMutation>;
export type CreateInterestMutationOptions = Apollo.BaseMutationOptions<CreateInterestMutation, CreateInterestMutationVariables>;
export const UpdateInterestDocument = gql`
    mutation UpdateInterest($id: ID!, $name: String!, $iconOn: File, $iconOff: File, $active: Boolean!) {
  updateInterest(input: {id: $id, name: $name, iconOn: $iconOn, iconOff: $iconOff, active: $active}) {
    interest {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateInterestMutationFn = Apollo.MutationFunction<UpdateInterestMutation, UpdateInterestMutationVariables>;

/**
 * __useUpdateInterestMutation__
 *
 * To run a mutation, you first call `useUpdateInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterestMutation, { data, loading, error }] = useUpdateInterestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      iconOn: // value for 'iconOn'
 *      iconOff: // value for 'iconOff'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateInterestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterestMutation, UpdateInterestMutationVariables>) {
        return Apollo.useMutation<UpdateInterestMutation, UpdateInterestMutationVariables>(UpdateInterestDocument, baseOptions);
      }
export type UpdateInterestMutationHookResult = ReturnType<typeof useUpdateInterestMutation>;
export type UpdateInterestMutationResult = Apollo.MutationResult<UpdateInterestMutation>;
export type UpdateInterestMutationOptions = Apollo.BaseMutationOptions<UpdateInterestMutation, UpdateInterestMutationVariables>;
export const GenerateLeaderboardAdminDocument = gql`
    mutation GenerateLeaderboardAdmin($string: String!) {
  generateLeaderboardAdmin(input: {string: $string}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type GenerateLeaderboardAdminMutationFn = Apollo.MutationFunction<GenerateLeaderboardAdminMutation, GenerateLeaderboardAdminMutationVariables>;

/**
 * __useGenerateLeaderboardAdminMutation__
 *
 * To run a mutation, you first call `useGenerateLeaderboardAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLeaderboardAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLeaderboardAdminMutation, { data, loading, error }] = useGenerateLeaderboardAdminMutation({
 *   variables: {
 *      string: // value for 'string'
 *   },
 * });
 */
export function useGenerateLeaderboardAdminMutation(baseOptions?: Apollo.MutationHookOptions<GenerateLeaderboardAdminMutation, GenerateLeaderboardAdminMutationVariables>) {
        return Apollo.useMutation<GenerateLeaderboardAdminMutation, GenerateLeaderboardAdminMutationVariables>(GenerateLeaderboardAdminDocument, baseOptions);
      }
export type GenerateLeaderboardAdminMutationHookResult = ReturnType<typeof useGenerateLeaderboardAdminMutation>;
export type GenerateLeaderboardAdminMutationResult = Apollo.MutationResult<GenerateLeaderboardAdminMutation>;
export type GenerateLeaderboardAdminMutationOptions = Apollo.BaseMutationOptions<GenerateLeaderboardAdminMutation, GenerateLeaderboardAdminMutationVariables>;
export const CreateMarkupPricingDocument = gql`
    mutation CreateMarkupPricing($countryCode: CountryCode!, $active: Boolean!, $uniqueId: String!, $percentage: Float, $name: String, $description: String, $merchantId: Int, $productId: ID, $invoiceId: String, $itemId: ID, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime, $vendorId: ID!) {
  createMarkupPricing(input: {percentage: $percentage, name: $name, description: $description, active: $active, uniqueId: $uniqueId, merchantId: $merchantId, productId: $productId, invoiceId: $invoiceId, countryCode: $countryCode, itemId: $itemId, startDate: $startDate, endDate: $endDate, vendorId: $vendorId}) {
    markupPricing {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateMarkupPricingMutationFn = Apollo.MutationFunction<CreateMarkupPricingMutation, CreateMarkupPricingMutationVariables>;

/**
 * __useCreateMarkupPricingMutation__
 *
 * To run a mutation, you first call `useCreateMarkupPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarkupPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarkupPricingMutation, { data, loading, error }] = useCreateMarkupPricingMutation({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      active: // value for 'active'
 *      uniqueId: // value for 'uniqueId'
 *      percentage: // value for 'percentage'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      merchantId: // value for 'merchantId'
 *      productId: // value for 'productId'
 *      invoiceId: // value for 'invoiceId'
 *      itemId: // value for 'itemId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useCreateMarkupPricingMutation(baseOptions?: Apollo.MutationHookOptions<CreateMarkupPricingMutation, CreateMarkupPricingMutationVariables>) {
        return Apollo.useMutation<CreateMarkupPricingMutation, CreateMarkupPricingMutationVariables>(CreateMarkupPricingDocument, baseOptions);
      }
export type CreateMarkupPricingMutationHookResult = ReturnType<typeof useCreateMarkupPricingMutation>;
export type CreateMarkupPricingMutationResult = Apollo.MutationResult<CreateMarkupPricingMutation>;
export type CreateMarkupPricingMutationOptions = Apollo.BaseMutationOptions<CreateMarkupPricingMutation, CreateMarkupPricingMutationVariables>;
export const UpdateMarkupPricingDocument = gql`
    mutation UpdateMarkupPricing($id: ID!, $percentage: Float, $name: String, $description: String, $active: Boolean, $uniqueId: String, $merchantId: Int, $productId: ID, $invoiceId: String, $countryCode: CountryCode, $itemId: ID, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime, $vendorId: ID) {
  updateMarkupPricing(input: {id: $id, percentage: $percentage, name: $name, description: $description, active: $active, uniqueId: $uniqueId, merchantId: $merchantId, productId: $productId, invoiceId: $invoiceId, countryCode: $countryCode, itemId: $itemId, startDate: $startDate, endDate: $endDate, vendorId: $vendorId}) {
    markupPricing {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateMarkupPricingMutationFn = Apollo.MutationFunction<UpdateMarkupPricingMutation, UpdateMarkupPricingMutationVariables>;

/**
 * __useUpdateMarkupPricingMutation__
 *
 * To run a mutation, you first call `useUpdateMarkupPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarkupPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarkupPricingMutation, { data, loading, error }] = useUpdateMarkupPricingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      percentage: // value for 'percentage'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      active: // value for 'active'
 *      uniqueId: // value for 'uniqueId'
 *      merchantId: // value for 'merchantId'
 *      productId: // value for 'productId'
 *      invoiceId: // value for 'invoiceId'
 *      countryCode: // value for 'countryCode'
 *      itemId: // value for 'itemId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useUpdateMarkupPricingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarkupPricingMutation, UpdateMarkupPricingMutationVariables>) {
        return Apollo.useMutation<UpdateMarkupPricingMutation, UpdateMarkupPricingMutationVariables>(UpdateMarkupPricingDocument, baseOptions);
      }
export type UpdateMarkupPricingMutationHookResult = ReturnType<typeof useUpdateMarkupPricingMutation>;
export type UpdateMarkupPricingMutationResult = Apollo.MutationResult<UpdateMarkupPricingMutation>;
export type UpdateMarkupPricingMutationOptions = Apollo.BaseMutationOptions<UpdateMarkupPricingMutation, UpdateMarkupPricingMutationVariables>;
export const CheckCategoryDocument = gql`
    mutation CheckCategory($name: String!) {
  checkCategory(input: {name: $name}) {
    categories {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CheckCategoryMutationFn = Apollo.MutationFunction<CheckCategoryMutation, CheckCategoryMutationVariables>;

/**
 * __useCheckCategoryMutation__
 *
 * To run a mutation, you first call `useCheckCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkCategoryMutation, { data, loading, error }] = useCheckCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCheckCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CheckCategoryMutation, CheckCategoryMutationVariables>) {
        return Apollo.useMutation<CheckCategoryMutation, CheckCategoryMutationVariables>(CheckCategoryDocument, baseOptions);
      }
export type CheckCategoryMutationHookResult = ReturnType<typeof useCheckCategoryMutation>;
export type CheckCategoryMutationResult = Apollo.MutationResult<CheckCategoryMutation>;
export type CheckCategoryMutationOptions = Apollo.BaseMutationOptions<CheckCategoryMutation, CheckCategoryMutationVariables>;
export const CheckMerchandiseVariantDocument = gql`
    mutation CheckMerchandiseVariant($sku: String!) {
  checkMerchandiseVariant(input: {sku: $sku}) {
    merchandiseVariants {
      id
      name
      sku
      price
      stock
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CheckMerchandiseVariantMutationFn = Apollo.MutationFunction<CheckMerchandiseVariantMutation, CheckMerchandiseVariantMutationVariables>;

/**
 * __useCheckMerchandiseVariantMutation__
 *
 * To run a mutation, you first call `useCheckMerchandiseVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckMerchandiseVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkMerchandiseVariantMutation, { data, loading, error }] = useCheckMerchandiseVariantMutation({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useCheckMerchandiseVariantMutation(baseOptions?: Apollo.MutationHookOptions<CheckMerchandiseVariantMutation, CheckMerchandiseVariantMutationVariables>) {
        return Apollo.useMutation<CheckMerchandiseVariantMutation, CheckMerchandiseVariantMutationVariables>(CheckMerchandiseVariantDocument, baseOptions);
      }
export type CheckMerchandiseVariantMutationHookResult = ReturnType<typeof useCheckMerchandiseVariantMutation>;
export type CheckMerchandiseVariantMutationResult = Apollo.MutationResult<CheckMerchandiseVariantMutation>;
export type CheckMerchandiseVariantMutationOptions = Apollo.BaseMutationOptions<CheckMerchandiseVariantMutation, CheckMerchandiseVariantMutationVariables>;
export const CheckPostDocument = gql`
    mutation CheckPost($title: String!) {
  checkPost(input: {title: $title}) {
    posts {
      id
      url
      isYoutube
      views
      active
      title
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CheckPostMutationFn = Apollo.MutationFunction<CheckPostMutation, CheckPostMutationVariables>;

/**
 * __useCheckPostMutation__
 *
 * To run a mutation, you first call `useCheckPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPostMutation, { data, loading, error }] = useCheckPostMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCheckPostMutation(baseOptions?: Apollo.MutationHookOptions<CheckPostMutation, CheckPostMutationVariables>) {
        return Apollo.useMutation<CheckPostMutation, CheckPostMutationVariables>(CheckPostDocument, baseOptions);
      }
export type CheckPostMutationHookResult = ReturnType<typeof useCheckPostMutation>;
export type CheckPostMutationResult = Apollo.MutationResult<CheckPostMutation>;
export type CheckPostMutationOptions = Apollo.BaseMutationOptions<CheckPostMutation, CheckPostMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($name: String!) {
  createCategory(input: {name: $name}) {
    category {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, baseOptions);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const CreateMerchandiseDocument = gql`
    mutation CreateMerchandise($name: String!, $active: Boolean!, $images: [File!]!, $startDate: String, $endDate: String, $startTime: String, $endTime: String, $postId: ID!, $categoryId: ID!, $position: Int) {
  createMerchandise(input: {name: $name, active: $active, images: $images, startDate: $startDate, endDate: $endDate, startTime: $startTime, endTime: $endTime, postId: $postId, categoryId: $categoryId, position: $position}) {
    merchandise {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateMerchandiseMutationFn = Apollo.MutationFunction<CreateMerchandiseMutation, CreateMerchandiseMutationVariables>;

/**
 * __useCreateMerchandiseMutation__
 *
 * To run a mutation, you first call `useCreateMerchandiseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchandiseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchandiseMutation, { data, loading, error }] = useCreateMerchandiseMutation({
 *   variables: {
 *      name: // value for 'name'
 *      active: // value for 'active'
 *      images: // value for 'images'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      postId: // value for 'postId'
 *      categoryId: // value for 'categoryId'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useCreateMerchandiseMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchandiseMutation, CreateMerchandiseMutationVariables>) {
        return Apollo.useMutation<CreateMerchandiseMutation, CreateMerchandiseMutationVariables>(CreateMerchandiseDocument, baseOptions);
      }
export type CreateMerchandiseMutationHookResult = ReturnType<typeof useCreateMerchandiseMutation>;
export type CreateMerchandiseMutationResult = Apollo.MutationResult<CreateMerchandiseMutation>;
export type CreateMerchandiseMutationOptions = Apollo.BaseMutationOptions<CreateMerchandiseMutation, CreateMerchandiseMutationVariables>;
export const CreateMerchandiseVariantDocument = gql`
    mutation CreateMerchandiseVariant($merchandiseId: ID!, $name: String!, $sku: String!, $price: Float!, $stock: Int!, $position: Int) {
  createMerchandiseVariant(input: {merchandiseId: $merchandiseId, name: $name, sku: $sku, price: $price, stock: $stock, position: $position}) {
    merchandiseVariant {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateMerchandiseVariantMutationFn = Apollo.MutationFunction<CreateMerchandiseVariantMutation, CreateMerchandiseVariantMutationVariables>;

/**
 * __useCreateMerchandiseVariantMutation__
 *
 * To run a mutation, you first call `useCreateMerchandiseVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchandiseVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchandiseVariantMutation, { data, loading, error }] = useCreateMerchandiseVariantMutation({
 *   variables: {
 *      merchandiseId: // value for 'merchandiseId'
 *      name: // value for 'name'
 *      sku: // value for 'sku'
 *      price: // value for 'price'
 *      stock: // value for 'stock'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useCreateMerchandiseVariantMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchandiseVariantMutation, CreateMerchandiseVariantMutationVariables>) {
        return Apollo.useMutation<CreateMerchandiseVariantMutation, CreateMerchandiseVariantMutationVariables>(CreateMerchandiseVariantDocument, baseOptions);
      }
export type CreateMerchandiseVariantMutationHookResult = ReturnType<typeof useCreateMerchandiseVariantMutation>;
export type CreateMerchandiseVariantMutationResult = Apollo.MutationResult<CreateMerchandiseVariantMutation>;
export type CreateMerchandiseVariantMutationOptions = Apollo.BaseMutationOptions<CreateMerchandiseVariantMutation, CreateMerchandiseVariantMutationVariables>;
export const UpdateMerchandiseDocument = gql`
    mutation UpdateMerchandise($id: ID!, $name: String!, $active: Boolean!, $postId: ID!, $categoryId: ID!, $startDate: String, $endDate: String, $startTime: String, $endTime: String, $position: Int) {
  updateMerchandise(input: {id: $id, name: $name, active: $active, postId: $postId, categoryId: $categoryId, startDate: $startDate, endDate: $endDate, startTime: $startTime, endTime: $endTime, position: $position}) {
    merchandise {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateMerchandiseMutationFn = Apollo.MutationFunction<UpdateMerchandiseMutation, UpdateMerchandiseMutationVariables>;

/**
 * __useUpdateMerchandiseMutation__
 *
 * To run a mutation, you first call `useUpdateMerchandiseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchandiseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchandiseMutation, { data, loading, error }] = useUpdateMerchandiseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      active: // value for 'active'
 *      postId: // value for 'postId'
 *      categoryId: // value for 'categoryId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdateMerchandiseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchandiseMutation, UpdateMerchandiseMutationVariables>) {
        return Apollo.useMutation<UpdateMerchandiseMutation, UpdateMerchandiseMutationVariables>(UpdateMerchandiseDocument, baseOptions);
      }
export type UpdateMerchandiseMutationHookResult = ReturnType<typeof useUpdateMerchandiseMutation>;
export type UpdateMerchandiseMutationResult = Apollo.MutationResult<UpdateMerchandiseMutation>;
export type UpdateMerchandiseMutationOptions = Apollo.BaseMutationOptions<UpdateMerchandiseMutation, UpdateMerchandiseMutationVariables>;
export const UpdateMerchandiseImagesDocument = gql`
    mutation UpdateMerchandiseImages($id: ID!, $isUpdate: Boolean!, $images: [File!]) {
  updateMerchandiseImages(input: {id: $id, isUpdate: $isUpdate, images: $images}) {
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateMerchandiseImagesMutationFn = Apollo.MutationFunction<UpdateMerchandiseImagesMutation, UpdateMerchandiseImagesMutationVariables>;

/**
 * __useUpdateMerchandiseImagesMutation__
 *
 * To run a mutation, you first call `useUpdateMerchandiseImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchandiseImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchandiseImagesMutation, { data, loading, error }] = useUpdateMerchandiseImagesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isUpdate: // value for 'isUpdate'
 *      images: // value for 'images'
 *   },
 * });
 */
export function useUpdateMerchandiseImagesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchandiseImagesMutation, UpdateMerchandiseImagesMutationVariables>) {
        return Apollo.useMutation<UpdateMerchandiseImagesMutation, UpdateMerchandiseImagesMutationVariables>(UpdateMerchandiseImagesDocument, baseOptions);
      }
export type UpdateMerchandiseImagesMutationHookResult = ReturnType<typeof useUpdateMerchandiseImagesMutation>;
export type UpdateMerchandiseImagesMutationResult = Apollo.MutationResult<UpdateMerchandiseImagesMutation>;
export type UpdateMerchandiseImagesMutationOptions = Apollo.BaseMutationOptions<UpdateMerchandiseImagesMutation, UpdateMerchandiseImagesMutationVariables>;
export const UpdateMerchandiseVariantDocument = gql`
    mutation UpdateMerchandiseVariant($id: ID!, $merchandiseId: ID, $name: String, $sku: String, $price: Float, $stock: Int, $position: Int) {
  updateMerchandiseVariant(input: {id: $id, merchandiseId: $merchandiseId, name: $name, sku: $sku, price: $price, stock: $stock, position: $position}) {
    merchandiseVariant {
      id
      name
      merchandise {
        id
        name
      }
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateMerchandiseVariantMutationFn = Apollo.MutationFunction<UpdateMerchandiseVariantMutation, UpdateMerchandiseVariantMutationVariables>;

/**
 * __useUpdateMerchandiseVariantMutation__
 *
 * To run a mutation, you first call `useUpdateMerchandiseVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchandiseVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchandiseVariantMutation, { data, loading, error }] = useUpdateMerchandiseVariantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      merchandiseId: // value for 'merchandiseId'
 *      name: // value for 'name'
 *      sku: // value for 'sku'
 *      price: // value for 'price'
 *      stock: // value for 'stock'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdateMerchandiseVariantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchandiseVariantMutation, UpdateMerchandiseVariantMutationVariables>) {
        return Apollo.useMutation<UpdateMerchandiseVariantMutation, UpdateMerchandiseVariantMutationVariables>(UpdateMerchandiseVariantDocument, baseOptions);
      }
export type UpdateMerchandiseVariantMutationHookResult = ReturnType<typeof useUpdateMerchandiseVariantMutation>;
export type UpdateMerchandiseVariantMutationResult = Apollo.MutationResult<UpdateMerchandiseVariantMutation>;
export type UpdateMerchandiseVariantMutationOptions = Apollo.BaseMutationOptions<UpdateMerchandiseVariantMutation, UpdateMerchandiseVariantMutationVariables>;
export const CreateOfficialChannelDocument = gql`
    mutation CreateOfficialChannel($active: Boolean!, $name: String!, $url: String, $phoneNumber: String, $accountId: String) {
  createOfficialChannel(input: {active: $active, name: $name, url: $url, phoneNumber: $phoneNumber, accountId: $accountId}) {
    officialChannel {
      ...OfficialChannel
    }
    errors {
      path
      detail
    }
  }
}
    ${OfficialChannelFragmentDoc}`;
export type CreateOfficialChannelMutationFn = Apollo.MutationFunction<CreateOfficialChannelMutation, CreateOfficialChannelMutationVariables>;

/**
 * __useCreateOfficialChannelMutation__
 *
 * To run a mutation, you first call `useCreateOfficialChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficialChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficialChannelMutation, { data, loading, error }] = useCreateOfficialChannelMutation({
 *   variables: {
 *      active: // value for 'active'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *      phoneNumber: // value for 'phoneNumber'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCreateOfficialChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfficialChannelMutation, CreateOfficialChannelMutationVariables>) {
        return Apollo.useMutation<CreateOfficialChannelMutation, CreateOfficialChannelMutationVariables>(CreateOfficialChannelDocument, baseOptions);
      }
export type CreateOfficialChannelMutationHookResult = ReturnType<typeof useCreateOfficialChannelMutation>;
export type CreateOfficialChannelMutationResult = Apollo.MutationResult<CreateOfficialChannelMutation>;
export type CreateOfficialChannelMutationOptions = Apollo.BaseMutationOptions<CreateOfficialChannelMutation, CreateOfficialChannelMutationVariables>;
export const UpdateOfficialChannelDocument = gql`
    mutation UpdateOfficialChannel($id: ID!, $active: Boolean, $name: String, $url: String, $phoneNumber: String, $accountId: String) {
  updateOfficialChannel(input: {id: $id, active: $active, name: $name, url: $url, phoneNumber: $phoneNumber, accountId: $accountId}) {
    officialChannel {
      ...OfficialChannel
    }
    errors {
      path
      detail
    }
  }
}
    ${OfficialChannelFragmentDoc}`;
export type UpdateOfficialChannelMutationFn = Apollo.MutationFunction<UpdateOfficialChannelMutation, UpdateOfficialChannelMutationVariables>;

/**
 * __useUpdateOfficialChannelMutation__
 *
 * To run a mutation, you first call `useUpdateOfficialChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficialChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficialChannelMutation, { data, loading, error }] = useUpdateOfficialChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *      phoneNumber: // value for 'phoneNumber'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUpdateOfficialChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfficialChannelMutation, UpdateOfficialChannelMutationVariables>) {
        return Apollo.useMutation<UpdateOfficialChannelMutation, UpdateOfficialChannelMutationVariables>(UpdateOfficialChannelDocument, baseOptions);
      }
export type UpdateOfficialChannelMutationHookResult = ReturnType<typeof useUpdateOfficialChannelMutation>;
export type UpdateOfficialChannelMutationResult = Apollo.MutationResult<UpdateOfficialChannelMutation>;
export type UpdateOfficialChannelMutationOptions = Apollo.BaseMutationOptions<UpdateOfficialChannelMutation, UpdateOfficialChannelMutationVariables>;
export const UpdateOrderStatusDocument = gql`
    mutation UpdateOrderStatus($id: ID!, $statusType: String!) {
  updateOrderStatus(input: {id: $id, statusType: $statusType}) {
    order {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      statusType: // value for 'statusType'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>) {
        return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument, baseOptions);
      }
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;
export const UpdateTrackingOrderDocument = gql`
    mutation UpdateTrackingOrder($id: ID!, $trackingNumber: String!, $trackingUrl: String!) {
  updateTrackingOrder(input: {id: $id, trackingNumber: $trackingNumber, trackingUrl: $trackingUrl}) {
    order {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateTrackingOrderMutationFn = Apollo.MutationFunction<UpdateTrackingOrderMutation, UpdateTrackingOrderMutationVariables>;

/**
 * __useUpdateTrackingOrderMutation__
 *
 * To run a mutation, you first call `useUpdateTrackingOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrackingOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrackingOrderMutation, { data, loading, error }] = useUpdateTrackingOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trackingNumber: // value for 'trackingNumber'
 *      trackingUrl: // value for 'trackingUrl'
 *   },
 * });
 */
export function useUpdateTrackingOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrackingOrderMutation, UpdateTrackingOrderMutationVariables>) {
        return Apollo.useMutation<UpdateTrackingOrderMutation, UpdateTrackingOrderMutationVariables>(UpdateTrackingOrderDocument, baseOptions);
      }
export type UpdateTrackingOrderMutationHookResult = ReturnType<typeof useUpdateTrackingOrderMutation>;
export type UpdateTrackingOrderMutationResult = Apollo.MutationResult<UpdateTrackingOrderMutation>;
export type UpdateTrackingOrderMutationOptions = Apollo.BaseMutationOptions<UpdateTrackingOrderMutation, UpdateTrackingOrderMutationVariables>;
export const CompletePartneringRequestDocument = gql`
    mutation CompletePartneringRequest($id: ID!) {
  completePartneringRequest(input: {id: $id}) {
    partneringRequest {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CompletePartneringRequestMutationFn = Apollo.MutationFunction<CompletePartneringRequestMutation, CompletePartneringRequestMutationVariables>;

/**
 * __useCompletePartneringRequestMutation__
 *
 * To run a mutation, you first call `useCompletePartneringRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePartneringRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePartneringRequestMutation, { data, loading, error }] = useCompletePartneringRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompletePartneringRequestMutation(baseOptions?: Apollo.MutationHookOptions<CompletePartneringRequestMutation, CompletePartneringRequestMutationVariables>) {
        return Apollo.useMutation<CompletePartneringRequestMutation, CompletePartneringRequestMutationVariables>(CompletePartneringRequestDocument, baseOptions);
      }
export type CompletePartneringRequestMutationHookResult = ReturnType<typeof useCompletePartneringRequestMutation>;
export type CompletePartneringRequestMutationResult = Apollo.MutationResult<CompletePartneringRequestMutation>;
export type CompletePartneringRequestMutationOptions = Apollo.BaseMutationOptions<CompletePartneringRequestMutation, CompletePartneringRequestMutationVariables>;
export const RefundPartneringRequestDocument = gql`
    mutation RefundPartneringRequest($id: ID!) {
  refundPartneringRequest(input: {id: $id}) {
    partneringRequest {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type RefundPartneringRequestMutationFn = Apollo.MutationFunction<RefundPartneringRequestMutation, RefundPartneringRequestMutationVariables>;

/**
 * __useRefundPartneringRequestMutation__
 *
 * To run a mutation, you first call `useRefundPartneringRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundPartneringRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundPartneringRequestMutation, { data, loading, error }] = useRefundPartneringRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefundPartneringRequestMutation(baseOptions?: Apollo.MutationHookOptions<RefundPartneringRequestMutation, RefundPartneringRequestMutationVariables>) {
        return Apollo.useMutation<RefundPartneringRequestMutation, RefundPartneringRequestMutationVariables>(RefundPartneringRequestDocument, baseOptions);
      }
export type RefundPartneringRequestMutationHookResult = ReturnType<typeof useRefundPartneringRequestMutation>;
export type RefundPartneringRequestMutationResult = Apollo.MutationResult<RefundPartneringRequestMutation>;
export type RefundPartneringRequestMutationOptions = Apollo.BaseMutationOptions<RefundPartneringRequestMutation, RefundPartneringRequestMutationVariables>;
export const ReportPartneringRequestDocument = gql`
    mutation ReportPartneringRequest($id: ID!, $reportReason: String!) {
  reportPartneringRequest(input: {id: $id, reportReason: $reportReason}) {
    partneringRequest {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type ReportPartneringRequestMutationFn = Apollo.MutationFunction<ReportPartneringRequestMutation, ReportPartneringRequestMutationVariables>;

/**
 * __useReportPartneringRequestMutation__
 *
 * To run a mutation, you first call `useReportPartneringRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPartneringRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPartneringRequestMutation, { data, loading, error }] = useReportPartneringRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reportReason: // value for 'reportReason'
 *   },
 * });
 */
export function useReportPartneringRequestMutation(baseOptions?: Apollo.MutationHookOptions<ReportPartneringRequestMutation, ReportPartneringRequestMutationVariables>) {
        return Apollo.useMutation<ReportPartneringRequestMutation, ReportPartneringRequestMutationVariables>(ReportPartneringRequestDocument, baseOptions);
      }
export type ReportPartneringRequestMutationHookResult = ReturnType<typeof useReportPartneringRequestMutation>;
export type ReportPartneringRequestMutationResult = Apollo.MutationResult<ReportPartneringRequestMutation>;
export type ReportPartneringRequestMutationOptions = Apollo.BaseMutationOptions<ReportPartneringRequestMutation, ReportPartneringRequestMutationVariables>;
export const ReviewPartneringRequestDocument = gql`
    mutation ReviewPartneringRequest($id: ID!) {
  reviewPartneringRequest(input: {id: $id}) {
    partneringRequest {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type ReviewPartneringRequestMutationFn = Apollo.MutationFunction<ReviewPartneringRequestMutation, ReviewPartneringRequestMutationVariables>;

/**
 * __useReviewPartneringRequestMutation__
 *
 * To run a mutation, you first call `useReviewPartneringRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewPartneringRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewPartneringRequestMutation, { data, loading, error }] = useReviewPartneringRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewPartneringRequestMutation(baseOptions?: Apollo.MutationHookOptions<ReviewPartneringRequestMutation, ReviewPartneringRequestMutationVariables>) {
        return Apollo.useMutation<ReviewPartneringRequestMutation, ReviewPartneringRequestMutationVariables>(ReviewPartneringRequestDocument, baseOptions);
      }
export type ReviewPartneringRequestMutationHookResult = ReturnType<typeof useReviewPartneringRequestMutation>;
export type ReviewPartneringRequestMutationResult = Apollo.MutationResult<ReviewPartneringRequestMutation>;
export type ReviewPartneringRequestMutationOptions = Apollo.BaseMutationOptions<ReviewPartneringRequestMutation, ReviewPartneringRequestMutationVariables>;
export const UpdateAdminNotePartneringRequestDocument = gql`
    mutation UpdateAdminNotePartneringRequest($id: ID!, $adminNote: String!) {
  updateAdminNotePartneringRequest(input: {id: $id, adminNote: $adminNote}) {
    partneringRequest {
      id
      adminNote
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateAdminNotePartneringRequestMutationFn = Apollo.MutationFunction<UpdateAdminNotePartneringRequestMutation, UpdateAdminNotePartneringRequestMutationVariables>;

/**
 * __useUpdateAdminNotePartneringRequestMutation__
 *
 * To run a mutation, you first call `useUpdateAdminNotePartneringRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminNotePartneringRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminNotePartneringRequestMutation, { data, loading, error }] = useUpdateAdminNotePartneringRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      adminNote: // value for 'adminNote'
 *   },
 * });
 */
export function useUpdateAdminNotePartneringRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminNotePartneringRequestMutation, UpdateAdminNotePartneringRequestMutationVariables>) {
        return Apollo.useMutation<UpdateAdminNotePartneringRequestMutation, UpdateAdminNotePartneringRequestMutationVariables>(UpdateAdminNotePartneringRequestDocument, baseOptions);
      }
export type UpdateAdminNotePartneringRequestMutationHookResult = ReturnType<typeof useUpdateAdminNotePartneringRequestMutation>;
export type UpdateAdminNotePartneringRequestMutationResult = Apollo.MutationResult<UpdateAdminNotePartneringRequestMutation>;
export type UpdateAdminNotePartneringRequestMutationOptions = Apollo.BaseMutationOptions<UpdateAdminNotePartneringRequestMutation, UpdateAdminNotePartneringRequestMutationVariables>;
export const UpdateRatingPartneringRequestDocument = gql`
    mutation UpdateRatingPartneringRequest($id: ID!, $rating: Int!) {
  updateRatingPartneringRequest(input: {id: $id, rating: $rating}) {
    partneringRequest {
      id
      rating
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateRatingPartneringRequestMutationFn = Apollo.MutationFunction<UpdateRatingPartneringRequestMutation, UpdateRatingPartneringRequestMutationVariables>;

/**
 * __useUpdateRatingPartneringRequestMutation__
 *
 * To run a mutation, you first call `useUpdateRatingPartneringRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRatingPartneringRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRatingPartneringRequestMutation, { data, loading, error }] = useUpdateRatingPartneringRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useUpdateRatingPartneringRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRatingPartneringRequestMutation, UpdateRatingPartneringRequestMutationVariables>) {
        return Apollo.useMutation<UpdateRatingPartneringRequestMutation, UpdateRatingPartneringRequestMutationVariables>(UpdateRatingPartneringRequestDocument, baseOptions);
      }
export type UpdateRatingPartneringRequestMutationHookResult = ReturnType<typeof useUpdateRatingPartneringRequestMutation>;
export type UpdateRatingPartneringRequestMutationResult = Apollo.MutationResult<UpdateRatingPartneringRequestMutation>;
export type UpdateRatingPartneringRequestMutationOptions = Apollo.BaseMutationOptions<UpdateRatingPartneringRequestMutation, UpdateRatingPartneringRequestMutationVariables>;
export const ApprovePayoutTransactionDocument = gql`
    mutation ApprovePayoutTransaction($id: ID!) {
  approvePayoutTransaction(input: {id: $id}) {
    payout {
      ...Payout
    }
    errors {
      ...Error
    }
  }
}
    ${PayoutFragmentDoc}
${ErrorFragmentDoc}`;
export type ApprovePayoutTransactionMutationFn = Apollo.MutationFunction<ApprovePayoutTransactionMutation, ApprovePayoutTransactionMutationVariables>;

/**
 * __useApprovePayoutTransactionMutation__
 *
 * To run a mutation, you first call `useApprovePayoutTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePayoutTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePayoutTransactionMutation, { data, loading, error }] = useApprovePayoutTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApprovePayoutTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ApprovePayoutTransactionMutation, ApprovePayoutTransactionMutationVariables>) {
        return Apollo.useMutation<ApprovePayoutTransactionMutation, ApprovePayoutTransactionMutationVariables>(ApprovePayoutTransactionDocument, baseOptions);
      }
export type ApprovePayoutTransactionMutationHookResult = ReturnType<typeof useApprovePayoutTransactionMutation>;
export type ApprovePayoutTransactionMutationResult = Apollo.MutationResult<ApprovePayoutTransactionMutation>;
export type ApprovePayoutTransactionMutationOptions = Apollo.BaseMutationOptions<ApprovePayoutTransactionMutation, ApprovePayoutTransactionMutationVariables>;
export const CompletePayoutTransactionDocument = gql`
    mutation CompletePayoutTransaction($id: ID!) {
  completePayoutTransaction(input: {id: $id}) {
    payout {
      ...Payout
    }
    errors {
      ...Error
    }
  }
}
    ${PayoutFragmentDoc}
${ErrorFragmentDoc}`;
export type CompletePayoutTransactionMutationFn = Apollo.MutationFunction<CompletePayoutTransactionMutation, CompletePayoutTransactionMutationVariables>;

/**
 * __useCompletePayoutTransactionMutation__
 *
 * To run a mutation, you first call `useCompletePayoutTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePayoutTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePayoutTransactionMutation, { data, loading, error }] = useCompletePayoutTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompletePayoutTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CompletePayoutTransactionMutation, CompletePayoutTransactionMutationVariables>) {
        return Apollo.useMutation<CompletePayoutTransactionMutation, CompletePayoutTransactionMutationVariables>(CompletePayoutTransactionDocument, baseOptions);
      }
export type CompletePayoutTransactionMutationHookResult = ReturnType<typeof useCompletePayoutTransactionMutation>;
export type CompletePayoutTransactionMutationResult = Apollo.MutationResult<CompletePayoutTransactionMutation>;
export type CompletePayoutTransactionMutationOptions = Apollo.BaseMutationOptions<CompletePayoutTransactionMutation, CompletePayoutTransactionMutationVariables>;
export const CreatePayoutDocument = gql`
    mutation CreatePayout($amount: Int!, $pilotId: ID!) {
  createPayout(input: {amount: $amount, pilotId: $pilotId}) {
    payout {
      ...Payout
    }
    errors {
      path
      detail
    }
  }
}
    ${PayoutFragmentDoc}`;
export type CreatePayoutMutationFn = Apollo.MutationFunction<CreatePayoutMutation, CreatePayoutMutationVariables>;

/**
 * __useCreatePayoutMutation__
 *
 * To run a mutation, you first call `useCreatePayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayoutMutation, { data, loading, error }] = useCreatePayoutMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      pilotId: // value for 'pilotId'
 *   },
 * });
 */
export function useCreatePayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayoutMutation, CreatePayoutMutationVariables>) {
        return Apollo.useMutation<CreatePayoutMutation, CreatePayoutMutationVariables>(CreatePayoutDocument, baseOptions);
      }
export type CreatePayoutMutationHookResult = ReturnType<typeof useCreatePayoutMutation>;
export type CreatePayoutMutationResult = Apollo.MutationResult<CreatePayoutMutation>;
export type CreatePayoutMutationOptions = Apollo.BaseMutationOptions<CreatePayoutMutation, CreatePayoutMutationVariables>;
export const FailedPayoutTransactionDocument = gql`
    mutation FailedPayoutTransaction($id: ID!) {
  failedPayoutTransaction(input: {id: $id}) {
    payout {
      ...Payout
    }
    errors {
      ...Error
    }
  }
}
    ${PayoutFragmentDoc}
${ErrorFragmentDoc}`;
export type FailedPayoutTransactionMutationFn = Apollo.MutationFunction<FailedPayoutTransactionMutation, FailedPayoutTransactionMutationVariables>;

/**
 * __useFailedPayoutTransactionMutation__
 *
 * To run a mutation, you first call `useFailedPayoutTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFailedPayoutTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [failedPayoutTransactionMutation, { data, loading, error }] = useFailedPayoutTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFailedPayoutTransactionMutation(baseOptions?: Apollo.MutationHookOptions<FailedPayoutTransactionMutation, FailedPayoutTransactionMutationVariables>) {
        return Apollo.useMutation<FailedPayoutTransactionMutation, FailedPayoutTransactionMutationVariables>(FailedPayoutTransactionDocument, baseOptions);
      }
export type FailedPayoutTransactionMutationHookResult = ReturnType<typeof useFailedPayoutTransactionMutation>;
export type FailedPayoutTransactionMutationResult = Apollo.MutationResult<FailedPayoutTransactionMutation>;
export type FailedPayoutTransactionMutationOptions = Apollo.BaseMutationOptions<FailedPayoutTransactionMutation, FailedPayoutTransactionMutationVariables>;
export const RejectPayoutTransactionDocument = gql`
    mutation RejectPayoutTransaction($id: ID!, $rejectReason: String!) {
  rejectPayoutTransaction(input: {id: $id, rejectReason: $rejectReason}) {
    payout {
      ...Payout
    }
    errors {
      ...Error
    }
  }
}
    ${PayoutFragmentDoc}
${ErrorFragmentDoc}`;
export type RejectPayoutTransactionMutationFn = Apollo.MutationFunction<RejectPayoutTransactionMutation, RejectPayoutTransactionMutationVariables>;

/**
 * __useRejectPayoutTransactionMutation__
 *
 * To run a mutation, you first call `useRejectPayoutTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectPayoutTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectPayoutTransactionMutation, { data, loading, error }] = useRejectPayoutTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rejectReason: // value for 'rejectReason'
 *   },
 * });
 */
export function useRejectPayoutTransactionMutation(baseOptions?: Apollo.MutationHookOptions<RejectPayoutTransactionMutation, RejectPayoutTransactionMutationVariables>) {
        return Apollo.useMutation<RejectPayoutTransactionMutation, RejectPayoutTransactionMutationVariables>(RejectPayoutTransactionDocument, baseOptions);
      }
export type RejectPayoutTransactionMutationHookResult = ReturnType<typeof useRejectPayoutTransactionMutation>;
export type RejectPayoutTransactionMutationResult = Apollo.MutationResult<RejectPayoutTransactionMutation>;
export type RejectPayoutTransactionMutationOptions = Apollo.BaseMutationOptions<RejectPayoutTransactionMutation, RejectPayoutTransactionMutationVariables>;
export const UpdateAdminNotePayoutDocument = gql`
    mutation UpdateAdminNotePayout($id: ID!, $adminNote: String!) {
  updateAdminNotePayout(input: {id: $id, adminNote: $adminNote}) {
    payout {
      id
      adminNote
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateAdminNotePayoutMutationFn = Apollo.MutationFunction<UpdateAdminNotePayoutMutation, UpdateAdminNotePayoutMutationVariables>;

/**
 * __useUpdateAdminNotePayoutMutation__
 *
 * To run a mutation, you first call `useUpdateAdminNotePayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminNotePayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminNotePayoutMutation, { data, loading, error }] = useUpdateAdminNotePayoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      adminNote: // value for 'adminNote'
 *   },
 * });
 */
export function useUpdateAdminNotePayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminNotePayoutMutation, UpdateAdminNotePayoutMutationVariables>) {
        return Apollo.useMutation<UpdateAdminNotePayoutMutation, UpdateAdminNotePayoutMutationVariables>(UpdateAdminNotePayoutDocument, baseOptions);
      }
export type UpdateAdminNotePayoutMutationHookResult = ReturnType<typeof useUpdateAdminNotePayoutMutation>;
export type UpdateAdminNotePayoutMutationResult = Apollo.MutationResult<UpdateAdminNotePayoutMutation>;
export type UpdateAdminNotePayoutMutationOptions = Apollo.BaseMutationOptions<UpdateAdminNotePayoutMutation, UpdateAdminNotePayoutMutationVariables>;
export const CreatePilotBannerDocument = gql`
    mutation CreatePilotBanner($active: Boolean!, $title: String!, $url: String, $image: File!, $poster: Boolean!, $screen: String) {
  createPilotBanner(input: {active: $active, title: $title, url: $url, image: $image, poster: $poster, screen: $screen}) {
    pilotBanner {
      id
      title
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreatePilotBannerMutationFn = Apollo.MutationFunction<CreatePilotBannerMutation, CreatePilotBannerMutationVariables>;

/**
 * __useCreatePilotBannerMutation__
 *
 * To run a mutation, you first call `useCreatePilotBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePilotBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPilotBannerMutation, { data, loading, error }] = useCreatePilotBannerMutation({
 *   variables: {
 *      active: // value for 'active'
 *      title: // value for 'title'
 *      url: // value for 'url'
 *      image: // value for 'image'
 *      poster: // value for 'poster'
 *      screen: // value for 'screen'
 *   },
 * });
 */
export function useCreatePilotBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePilotBannerMutation, CreatePilotBannerMutationVariables>) {
        return Apollo.useMutation<CreatePilotBannerMutation, CreatePilotBannerMutationVariables>(CreatePilotBannerDocument, baseOptions);
      }
export type CreatePilotBannerMutationHookResult = ReturnType<typeof useCreatePilotBannerMutation>;
export type CreatePilotBannerMutationResult = Apollo.MutationResult<CreatePilotBannerMutation>;
export type CreatePilotBannerMutationOptions = Apollo.BaseMutationOptions<CreatePilotBannerMutation, CreatePilotBannerMutationVariables>;
export const UpdatePilotBannerDocument = gql`
    mutation UpdatePilotBanner($id: ID!, $active: Boolean!, $title: String, $url: String, $image: File, $poster: Boolean!, $screen: String) {
  updatePilotBanner(input: {id: $id, active: $active, title: $title, url: $url, image: $image, poster: $poster, screen: $screen}) {
    pilotBanner {
      id
      title
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdatePilotBannerMutationFn = Apollo.MutationFunction<UpdatePilotBannerMutation, UpdatePilotBannerMutationVariables>;

/**
 * __useUpdatePilotBannerMutation__
 *
 * To run a mutation, you first call `useUpdatePilotBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePilotBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePilotBannerMutation, { data, loading, error }] = useUpdatePilotBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *      title: // value for 'title'
 *      url: // value for 'url'
 *      image: // value for 'image'
 *      poster: // value for 'poster'
 *      screen: // value for 'screen'
 *   },
 * });
 */
export function useUpdatePilotBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePilotBannerMutation, UpdatePilotBannerMutationVariables>) {
        return Apollo.useMutation<UpdatePilotBannerMutation, UpdatePilotBannerMutationVariables>(UpdatePilotBannerDocument, baseOptions);
      }
export type UpdatePilotBannerMutationHookResult = ReturnType<typeof useUpdatePilotBannerMutation>;
export type UpdatePilotBannerMutationResult = Apollo.MutationResult<UpdatePilotBannerMutation>;
export type UpdatePilotBannerMutationOptions = Apollo.BaseMutationOptions<UpdatePilotBannerMutation, UpdatePilotBannerMutationVariables>;
export const DeliverPilotRewardDocument = gql`
    mutation DeliverPilotReward($id: ID!) {
  deliverPilotReward(input: {id: $id}) {
    pilotReward {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type DeliverPilotRewardMutationFn = Apollo.MutationFunction<DeliverPilotRewardMutation, DeliverPilotRewardMutationVariables>;

/**
 * __useDeliverPilotRewardMutation__
 *
 * To run a mutation, you first call `useDeliverPilotRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliverPilotRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliverPilotRewardMutation, { data, loading, error }] = useDeliverPilotRewardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeliverPilotRewardMutation(baseOptions?: Apollo.MutationHookOptions<DeliverPilotRewardMutation, DeliverPilotRewardMutationVariables>) {
        return Apollo.useMutation<DeliverPilotRewardMutation, DeliverPilotRewardMutationVariables>(DeliverPilotRewardDocument, baseOptions);
      }
export type DeliverPilotRewardMutationHookResult = ReturnType<typeof useDeliverPilotRewardMutation>;
export type DeliverPilotRewardMutationResult = Apollo.MutationResult<DeliverPilotRewardMutation>;
export type DeliverPilotRewardMutationOptions = Apollo.BaseMutationOptions<DeliverPilotRewardMutation, DeliverPilotRewardMutationVariables>;
export const RemovePilotTierDocument = gql`
    mutation RemovePilotTier($id: ID!) {
  removePilotTier(input: {id: $id}) {
    gameAccount {
      id
      pilotTier {
        ...PilotTier
      }
    }
    errors {
      ...Error
    }
  }
}
    ${PilotTierFragmentDoc}
${ErrorFragmentDoc}`;
export type RemovePilotTierMutationFn = Apollo.MutationFunction<RemovePilotTierMutation, RemovePilotTierMutationVariables>;

/**
 * __useRemovePilotTierMutation__
 *
 * To run a mutation, you first call `useRemovePilotTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePilotTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePilotTierMutation, { data, loading, error }] = useRemovePilotTierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePilotTierMutation(baseOptions?: Apollo.MutationHookOptions<RemovePilotTierMutation, RemovePilotTierMutationVariables>) {
        return Apollo.useMutation<RemovePilotTierMutation, RemovePilotTierMutationVariables>(RemovePilotTierDocument, baseOptions);
      }
export type RemovePilotTierMutationHookResult = ReturnType<typeof useRemovePilotTierMutation>;
export type RemovePilotTierMutationResult = Apollo.MutationResult<RemovePilotTierMutation>;
export type RemovePilotTierMutationOptions = Apollo.BaseMutationOptions<RemovePilotTierMutation, RemovePilotTierMutationVariables>;
export const UpdatePilotTierDocument = gql`
    mutation UpdatePilotTier($ids: [ID!]!, $pilotTierId: String!) {
  updatePilotTier(input: {ids: $ids, pilotTierId: $pilotTierId}) {
    gameAccounts {
      id
      pilotTier {
        ...PilotTier
      }
    }
    errors {
      ...Error
    }
  }
}
    ${PilotTierFragmentDoc}
${ErrorFragmentDoc}`;
export type UpdatePilotTierMutationFn = Apollo.MutationFunction<UpdatePilotTierMutation, UpdatePilotTierMutationVariables>;

/**
 * __useUpdatePilotTierMutation__
 *
 * To run a mutation, you first call `useUpdatePilotTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePilotTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePilotTierMutation, { data, loading, error }] = useUpdatePilotTierMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      pilotTierId: // value for 'pilotTierId'
 *   },
 * });
 */
export function useUpdatePilotTierMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePilotTierMutation, UpdatePilotTierMutationVariables>) {
        return Apollo.useMutation<UpdatePilotTierMutation, UpdatePilotTierMutationVariables>(UpdatePilotTierDocument, baseOptions);
      }
export type UpdatePilotTierMutationHookResult = ReturnType<typeof useUpdatePilotTierMutation>;
export type UpdatePilotTierMutationResult = Apollo.MutationResult<UpdatePilotTierMutation>;
export type UpdatePilotTierMutationOptions = Apollo.BaseMutationOptions<UpdatePilotTierMutation, UpdatePilotTierMutationVariables>;
export const ActivatePilotDocument = gql`
    mutation ActivatePilot($id: ID!) {
  activatePilot(input: {id: $id}) {
    pilot {
      id
      suspend
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ActivatePilotMutationFn = Apollo.MutationFunction<ActivatePilotMutation, ActivatePilotMutationVariables>;

/**
 * __useActivatePilotMutation__
 *
 * To run a mutation, you first call `useActivatePilotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivatePilotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activatePilotMutation, { data, loading, error }] = useActivatePilotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivatePilotMutation(baseOptions?: Apollo.MutationHookOptions<ActivatePilotMutation, ActivatePilotMutationVariables>) {
        return Apollo.useMutation<ActivatePilotMutation, ActivatePilotMutationVariables>(ActivatePilotDocument, baseOptions);
      }
export type ActivatePilotMutationHookResult = ReturnType<typeof useActivatePilotMutation>;
export type ActivatePilotMutationResult = Apollo.MutationResult<ActivatePilotMutation>;
export type ActivatePilotMutationOptions = Apollo.BaseMutationOptions<ActivatePilotMutation, ActivatePilotMutationVariables>;
export const ApproveBankInfoDocument = gql`
    mutation ApproveBankInfo($pilotId: ID!) {
  approveBankInfo(input: {pilotId: $pilotId}) {
    bankInfo {
      ...BankInfo
    }
    errors {
      ...Error
    }
  }
}
    ${BankInfoFragmentDoc}
${ErrorFragmentDoc}`;
export type ApproveBankInfoMutationFn = Apollo.MutationFunction<ApproveBankInfoMutation, ApproveBankInfoMutationVariables>;

/**
 * __useApproveBankInfoMutation__
 *
 * To run a mutation, you first call `useApproveBankInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveBankInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveBankInfoMutation, { data, loading, error }] = useApproveBankInfoMutation({
 *   variables: {
 *      pilotId: // value for 'pilotId'
 *   },
 * });
 */
export function useApproveBankInfoMutation(baseOptions?: Apollo.MutationHookOptions<ApproveBankInfoMutation, ApproveBankInfoMutationVariables>) {
        return Apollo.useMutation<ApproveBankInfoMutation, ApproveBankInfoMutationVariables>(ApproveBankInfoDocument, baseOptions);
      }
export type ApproveBankInfoMutationHookResult = ReturnType<typeof useApproveBankInfoMutation>;
export type ApproveBankInfoMutationResult = Apollo.MutationResult<ApproveBankInfoMutation>;
export type ApproveBankInfoMutationOptions = Apollo.BaseMutationOptions<ApproveBankInfoMutation, ApproveBankInfoMutationVariables>;
export const ApproveScreenshotDocument = gql`
    mutation ApproveScreenshot($id: ID!) {
  approveScreenshot(input: {id: $id}) {
    gameAccount {
      id
      screenshotState
      codeState
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ApproveScreenshotMutationFn = Apollo.MutationFunction<ApproveScreenshotMutation, ApproveScreenshotMutationVariables>;

/**
 * __useApproveScreenshotMutation__
 *
 * To run a mutation, you first call `useApproveScreenshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveScreenshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveScreenshotMutation, { data, loading, error }] = useApproveScreenshotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveScreenshotMutation(baseOptions?: Apollo.MutationHookOptions<ApproveScreenshotMutation, ApproveScreenshotMutationVariables>) {
        return Apollo.useMutation<ApproveScreenshotMutation, ApproveScreenshotMutationVariables>(ApproveScreenshotDocument, baseOptions);
      }
export type ApproveScreenshotMutationHookResult = ReturnType<typeof useApproveScreenshotMutation>;
export type ApproveScreenshotMutationResult = Apollo.MutationResult<ApproveScreenshotMutation>;
export type ApproveScreenshotMutationOptions = Apollo.BaseMutationOptions<ApproveScreenshotMutation, ApproveScreenshotMutationVariables>;
export const ApproveSuspendRequestDocument = gql`
    mutation ApproveSuspendRequest($id: ID!) {
  approveSuspendRequest(input: {id: $id}) {
    suspendRequest {
      ...SuspendRequest
    }
    errors {
      ...Error
    }
  }
}
    ${SuspendRequestFragmentDoc}
${ErrorFragmentDoc}`;
export type ApproveSuspendRequestMutationFn = Apollo.MutationFunction<ApproveSuspendRequestMutation, ApproveSuspendRequestMutationVariables>;

/**
 * __useApproveSuspendRequestMutation__
 *
 * To run a mutation, you first call `useApproveSuspendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSuspendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSuspendRequestMutation, { data, loading, error }] = useApproveSuspendRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveSuspendRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveSuspendRequestMutation, ApproveSuspendRequestMutationVariables>) {
        return Apollo.useMutation<ApproveSuspendRequestMutation, ApproveSuspendRequestMutationVariables>(ApproveSuspendRequestDocument, baseOptions);
      }
export type ApproveSuspendRequestMutationHookResult = ReturnType<typeof useApproveSuspendRequestMutation>;
export type ApproveSuspendRequestMutationResult = Apollo.MutationResult<ApproveSuspendRequestMutation>;
export type ApproveSuspendRequestMutationOptions = Apollo.BaseMutationOptions<ApproveSuspendRequestMutation, ApproveSuspendRequestMutationVariables>;
export const ApproveVerificationCodeDocument = gql`
    mutation ApproveVerificationCode($id: ID!) {
  approveVerificationCode(input: {id: $id}) {
    gameAccount {
      id
      screenshotState
      codeState
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ApproveVerificationCodeMutationFn = Apollo.MutationFunction<ApproveVerificationCodeMutation, ApproveVerificationCodeMutationVariables>;

/**
 * __useApproveVerificationCodeMutation__
 *
 * To run a mutation, you first call `useApproveVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveVerificationCodeMutation, { data, loading, error }] = useApproveVerificationCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApproveVerificationCodeMutation, ApproveVerificationCodeMutationVariables>) {
        return Apollo.useMutation<ApproveVerificationCodeMutation, ApproveVerificationCodeMutationVariables>(ApproveVerificationCodeDocument, baseOptions);
      }
export type ApproveVerificationCodeMutationHookResult = ReturnType<typeof useApproveVerificationCodeMutation>;
export type ApproveVerificationCodeMutationResult = Apollo.MutationResult<ApproveVerificationCodeMutation>;
export type ApproveVerificationCodeMutationOptions = Apollo.BaseMutationOptions<ApproveVerificationCodeMutation, ApproveVerificationCodeMutationVariables>;
export const CreatePilotDocument = gql`
    mutation CreatePilot($phoneNumber: String, $nickname: String!, $email: String!, $birthday: ISO8601DateTime!, $accountId: String!, $gameId: ID!, $gameRankId: ID!, $rankTier: Int!, $rankStar: Int!, $rankPoint: Int!, $screenshots: [File!]!, $image1: File!, $image2: File!, $image3: File!, $name: String!, $bankName: String!, $bankAccountNo: String!, $country: String!, $ic: String!, $icImage: File!, $selfie: File!) {
  createPilot(input: {phoneNumber: $phoneNumber, nickname: $nickname, email: $email, birthday: $birthday, accountId: $accountId, gameId: $gameId, gameRankId: $gameRankId, rankTier: $rankTier, rankStar: $rankStar, rankPoint: $rankPoint, screenshots: $screenshots, image1: $image1, image2: $image2, image3: $image3, name: $name, bankName: $bankName, bankAccountNo: $bankAccountNo, country: $country, ic: $ic, icImage: $icImage, selfie: $selfie}) {
    pilot {
      ...Pilot
    }
    errors {
      path
      detail
    }
  }
}
    ${PilotFragmentDoc}`;
export type CreatePilotMutationFn = Apollo.MutationFunction<CreatePilotMutation, CreatePilotMutationVariables>;

/**
 * __useCreatePilotMutation__
 *
 * To run a mutation, you first call `useCreatePilotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePilotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPilotMutation, { data, loading, error }] = useCreatePilotMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      nickname: // value for 'nickname'
 *      email: // value for 'email'
 *      birthday: // value for 'birthday'
 *      accountId: // value for 'accountId'
 *      gameId: // value for 'gameId'
 *      gameRankId: // value for 'gameRankId'
 *      rankTier: // value for 'rankTier'
 *      rankStar: // value for 'rankStar'
 *      rankPoint: // value for 'rankPoint'
 *      screenshots: // value for 'screenshots'
 *      image1: // value for 'image1'
 *      image2: // value for 'image2'
 *      image3: // value for 'image3'
 *      name: // value for 'name'
 *      bankName: // value for 'bankName'
 *      bankAccountNo: // value for 'bankAccountNo'
 *      country: // value for 'country'
 *      ic: // value for 'ic'
 *      icImage: // value for 'icImage'
 *      selfie: // value for 'selfie'
 *   },
 * });
 */
export function useCreatePilotMutation(baseOptions?: Apollo.MutationHookOptions<CreatePilotMutation, CreatePilotMutationVariables>) {
        return Apollo.useMutation<CreatePilotMutation, CreatePilotMutationVariables>(CreatePilotDocument, baseOptions);
      }
export type CreatePilotMutationHookResult = ReturnType<typeof useCreatePilotMutation>;
export type CreatePilotMutationResult = Apollo.MutationResult<CreatePilotMutation>;
export type CreatePilotMutationOptions = Apollo.BaseMutationOptions<CreatePilotMutation, CreatePilotMutationVariables>;
export const CreateSuspendRequestDocument = gql`
    mutation CreateSuspendRequest($pilotId: ID!, $reason: String!) {
  createSuspendRequest(input: {pilotId: $pilotId, reason: $reason}) {
    suspendRequest {
      ...SuspendRequest
    }
    errors {
      ...Error
    }
  }
}
    ${SuspendRequestFragmentDoc}
${ErrorFragmentDoc}`;
export type CreateSuspendRequestMutationFn = Apollo.MutationFunction<CreateSuspendRequestMutation, CreateSuspendRequestMutationVariables>;

/**
 * __useCreateSuspendRequestMutation__
 *
 * To run a mutation, you first call `useCreateSuspendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuspendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuspendRequestMutation, { data, loading, error }] = useCreateSuspendRequestMutation({
 *   variables: {
 *      pilotId: // value for 'pilotId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateSuspendRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateSuspendRequestMutation, CreateSuspendRequestMutationVariables>) {
        return Apollo.useMutation<CreateSuspendRequestMutation, CreateSuspendRequestMutationVariables>(CreateSuspendRequestDocument, baseOptions);
      }
export type CreateSuspendRequestMutationHookResult = ReturnType<typeof useCreateSuspendRequestMutation>;
export type CreateSuspendRequestMutationResult = Apollo.MutationResult<CreateSuspendRequestMutation>;
export type CreateSuspendRequestMutationOptions = Apollo.BaseMutationOptions<CreateSuspendRequestMutation, CreateSuspendRequestMutationVariables>;
export const RejectBankInfoDocument = gql`
    mutation RejectBankInfo($pilotId: ID!, $rejectReason: String!) {
  rejectBankInfo(input: {pilotId: $pilotId, rejectReason: $rejectReason}) {
    bankInfo {
      ...BankInfo
    }
    errors {
      ...Error
    }
  }
}
    ${BankInfoFragmentDoc}
${ErrorFragmentDoc}`;
export type RejectBankInfoMutationFn = Apollo.MutationFunction<RejectBankInfoMutation, RejectBankInfoMutationVariables>;

/**
 * __useRejectBankInfoMutation__
 *
 * To run a mutation, you first call `useRejectBankInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectBankInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectBankInfoMutation, { data, loading, error }] = useRejectBankInfoMutation({
 *   variables: {
 *      pilotId: // value for 'pilotId'
 *      rejectReason: // value for 'rejectReason'
 *   },
 * });
 */
export function useRejectBankInfoMutation(baseOptions?: Apollo.MutationHookOptions<RejectBankInfoMutation, RejectBankInfoMutationVariables>) {
        return Apollo.useMutation<RejectBankInfoMutation, RejectBankInfoMutationVariables>(RejectBankInfoDocument, baseOptions);
      }
export type RejectBankInfoMutationHookResult = ReturnType<typeof useRejectBankInfoMutation>;
export type RejectBankInfoMutationResult = Apollo.MutationResult<RejectBankInfoMutation>;
export type RejectBankInfoMutationOptions = Apollo.BaseMutationOptions<RejectBankInfoMutation, RejectBankInfoMutationVariables>;
export const RejectScreenshotDocument = gql`
    mutation RejectScreenshot($id: ID!, $rejectReason: String!) {
  rejectScreenshot(input: {id: $id, rejectReason: $rejectReason}) {
    gameAccount {
      id
      screenshotState
      codeState
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RejectScreenshotMutationFn = Apollo.MutationFunction<RejectScreenshotMutation, RejectScreenshotMutationVariables>;

/**
 * __useRejectScreenshotMutation__
 *
 * To run a mutation, you first call `useRejectScreenshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectScreenshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectScreenshotMutation, { data, loading, error }] = useRejectScreenshotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rejectReason: // value for 'rejectReason'
 *   },
 * });
 */
export function useRejectScreenshotMutation(baseOptions?: Apollo.MutationHookOptions<RejectScreenshotMutation, RejectScreenshotMutationVariables>) {
        return Apollo.useMutation<RejectScreenshotMutation, RejectScreenshotMutationVariables>(RejectScreenshotDocument, baseOptions);
      }
export type RejectScreenshotMutationHookResult = ReturnType<typeof useRejectScreenshotMutation>;
export type RejectScreenshotMutationResult = Apollo.MutationResult<RejectScreenshotMutation>;
export type RejectScreenshotMutationOptions = Apollo.BaseMutationOptions<RejectScreenshotMutation, RejectScreenshotMutationVariables>;
export const RejectSuspendRequestDocument = gql`
    mutation RejectSuspendRequest($id: ID!) {
  rejectSuspendRequest(input: {id: $id}) {
    suspendRequest {
      ...SuspendRequest
    }
    errors {
      ...Error
    }
  }
}
    ${SuspendRequestFragmentDoc}
${ErrorFragmentDoc}`;
export type RejectSuspendRequestMutationFn = Apollo.MutationFunction<RejectSuspendRequestMutation, RejectSuspendRequestMutationVariables>;

/**
 * __useRejectSuspendRequestMutation__
 *
 * To run a mutation, you first call `useRejectSuspendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectSuspendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectSuspendRequestMutation, { data, loading, error }] = useRejectSuspendRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectSuspendRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectSuspendRequestMutation, RejectSuspendRequestMutationVariables>) {
        return Apollo.useMutation<RejectSuspendRequestMutation, RejectSuspendRequestMutationVariables>(RejectSuspendRequestDocument, baseOptions);
      }
export type RejectSuspendRequestMutationHookResult = ReturnType<typeof useRejectSuspendRequestMutation>;
export type RejectSuspendRequestMutationResult = Apollo.MutationResult<RejectSuspendRequestMutation>;
export type RejectSuspendRequestMutationOptions = Apollo.BaseMutationOptions<RejectSuspendRequestMutation, RejectSuspendRequestMutationVariables>;
export const RejectVerificationCodeDocument = gql`
    mutation RejectVerificationCode($id: ID!) {
  rejectVerificationCode(input: {id: $id}) {
    gameAccount {
      id
      screenshotState
      codeState
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RejectVerificationCodeMutationFn = Apollo.MutationFunction<RejectVerificationCodeMutation, RejectVerificationCodeMutationVariables>;

/**
 * __useRejectVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRejectVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectVerificationCodeMutation, { data, loading, error }] = useRejectVerificationCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<RejectVerificationCodeMutation, RejectVerificationCodeMutationVariables>) {
        return Apollo.useMutation<RejectVerificationCodeMutation, RejectVerificationCodeMutationVariables>(RejectVerificationCodeDocument, baseOptions);
      }
export type RejectVerificationCodeMutationHookResult = ReturnType<typeof useRejectVerificationCodeMutation>;
export type RejectVerificationCodeMutationResult = Apollo.MutationResult<RejectVerificationCodeMutation>;
export type RejectVerificationCodeMutationOptions = Apollo.BaseMutationOptions<RejectVerificationCodeMutation, RejectVerificationCodeMutationVariables>;
export const RemindPilotTestDocument = gql`
    mutation RemindPilotTest($gameAccountId: ID!) {
  remindPilotTest(input: {gameAccountId: $gameAccountId}) {
    gameAccount {
      id
      remindAt
      remindCount
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemindPilotTestMutationFn = Apollo.MutationFunction<RemindPilotTestMutation, RemindPilotTestMutationVariables>;

/**
 * __useRemindPilotTestMutation__
 *
 * To run a mutation, you first call `useRemindPilotTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemindPilotTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remindPilotTestMutation, { data, loading, error }] = useRemindPilotTestMutation({
 *   variables: {
 *      gameAccountId: // value for 'gameAccountId'
 *   },
 * });
 */
export function useRemindPilotTestMutation(baseOptions?: Apollo.MutationHookOptions<RemindPilotTestMutation, RemindPilotTestMutationVariables>) {
        return Apollo.useMutation<RemindPilotTestMutation, RemindPilotTestMutationVariables>(RemindPilotTestDocument, baseOptions);
      }
export type RemindPilotTestMutationHookResult = ReturnType<typeof useRemindPilotTestMutation>;
export type RemindPilotTestMutationResult = Apollo.MutationResult<RemindPilotTestMutation>;
export type RemindPilotTestMutationOptions = Apollo.BaseMutationOptions<RemindPilotTestMutation, RemindPilotTestMutationVariables>;
export const RemovePilotCreditTransactionDocument = gql`
    mutation RemovePilotCreditTransaction($creditTransactionId: ID!) {
  removePilotCreditTransaction(input: {creditTransactionId: $creditTransactionId}) {
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemovePilotCreditTransactionMutationFn = Apollo.MutationFunction<RemovePilotCreditTransactionMutation, RemovePilotCreditTransactionMutationVariables>;

/**
 * __useRemovePilotCreditTransactionMutation__
 *
 * To run a mutation, you first call `useRemovePilotCreditTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePilotCreditTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePilotCreditTransactionMutation, { data, loading, error }] = useRemovePilotCreditTransactionMutation({
 *   variables: {
 *      creditTransactionId: // value for 'creditTransactionId'
 *   },
 * });
 */
export function useRemovePilotCreditTransactionMutation(baseOptions?: Apollo.MutationHookOptions<RemovePilotCreditTransactionMutation, RemovePilotCreditTransactionMutationVariables>) {
        return Apollo.useMutation<RemovePilotCreditTransactionMutation, RemovePilotCreditTransactionMutationVariables>(RemovePilotCreditTransactionDocument, baseOptions);
      }
export type RemovePilotCreditTransactionMutationHookResult = ReturnType<typeof useRemovePilotCreditTransactionMutation>;
export type RemovePilotCreditTransactionMutationResult = Apollo.MutationResult<RemovePilotCreditTransactionMutation>;
export type RemovePilotCreditTransactionMutationOptions = Apollo.BaseMutationOptions<RemovePilotCreditTransactionMutation, RemovePilotCreditTransactionMutationVariables>;
export const UpdateTrainedPilotDocument = gql`
    mutation UpdateTrainedPilot($id: ID!) {
  updateTrainedPilot(input: {id: $id}) {
    pilot {
      id
      trained
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateTrainedPilotMutationFn = Apollo.MutationFunction<UpdateTrainedPilotMutation, UpdateTrainedPilotMutationVariables>;

/**
 * __useUpdateTrainedPilotMutation__
 *
 * To run a mutation, you first call `useUpdateTrainedPilotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainedPilotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainedPilotMutation, { data, loading, error }] = useUpdateTrainedPilotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTrainedPilotMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrainedPilotMutation, UpdateTrainedPilotMutationVariables>) {
        return Apollo.useMutation<UpdateTrainedPilotMutation, UpdateTrainedPilotMutationVariables>(UpdateTrainedPilotDocument, baseOptions);
      }
export type UpdateTrainedPilotMutationHookResult = ReturnType<typeof useUpdateTrainedPilotMutation>;
export type UpdateTrainedPilotMutationResult = Apollo.MutationResult<UpdateTrainedPilotMutation>;
export type UpdateTrainedPilotMutationOptions = Apollo.BaseMutationOptions<UpdateTrainedPilotMutation, UpdateTrainedPilotMutationVariables>;
export const CancelTeamWalletDocument = gql`
    mutation CancelTeamWallet($id: ID!, $combatTeamId: ID) {
  cancelTeamWallet(input: {id: $id, combatTeamId: $combatTeamId}) {
    poolPrize {
      ...PoolPrize
    }
    errors {
      path
      detail
    }
  }
}
    ${PoolPrizeFragmentDoc}`;
export type CancelTeamWalletMutationFn = Apollo.MutationFunction<CancelTeamWalletMutation, CancelTeamWalletMutationVariables>;

/**
 * __useCancelTeamWalletMutation__
 *
 * To run a mutation, you first call `useCancelTeamWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTeamWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTeamWalletMutation, { data, loading, error }] = useCancelTeamWalletMutation({
 *   variables: {
 *      id: // value for 'id'
 *      combatTeamId: // value for 'combatTeamId'
 *   },
 * });
 */
export function useCancelTeamWalletMutation(baseOptions?: Apollo.MutationHookOptions<CancelTeamWalletMutation, CancelTeamWalletMutationVariables>) {
        return Apollo.useMutation<CancelTeamWalletMutation, CancelTeamWalletMutationVariables>(CancelTeamWalletDocument, baseOptions);
      }
export type CancelTeamWalletMutationHookResult = ReturnType<typeof useCancelTeamWalletMutation>;
export type CancelTeamWalletMutationResult = Apollo.MutationResult<CancelTeamWalletMutation>;
export type CancelTeamWalletMutationOptions = Apollo.BaseMutationOptions<CancelTeamWalletMutation, CancelTeamWalletMutationVariables>;
export const UpdatePoolPrizeDocument = gql`
    mutation UpdatePoolPrize($id: ID!, $leaderboardId: ID, $name: String, $amount: Float, $ordering: Int) {
  updatePoolPrize(input: {id: $id, leaderboardId: $leaderboardId, name: $name, amount: $amount, ordering: $ordering}) {
    poolPrize {
      ...PoolPrize
    }
    errors {
      path
      detail
    }
  }
}
    ${PoolPrizeFragmentDoc}`;
export type UpdatePoolPrizeMutationFn = Apollo.MutationFunction<UpdatePoolPrizeMutation, UpdatePoolPrizeMutationVariables>;

/**
 * __useUpdatePoolPrizeMutation__
 *
 * To run a mutation, you first call `useUpdatePoolPrizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePoolPrizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePoolPrizeMutation, { data, loading, error }] = useUpdatePoolPrizeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      leaderboardId: // value for 'leaderboardId'
 *      name: // value for 'name'
 *      amount: // value for 'amount'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useUpdatePoolPrizeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePoolPrizeMutation, UpdatePoolPrizeMutationVariables>) {
        return Apollo.useMutation<UpdatePoolPrizeMutation, UpdatePoolPrizeMutationVariables>(UpdatePoolPrizeDocument, baseOptions);
      }
export type UpdatePoolPrizeMutationHookResult = ReturnType<typeof useUpdatePoolPrizeMutation>;
export type UpdatePoolPrizeMutationResult = Apollo.MutationResult<UpdatePoolPrizeMutation>;
export type UpdatePoolPrizeMutationOptions = Apollo.BaseMutationOptions<UpdatePoolPrizeMutation, UpdatePoolPrizeMutationVariables>;
export const UpdateTeamWalletDocument = gql`
    mutation UpdateTeamWallet($id: ID!, $combatTeamId: ID) {
  updateTeamWallet(input: {id: $id, combatTeamId: $combatTeamId}) {
    poolPrize {
      ...PoolPrize
    }
    errors {
      path
      detail
    }
  }
}
    ${PoolPrizeFragmentDoc}`;
export type UpdateTeamWalletMutationFn = Apollo.MutationFunction<UpdateTeamWalletMutation, UpdateTeamWalletMutationVariables>;

/**
 * __useUpdateTeamWalletMutation__
 *
 * To run a mutation, you first call `useUpdateTeamWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamWalletMutation, { data, loading, error }] = useUpdateTeamWalletMutation({
 *   variables: {
 *      id: // value for 'id'
 *      combatTeamId: // value for 'combatTeamId'
 *   },
 * });
 */
export function useUpdateTeamWalletMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamWalletMutation, UpdateTeamWalletMutationVariables>) {
        return Apollo.useMutation<UpdateTeamWalletMutation, UpdateTeamWalletMutationVariables>(UpdateTeamWalletDocument, baseOptions);
      }
export type UpdateTeamWalletMutationHookResult = ReturnType<typeof useUpdateTeamWalletMutation>;
export type UpdateTeamWalletMutationResult = Apollo.MutationResult<UpdateTeamWalletMutation>;
export type UpdateTeamWalletMutationOptions = Apollo.BaseMutationOptions<UpdateTeamWalletMutation, UpdateTeamWalletMutationVariables>;
export const CreatePostDocument = gql`
    mutation CreatePost($url: String, $isYoutube: Boolean, $active: Boolean, $title: String, $text: String, $postType: PostType, $newsImage: File) {
  createPost(input: {url: $url, isYoutube: $isYoutube, active: $active, title: $title, text: $text, postType: $postType, newsImage: $newsImage}) {
    post {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      url: // value for 'url'
 *      isYoutube: // value for 'isYoutube'
 *      active: // value for 'active'
 *      title: // value for 'title'
 *      text: // value for 'text'
 *      postType: // value for 'postType'
 *      newsImage: // value for 'newsImage'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, baseOptions);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const UpdatePostDocument = gql`
    mutation UpdatePost($id: ID!, $url: String, $isYoutube: Boolean, $active: Boolean, $title: String, $text: String, $postType: PostType, $newsImage: File) {
  updatePost(input: {id: $id, url: $url, isYoutube: $isYoutube, active: $active, title: $title, text: $text, postType: $postType, newsImage: $newsImage}) {
    post {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      url: // value for 'url'
 *      isYoutube: // value for 'isYoutube'
 *      active: // value for 'active'
 *      title: // value for 'title'
 *      text: // value for 'text'
 *      postType: // value for 'postType'
 *      newsImage: // value for 'newsImage'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, baseOptions);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const CreatePrizeDocument = gql`
    mutation CreatePrize($value: Float!, $prizeName: String!, $prizeType: PrizeType!, $icon: File!, $position: Int!, $active: Boolean!, $limit: Int) {
  createPrize(input: {value: $value, prizeName: $prizeName, prizeType: $prizeType, icon: $icon, position: $position, active: $active, limit: $limit}) {
    prize {
      id
      prizeName
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreatePrizeMutationFn = Apollo.MutationFunction<CreatePrizeMutation, CreatePrizeMutationVariables>;

/**
 * __useCreatePrizeMutation__
 *
 * To run a mutation, you first call `useCreatePrizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrizeMutation, { data, loading, error }] = useCreatePrizeMutation({
 *   variables: {
 *      value: // value for 'value'
 *      prizeName: // value for 'prizeName'
 *      prizeType: // value for 'prizeType'
 *      icon: // value for 'icon'
 *      position: // value for 'position'
 *      active: // value for 'active'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCreatePrizeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrizeMutation, CreatePrizeMutationVariables>) {
        return Apollo.useMutation<CreatePrizeMutation, CreatePrizeMutationVariables>(CreatePrizeDocument, baseOptions);
      }
export type CreatePrizeMutationHookResult = ReturnType<typeof useCreatePrizeMutation>;
export type CreatePrizeMutationResult = Apollo.MutationResult<CreatePrizeMutation>;
export type CreatePrizeMutationOptions = Apollo.BaseMutationOptions<CreatePrizeMutation, CreatePrizeMutationVariables>;
export const UpdatePrizeDocument = gql`
    mutation UpdatePrize($id: ID!, $value: Float, $prizeName: String, $prizeType: PrizeType, $icon: File, $position: Int, $active: Boolean, $limit: Int) {
  updatePrize(input: {id: $id, value: $value, prizeName: $prizeName, prizeType: $prizeType, icon: $icon, position: $position, active: $active, limit: $limit}) {
    prize {
      id
      prizeName
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdatePrizeMutationFn = Apollo.MutationFunction<UpdatePrizeMutation, UpdatePrizeMutationVariables>;

/**
 * __useUpdatePrizeMutation__
 *
 * To run a mutation, you first call `useUpdatePrizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrizeMutation, { data, loading, error }] = useUpdatePrizeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      prizeName: // value for 'prizeName'
 *      prizeType: // value for 'prizeType'
 *      icon: // value for 'icon'
 *      position: // value for 'position'
 *      active: // value for 'active'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUpdatePrizeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrizeMutation, UpdatePrizeMutationVariables>) {
        return Apollo.useMutation<UpdatePrizeMutation, UpdatePrizeMutationVariables>(UpdatePrizeDocument, baseOptions);
      }
export type UpdatePrizeMutationHookResult = ReturnType<typeof useUpdatePrizeMutation>;
export type UpdatePrizeMutationResult = Apollo.MutationResult<UpdatePrizeMutation>;
export type UpdatePrizeMutationOptions = Apollo.BaseMutationOptions<UpdatePrizeMutation, UpdatePrizeMutationVariables>;
export const CreateProficientRoleDocument = gql`
    mutation CreateProficientRole($name: String!, $kind: ProficientRoleKind!, $image: File!, $selectedImage: File!, $gameId: ID!) {
  createProficientRole(input: {name: $name, kind: $kind, image: $image, selectedImage: $selectedImage, gameId: $gameId}) {
    proficientRole {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateProficientRoleMutationFn = Apollo.MutationFunction<CreateProficientRoleMutation, CreateProficientRoleMutationVariables>;

/**
 * __useCreateProficientRoleMutation__
 *
 * To run a mutation, you first call `useCreateProficientRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProficientRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProficientRoleMutation, { data, loading, error }] = useCreateProficientRoleMutation({
 *   variables: {
 *      name: // value for 'name'
 *      kind: // value for 'kind'
 *      image: // value for 'image'
 *      selectedImage: // value for 'selectedImage'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useCreateProficientRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateProficientRoleMutation, CreateProficientRoleMutationVariables>) {
        return Apollo.useMutation<CreateProficientRoleMutation, CreateProficientRoleMutationVariables>(CreateProficientRoleDocument, baseOptions);
      }
export type CreateProficientRoleMutationHookResult = ReturnType<typeof useCreateProficientRoleMutation>;
export type CreateProficientRoleMutationResult = Apollo.MutationResult<CreateProficientRoleMutation>;
export type CreateProficientRoleMutationOptions = Apollo.BaseMutationOptions<CreateProficientRoleMutation, CreateProficientRoleMutationVariables>;
export const UpdateProficientRoleDocument = gql`
    mutation UpdateProficientRole($id: ID!, $name: String, $kind: ProficientRoleKind, $image: File, $selectedImage: File, $gameId: ID) {
  updateProficientRole(input: {id: $id, name: $name, kind: $kind, image: $image, selectedImage: $selectedImage, gameId: $gameId}) {
    proficientRole {
      id
      name
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateProficientRoleMutationFn = Apollo.MutationFunction<UpdateProficientRoleMutation, UpdateProficientRoleMutationVariables>;

/**
 * __useUpdateProficientRoleMutation__
 *
 * To run a mutation, you first call `useUpdateProficientRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProficientRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProficientRoleMutation, { data, loading, error }] = useUpdateProficientRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      kind: // value for 'kind'
 *      image: // value for 'image'
 *      selectedImage: // value for 'selectedImage'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useUpdateProficientRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProficientRoleMutation, UpdateProficientRoleMutationVariables>) {
        return Apollo.useMutation<UpdateProficientRoleMutation, UpdateProficientRoleMutationVariables>(UpdateProficientRoleDocument, baseOptions);
      }
export type UpdateProficientRoleMutationHookResult = ReturnType<typeof useUpdateProficientRoleMutation>;
export type UpdateProficientRoleMutationResult = Apollo.MutationResult<UpdateProficientRoleMutation>;
export type UpdateProficientRoleMutationOptions = Apollo.BaseMutationOptions<UpdateProficientRoleMutation, UpdateProficientRoleMutationVariables>;
export const CreatePromoCodeDocument = gql`
    mutation CreatePromoCode($active: Boolean!, $amount: Int!, $minimumAmount: Int!, $availableFor: String!, $code: String!, $validUntil: ISO8601DateTime, $gameType: String!, $redeemLimit: Int, $userIds: [String!], $prosOnly: Boolean, $whitelistsOnly: Boolean) {
  createPromoCode(input: {active: $active, amount: $amount, minimumAmount: $minimumAmount, availableFor: $availableFor, code: $code, validUntil: $validUntil, gameType: $gameType, redeemLimit: $redeemLimit, userIds: $userIds, prosOnly: $prosOnly, whitelistsOnly: $whitelistsOnly}) {
    promoCode {
      ...PromoCode
    }
    errors {
      path
      detail
    }
  }
}
    ${PromoCodeFragmentDoc}`;
export type CreatePromoCodeMutationFn = Apollo.MutationFunction<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;

/**
 * __useCreatePromoCodeMutation__
 *
 * To run a mutation, you first call `useCreatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoCodeMutation, { data, loading, error }] = useCreatePromoCodeMutation({
 *   variables: {
 *      active: // value for 'active'
 *      amount: // value for 'amount'
 *      minimumAmount: // value for 'minimumAmount'
 *      availableFor: // value for 'availableFor'
 *      code: // value for 'code'
 *      validUntil: // value for 'validUntil'
 *      gameType: // value for 'gameType'
 *      redeemLimit: // value for 'redeemLimit'
 *      userIds: // value for 'userIds'
 *      prosOnly: // value for 'prosOnly'
 *      whitelistsOnly: // value for 'whitelistsOnly'
 *   },
 * });
 */
export function useCreatePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>) {
        return Apollo.useMutation<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>(CreatePromoCodeDocument, baseOptions);
      }
export type CreatePromoCodeMutationHookResult = ReturnType<typeof useCreatePromoCodeMutation>;
export type CreatePromoCodeMutationResult = Apollo.MutationResult<CreatePromoCodeMutation>;
export type CreatePromoCodeMutationOptions = Apollo.BaseMutationOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;
export const UpdatePromoCodeDocument = gql`
    mutation UpdatePromoCode($id: ID!, $active: Boolean, $amount: Int, $minimumAmount: Int, $availableFor: String, $code: String, $validUntil: ISO8601DateTime, $gameType: String, $redeemLimit: Int, $userIds: [String!]) {
  updatePromoCode(input: {id: $id, active: $active, amount: $amount, minimumAmount: $minimumAmount, availableFor: $availableFor, code: $code, validUntil: $validUntil, gameType: $gameType, redeemLimit: $redeemLimit, userIds: $userIds}) {
    promoCode {
      id
      active
      amount
      minimumAmount
      code
      availableFor
      validUntil
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdatePromoCodeMutationFn = Apollo.MutationFunction<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>;

/**
 * __useUpdatePromoCodeMutation__
 *
 * To run a mutation, you first call `useUpdatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromoCodeMutation, { data, loading, error }] = useUpdatePromoCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *      amount: // value for 'amount'
 *      minimumAmount: // value for 'minimumAmount'
 *      availableFor: // value for 'availableFor'
 *      code: // value for 'code'
 *      validUntil: // value for 'validUntil'
 *      gameType: // value for 'gameType'
 *      redeemLimit: // value for 'redeemLimit'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useUpdatePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>) {
        return Apollo.useMutation<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>(UpdatePromoCodeDocument, baseOptions);
      }
export type UpdatePromoCodeMutationHookResult = ReturnType<typeof useUpdatePromoCodeMutation>;
export type UpdatePromoCodeMutationResult = Apollo.MutationResult<UpdatePromoCodeMutation>;
export type UpdatePromoCodeMutationOptions = Apollo.BaseMutationOptions<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>;
export const CreatePublisherDocument = gql`
    mutation CreatePublisher($name: String!, $description: String, $publisherLogo: File) {
  createPublisher(input: {name: $name, description: $description, publisherLogo: $publisherLogo}) {
    publisher {
      ...Publisher
    }
    errors {
      path
      detail
    }
  }
}
    ${PublisherFragmentDoc}`;
export type CreatePublisherMutationFn = Apollo.MutationFunction<CreatePublisherMutation, CreatePublisherMutationVariables>;

/**
 * __useCreatePublisherMutation__
 *
 * To run a mutation, you first call `useCreatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherMutation, { data, loading, error }] = useCreatePublisherMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      publisherLogo: // value for 'publisherLogo'
 *   },
 * });
 */
export function useCreatePublisherMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublisherMutation, CreatePublisherMutationVariables>) {
        return Apollo.useMutation<CreatePublisherMutation, CreatePublisherMutationVariables>(CreatePublisherDocument, baseOptions);
      }
export type CreatePublisherMutationHookResult = ReturnType<typeof useCreatePublisherMutation>;
export type CreatePublisherMutationResult = Apollo.MutationResult<CreatePublisherMutation>;
export type CreatePublisherMutationOptions = Apollo.BaseMutationOptions<CreatePublisherMutation, CreatePublisherMutationVariables>;
export const UpdatePublisherDocument = gql`
    mutation UpdatePublisher($id: ID!, $name: String, $description: String) {
  updatePublisher(input: {id: $id, name: $name, description: $description}) {
    publisher {
      ...Publisher
    }
    errors {
      path
      detail
    }
  }
}
    ${PublisherFragmentDoc}`;
export type UpdatePublisherMutationFn = Apollo.MutationFunction<UpdatePublisherMutation, UpdatePublisherMutationVariables>;

/**
 * __useUpdatePublisherMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherMutation, { data, loading, error }] = useUpdatePublisherMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdatePublisherMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublisherMutation, UpdatePublisherMutationVariables>) {
        return Apollo.useMutation<UpdatePublisherMutation, UpdatePublisherMutationVariables>(UpdatePublisherDocument, baseOptions);
      }
export type UpdatePublisherMutationHookResult = ReturnType<typeof useUpdatePublisherMutation>;
export type UpdatePublisherMutationResult = Apollo.MutationResult<UpdatePublisherMutation>;
export type UpdatePublisherMutationOptions = Apollo.BaseMutationOptions<UpdatePublisherMutation, UpdatePublisherMutationVariables>;
export const ApproveOrRejectReportRequestDocument = gql`
    mutation ApproveOrRejectReportRequest($id: ID!, $isApproved: Boolean!) {
  approveOrRejectReportRequest(input: {id: $id, isApproved: $isApproved}) {
    reportRequests {
      id
      reason
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type ApproveOrRejectReportRequestMutationFn = Apollo.MutationFunction<ApproveOrRejectReportRequestMutation, ApproveOrRejectReportRequestMutationVariables>;

/**
 * __useApproveOrRejectReportRequestMutation__
 *
 * To run a mutation, you first call `useApproveOrRejectReportRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveOrRejectReportRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveOrRejectReportRequestMutation, { data, loading, error }] = useApproveOrRejectReportRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isApproved: // value for 'isApproved'
 *   },
 * });
 */
export function useApproveOrRejectReportRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveOrRejectReportRequestMutation, ApproveOrRejectReportRequestMutationVariables>) {
        return Apollo.useMutation<ApproveOrRejectReportRequestMutation, ApproveOrRejectReportRequestMutationVariables>(ApproveOrRejectReportRequestDocument, baseOptions);
      }
export type ApproveOrRejectReportRequestMutationHookResult = ReturnType<typeof useApproveOrRejectReportRequestMutation>;
export type ApproveOrRejectReportRequestMutationResult = Apollo.MutationResult<ApproveOrRejectReportRequestMutation>;
export type ApproveOrRejectReportRequestMutationOptions = Apollo.BaseMutationOptions<ApproveOrRejectReportRequestMutation, ApproveOrRejectReportRequestMutationVariables>;
export const CheckGamerBannerDocument = gql`
    mutation CheckGamerBanner($title: String!) {
  checkGamerBanner(input: {title: $title}) {
    gamerBanners {
      id
      url
      title
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CheckGamerBannerMutationFn = Apollo.MutationFunction<CheckGamerBannerMutation, CheckGamerBannerMutationVariables>;

/**
 * __useCheckGamerBannerMutation__
 *
 * To run a mutation, you first call `useCheckGamerBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckGamerBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkGamerBannerMutation, { data, loading, error }] = useCheckGamerBannerMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCheckGamerBannerMutation(baseOptions?: Apollo.MutationHookOptions<CheckGamerBannerMutation, CheckGamerBannerMutationVariables>) {
        return Apollo.useMutation<CheckGamerBannerMutation, CheckGamerBannerMutationVariables>(CheckGamerBannerDocument, baseOptions);
      }
export type CheckGamerBannerMutationHookResult = ReturnType<typeof useCheckGamerBannerMutation>;
export type CheckGamerBannerMutationResult = Apollo.MutationResult<CheckGamerBannerMutation>;
export type CheckGamerBannerMutationOptions = Apollo.BaseMutationOptions<CheckGamerBannerMutation, CheckGamerBannerMutationVariables>;
export const CreateSponsorAdDocument = gql`
    mutation CreateSponsorAd($adsName: String!, $sponsorName: String!, $url: String!, $assetType: SponsorAdAsset, $layoutType: SponsorAdLayout, $image: File, $video: File, $active: Boolean, $gamerBannerId: ID, $position: Int, $screenRoute: String) {
  createSponsorAd(input: {adsName: $adsName, sponsorName: $sponsorName, url: $url, assetType: $assetType, layoutType: $layoutType, image: $image, video: $video, active: $active, gamerBannerId: $gamerBannerId, position: $position, screenRoute: $screenRoute}) {
    sponsorAd {
      id
      adsName
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type CreateSponsorAdMutationFn = Apollo.MutationFunction<CreateSponsorAdMutation, CreateSponsorAdMutationVariables>;

/**
 * __useCreateSponsorAdMutation__
 *
 * To run a mutation, you first call `useCreateSponsorAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSponsorAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSponsorAdMutation, { data, loading, error }] = useCreateSponsorAdMutation({
 *   variables: {
 *      adsName: // value for 'adsName'
 *      sponsorName: // value for 'sponsorName'
 *      url: // value for 'url'
 *      assetType: // value for 'assetType'
 *      layoutType: // value for 'layoutType'
 *      image: // value for 'image'
 *      video: // value for 'video'
 *      active: // value for 'active'
 *      gamerBannerId: // value for 'gamerBannerId'
 *      position: // value for 'position'
 *      screenRoute: // value for 'screenRoute'
 *   },
 * });
 */
export function useCreateSponsorAdMutation(baseOptions?: Apollo.MutationHookOptions<CreateSponsorAdMutation, CreateSponsorAdMutationVariables>) {
        return Apollo.useMutation<CreateSponsorAdMutation, CreateSponsorAdMutationVariables>(CreateSponsorAdDocument, baseOptions);
      }
export type CreateSponsorAdMutationHookResult = ReturnType<typeof useCreateSponsorAdMutation>;
export type CreateSponsorAdMutationResult = Apollo.MutationResult<CreateSponsorAdMutation>;
export type CreateSponsorAdMutationOptions = Apollo.BaseMutationOptions<CreateSponsorAdMutation, CreateSponsorAdMutationVariables>;
export const UpdateSponsorAdDocument = gql`
    mutation UpdateSponsorAd($id: ID!, $adsName: String, $sponsorName: String, $url: String, $assetType: SponsorAdAsset, $layoutType: SponsorAdLayout, $image: File, $video: File, $active: Boolean, $gamerBannerId: ID, $position: Int, $screenRoute: String) {
  updateSponsorAd(input: {id: $id, adsName: $adsName, sponsorName: $sponsorName, url: $url, assetType: $assetType, layoutType: $layoutType, image: $image, video: $video, active: $active, gamerBannerId: $gamerBannerId, position: $position, screenRoute: $screenRoute}) {
    sponsorAd {
      id
      adsName
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateSponsorAdMutationFn = Apollo.MutationFunction<UpdateSponsorAdMutation, UpdateSponsorAdMutationVariables>;

/**
 * __useUpdateSponsorAdMutation__
 *
 * To run a mutation, you first call `useUpdateSponsorAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSponsorAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSponsorAdMutation, { data, loading, error }] = useUpdateSponsorAdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      adsName: // value for 'adsName'
 *      sponsorName: // value for 'sponsorName'
 *      url: // value for 'url'
 *      assetType: // value for 'assetType'
 *      layoutType: // value for 'layoutType'
 *      image: // value for 'image'
 *      video: // value for 'video'
 *      active: // value for 'active'
 *      gamerBannerId: // value for 'gamerBannerId'
 *      position: // value for 'position'
 *      screenRoute: // value for 'screenRoute'
 *   },
 * });
 */
export function useUpdateSponsorAdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSponsorAdMutation, UpdateSponsorAdMutationVariables>) {
        return Apollo.useMutation<UpdateSponsorAdMutation, UpdateSponsorAdMutationVariables>(UpdateSponsorAdDocument, baseOptions);
      }
export type UpdateSponsorAdMutationHookResult = ReturnType<typeof useUpdateSponsorAdMutation>;
export type UpdateSponsorAdMutationResult = Apollo.MutationResult<UpdateSponsorAdMutation>;
export type UpdateSponsorAdMutationOptions = Apollo.BaseMutationOptions<UpdateSponsorAdMutation, UpdateSponsorAdMutationVariables>;
export const ApproveTeamPayoutTransactionDocument = gql`
    mutation ApproveTeamPayoutTransaction($id: ID!) {
  approveTeamPayoutTransaction(input: {id: $id}) {
    teamPayout {
      ...TeamPayout
    }
    errors {
      ...Error
    }
  }
}
    ${TeamPayoutFragmentDoc}
${ErrorFragmentDoc}`;
export type ApproveTeamPayoutTransactionMutationFn = Apollo.MutationFunction<ApproveTeamPayoutTransactionMutation, ApproveTeamPayoutTransactionMutationVariables>;

/**
 * __useApproveTeamPayoutTransactionMutation__
 *
 * To run a mutation, you first call `useApproveTeamPayoutTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTeamPayoutTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTeamPayoutTransactionMutation, { data, loading, error }] = useApproveTeamPayoutTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveTeamPayoutTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ApproveTeamPayoutTransactionMutation, ApproveTeamPayoutTransactionMutationVariables>) {
        return Apollo.useMutation<ApproveTeamPayoutTransactionMutation, ApproveTeamPayoutTransactionMutationVariables>(ApproveTeamPayoutTransactionDocument, baseOptions);
      }
export type ApproveTeamPayoutTransactionMutationHookResult = ReturnType<typeof useApproveTeamPayoutTransactionMutation>;
export type ApproveTeamPayoutTransactionMutationResult = Apollo.MutationResult<ApproveTeamPayoutTransactionMutation>;
export type ApproveTeamPayoutTransactionMutationOptions = Apollo.BaseMutationOptions<ApproveTeamPayoutTransactionMutation, ApproveTeamPayoutTransactionMutationVariables>;
export const CompleteTeamPayoutTransactionDocument = gql`
    mutation CompleteTeamPayoutTransaction($id: ID!) {
  completeTeamPayoutTransaction(input: {id: $id}) {
    teamPayout {
      ...TeamPayout
    }
    errors {
      ...Error
    }
  }
}
    ${TeamPayoutFragmentDoc}
${ErrorFragmentDoc}`;
export type CompleteTeamPayoutTransactionMutationFn = Apollo.MutationFunction<CompleteTeamPayoutTransactionMutation, CompleteTeamPayoutTransactionMutationVariables>;

/**
 * __useCompleteTeamPayoutTransactionMutation__
 *
 * To run a mutation, you first call `useCompleteTeamPayoutTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTeamPayoutTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTeamPayoutTransactionMutation, { data, loading, error }] = useCompleteTeamPayoutTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteTeamPayoutTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CompleteTeamPayoutTransactionMutation, CompleteTeamPayoutTransactionMutationVariables>) {
        return Apollo.useMutation<CompleteTeamPayoutTransactionMutation, CompleteTeamPayoutTransactionMutationVariables>(CompleteTeamPayoutTransactionDocument, baseOptions);
      }
export type CompleteTeamPayoutTransactionMutationHookResult = ReturnType<typeof useCompleteTeamPayoutTransactionMutation>;
export type CompleteTeamPayoutTransactionMutationResult = Apollo.MutationResult<CompleteTeamPayoutTransactionMutation>;
export type CompleteTeamPayoutTransactionMutationOptions = Apollo.BaseMutationOptions<CompleteTeamPayoutTransactionMutation, CompleteTeamPayoutTransactionMutationVariables>;
export const FailedTeamPayoutTransactionDocument = gql`
    mutation FailedTeamPayoutTransaction($id: ID!) {
  failedTeamPayoutTransaction(input: {id: $id}) {
    teamPayout {
      ...TeamPayout
    }
    errors {
      ...Error
    }
  }
}
    ${TeamPayoutFragmentDoc}
${ErrorFragmentDoc}`;
export type FailedTeamPayoutTransactionMutationFn = Apollo.MutationFunction<FailedTeamPayoutTransactionMutation, FailedTeamPayoutTransactionMutationVariables>;

/**
 * __useFailedTeamPayoutTransactionMutation__
 *
 * To run a mutation, you first call `useFailedTeamPayoutTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFailedTeamPayoutTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [failedTeamPayoutTransactionMutation, { data, loading, error }] = useFailedTeamPayoutTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFailedTeamPayoutTransactionMutation(baseOptions?: Apollo.MutationHookOptions<FailedTeamPayoutTransactionMutation, FailedTeamPayoutTransactionMutationVariables>) {
        return Apollo.useMutation<FailedTeamPayoutTransactionMutation, FailedTeamPayoutTransactionMutationVariables>(FailedTeamPayoutTransactionDocument, baseOptions);
      }
export type FailedTeamPayoutTransactionMutationHookResult = ReturnType<typeof useFailedTeamPayoutTransactionMutation>;
export type FailedTeamPayoutTransactionMutationResult = Apollo.MutationResult<FailedTeamPayoutTransactionMutation>;
export type FailedTeamPayoutTransactionMutationOptions = Apollo.BaseMutationOptions<FailedTeamPayoutTransactionMutation, FailedTeamPayoutTransactionMutationVariables>;
export const RejectTeamPayoutTransactionDocument = gql`
    mutation RejectTeamPayoutTransaction($id: ID!, $rejectReason: String!) {
  rejectTeamPayoutTransaction(input: {id: $id, rejectReason: $rejectReason}) {
    teamPayout {
      ...TeamPayout
    }
    errors {
      ...Error
    }
  }
}
    ${TeamPayoutFragmentDoc}
${ErrorFragmentDoc}`;
export type RejectTeamPayoutTransactionMutationFn = Apollo.MutationFunction<RejectTeamPayoutTransactionMutation, RejectTeamPayoutTransactionMutationVariables>;

/**
 * __useRejectTeamPayoutTransactionMutation__
 *
 * To run a mutation, you first call `useRejectTeamPayoutTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTeamPayoutTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTeamPayoutTransactionMutation, { data, loading, error }] = useRejectTeamPayoutTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rejectReason: // value for 'rejectReason'
 *   },
 * });
 */
export function useRejectTeamPayoutTransactionMutation(baseOptions?: Apollo.MutationHookOptions<RejectTeamPayoutTransactionMutation, RejectTeamPayoutTransactionMutationVariables>) {
        return Apollo.useMutation<RejectTeamPayoutTransactionMutation, RejectTeamPayoutTransactionMutationVariables>(RejectTeamPayoutTransactionDocument, baseOptions);
      }
export type RejectTeamPayoutTransactionMutationHookResult = ReturnType<typeof useRejectTeamPayoutTransactionMutation>;
export type RejectTeamPayoutTransactionMutationResult = Apollo.MutationResult<RejectTeamPayoutTransactionMutation>;
export type RejectTeamPayoutTransactionMutationOptions = Apollo.BaseMutationOptions<RejectTeamPayoutTransactionMutation, RejectTeamPayoutTransactionMutationVariables>;
export const UpdateAdminNoteTeamPayoutDocument = gql`
    mutation UpdateAdminNoteTeamPayout($id: ID!, $adminNote: String!) {
  updateAdminNoteTeamPayout(input: {id: $id, adminNote: $adminNote}) {
    teamPayout {
      id
      adminNote
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateAdminNoteTeamPayoutMutationFn = Apollo.MutationFunction<UpdateAdminNoteTeamPayoutMutation, UpdateAdminNoteTeamPayoutMutationVariables>;

/**
 * __useUpdateAdminNoteTeamPayoutMutation__
 *
 * To run a mutation, you first call `useUpdateAdminNoteTeamPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminNoteTeamPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminNoteTeamPayoutMutation, { data, loading, error }] = useUpdateAdminNoteTeamPayoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      adminNote: // value for 'adminNote'
 *   },
 * });
 */
export function useUpdateAdminNoteTeamPayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminNoteTeamPayoutMutation, UpdateAdminNoteTeamPayoutMutationVariables>) {
        return Apollo.useMutation<UpdateAdminNoteTeamPayoutMutation, UpdateAdminNoteTeamPayoutMutationVariables>(UpdateAdminNoteTeamPayoutDocument, baseOptions);
      }
export type UpdateAdminNoteTeamPayoutMutationHookResult = ReturnType<typeof useUpdateAdminNoteTeamPayoutMutation>;
export type UpdateAdminNoteTeamPayoutMutationResult = Apollo.MutationResult<UpdateAdminNoteTeamPayoutMutation>;
export type UpdateAdminNoteTeamPayoutMutationOptions = Apollo.BaseMutationOptions<UpdateAdminNoteTeamPayoutMutation, UpdateAdminNoteTeamPayoutMutationVariables>;
export const AddMemberDocument = gql`
    mutation AddMember($userId: ID!, $teamId: ID!) {
  addMember(input: {userId: $userId, teamId: $teamId}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type AddMemberMutationFn = Apollo.MutationFunction<AddMemberMutation, AddMemberMutationVariables>;

/**
 * __useAddMemberMutation__
 *
 * To run a mutation, you first call `useAddMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberMutation, { data, loading, error }] = useAddMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAddMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberMutation, AddMemberMutationVariables>) {
        return Apollo.useMutation<AddMemberMutation, AddMemberMutationVariables>(AddMemberDocument, baseOptions);
      }
export type AddMemberMutationHookResult = ReturnType<typeof useAddMemberMutation>;
export type AddMemberMutationResult = Apollo.MutationResult<AddMemberMutation>;
export type AddMemberMutationOptions = Apollo.BaseMutationOptions<AddMemberMutation, AddMemberMutationVariables>;
export const CheckPilotDocument = gql`
    mutation CheckPilot($nickname: String!) {
  checkPilot(input: {nickname: $nickname}) {
    pilots {
      id
      nickname
      phoneNumber
      avatarUrl
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CheckPilotMutationFn = Apollo.MutationFunction<CheckPilotMutation, CheckPilotMutationVariables>;

/**
 * __useCheckPilotMutation__
 *
 * To run a mutation, you first call `useCheckPilotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPilotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPilotMutation, { data, loading, error }] = useCheckPilotMutation({
 *   variables: {
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useCheckPilotMutation(baseOptions?: Apollo.MutationHookOptions<CheckPilotMutation, CheckPilotMutationVariables>) {
        return Apollo.useMutation<CheckPilotMutation, CheckPilotMutationVariables>(CheckPilotDocument, baseOptions);
      }
export type CheckPilotMutationHookResult = ReturnType<typeof useCheckPilotMutation>;
export type CheckPilotMutationResult = Apollo.MutationResult<CheckPilotMutation>;
export type CheckPilotMutationOptions = Apollo.BaseMutationOptions<CheckPilotMutation, CheckPilotMutationVariables>;
export const CreateSuspendTeamDocument = gql`
    mutation CreateSuspendTeam($id: ID!, $reason: String!) {
  createSuspendTeam(input: {id: $id, reason: $reason}) {
    combatTeam {
      id
      suspended
      reason
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateSuspendTeamMutationFn = Apollo.MutationFunction<CreateSuspendTeamMutation, CreateSuspendTeamMutationVariables>;

/**
 * __useCreateSuspendTeamMutation__
 *
 * To run a mutation, you first call `useCreateSuspendTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuspendTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuspendTeamMutation, { data, loading, error }] = useCreateSuspendTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateSuspendTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateSuspendTeamMutation, CreateSuspendTeamMutationVariables>) {
        return Apollo.useMutation<CreateSuspendTeamMutation, CreateSuspendTeamMutationVariables>(CreateSuspendTeamDocument, baseOptions);
      }
export type CreateSuspendTeamMutationHookResult = ReturnType<typeof useCreateSuspendTeamMutation>;
export type CreateSuspendTeamMutationResult = Apollo.MutationResult<CreateSuspendTeamMutation>;
export type CreateSuspendTeamMutationOptions = Apollo.BaseMutationOptions<CreateSuspendTeamMutation, CreateSuspendTeamMutationVariables>;
export const CreateSuspendTeamAndMembersDocument = gql`
    mutation CreateSuspendTeamAndMembers($id: ID!, $reason: String!) {
  createSuspendTeamAndMembers(input: {id: $id, reason: $reason}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateSuspendTeamAndMembersMutationFn = Apollo.MutationFunction<CreateSuspendTeamAndMembersMutation, CreateSuspendTeamAndMembersMutationVariables>;

/**
 * __useCreateSuspendTeamAndMembersMutation__
 *
 * To run a mutation, you first call `useCreateSuspendTeamAndMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuspendTeamAndMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuspendTeamAndMembersMutation, { data, loading, error }] = useCreateSuspendTeamAndMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateSuspendTeamAndMembersMutation(baseOptions?: Apollo.MutationHookOptions<CreateSuspendTeamAndMembersMutation, CreateSuspendTeamAndMembersMutationVariables>) {
        return Apollo.useMutation<CreateSuspendTeamAndMembersMutation, CreateSuspendTeamAndMembersMutationVariables>(CreateSuspendTeamAndMembersDocument, baseOptions);
      }
export type CreateSuspendTeamAndMembersMutationHookResult = ReturnType<typeof useCreateSuspendTeamAndMembersMutation>;
export type CreateSuspendTeamAndMembersMutationResult = Apollo.MutationResult<CreateSuspendTeamAndMembersMutation>;
export type CreateSuspendTeamAndMembersMutationOptions = Apollo.BaseMutationOptions<CreateSuspendTeamAndMembersMutation, CreateSuspendTeamAndMembersMutationVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($name: String!, $icon: File!, $themeColor: String!, $tier: ClubTier!, $description: String, $userId: ID!) {
  createTeam(input: {name: $name, icon: $icon, themeColor: $themeColor, tier: $tier, description: $description, userId: $userId}) {
    team {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *      themeColor: // value for 'themeColor'
 *      tier: // value for 'tier'
 *      description: // value for 'description'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, baseOptions);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const CreateClubDocument = gql`
    mutation CreateClub($name: String!, $icon: File!, $themeColor: String!, $tier: ClubTier!, $description: String, $userId: ID!) {
  createClub(input: {name: $name, icon: $icon, themeColor: $themeColor, tier: $tier, description: $description, userId: $userId}) {
    club {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateClubMutationFn = Apollo.MutationFunction<CreateClubMutation, CreateClubMutationVariables>;

/**
 * __useCreateClubMutation__
 *
 * To run a mutation, you first call `useCreateClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClubMutation, { data, loading, error }] = useCreateClubMutation({
 *   variables: {
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *      themeColor: // value for 'themeColor'
 *      tier: // value for 'tier'
 *      description: // value for 'description'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateClubMutation(baseOptions?: Apollo.MutationHookOptions<CreateClubMutation, CreateClubMutationVariables>) {
        return Apollo.useMutation<CreateClubMutation, CreateClubMutationVariables>(CreateClubDocument, baseOptions);
      }
export type CreateClubMutationHookResult = ReturnType<typeof useCreateClubMutation>;
export type CreateClubMutationResult = Apollo.MutationResult<CreateClubMutation>;
export type CreateClubMutationOptions = Apollo.BaseMutationOptions<CreateClubMutation, CreateClubMutationVariables>;
export const RemoveTeamMemberDocument = gql`
    mutation RemoveTeamMember($userId: ID!, $teamId: ID!) {
  removeTeamMember(input: {userId: $userId, teamId: $teamId}) {
    team {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveTeamMemberMutationFn = Apollo.MutationFunction<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;

/**
 * __useRemoveTeamMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMemberMutation, { data, loading, error }] = useRemoveTeamMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useRemoveTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>) {
        return Apollo.useMutation<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument, baseOptions);
      }
export type RemoveTeamMemberMutationHookResult = ReturnType<typeof useRemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationResult = Apollo.MutationResult<RemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationOptions = Apollo.BaseMutationOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;
export const RevokeSuspendCurrentCombatTeamDocument = gql`
    mutation RevokeSuspendCurrentCombatTeam($id: ID!) {
  revokeSuspendCurrentCombatTeam(input: {id: $id}) {
    combatTeam {
      id
      suspended
      reason
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RevokeSuspendCurrentCombatTeamMutationFn = Apollo.MutationFunction<RevokeSuspendCurrentCombatTeamMutation, RevokeSuspendCurrentCombatTeamMutationVariables>;

/**
 * __useRevokeSuspendCurrentCombatTeamMutation__
 *
 * To run a mutation, you first call `useRevokeSuspendCurrentCombatTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeSuspendCurrentCombatTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeSuspendCurrentCombatTeamMutation, { data, loading, error }] = useRevokeSuspendCurrentCombatTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeSuspendCurrentCombatTeamMutation(baseOptions?: Apollo.MutationHookOptions<RevokeSuspendCurrentCombatTeamMutation, RevokeSuspendCurrentCombatTeamMutationVariables>) {
        return Apollo.useMutation<RevokeSuspendCurrentCombatTeamMutation, RevokeSuspendCurrentCombatTeamMutationVariables>(RevokeSuspendCurrentCombatTeamDocument, baseOptions);
      }
export type RevokeSuspendCurrentCombatTeamMutationHookResult = ReturnType<typeof useRevokeSuspendCurrentCombatTeamMutation>;
export type RevokeSuspendCurrentCombatTeamMutationResult = Apollo.MutationResult<RevokeSuspendCurrentCombatTeamMutation>;
export type RevokeSuspendCurrentCombatTeamMutationOptions = Apollo.BaseMutationOptions<RevokeSuspendCurrentCombatTeamMutation, RevokeSuspendCurrentCombatTeamMutationVariables>;
export const RevokeTeamSuspensionDocument = gql`
    mutation RevokeTeamSuspension($id: ID!) {
  revokeTeamSuspension(input: {id: $id}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RevokeTeamSuspensionMutationFn = Apollo.MutationFunction<RevokeTeamSuspensionMutation, RevokeTeamSuspensionMutationVariables>;

/**
 * __useRevokeTeamSuspensionMutation__
 *
 * To run a mutation, you first call `useRevokeTeamSuspensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeTeamSuspensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeTeamSuspensionMutation, { data, loading, error }] = useRevokeTeamSuspensionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeTeamSuspensionMutation(baseOptions?: Apollo.MutationHookOptions<RevokeTeamSuspensionMutation, RevokeTeamSuspensionMutationVariables>) {
        return Apollo.useMutation<RevokeTeamSuspensionMutation, RevokeTeamSuspensionMutationVariables>(RevokeTeamSuspensionDocument, baseOptions);
      }
export type RevokeTeamSuspensionMutationHookResult = ReturnType<typeof useRevokeTeamSuspensionMutation>;
export type RevokeTeamSuspensionMutationResult = Apollo.MutationResult<RevokeTeamSuspensionMutation>;
export type RevokeTeamSuspensionMutationOptions = Apollo.BaseMutationOptions<RevokeTeamSuspensionMutation, RevokeTeamSuspensionMutationVariables>;
export const CreateTopUpProductDocument = gql`
    mutation CreateTopUpProduct($originId: ID!, $title: String!, $description: String, $vendorId: ID!, $vocaId: ID, $code: String, $countryCodes: [String!], $categoryId: ID!, $genre: GameGenre, $featured: Boolean, $ordering: Int, $publisherId: ID, $isActive: Boolean, $logo: File, $cover: File, $avatar: File, $helper: File) {
  createTopUpProduct(input: {originId: $originId, title: $title, description: $description, vendorId: $vendorId, vocaId: $vocaId, code: $code, countryCodes: $countryCodes, categoryId: $categoryId, genre: $genre, featured: $featured, ordering: $ordering, publisherId: $publisherId, isActive: $isActive, logo: $logo, cover: $cover, avatar: $avatar, helper: $helper}) {
    topUpProduct {
      ...TopUpProduct
    }
    errors {
      path
      detail
    }
  }
}
    ${TopUpProductFragmentDoc}`;
export type CreateTopUpProductMutationFn = Apollo.MutationFunction<CreateTopUpProductMutation, CreateTopUpProductMutationVariables>;

/**
 * __useCreateTopUpProductMutation__
 *
 * To run a mutation, you first call `useCreateTopUpProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopUpProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopUpProductMutation, { data, loading, error }] = useCreateTopUpProductMutation({
 *   variables: {
 *      originId: // value for 'originId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      vendorId: // value for 'vendorId'
 *      vocaId: // value for 'vocaId'
 *      code: // value for 'code'
 *      countryCodes: // value for 'countryCodes'
 *      categoryId: // value for 'categoryId'
 *      genre: // value for 'genre'
 *      featured: // value for 'featured'
 *      ordering: // value for 'ordering'
 *      publisherId: // value for 'publisherId'
 *      isActive: // value for 'isActive'
 *      logo: // value for 'logo'
 *      cover: // value for 'cover'
 *      avatar: // value for 'avatar'
 *      helper: // value for 'helper'
 *   },
 * });
 */
export function useCreateTopUpProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateTopUpProductMutation, CreateTopUpProductMutationVariables>) {
        return Apollo.useMutation<CreateTopUpProductMutation, CreateTopUpProductMutationVariables>(CreateTopUpProductDocument, baseOptions);
      }
export type CreateTopUpProductMutationHookResult = ReturnType<typeof useCreateTopUpProductMutation>;
export type CreateTopUpProductMutationResult = Apollo.MutationResult<CreateTopUpProductMutation>;
export type CreateTopUpProductMutationOptions = Apollo.BaseMutationOptions<CreateTopUpProductMutation, CreateTopUpProductMutationVariables>;
export const UpdateTopUpProductDocument = gql`
    mutation UpdateTopUpProduct($id: ID!, $originId: ID, $title: String, $description: String, $vendorId: ID, $vocaId: ID, $code: String, $countryCodes: [String!], $categoryId: ID, $genre: GameGenre, $featured: Boolean, $ordering: Int, $publisherId: ID, $isActive: Boolean, $logo: File, $cover: File, $avatar: File, $helper: File, $publisherLogo: File) {
  updateTopUpProduct(input: {id: $id, originId: $originId, title: $title, description: $description, vendorId: $vendorId, vocaId: $vocaId, code: $code, countryCodes: $countryCodes, categoryId: $categoryId, genre: $genre, featured: $featured, ordering: $ordering, publisherId: $publisherId, isActive: $isActive, logo: $logo, cover: $cover, avatar: $avatar, helper: $helper, publisherLogo: $publisherLogo}) {
    topUpProduct {
      ...TopUpProduct
    }
    errors {
      path
      detail
    }
  }
}
    ${TopUpProductFragmentDoc}`;
export type UpdateTopUpProductMutationFn = Apollo.MutationFunction<UpdateTopUpProductMutation, UpdateTopUpProductMutationVariables>;

/**
 * __useUpdateTopUpProductMutation__
 *
 * To run a mutation, you first call `useUpdateTopUpProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopUpProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopUpProductMutation, { data, loading, error }] = useUpdateTopUpProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      originId: // value for 'originId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      vendorId: // value for 'vendorId'
 *      vocaId: // value for 'vocaId'
 *      code: // value for 'code'
 *      countryCodes: // value for 'countryCodes'
 *      categoryId: // value for 'categoryId'
 *      genre: // value for 'genre'
 *      featured: // value for 'featured'
 *      ordering: // value for 'ordering'
 *      publisherId: // value for 'publisherId'
 *      isActive: // value for 'isActive'
 *      logo: // value for 'logo'
 *      cover: // value for 'cover'
 *      avatar: // value for 'avatar'
 *      helper: // value for 'helper'
 *      publisherLogo: // value for 'publisherLogo'
 *   },
 * });
 */
export function useUpdateTopUpProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopUpProductMutation, UpdateTopUpProductMutationVariables>) {
        return Apollo.useMutation<UpdateTopUpProductMutation, UpdateTopUpProductMutationVariables>(UpdateTopUpProductDocument, baseOptions);
      }
export type UpdateTopUpProductMutationHookResult = ReturnType<typeof useUpdateTopUpProductMutation>;
export type UpdateTopUpProductMutationResult = Apollo.MutationResult<UpdateTopUpProductMutation>;
export type UpdateTopUpProductMutationOptions = Apollo.BaseMutationOptions<UpdateTopUpProductMutation, UpdateTopUpProductMutationVariables>;
export const UpdateTournamentEventDocument = gql`
    mutation UpdateTournamentEvent($id: ID!, $name: String, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime, $startTime: String, $endTime: String, $isAdmin: Boolean) {
  updateTournamentEvent(input: {id: $id, name: $name, startDate: $startDate, endDate: $endDate, startTime: $startTime, endTime: $endTime, isAdmin: $isAdmin}) {
    tournamentEvent {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateTournamentEventMutationFn = Apollo.MutationFunction<UpdateTournamentEventMutation, UpdateTournamentEventMutationVariables>;

/**
 * __useUpdateTournamentEventMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentEventMutation, { data, loading, error }] = useUpdateTournamentEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useUpdateTournamentEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTournamentEventMutation, UpdateTournamentEventMutationVariables>) {
        return Apollo.useMutation<UpdateTournamentEventMutation, UpdateTournamentEventMutationVariables>(UpdateTournamentEventDocument, baseOptions);
      }
export type UpdateTournamentEventMutationHookResult = ReturnType<typeof useUpdateTournamentEventMutation>;
export type UpdateTournamentEventMutationResult = Apollo.MutationResult<UpdateTournamentEventMutation>;
export type UpdateTournamentEventMutationOptions = Apollo.BaseMutationOptions<UpdateTournamentEventMutation, UpdateTournamentEventMutationVariables>;
export const TournamentTeamDocument = gql`
    mutation TournamentTeam($id: ID!, $stateRequest: String!) {
  tournamentTeam(input: {id: $id, stateRequest: $stateRequest}) {
    tournamentTeam {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type TournamentTeamMutationFn = Apollo.MutationFunction<TournamentTeamMutation, TournamentTeamMutationVariables>;

/**
 * __useTournamentTeamMutation__
 *
 * To run a mutation, you first call `useTournamentTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tournamentTeamMutation, { data, loading, error }] = useTournamentTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stateRequest: // value for 'stateRequest'
 *   },
 * });
 */
export function useTournamentTeamMutation(baseOptions?: Apollo.MutationHookOptions<TournamentTeamMutation, TournamentTeamMutationVariables>) {
        return Apollo.useMutation<TournamentTeamMutation, TournamentTeamMutationVariables>(TournamentTeamDocument, baseOptions);
      }
export type TournamentTeamMutationHookResult = ReturnType<typeof useTournamentTeamMutation>;
export type TournamentTeamMutationResult = Apollo.MutationResult<TournamentTeamMutation>;
export type TournamentTeamMutationOptions = Apollo.BaseMutationOptions<TournamentTeamMutation, TournamentTeamMutationVariables>;
export const UpdateTournamentDocument = gql`
    mutation UpdateTournament($id: ID!, $name: String, $logo: File, $backgroundImage: File) {
  updateTournament(input: {id: $id, name: $name, logo: $logo, backgroundImage: $backgroundImage}) {
    tournament {
      id
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type UpdateTournamentMutationFn = Apollo.MutationFunction<UpdateTournamentMutation, UpdateTournamentMutationVariables>;

/**
 * __useUpdateTournamentMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMutation, { data, loading, error }] = useUpdateTournamentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      logo: // value for 'logo'
 *      backgroundImage: // value for 'backgroundImage'
 *   },
 * });
 */
export function useUpdateTournamentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTournamentMutation, UpdateTournamentMutationVariables>) {
        return Apollo.useMutation<UpdateTournamentMutation, UpdateTournamentMutationVariables>(UpdateTournamentDocument, baseOptions);
      }
export type UpdateTournamentMutationHookResult = ReturnType<typeof useUpdateTournamentMutation>;
export type UpdateTournamentMutationResult = Apollo.MutationResult<UpdateTournamentMutation>;
export type UpdateTournamentMutationOptions = Apollo.BaseMutationOptions<UpdateTournamentMutation, UpdateTournamentMutationVariables>;
export const BlastGamersDocument = gql`
    mutation BlastGamers($title: String!, $body: String!, $userIds: [ID!], $isScheduled: Boolean, $scheduleDate: ISO8601DateTime, $scheduleTime: String, $scheduleDatetime: ISO8601DateTime) {
  blastGamers(input: {title: $title, body: $body, userIds: $userIds, isScheduled: $isScheduled, scheduleDate: $scheduleDate, scheduleTime: $scheduleTime, scheduleDatetime: $scheduleDatetime}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type BlastGamersMutationFn = Apollo.MutationFunction<BlastGamersMutation, BlastGamersMutationVariables>;

/**
 * __useBlastGamersMutation__
 *
 * To run a mutation, you first call `useBlastGamersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlastGamersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blastGamersMutation, { data, loading, error }] = useBlastGamersMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      userIds: // value for 'userIds'
 *      isScheduled: // value for 'isScheduled'
 *      scheduleDate: // value for 'scheduleDate'
 *      scheduleTime: // value for 'scheduleTime'
 *      scheduleDatetime: // value for 'scheduleDatetime'
 *   },
 * });
 */
export function useBlastGamersMutation(baseOptions?: Apollo.MutationHookOptions<BlastGamersMutation, BlastGamersMutationVariables>) {
        return Apollo.useMutation<BlastGamersMutation, BlastGamersMutationVariables>(BlastGamersDocument, baseOptions);
      }
export type BlastGamersMutationHookResult = ReturnType<typeof useBlastGamersMutation>;
export type BlastGamersMutationResult = Apollo.MutationResult<BlastGamersMutation>;
export type BlastGamersMutationOptions = Apollo.BaseMutationOptions<BlastGamersMutation, BlastGamersMutationVariables>;
export const DeleteUserBlastScheduleDocument = gql`
    mutation DeleteUserBlastSchedule($id: ID!) {
  deleteUserBlastSchedule(input: {id: $id}) {
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteUserBlastScheduleMutationFn = Apollo.MutationFunction<DeleteUserBlastScheduleMutation, DeleteUserBlastScheduleMutationVariables>;

/**
 * __useDeleteUserBlastScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteUserBlastScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBlastScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBlastScheduleMutation, { data, loading, error }] = useDeleteUserBlastScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserBlastScheduleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserBlastScheduleMutation, DeleteUserBlastScheduleMutationVariables>) {
        return Apollo.useMutation<DeleteUserBlastScheduleMutation, DeleteUserBlastScheduleMutationVariables>(DeleteUserBlastScheduleDocument, baseOptions);
      }
export type DeleteUserBlastScheduleMutationHookResult = ReturnType<typeof useDeleteUserBlastScheduleMutation>;
export type DeleteUserBlastScheduleMutationResult = Apollo.MutationResult<DeleteUserBlastScheduleMutation>;
export type DeleteUserBlastScheduleMutationOptions = Apollo.BaseMutationOptions<DeleteUserBlastScheduleMutation, DeleteUserBlastScheduleMutationVariables>;
export const RescheduleBlastDocument = gql`
    mutation RescheduleBlast($ids: [ID!]!) {
  rescheduleBlast(input: {ids: $ids}) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RescheduleBlastMutationFn = Apollo.MutationFunction<RescheduleBlastMutation, RescheduleBlastMutationVariables>;

/**
 * __useRescheduleBlastMutation__
 *
 * To run a mutation, you first call `useRescheduleBlastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleBlastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleBlastMutation, { data, loading, error }] = useRescheduleBlastMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRescheduleBlastMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleBlastMutation, RescheduleBlastMutationVariables>) {
        return Apollo.useMutation<RescheduleBlastMutation, RescheduleBlastMutationVariables>(RescheduleBlastDocument, baseOptions);
      }
export type RescheduleBlastMutationHookResult = ReturnType<typeof useRescheduleBlastMutation>;
export type RescheduleBlastMutationResult = Apollo.MutationResult<RescheduleBlastMutation>;
export type RescheduleBlastMutationOptions = Apollo.BaseMutationOptions<RescheduleBlastMutation, RescheduleBlastMutationVariables>;
export const UpdateUserBlastScheduleDocument = gql`
    mutation UpdateUserBlastSchedule($id: ID!, $title: String!, $body: String!, $userIds: [ID!], $isScheduled: Boolean, $scheduleDate: ISO8601DateTime, $scheduleTime: String, $scheduleDatetime: ISO8601DateTime) {
  updateUserBlastSchedule(input: {id: $id, title: $title, body: $body, userIds: $userIds, isScheduled: $isScheduled, scheduleDate: $scheduleDate, scheduleTime: $scheduleTime, scheduleDatetime: $scheduleDatetime}) {
    userBlastSchedule {
      id
    }
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateUserBlastScheduleMutationFn = Apollo.MutationFunction<UpdateUserBlastScheduleMutation, UpdateUserBlastScheduleMutationVariables>;

/**
 * __useUpdateUserBlastScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateUserBlastScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBlastScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBlastScheduleMutation, { data, loading, error }] = useUpdateUserBlastScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      userIds: // value for 'userIds'
 *      isScheduled: // value for 'isScheduled'
 *      scheduleDate: // value for 'scheduleDate'
 *      scheduleTime: // value for 'scheduleTime'
 *      scheduleDatetime: // value for 'scheduleDatetime'
 *   },
 * });
 */
export function useUpdateUserBlastScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserBlastScheduleMutation, UpdateUserBlastScheduleMutationVariables>) {
        return Apollo.useMutation<UpdateUserBlastScheduleMutation, UpdateUserBlastScheduleMutationVariables>(UpdateUserBlastScheduleDocument, baseOptions);
      }
export type UpdateUserBlastScheduleMutationHookResult = ReturnType<typeof useUpdateUserBlastScheduleMutation>;
export type UpdateUserBlastScheduleMutationResult = Apollo.MutationResult<UpdateUserBlastScheduleMutation>;
export type UpdateUserBlastScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateUserBlastScheduleMutation, UpdateUserBlastScheduleMutationVariables>;
export const DeliverUserPrizeDocument = gql`
    mutation DeliverUserPrize($id: ID!) {
  deliverUserPrize(input: {id: $id}) {
    userPrize {
      id
      state
    }
    errors {
      path
      detail
    }
  }
}
    `;
export type DeliverUserPrizeMutationFn = Apollo.MutationFunction<DeliverUserPrizeMutation, DeliverUserPrizeMutationVariables>;

/**
 * __useDeliverUserPrizeMutation__
 *
 * To run a mutation, you first call `useDeliverUserPrizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliverUserPrizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliverUserPrizeMutation, { data, loading, error }] = useDeliverUserPrizeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeliverUserPrizeMutation(baseOptions?: Apollo.MutationHookOptions<DeliverUserPrizeMutation, DeliverUserPrizeMutationVariables>) {
        return Apollo.useMutation<DeliverUserPrizeMutation, DeliverUserPrizeMutationVariables>(DeliverUserPrizeDocument, baseOptions);
      }
export type DeliverUserPrizeMutationHookResult = ReturnType<typeof useDeliverUserPrizeMutation>;
export type DeliverUserPrizeMutationResult = Apollo.MutationResult<DeliverUserPrizeMutation>;
export type DeliverUserPrizeMutationOptions = Apollo.BaseMutationOptions<DeliverUserPrizeMutation, DeliverUserPrizeMutationVariables>;
export const ActivateUserDocument = gql`
    mutation ActivateUser($id: ID!) {
  activateUser(input: {id: $id}) {
    user {
      id
      suspend
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ActivateUserMutationFn = Apollo.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return Apollo.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const ApproveSuspendGamerRequestDocument = gql`
    mutation ApproveSuspendGamerRequest($id: ID!) {
  approveSuspendGamerRequest(input: {id: $id}) {
    suspendRequest {
      ...SuspendGamerRequest
    }
    errors {
      ...Error
    }
  }
}
    ${SuspendGamerRequestFragmentDoc}
${ErrorFragmentDoc}`;
export type ApproveSuspendGamerRequestMutationFn = Apollo.MutationFunction<ApproveSuspendGamerRequestMutation, ApproveSuspendGamerRequestMutationVariables>;

/**
 * __useApproveSuspendGamerRequestMutation__
 *
 * To run a mutation, you first call `useApproveSuspendGamerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSuspendGamerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSuspendGamerRequestMutation, { data, loading, error }] = useApproveSuspendGamerRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveSuspendGamerRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveSuspendGamerRequestMutation, ApproveSuspendGamerRequestMutationVariables>) {
        return Apollo.useMutation<ApproveSuspendGamerRequestMutation, ApproveSuspendGamerRequestMutationVariables>(ApproveSuspendGamerRequestDocument, baseOptions);
      }
export type ApproveSuspendGamerRequestMutationHookResult = ReturnType<typeof useApproveSuspendGamerRequestMutation>;
export type ApproveSuspendGamerRequestMutationResult = Apollo.MutationResult<ApproveSuspendGamerRequestMutation>;
export type ApproveSuspendGamerRequestMutationOptions = Apollo.BaseMutationOptions<ApproveSuspendGamerRequestMutation, ApproveSuspendGamerRequestMutationVariables>;
export const CreateSuspendGamerRequestDocument = gql`
    mutation CreateSuspendGamerRequest($userId: ID!, $reason: String!) {
  createSuspendGamerRequest(input: {userId: $userId, reason: $reason}) {
    suspendRequest {
      ...SuspendGamerRequest
    }
    errors {
      ...Error
    }
  }
}
    ${SuspendGamerRequestFragmentDoc}
${ErrorFragmentDoc}`;
export type CreateSuspendGamerRequestMutationFn = Apollo.MutationFunction<CreateSuspendGamerRequestMutation, CreateSuspendGamerRequestMutationVariables>;

/**
 * __useCreateSuspendGamerRequestMutation__
 *
 * To run a mutation, you first call `useCreateSuspendGamerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuspendGamerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuspendGamerRequestMutation, { data, loading, error }] = useCreateSuspendGamerRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateSuspendGamerRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateSuspendGamerRequestMutation, CreateSuspendGamerRequestMutationVariables>) {
        return Apollo.useMutation<CreateSuspendGamerRequestMutation, CreateSuspendGamerRequestMutationVariables>(CreateSuspendGamerRequestDocument, baseOptions);
      }
export type CreateSuspendGamerRequestMutationHookResult = ReturnType<typeof useCreateSuspendGamerRequestMutation>;
export type CreateSuspendGamerRequestMutationResult = Apollo.MutationResult<CreateSuspendGamerRequestMutation>;
export type CreateSuspendGamerRequestMutationOptions = Apollo.BaseMutationOptions<CreateSuspendGamerRequestMutation, CreateSuspendGamerRequestMutationVariables>;
export const RejectSuspendGamerRequestDocument = gql`
    mutation RejectSuspendGamerRequest($id: ID!) {
  rejectSuspendGamerRequest(input: {id: $id}) {
    suspendRequest {
      ...SuspendGamerRequest
    }
    errors {
      ...Error
    }
  }
}
    ${SuspendGamerRequestFragmentDoc}
${ErrorFragmentDoc}`;
export type RejectSuspendGamerRequestMutationFn = Apollo.MutationFunction<RejectSuspendGamerRequestMutation, RejectSuspendGamerRequestMutationVariables>;

/**
 * __useRejectSuspendGamerRequestMutation__
 *
 * To run a mutation, you first call `useRejectSuspendGamerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectSuspendGamerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectSuspendGamerRequestMutation, { data, loading, error }] = useRejectSuspendGamerRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectSuspendGamerRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectSuspendGamerRequestMutation, RejectSuspendGamerRequestMutationVariables>) {
        return Apollo.useMutation<RejectSuspendGamerRequestMutation, RejectSuspendGamerRequestMutationVariables>(RejectSuspendGamerRequestDocument, baseOptions);
      }
export type RejectSuspendGamerRequestMutationHookResult = ReturnType<typeof useRejectSuspendGamerRequestMutation>;
export type RejectSuspendGamerRequestMutationResult = Apollo.MutationResult<RejectSuspendGamerRequestMutation>;
export type RejectSuspendGamerRequestMutationOptions = Apollo.BaseMutationOptions<RejectSuspendGamerRequestMutation, RejectSuspendGamerRequestMutationVariables>;
export const RemoveUserCreditTransactionDocument = gql`
    mutation RemoveUserCreditTransaction($creditTransactionId: ID!) {
  removeUserCreditTransaction(input: {creditTransactionId: $creditTransactionId}) {
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveUserCreditTransactionMutationFn = Apollo.MutationFunction<RemoveUserCreditTransactionMutation, RemoveUserCreditTransactionMutationVariables>;

/**
 * __useRemoveUserCreditTransactionMutation__
 *
 * To run a mutation, you first call `useRemoveUserCreditTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserCreditTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserCreditTransactionMutation, { data, loading, error }] = useRemoveUserCreditTransactionMutation({
 *   variables: {
 *      creditTransactionId: // value for 'creditTransactionId'
 *   },
 * });
 */
export function useRemoveUserCreditTransactionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserCreditTransactionMutation, RemoveUserCreditTransactionMutationVariables>) {
        return Apollo.useMutation<RemoveUserCreditTransactionMutation, RemoveUserCreditTransactionMutationVariables>(RemoveUserCreditTransactionDocument, baseOptions);
      }
export type RemoveUserCreditTransactionMutationHookResult = ReturnType<typeof useRemoveUserCreditTransactionMutation>;
export type RemoveUserCreditTransactionMutationResult = Apollo.MutationResult<RemoveUserCreditTransactionMutation>;
export type RemoveUserCreditTransactionMutationOptions = Apollo.BaseMutationOptions<RemoveUserCreditTransactionMutation, RemoveUserCreditTransactionMutationVariables>;
export const UpdateAdminNoteUserDocument = gql`
    mutation UpdateAdminNoteUser($id: ID!, $adminNote: String!) {
  updateAdminNoteUser(input: {id: $id, adminNote: $adminNote}) {
    user {
      id
      adminNote
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateAdminNoteUserMutationFn = Apollo.MutationFunction<UpdateAdminNoteUserMutation, UpdateAdminNoteUserMutationVariables>;

/**
 * __useUpdateAdminNoteUserMutation__
 *
 * To run a mutation, you first call `useUpdateAdminNoteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminNoteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminNoteUserMutation, { data, loading, error }] = useUpdateAdminNoteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      adminNote: // value for 'adminNote'
 *   },
 * });
 */
export function useUpdateAdminNoteUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminNoteUserMutation, UpdateAdminNoteUserMutationVariables>) {
        return Apollo.useMutation<UpdateAdminNoteUserMutation, UpdateAdminNoteUserMutationVariables>(UpdateAdminNoteUserDocument, baseOptions);
      }
export type UpdateAdminNoteUserMutationHookResult = ReturnType<typeof useUpdateAdminNoteUserMutation>;
export type UpdateAdminNoteUserMutationResult = Apollo.MutationResult<UpdateAdminNoteUserMutation>;
export type UpdateAdminNoteUserMutationOptions = Apollo.BaseMutationOptions<UpdateAdminNoteUserMutation, UpdateAdminNoteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $beStar: Boolean!, $bePro: Boolean!, $beKol: Boolean!, $verified: Boolean!, $avatar: File, $proPhoto: File, $coverPicture: File, $nationality: String, $officialName: String, $introText: String, $overview: String) {
  updateUser(input: {id: $id, beStar: $beStar, bePro: $bePro, beKol: $beKol, verified: $verified, avatar: $avatar, proPhoto: $proPhoto, coverPicture: $coverPicture, nationality: $nationality, officialName: $officialName, introText: $introText, overview: $overview}) {
    user {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      beStar: // value for 'beStar'
 *      bePro: // value for 'bePro'
 *      beKol: // value for 'beKol'
 *      verified: // value for 'verified'
 *      avatar: // value for 'avatar'
 *      proPhoto: // value for 'proPhoto'
 *      coverPicture: // value for 'coverPicture'
 *      nationality: // value for 'nationality'
 *      officialName: // value for 'officialName'
 *      introText: // value for 'introText'
 *      overview: // value for 'overview'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateVendorDocument = gql`
    mutation CreateVendor($name: String!, $description: String) {
  createVendor(input: {name: $name, description: $description}) {
    vendor {
      ...Vendor
    }
    errors {
      path
      detail
    }
  }
}
    ${VendorFragmentDoc}`;
export type CreateVendorMutationFn = Apollo.MutationFunction<CreateVendorMutation, CreateVendorMutationVariables>;

/**
 * __useCreateVendorMutation__
 *
 * To run a mutation, you first call `useCreateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorMutation, { data, loading, error }] = useCreateVendorMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateVendorMutation(baseOptions?: Apollo.MutationHookOptions<CreateVendorMutation, CreateVendorMutationVariables>) {
        return Apollo.useMutation<CreateVendorMutation, CreateVendorMutationVariables>(CreateVendorDocument, baseOptions);
      }
export type CreateVendorMutationHookResult = ReturnType<typeof useCreateVendorMutation>;
export type CreateVendorMutationResult = Apollo.MutationResult<CreateVendorMutation>;
export type CreateVendorMutationOptions = Apollo.BaseMutationOptions<CreateVendorMutation, CreateVendorMutationVariables>;
export const UpdateVendorDocument = gql`
    mutation UpdateVendor($id: ID!, $name: String, $description: String) {
  updateVendor(input: {id: $id, name: $name, description: $description}) {
    vendor {
      ...Vendor
    }
    errors {
      path
      detail
    }
  }
}
    ${VendorFragmentDoc}`;
export type UpdateVendorMutationFn = Apollo.MutationFunction<UpdateVendorMutation, UpdateVendorMutationVariables>;

/**
 * __useUpdateVendorMutation__
 *
 * To run a mutation, you first call `useUpdateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorMutation, { data, loading, error }] = useUpdateVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateVendorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVendorMutation, UpdateVendorMutationVariables>) {
        return Apollo.useMutation<UpdateVendorMutation, UpdateVendorMutationVariables>(UpdateVendorDocument, baseOptions);
      }
export type UpdateVendorMutationHookResult = ReturnType<typeof useUpdateVendorMutation>;
export type UpdateVendorMutationResult = Apollo.MutationResult<UpdateVendorMutation>;
export type UpdateVendorMutationOptions = Apollo.BaseMutationOptions<UpdateVendorMutation, UpdateVendorMutationVariables>;
export const ActiveUsersAnalyticDocument = gql`
    query ActiveUsersAnalytic($startDate: ISO8601DateTime, $endDate: ISO8601DateTime) {
  activeUsersAnalytic {
    activeUsersCombatArena(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    activeUsersPartneringRequest(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    activeUsersBuyGameCredit(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    activeUsersTopUp(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    activeUsersGameBundles(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    activeUsersCreatePost(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    activeUsersOnPostLike(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    activeUsersOnPostComment(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    activeUsersCreateTeam(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    activeUsersJoinTeam(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    registeredUsers(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
    uniqueUsers(startDate: $startDate, endDate: $endDate) {
      size
      label
    }
  }
}
    `;

/**
 * __useActiveUsersAnalyticQuery__
 *
 * To run a query within a React component, call `useActiveUsersAnalyticQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveUsersAnalyticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveUsersAnalyticQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useActiveUsersAnalyticQuery(baseOptions?: Apollo.QueryHookOptions<ActiveUsersAnalyticQuery, ActiveUsersAnalyticQueryVariables>) {
        return Apollo.useQuery<ActiveUsersAnalyticQuery, ActiveUsersAnalyticQueryVariables>(ActiveUsersAnalyticDocument, baseOptions);
      }
export function useActiveUsersAnalyticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveUsersAnalyticQuery, ActiveUsersAnalyticQueryVariables>) {
          return Apollo.useLazyQuery<ActiveUsersAnalyticQuery, ActiveUsersAnalyticQueryVariables>(ActiveUsersAnalyticDocument, baseOptions);
        }
export type ActiveUsersAnalyticQueryHookResult = ReturnType<typeof useActiveUsersAnalyticQuery>;
export type ActiveUsersAnalyticLazyQueryHookResult = ReturnType<typeof useActiveUsersAnalyticLazyQuery>;
export type ActiveUsersAnalyticQueryResult = Apollo.QueryResult<ActiveUsersAnalyticQuery, ActiveUsersAnalyticQueryVariables>;
export const BankVerificationsPageDocument = gql`
    query bankVerificationsPage($gameName: String, $page: Int, $searchString: String, $suspended: Boolean) {
  bankVerifications(gameName: $gameName, page: $page, searchString: $searchString, suspended: $suspended) {
    count
    data {
      id
      accountId
      gameRank {
        id
        name
      }
      game {
        id
        name
      }
      pilot {
        id
        email
        phoneNumber
        nickname
        creditWallet {
          amount
        }
        online
        bankInfo {
          ...BankInfo
        }
        trained
      }
      screenshotState
    }
  }
}
    ${BankInfoFragmentDoc}`;

/**
 * __useBankVerificationsPageQuery__
 *
 * To run a query within a React component, call `useBankVerificationsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankVerificationsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankVerificationsPageQuery({
 *   variables: {
 *      gameName: // value for 'gameName'
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      suspended: // value for 'suspended'
 *   },
 * });
 */
export function useBankVerificationsPageQuery(baseOptions?: Apollo.QueryHookOptions<BankVerificationsPageQuery, BankVerificationsPageQueryVariables>) {
        return Apollo.useQuery<BankVerificationsPageQuery, BankVerificationsPageQueryVariables>(BankVerificationsPageDocument, baseOptions);
      }
export function useBankVerificationsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BankVerificationsPageQuery, BankVerificationsPageQueryVariables>) {
          return Apollo.useLazyQuery<BankVerificationsPageQuery, BankVerificationsPageQueryVariables>(BankVerificationsPageDocument, baseOptions);
        }
export type BankVerificationsPageQueryHookResult = ReturnType<typeof useBankVerificationsPageQuery>;
export type BankVerificationsPageLazyQueryHookResult = ReturnType<typeof useBankVerificationsPageLazyQuery>;
export type BankVerificationsPageQueryResult = Apollo.QueryResult<BankVerificationsPageQuery, BankVerificationsPageQueryVariables>;
export const BookingIdPageDocument = gql`
    query BookingIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  booking(id: $id) {
    id
    state
    reporter {
      id
      nickname
      email
      phoneNumber
    }
    reportReason
    user {
      id
      nickname
      email
      phoneNumber
    }
    rating
    ratingRemark
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useBookingIdPageQuery__
 *
 * To run a query within a React component, call `useBookingIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingIdPageQuery(baseOptions?: Apollo.QueryHookOptions<BookingIdPageQuery, BookingIdPageQueryVariables>) {
        return Apollo.useQuery<BookingIdPageQuery, BookingIdPageQueryVariables>(BookingIdPageDocument, baseOptions);
      }
export function useBookingIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingIdPageQuery, BookingIdPageQueryVariables>) {
          return Apollo.useLazyQuery<BookingIdPageQuery, BookingIdPageQueryVariables>(BookingIdPageDocument, baseOptions);
        }
export type BookingIdPageQueryHookResult = ReturnType<typeof useBookingIdPageQuery>;
export type BookingIdPageLazyQueryHookResult = ReturnType<typeof useBookingIdPageLazyQuery>;
export type BookingIdPageQueryResult = Apollo.QueryResult<BookingIdPageQuery, BookingIdPageQueryVariables>;
export const BookingChatroomDocument = gql`
    query BookingChatroom($id: ID!, $userId: ID!) {
  me {
    ...BasicAdmin
  }
  booking(id: $id) {
    id
    state
    reporter {
      id
      nickname
      email
      phoneNumber
    }
    reportReason
    user {
      id
      nickname
      email
      phoneNumber
      privateChatroom(userId: $userId) {
        id
        messages {
          id
          imageUrl
          user {
            id
            nickname
            email
            phoneNumber
          }
          createdAt
          text
        }
        createdAt
      }
    }
    rating
    ratingRemark
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useBookingChatroomQuery__
 *
 * To run a query within a React component, call `useBookingChatroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingChatroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingChatroomQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBookingChatroomQuery(baseOptions?: Apollo.QueryHookOptions<BookingChatroomQuery, BookingChatroomQueryVariables>) {
        return Apollo.useQuery<BookingChatroomQuery, BookingChatroomQueryVariables>(BookingChatroomDocument, baseOptions);
      }
export function useBookingChatroomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingChatroomQuery, BookingChatroomQueryVariables>) {
          return Apollo.useLazyQuery<BookingChatroomQuery, BookingChatroomQueryVariables>(BookingChatroomDocument, baseOptions);
        }
export type BookingChatroomQueryHookResult = ReturnType<typeof useBookingChatroomQuery>;
export type BookingChatroomLazyQueryHookResult = ReturnType<typeof useBookingChatroomLazyQuery>;
export type BookingChatroomQueryResult = Apollo.QueryResult<BookingChatroomQuery, BookingChatroomQueryVariables>;
export const AllCaiLeaderboardDocument = gql`
    query AllCaiLeaderboard($fromDate: ISO8601DateTime!, $toDate: ISO8601DateTime!) {
  caiLeaderboards(fromDate: $fromDate, toDate: $toDate) {
    id
    country
    state
    town
    name
    tier
    totalMatches
    score
  }
}
    `;

/**
 * __useAllCaiLeaderboardQuery__
 *
 * To run a query within a React component, call `useAllCaiLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCaiLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCaiLeaderboardQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useAllCaiLeaderboardQuery(baseOptions?: Apollo.QueryHookOptions<AllCaiLeaderboardQuery, AllCaiLeaderboardQueryVariables>) {
        return Apollo.useQuery<AllCaiLeaderboardQuery, AllCaiLeaderboardQueryVariables>(AllCaiLeaderboardDocument, baseOptions);
      }
export function useAllCaiLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCaiLeaderboardQuery, AllCaiLeaderboardQueryVariables>) {
          return Apollo.useLazyQuery<AllCaiLeaderboardQuery, AllCaiLeaderboardQueryVariables>(AllCaiLeaderboardDocument, baseOptions);
        }
export type AllCaiLeaderboardQueryHookResult = ReturnType<typeof useAllCaiLeaderboardQuery>;
export type AllCaiLeaderboardLazyQueryHookResult = ReturnType<typeof useAllCaiLeaderboardLazyQuery>;
export type AllCaiLeaderboardQueryResult = Apollo.QueryResult<AllCaiLeaderboardQuery, AllCaiLeaderboardQueryVariables>;
export const CareerHighlightIdPageDocument = gql`
    query CareerHighlightIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  careerHighlight(id: $id) {
    id
    startYear
    endYear
    title
    position
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useCareerHighlightIdPageQuery__
 *
 * To run a query within a React component, call `useCareerHighlightIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerHighlightIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerHighlightIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCareerHighlightIdPageQuery(baseOptions?: Apollo.QueryHookOptions<CareerHighlightIdPageQuery, CareerHighlightIdPageQueryVariables>) {
        return Apollo.useQuery<CareerHighlightIdPageQuery, CareerHighlightIdPageQueryVariables>(CareerHighlightIdPageDocument, baseOptions);
      }
export function useCareerHighlightIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareerHighlightIdPageQuery, CareerHighlightIdPageQueryVariables>) {
          return Apollo.useLazyQuery<CareerHighlightIdPageQuery, CareerHighlightIdPageQueryVariables>(CareerHighlightIdPageDocument, baseOptions);
        }
export type CareerHighlightIdPageQueryHookResult = ReturnType<typeof useCareerHighlightIdPageQuery>;
export type CareerHighlightIdPageLazyQueryHookResult = ReturnType<typeof useCareerHighlightIdPageLazyQuery>;
export type CareerHighlightIdPageQueryResult = Apollo.QueryResult<CareerHighlightIdPageQuery, CareerHighlightIdPageQueryVariables>;
export const ClanIdPageDocument = gql`
    query ClanIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  clan(id: $id) {
    id
    avatarUrl
    coverPictureUrl
    name
    owner {
      id
      nickname
      phoneNumber
      email
    }
    introText
    description
    membersCount
    postsCount
    likesCount
    followersCount
    targetType
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useClanIdPageQuery__
 *
 * To run a query within a React component, call `useClanIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClanIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClanIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClanIdPageQuery(baseOptions?: Apollo.QueryHookOptions<ClanIdPageQuery, ClanIdPageQueryVariables>) {
        return Apollo.useQuery<ClanIdPageQuery, ClanIdPageQueryVariables>(ClanIdPageDocument, baseOptions);
      }
export function useClanIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClanIdPageQuery, ClanIdPageQueryVariables>) {
          return Apollo.useLazyQuery<ClanIdPageQuery, ClanIdPageQueryVariables>(ClanIdPageDocument, baseOptions);
        }
export type ClanIdPageQueryHookResult = ReturnType<typeof useClanIdPageQuery>;
export type ClanIdPageLazyQueryHookResult = ReturnType<typeof useClanIdPageLazyQuery>;
export type ClanIdPageQueryResult = Apollo.QueryResult<ClanIdPageQuery, ClanIdPageQueryVariables>;
export const ClansIndexPageDocument = gql`
    query ClansIndexPage($page: Int, $searchString: String) {
  clanAccounts(page: $page, searchString: $searchString) {
    count
    data {
      id
      avatarUrl
      coverPictureUrl
      name
      membersCount
      postsCount
      likesCount
      followersCount
      targetType
    }
  }
}
    `;

/**
 * __useClansIndexPageQuery__
 *
 * To run a query within a React component, call `useClansIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClansIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClansIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useClansIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<ClansIndexPageQuery, ClansIndexPageQueryVariables>) {
        return Apollo.useQuery<ClansIndexPageQuery, ClansIndexPageQueryVariables>(ClansIndexPageDocument, baseOptions);
      }
export function useClansIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClansIndexPageQuery, ClansIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<ClansIndexPageQuery, ClansIndexPageQueryVariables>(ClansIndexPageDocument, baseOptions);
        }
export type ClansIndexPageQueryHookResult = ReturnType<typeof useClansIndexPageQuery>;
export type ClansIndexPageLazyQueryHookResult = ReturnType<typeof useClansIndexPageLazyQuery>;
export type ClansIndexPageQueryResult = Apollo.QueryResult<ClansIndexPageQuery, ClansIndexPageQueryVariables>;
export const ClubMemberAccountsIndexPageDocument = gql`
    query clubMemberAccountsIndexPage($clubId: ID!, $page: Int!, $searchString: String, $gameName: String, $gameId: String!) {
  clubMemberAccounts(clubId: $clubId, page: $page, searchString: $searchString, gameName: $gameName, gameId: $gameId) {
    count
    data {
      id
      accountId
      gameRank {
        id
        name
      }
      game {
        id
        name
      }
      pilot {
        id
        email
        phoneNumber
        nickname
        trained
        creditWallet {
          amount
        }
        online
        bankInfo {
          ...BankInfo
        }
      }
      club {
        ...Club
      }
      screenshotState
      aggregateRating
      completeGameBundlesCount
      completePartneringRequestsCount
    }
  }
}
    ${BankInfoFragmentDoc}
${ClubFragmentDoc}`;

/**
 * __useClubMemberAccountsIndexPageQuery__
 *
 * To run a query within a React component, call `useClubMemberAccountsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClubMemberAccountsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClubMemberAccountsIndexPageQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      gameName: // value for 'gameName'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useClubMemberAccountsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<ClubMemberAccountsIndexPageQuery, ClubMemberAccountsIndexPageQueryVariables>) {
        return Apollo.useQuery<ClubMemberAccountsIndexPageQuery, ClubMemberAccountsIndexPageQueryVariables>(ClubMemberAccountsIndexPageDocument, baseOptions);
      }
export function useClubMemberAccountsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClubMemberAccountsIndexPageQuery, ClubMemberAccountsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<ClubMemberAccountsIndexPageQuery, ClubMemberAccountsIndexPageQueryVariables>(ClubMemberAccountsIndexPageDocument, baseOptions);
        }
export type ClubMemberAccountsIndexPageQueryHookResult = ReturnType<typeof useClubMemberAccountsIndexPageQuery>;
export type ClubMemberAccountsIndexPageLazyQueryHookResult = ReturnType<typeof useClubMemberAccountsIndexPageLazyQuery>;
export type ClubMemberAccountsIndexPageQueryResult = Apollo.QueryResult<ClubMemberAccountsIndexPageQuery, ClubMemberAccountsIndexPageQueryVariables>;
export const ClubIdPageDocument = gql`
    query ClubIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  club(id: $id) {
    ...Club
  }
}
    ${BasicAdminFragmentDoc}
${ClubFragmentDoc}`;

/**
 * __useClubIdPageQuery__
 *
 * To run a query within a React component, call `useClubIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClubIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClubIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClubIdPageQuery(baseOptions?: Apollo.QueryHookOptions<ClubIdPageQuery, ClubIdPageQueryVariables>) {
        return Apollo.useQuery<ClubIdPageQuery, ClubIdPageQueryVariables>(ClubIdPageDocument, baseOptions);
      }
export function useClubIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClubIdPageQuery, ClubIdPageQueryVariables>) {
          return Apollo.useLazyQuery<ClubIdPageQuery, ClubIdPageQueryVariables>(ClubIdPageDocument, baseOptions);
        }
export type ClubIdPageQueryHookResult = ReturnType<typeof useClubIdPageQuery>;
export type ClubIdPageLazyQueryHookResult = ReturnType<typeof useClubIdPageLazyQuery>;
export type ClubIdPageQueryResult = Apollo.QueryResult<ClubIdPageQuery, ClubIdPageQueryVariables>;
export const ClubIndexPageDocument = gql`
    query ClubIndexPage($page: Int, $searchString: String) {
  me {
    ...BasicAdmin
  }
  clubCounts(page: $page, searchString: $searchString) {
    count
    data {
      id
      name
      icon
      description
      tier
      beStar
      verified
      themeColor
      tier
      founded
      origin
      introText
      overview
    }
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useClubIndexPageQuery__
 *
 * To run a query within a React component, call `useClubIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClubIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClubIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useClubIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<ClubIndexPageQuery, ClubIndexPageQueryVariables>) {
        return Apollo.useQuery<ClubIndexPageQuery, ClubIndexPageQueryVariables>(ClubIndexPageDocument, baseOptions);
      }
export function useClubIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClubIndexPageQuery, ClubIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<ClubIndexPageQuery, ClubIndexPageQueryVariables>(ClubIndexPageDocument, baseOptions);
        }
export type ClubIndexPageQueryHookResult = ReturnType<typeof useClubIndexPageQuery>;
export type ClubIndexPageLazyQueryHookResult = ReturnType<typeof useClubIndexPageLazyQuery>;
export type ClubIndexPageQueryResult = Apollo.QueryResult<ClubIndexPageQuery, ClubIndexPageQueryVariables>;
export const ClubsDocument = gql`
    query Clubs {
  clubs {
    ...Club
  }
}
    ${ClubFragmentDoc}`;

/**
 * __useClubsQuery__
 *
 * To run a query within a React component, call `useClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClubsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClubsQuery(baseOptions?: Apollo.QueryHookOptions<ClubsQuery, ClubsQueryVariables>) {
        return Apollo.useQuery<ClubsQuery, ClubsQueryVariables>(ClubsDocument, baseOptions);
      }
export function useClubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClubsQuery, ClubsQueryVariables>) {
          return Apollo.useLazyQuery<ClubsQuery, ClubsQueryVariables>(ClubsDocument, baseOptions);
        }
export type ClubsQueryHookResult = ReturnType<typeof useClubsQuery>;
export type ClubsLazyQueryHookResult = ReturnType<typeof useClubsLazyQuery>;
export type ClubsQueryResult = Apollo.QueryResult<ClubsQuery, ClubsQueryVariables>;
export const CombatRoomIdPageDocument = gql`
    query CombatRoomIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  combatRoom(id: $id) {
    id
    state
    duration
    createdAt
    startTime
    completeTime
    winTeam {
      id
      club {
        id
        name
      }
    }
    loseTeam {
      id
      club {
        id
        name
      }
    }
    accepter {
      id
      score
      suspended
      reason
      team {
        id
        name
      }
    }
    challenger {
      id
      score
      suspended
      reason
      team {
        id
        name
      }
    }
    accepterPlayerIds {
      id
      nickname
      email
      phoneNumber
      teamRole {
        id
        proficientRole {
          id
          name
        }
      }
      team {
        id
        name
      }
      playerIdsProficientRoles {
        id
        name
      }
      gameAccounts {
        id
        inGameName
      }
    }
    challengerPlayerIds {
      id
      nickname
      email
      phoneNumber
      teamRole {
        id
        proficientRole {
          id
          name
        }
      }
      team {
        id
        name
      }
      playerIdsProficientRoles {
        id
        name
      }
      gameAccounts {
        id
        inGameName
      }
    }
    matchResults {
      id
      resultScreenshots
      createdAt
      updatedAt
      combatTeam {
        id
        team {
          id
          name
        }
      }
      winTeam {
        id
        club {
          id
          name
        }
      }
      loseTeam {
        id
        club {
          id
          name
        }
      }
    }
    adminNote
    chatroom {
      id
      messages {
        id
        createdAt
        text
        user {
          id
          nickname
        }
      }
    }
    combatQueues {
      id
      score
      combatTeam {
        id
        team {
          id
          name
        }
      }
    }
    accepterWinScores
    challengerWinScores
    updatedScores
    roomUrl
    validationRemark
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useCombatRoomIdPageQuery__
 *
 * To run a query within a React component, call `useCombatRoomIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCombatRoomIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCombatRoomIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCombatRoomIdPageQuery(baseOptions?: Apollo.QueryHookOptions<CombatRoomIdPageQuery, CombatRoomIdPageQueryVariables>) {
        return Apollo.useQuery<CombatRoomIdPageQuery, CombatRoomIdPageQueryVariables>(CombatRoomIdPageDocument, baseOptions);
      }
export function useCombatRoomIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CombatRoomIdPageQuery, CombatRoomIdPageQueryVariables>) {
          return Apollo.useLazyQuery<CombatRoomIdPageQuery, CombatRoomIdPageQueryVariables>(CombatRoomIdPageDocument, baseOptions);
        }
export type CombatRoomIdPageQueryHookResult = ReturnType<typeof useCombatRoomIdPageQuery>;
export type CombatRoomIdPageLazyQueryHookResult = ReturnType<typeof useCombatRoomIdPageLazyQuery>;
export type CombatRoomIdPageQueryResult = Apollo.QueryResult<CombatRoomIdPageQuery, CombatRoomIdPageQueryVariables>;
export const CombatRoomsIndexPageDocument = gql`
    query CombatRoomsIndexPage($page: Int!, $searchString: String, $state: String, $year: String, $month: String, $week: String, $leaderboardId: ID) {
  combatRoomAccounts(page: $page, searchString: $searchString, state: $state, year: $year, month: $month, week: $week, leaderboardId: $leaderboardId) {
    count
    data {
      id
      state
      createdAt
      startTime
      completeTime
      accepter {
        team {
          id
          name
        }
      }
      challenger {
        team {
          id
          name
        }
      }
      matchResults {
        id
        winTeam {
          id
          name
        }
        loseTeam {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useCombatRoomsIndexPageQuery__
 *
 * To run a query within a React component, call `useCombatRoomsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCombatRoomsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCombatRoomsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      state: // value for 'state'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      week: // value for 'week'
 *      leaderboardId: // value for 'leaderboardId'
 *   },
 * });
 */
export function useCombatRoomsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<CombatRoomsIndexPageQuery, CombatRoomsIndexPageQueryVariables>) {
        return Apollo.useQuery<CombatRoomsIndexPageQuery, CombatRoomsIndexPageQueryVariables>(CombatRoomsIndexPageDocument, baseOptions);
      }
export function useCombatRoomsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CombatRoomsIndexPageQuery, CombatRoomsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<CombatRoomsIndexPageQuery, CombatRoomsIndexPageQueryVariables>(CombatRoomsIndexPageDocument, baseOptions);
        }
export type CombatRoomsIndexPageQueryHookResult = ReturnType<typeof useCombatRoomsIndexPageQuery>;
export type CombatRoomsIndexPageLazyQueryHookResult = ReturnType<typeof useCombatRoomsIndexPageLazyQuery>;
export type CombatRoomsIndexPageQueryResult = Apollo.QueryResult<CombatRoomsIndexPageQuery, CombatRoomsIndexPageQueryVariables>;
export const CombatRoomsCsvDocument = gql`
    query CombatRoomsCsv($fromDate: ISO8601DateTime, $toDate: ISO8601DateTime) {
  combatRoomAccountsCsv(fromDate: $fromDate, toDate: $toDate)
}
    `;

/**
 * __useCombatRoomsCsvQuery__
 *
 * To run a query within a React component, call `useCombatRoomsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useCombatRoomsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCombatRoomsCsvQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useCombatRoomsCsvQuery(baseOptions?: Apollo.QueryHookOptions<CombatRoomsCsvQuery, CombatRoomsCsvQueryVariables>) {
        return Apollo.useQuery<CombatRoomsCsvQuery, CombatRoomsCsvQueryVariables>(CombatRoomsCsvDocument, baseOptions);
      }
export function useCombatRoomsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CombatRoomsCsvQuery, CombatRoomsCsvQueryVariables>) {
          return Apollo.useLazyQuery<CombatRoomsCsvQuery, CombatRoomsCsvQueryVariables>(CombatRoomsCsvDocument, baseOptions);
        }
export type CombatRoomsCsvQueryHookResult = ReturnType<typeof useCombatRoomsCsvQuery>;
export type CombatRoomsCsvLazyQueryHookResult = ReturnType<typeof useCombatRoomsCsvLazyQuery>;
export type CombatRoomsCsvQueryResult = Apollo.QueryResult<CombatRoomsCsvQuery, CombatRoomsCsvQueryVariables>;
export const CombatTeamIdPageDocument = gql`
    query CombatTeamIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  combatTeam(id: $id) {
    id
    score
    totalMatches
    team {
      id
      name
      icon
      tier
    }
    state
    players {
      id
      nickname
      email
      phoneNumber
      icName
      icNumber
      discordId
      gameAccounts {
        id
        accountId
        inGameName
        serverId
      }
      team {
        id
        name
        club {
          id
          name
        }
      }
      teamRole {
        id
        proficientRole {
          id
          name
        }
      }
    }
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useCombatTeamIdPageQuery__
 *
 * To run a query within a React component, call `useCombatTeamIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCombatTeamIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCombatTeamIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCombatTeamIdPageQuery(baseOptions?: Apollo.QueryHookOptions<CombatTeamIdPageQuery, CombatTeamIdPageQueryVariables>) {
        return Apollo.useQuery<CombatTeamIdPageQuery, CombatTeamIdPageQueryVariables>(CombatTeamIdPageDocument, baseOptions);
      }
export function useCombatTeamIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CombatTeamIdPageQuery, CombatTeamIdPageQueryVariables>) {
          return Apollo.useLazyQuery<CombatTeamIdPageQuery, CombatTeamIdPageQueryVariables>(CombatTeamIdPageDocument, baseOptions);
        }
export type CombatTeamIdPageQueryHookResult = ReturnType<typeof useCombatTeamIdPageQuery>;
export type CombatTeamIdPageLazyQueryHookResult = ReturnType<typeof useCombatTeamIdPageLazyQuery>;
export type CombatTeamIdPageQueryResult = Apollo.QueryResult<CombatTeamIdPageQuery, CombatTeamIdPageQueryVariables>;
export const FaqIdPageDocument = gql`
    query FaqIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  faq(id: $id) {
    id
    question
    answer
    language
    categoryColumns
    position
    apps
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useFaqIdPageQuery__
 *
 * To run a query within a React component, call `useFaqIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFaqIdPageQuery(baseOptions?: Apollo.QueryHookOptions<FaqIdPageQuery, FaqIdPageQueryVariables>) {
        return Apollo.useQuery<FaqIdPageQuery, FaqIdPageQueryVariables>(FaqIdPageDocument, baseOptions);
      }
export function useFaqIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqIdPageQuery, FaqIdPageQueryVariables>) {
          return Apollo.useLazyQuery<FaqIdPageQuery, FaqIdPageQueryVariables>(FaqIdPageDocument, baseOptions);
        }
export type FaqIdPageQueryHookResult = ReturnType<typeof useFaqIdPageQuery>;
export type FaqIdPageLazyQueryHookResult = ReturnType<typeof useFaqIdPageLazyQuery>;
export type FaqIdPageQueryResult = Apollo.QueryResult<FaqIdPageQuery, FaqIdPageQueryVariables>;
export const FaqIndexPageDocument = gql`
    query FaqIndexPage($page: Int!, $searchString: String) {
  faqAccounts(page: $page, searchString: $searchString) {
    count
    data {
      id
      question
      answer
      language
      categoryColumns
      apps
    }
  }
}
    `;

/**
 * __useFaqIndexPageQuery__
 *
 * To run a query within a React component, call `useFaqIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useFaqIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<FaqIndexPageQuery, FaqIndexPageQueryVariables>) {
        return Apollo.useQuery<FaqIndexPageQuery, FaqIndexPageQueryVariables>(FaqIndexPageDocument, baseOptions);
      }
export function useFaqIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqIndexPageQuery, FaqIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<FaqIndexPageQuery, FaqIndexPageQueryVariables>(FaqIndexPageDocument, baseOptions);
        }
export type FaqIndexPageQueryHookResult = ReturnType<typeof useFaqIndexPageQuery>;
export type FaqIndexPageLazyQueryHookResult = ReturnType<typeof useFaqIndexPageLazyQuery>;
export type FaqIndexPageQueryResult = Apollo.QueryResult<FaqIndexPageQuery, FaqIndexPageQueryVariables>;
export const GameAccountsIndexPageDocument = gql`
    query gameAccountsIndexPage($approve: Boolean, $gameName: String, $rankName: String, $page: Int, $searchString: String, $filterWhere: FilterQuery, $suspended: Boolean, $trained: Boolean, $streamer: Boolean, $online: Boolean) {
  gameAccounts(approve: $approve, gameName: $gameName, rankName: $rankName, page: $page, searchString: $searchString, filterWhere: $filterWhere, suspended: $suspended, trained: $trained, streamer: $streamer, online: $online) {
    count
    data {
      id
      accountId
      gameRank {
        id
        name
      }
      game {
        id
        name
      }
      pilot {
        id
        email
        phoneNumber
        nickname
        trained
        creditWallet {
          amount
        }
        online
        bankInfo {
          ...BankInfo
        }
      }
      screenshotState
      pilotTier {
        ...PilotTier
      }
    }
  }
}
    ${BankInfoFragmentDoc}
${PilotTierFragmentDoc}`;

/**
 * __useGameAccountsIndexPageQuery__
 *
 * To run a query within a React component, call `useGameAccountsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameAccountsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameAccountsIndexPageQuery({
 *   variables: {
 *      approve: // value for 'approve'
 *      gameName: // value for 'gameName'
 *      rankName: // value for 'rankName'
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      filterWhere: // value for 'filterWhere'
 *      suspended: // value for 'suspended'
 *      trained: // value for 'trained'
 *      streamer: // value for 'streamer'
 *      online: // value for 'online'
 *   },
 * });
 */
export function useGameAccountsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<GameAccountsIndexPageQuery, GameAccountsIndexPageQueryVariables>) {
        return Apollo.useQuery<GameAccountsIndexPageQuery, GameAccountsIndexPageQueryVariables>(GameAccountsIndexPageDocument, baseOptions);
      }
export function useGameAccountsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameAccountsIndexPageQuery, GameAccountsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<GameAccountsIndexPageQuery, GameAccountsIndexPageQueryVariables>(GameAccountsIndexPageDocument, baseOptions);
        }
export type GameAccountsIndexPageQueryHookResult = ReturnType<typeof useGameAccountsIndexPageQuery>;
export type GameAccountsIndexPageLazyQueryHookResult = ReturnType<typeof useGameAccountsIndexPageLazyQuery>;
export type GameAccountsIndexPageQueryResult = Apollo.QueryResult<GameAccountsIndexPageQuery, GameAccountsIndexPageQueryVariables>;
export const GameMessagesDocument = gql`
    query GameMessages($gameChatroomId: String!, $searchString: String, $page: Int) {
  gameMessageAccounts(gameChatroomId: $gameChatroomId, searchString: $searchString, page: $page) {
    count
    messages {
      ...GameMessage
    }
  }
}
    ${GameMessageFragmentDoc}`;

/**
 * __useGameMessagesQuery__
 *
 * To run a query within a React component, call `useGameMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameMessagesQuery({
 *   variables: {
 *      gameChatroomId: // value for 'gameChatroomId'
 *      searchString: // value for 'searchString'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGameMessagesQuery(baseOptions?: Apollo.QueryHookOptions<GameMessagesQuery, GameMessagesQueryVariables>) {
        return Apollo.useQuery<GameMessagesQuery, GameMessagesQueryVariables>(GameMessagesDocument, baseOptions);
      }
export function useGameMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameMessagesQuery, GameMessagesQueryVariables>) {
          return Apollo.useLazyQuery<GameMessagesQuery, GameMessagesQueryVariables>(GameMessagesDocument, baseOptions);
        }
export type GameMessagesQueryHookResult = ReturnType<typeof useGameMessagesQuery>;
export type GameMessagesLazyQueryHookResult = ReturnType<typeof useGameMessagesLazyQuery>;
export type GameMessagesQueryResult = Apollo.QueryResult<GameMessagesQuery, GameMessagesQueryVariables>;
export const GameRankIdPageDocument = gql`
    query GameRankIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  gameRank(id: $id) {
    ...GameRank
  }
}
    ${BasicAdminFragmentDoc}
${GameRankFragmentDoc}`;

/**
 * __useGameRankIdPageQuery__
 *
 * To run a query within a React component, call `useGameRankIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameRankIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameRankIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGameRankIdPageQuery(baseOptions?: Apollo.QueryHookOptions<GameRankIdPageQuery, GameRankIdPageQueryVariables>) {
        return Apollo.useQuery<GameRankIdPageQuery, GameRankIdPageQueryVariables>(GameRankIdPageDocument, baseOptions);
      }
export function useGameRankIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameRankIdPageQuery, GameRankIdPageQueryVariables>) {
          return Apollo.useLazyQuery<GameRankIdPageQuery, GameRankIdPageQueryVariables>(GameRankIdPageDocument, baseOptions);
        }
export type GameRankIdPageQueryHookResult = ReturnType<typeof useGameRankIdPageQuery>;
export type GameRankIdPageLazyQueryHookResult = ReturnType<typeof useGameRankIdPageLazyQuery>;
export type GameRankIdPageQueryResult = Apollo.QueryResult<GameRankIdPageQuery, GameRankIdPageQueryVariables>;
export const BundleIdPageDocument = gql`
    query BundleIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  gameBundle(id: $id) {
    id
    promoCode
    originalAmount
    pilot {
      id
      email
      nickname
      phoneNumber
      creditWallet {
        amount
      }
    }
    user {
      id
      email
      nickname
      phoneNumber
    }
    reporter {
      id
      nickname
    }
    game {
      id
      name
    }
    remark
    rating
    ratingRemark
    amount
    currentGameRank {
      id
      name
      imageUrl
      rankStarMaxCount
    }
    targetGameRank {
      id
      name
      imageUrl
      rankStarMaxCount
    }
    currentRankStar
    currentRankTier
    targetRankTier
    targetRankStar
    currentRankPoint
    targetRankPoint
    completionScreenshotUrl
    country
    startTime
    completionTime
    state
    createdAt
    reportReason
    chatroom {
      id
      messages {
        id
        createdAt
        text
        imageUrl
        user {
          id
          nickname
        }
      }
    }
    perspectives {
      id
      name
    }
    queues {
      id
      name
    }
    proficientRoles {
      id
      name
    }
    communicationRating
    skillRating
    adminNote
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useBundleIdPageQuery__
 *
 * To run a query within a React component, call `useBundleIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundleIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundleIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBundleIdPageQuery(baseOptions?: Apollo.QueryHookOptions<BundleIdPageQuery, BundleIdPageQueryVariables>) {
        return Apollo.useQuery<BundleIdPageQuery, BundleIdPageQueryVariables>(BundleIdPageDocument, baseOptions);
      }
export function useBundleIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BundleIdPageQuery, BundleIdPageQueryVariables>) {
          return Apollo.useLazyQuery<BundleIdPageQuery, BundleIdPageQueryVariables>(BundleIdPageDocument, baseOptions);
        }
export type BundleIdPageQueryHookResult = ReturnType<typeof useBundleIdPageQuery>;
export type BundleIdPageLazyQueryHookResult = ReturnType<typeof useBundleIdPageLazyQuery>;
export type BundleIdPageQueryResult = Apollo.QueryResult<BundleIdPageQuery, BundleIdPageQueryVariables>;
export const AllGameBundlesPageDocument = gql`
    query AllGameBundlesPage($page: Int, $filterWhere: FilterPartneringQuery, $state: String, $suspicious: Boolean) {
  gameBundlesIndex(page: $page, filterWhere: $filterWhere, state: $state, suspicious: $suspicious) {
    count
    data {
      id
      pilot {
        id
        phoneNumber
        nickname
      }
      user {
        id
        phoneNumber
        nickname
      }
      game {
        id
        name
      }
      state
      createdAt
    }
  }
}
    `;

/**
 * __useAllGameBundlesPageQuery__
 *
 * To run a query within a React component, call `useAllGameBundlesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGameBundlesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGameBundlesPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filterWhere: // value for 'filterWhere'
 *      state: // value for 'state'
 *      suspicious: // value for 'suspicious'
 *   },
 * });
 */
export function useAllGameBundlesPageQuery(baseOptions?: Apollo.QueryHookOptions<AllGameBundlesPageQuery, AllGameBundlesPageQueryVariables>) {
        return Apollo.useQuery<AllGameBundlesPageQuery, AllGameBundlesPageQueryVariables>(AllGameBundlesPageDocument, baseOptions);
      }
export function useAllGameBundlesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllGameBundlesPageQuery, AllGameBundlesPageQueryVariables>) {
          return Apollo.useLazyQuery<AllGameBundlesPageQuery, AllGameBundlesPageQueryVariables>(AllGameBundlesPageDocument, baseOptions);
        }
export type AllGameBundlesPageQueryHookResult = ReturnType<typeof useAllGameBundlesPageQuery>;
export type AllGameBundlesPageLazyQueryHookResult = ReturnType<typeof useAllGameBundlesPageLazyQuery>;
export type AllGameBundlesPageQueryResult = Apollo.QueryResult<AllGameBundlesPageQuery, AllGameBundlesPageQueryVariables>;
export const GamerBannerIdPageDocument = gql`
    query GamerBannerIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  gamerBanner(id: $id) {
    id
    title
    createdAt
    url
    image
    active
    screen
    poster
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useGamerBannerIdPageQuery__
 *
 * To run a query within a React component, call `useGamerBannerIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamerBannerIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamerBannerIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGamerBannerIdPageQuery(baseOptions?: Apollo.QueryHookOptions<GamerBannerIdPageQuery, GamerBannerIdPageQueryVariables>) {
        return Apollo.useQuery<GamerBannerIdPageQuery, GamerBannerIdPageQueryVariables>(GamerBannerIdPageDocument, baseOptions);
      }
export function useGamerBannerIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamerBannerIdPageQuery, GamerBannerIdPageQueryVariables>) {
          return Apollo.useLazyQuery<GamerBannerIdPageQuery, GamerBannerIdPageQueryVariables>(GamerBannerIdPageDocument, baseOptions);
        }
export type GamerBannerIdPageQueryHookResult = ReturnType<typeof useGamerBannerIdPageQuery>;
export type GamerBannerIdPageLazyQueryHookResult = ReturnType<typeof useGamerBannerIdPageLazyQuery>;
export type GamerBannerIdPageQueryResult = Apollo.QueryResult<GamerBannerIdPageQuery, GamerBannerIdPageQueryVariables>;
export const GamerBannerIndexPageDocument = gql`
    query GamerBannerIndexPage($page: Int, $searchString: String, $active: Boolean) {
  me {
    ...BasicAdmin
  }
  gamerBanners(page: $page, searchString: $searchString, active: $active) {
    count
    data {
      id
      title
      createdAt
      url
      image
      active
      screen
      poster
    }
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useGamerBannerIndexPageQuery__
 *
 * To run a query within a React component, call `useGamerBannerIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamerBannerIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamerBannerIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGamerBannerIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<GamerBannerIndexPageQuery, GamerBannerIndexPageQueryVariables>) {
        return Apollo.useQuery<GamerBannerIndexPageQuery, GamerBannerIndexPageQueryVariables>(GamerBannerIndexPageDocument, baseOptions);
      }
export function useGamerBannerIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamerBannerIndexPageQuery, GamerBannerIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<GamerBannerIndexPageQuery, GamerBannerIndexPageQueryVariables>(GamerBannerIndexPageDocument, baseOptions);
        }
export type GamerBannerIndexPageQueryHookResult = ReturnType<typeof useGamerBannerIndexPageQuery>;
export type GamerBannerIndexPageLazyQueryHookResult = ReturnType<typeof useGamerBannerIndexPageLazyQuery>;
export type GamerBannerIndexPageQueryResult = Apollo.QueryResult<GamerBannerIndexPageQuery, GamerBannerIndexPageQueryVariables>;
export const GameIdPageDocument = gql`
    query GameIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  game(id: $id) {
    ...Game
  }
}
    ${BasicAdminFragmentDoc}
${GameFragmentDoc}`;

/**
 * __useGameIdPageQuery__
 *
 * To run a query within a React component, call `useGameIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGameIdPageQuery(baseOptions?: Apollo.QueryHookOptions<GameIdPageQuery, GameIdPageQueryVariables>) {
        return Apollo.useQuery<GameIdPageQuery, GameIdPageQueryVariables>(GameIdPageDocument, baseOptions);
      }
export function useGameIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameIdPageQuery, GameIdPageQueryVariables>) {
          return Apollo.useLazyQuery<GameIdPageQuery, GameIdPageQueryVariables>(GameIdPageDocument, baseOptions);
        }
export type GameIdPageQueryHookResult = ReturnType<typeof useGameIdPageQuery>;
export type GameIdPageLazyQueryHookResult = ReturnType<typeof useGameIdPageLazyQuery>;
export type GameIdPageQueryResult = Apollo.QueryResult<GameIdPageQuery, GameIdPageQueryVariables>;
export const GamesIndexPageDocument = gql`
    query GamesIndexPage($page: Int, $searchString: String) {
  gameCountAccounts(page: $page, searchString: $searchString) {
    count
    data {
      id
      iconUrl
      name
      developerName
      teamsCount
      gameAccountsCount
      ordering
    }
  }
}
    `;

/**
 * __useGamesIndexPageQuery__
 *
 * To run a query within a React component, call `useGamesIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGamesIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<GamesIndexPageQuery, GamesIndexPageQueryVariables>) {
        return Apollo.useQuery<GamesIndexPageQuery, GamesIndexPageQueryVariables>(GamesIndexPageDocument, baseOptions);
      }
export function useGamesIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamesIndexPageQuery, GamesIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<GamesIndexPageQuery, GamesIndexPageQueryVariables>(GamesIndexPageDocument, baseOptions);
        }
export type GamesIndexPageQueryHookResult = ReturnType<typeof useGamesIndexPageQuery>;
export type GamesIndexPageLazyQueryHookResult = ReturnType<typeof useGamesIndexPageLazyQuery>;
export type GamesIndexPageQueryResult = Apollo.QueryResult<GamesIndexPageQuery, GamesIndexPageQueryVariables>;
export const DashboardPageDocument = gql`
    query DashboardPage($page: Int) {
  me {
    ...BasicAdmin
  }
  analytics {
    totalUsers
    totalApprovePilots
    pendingPilots
    pendingPayouts
    reportsPartneringRequest
    reportsGameBundle
    flaggedCombatRooms
    pendingReportedRequests
    vocaCreditBalance
  }
  adminLogs(page: $page) {
    count
    data {
      ...AdminLog
    }
  }
}
    ${BasicAdminFragmentDoc}
${AdminLogFragmentDoc}`;

/**
 * __useDashboardPageQuery__
 *
 * To run a query within a React component, call `useDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useDashboardPageQuery(baseOptions?: Apollo.QueryHookOptions<DashboardPageQuery, DashboardPageQueryVariables>) {
        return Apollo.useQuery<DashboardPageQuery, DashboardPageQueryVariables>(DashboardPageDocument, baseOptions);
      }
export function useDashboardPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardPageQuery, DashboardPageQueryVariables>) {
          return Apollo.useLazyQuery<DashboardPageQuery, DashboardPageQueryVariables>(DashboardPageDocument, baseOptions);
        }
export type DashboardPageQueryHookResult = ReturnType<typeof useDashboardPageQuery>;
export type DashboardPageLazyQueryHookResult = ReturnType<typeof useDashboardPageLazyQuery>;
export type DashboardPageQueryResult = Apollo.QueryResult<DashboardPageQuery, DashboardPageQueryVariables>;
export const InterestIdPageDocument = gql`
    query InterestIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  interest(id: $id) {
    id
    name
    iconOnUrl
    iconOffUrl
    active
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useInterestIdPageQuery__
 *
 * To run a query within a React component, call `useInterestIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterestIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterestIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInterestIdPageQuery(baseOptions?: Apollo.QueryHookOptions<InterestIdPageQuery, InterestIdPageQueryVariables>) {
        return Apollo.useQuery<InterestIdPageQuery, InterestIdPageQueryVariables>(InterestIdPageDocument, baseOptions);
      }
export function useInterestIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterestIdPageQuery, InterestIdPageQueryVariables>) {
          return Apollo.useLazyQuery<InterestIdPageQuery, InterestIdPageQueryVariables>(InterestIdPageDocument, baseOptions);
        }
export type InterestIdPageQueryHookResult = ReturnType<typeof useInterestIdPageQuery>;
export type InterestIdPageLazyQueryHookResult = ReturnType<typeof useInterestIdPageLazyQuery>;
export type InterestIdPageQueryResult = Apollo.QueryResult<InterestIdPageQuery, InterestIdPageQueryVariables>;
export const InterestsIndexPageDocument = gql`
    query InterestsIndexPage($page: Int, $searchString: String, $active: String) {
  interestAccounts(page: $page, searchString: $searchString, active: $active) {
    count
    data {
      id
      name
      iconOnUrl
      iconOffUrl
      active
    }
  }
}
    `;

/**
 * __useInterestsIndexPageQuery__
 *
 * To run a query within a React component, call `useInterestsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterestsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterestsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useInterestsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<InterestsIndexPageQuery, InterestsIndexPageQueryVariables>) {
        return Apollo.useQuery<InterestsIndexPageQuery, InterestsIndexPageQueryVariables>(InterestsIndexPageDocument, baseOptions);
      }
export function useInterestsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterestsIndexPageQuery, InterestsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<InterestsIndexPageQuery, InterestsIndexPageQueryVariables>(InterestsIndexPageDocument, baseOptions);
        }
export type InterestsIndexPageQueryHookResult = ReturnType<typeof useInterestsIndexPageQuery>;
export type InterestsIndexPageLazyQueryHookResult = ReturnType<typeof useInterestsIndexPageLazyQuery>;
export type InterestsIndexPageQueryResult = Apollo.QueryResult<InterestsIndexPageQuery, InterestsIndexPageQueryVariables>;
export const LeaderboardPoolPrizeIdPageDocument = gql`
    query LeaderboardPoolPrizeIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  leaderboardPoolPrize(id: $id) {
    ...Leaderboard
  }
}
    ${BasicAdminFragmentDoc}
${LeaderboardFragmentDoc}`;

/**
 * __useLeaderboardPoolPrizeIdPageQuery__
 *
 * To run a query within a React component, call `useLeaderboardPoolPrizeIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderboardPoolPrizeIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderboardPoolPrizeIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaderboardPoolPrizeIdPageQuery(baseOptions?: Apollo.QueryHookOptions<LeaderboardPoolPrizeIdPageQuery, LeaderboardPoolPrizeIdPageQueryVariables>) {
        return Apollo.useQuery<LeaderboardPoolPrizeIdPageQuery, LeaderboardPoolPrizeIdPageQueryVariables>(LeaderboardPoolPrizeIdPageDocument, baseOptions);
      }
export function useLeaderboardPoolPrizeIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeaderboardPoolPrizeIdPageQuery, LeaderboardPoolPrizeIdPageQueryVariables>) {
          return Apollo.useLazyQuery<LeaderboardPoolPrizeIdPageQuery, LeaderboardPoolPrizeIdPageQueryVariables>(LeaderboardPoolPrizeIdPageDocument, baseOptions);
        }
export type LeaderboardPoolPrizeIdPageQueryHookResult = ReturnType<typeof useLeaderboardPoolPrizeIdPageQuery>;
export type LeaderboardPoolPrizeIdPageLazyQueryHookResult = ReturnType<typeof useLeaderboardPoolPrizeIdPageLazyQuery>;
export type LeaderboardPoolPrizeIdPageQueryResult = Apollo.QueryResult<LeaderboardPoolPrizeIdPageQuery, LeaderboardPoolPrizeIdPageQueryVariables>;
export const LeaderboardPoolPrizeIndexPageDocument = gql`
    query LeaderboardPoolPrizeIndexPage($page: Int!, $searchString: String, $gameId: String!) {
  leaderboardPoolPrizeAccounts(page: $page, searchString: $searchString, gameId: $gameId) {
    count
    data {
      ...Leaderboard
    }
  }
}
    ${LeaderboardFragmentDoc}`;

/**
 * __useLeaderboardPoolPrizeIndexPageQuery__
 *
 * To run a query within a React component, call `useLeaderboardPoolPrizeIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderboardPoolPrizeIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderboardPoolPrizeIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useLeaderboardPoolPrizeIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<LeaderboardPoolPrizeIndexPageQuery, LeaderboardPoolPrizeIndexPageQueryVariables>) {
        return Apollo.useQuery<LeaderboardPoolPrizeIndexPageQuery, LeaderboardPoolPrizeIndexPageQueryVariables>(LeaderboardPoolPrizeIndexPageDocument, baseOptions);
      }
export function useLeaderboardPoolPrizeIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeaderboardPoolPrizeIndexPageQuery, LeaderboardPoolPrizeIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<LeaderboardPoolPrizeIndexPageQuery, LeaderboardPoolPrizeIndexPageQueryVariables>(LeaderboardPoolPrizeIndexPageDocument, baseOptions);
        }
export type LeaderboardPoolPrizeIndexPageQueryHookResult = ReturnType<typeof useLeaderboardPoolPrizeIndexPageQuery>;
export type LeaderboardPoolPrizeIndexPageLazyQueryHookResult = ReturnType<typeof useLeaderboardPoolPrizeIndexPageLazyQuery>;
export type LeaderboardPoolPrizeIndexPageQueryResult = Apollo.QueryResult<LeaderboardPoolPrizeIndexPageQuery, LeaderboardPoolPrizeIndexPageQueryVariables>;
export const CombatTeamsDocument = gql`
    query CombatTeams($leaderboardId: String) {
  searchPoolPrizeTeams(leaderboardId: $leaderboardId) {
    id
    score
    team {
      id
      club {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCombatTeamsQuery__
 *
 * To run a query within a React component, call `useCombatTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCombatTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCombatTeamsQuery({
 *   variables: {
 *      leaderboardId: // value for 'leaderboardId'
 *   },
 * });
 */
export function useCombatTeamsQuery(baseOptions?: Apollo.QueryHookOptions<CombatTeamsQuery, CombatTeamsQueryVariables>) {
        return Apollo.useQuery<CombatTeamsQuery, CombatTeamsQueryVariables>(CombatTeamsDocument, baseOptions);
      }
export function useCombatTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CombatTeamsQuery, CombatTeamsQueryVariables>) {
          return Apollo.useLazyQuery<CombatTeamsQuery, CombatTeamsQueryVariables>(CombatTeamsDocument, baseOptions);
        }
export type CombatTeamsQueryHookResult = ReturnType<typeof useCombatTeamsQuery>;
export type CombatTeamsLazyQueryHookResult = ReturnType<typeof useCombatTeamsLazyQuery>;
export type CombatTeamsQueryResult = Apollo.QueryResult<CombatTeamsQuery, CombatTeamsQueryVariables>;
export const LeaderboardIdPageDocument = gql`
    query LeaderboardIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  leaderboard(id: $id) {
    id
    team {
      id
      name
    }
    combatRooms {
      id
      state
      createdAt
      accepterWinScores
      challengerWinScores
      accepter {
        id
        team {
          id
          name
        }
      }
      challenger {
        id
        team {
          id
          name
        }
      }
      matchResults {
        id
        winTeam {
          id
          name
        }
        loseTeam {
          id
          name
        }
        createdAt
        updatedAt
      }
      winTeam {
        id
        club {
          id
          name
        }
      }
      loseTeam {
        id
        club {
          id
          name
        }
      }
    }
    combatRoomAdminLogs {
      ...AdminLog
    }
  }
}
    ${BasicAdminFragmentDoc}
${AdminLogFragmentDoc}`;

/**
 * __useLeaderboardIdPageQuery__
 *
 * To run a query within a React component, call `useLeaderboardIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderboardIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderboardIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaderboardIdPageQuery(baseOptions?: Apollo.QueryHookOptions<LeaderboardIdPageQuery, LeaderboardIdPageQueryVariables>) {
        return Apollo.useQuery<LeaderboardIdPageQuery, LeaderboardIdPageQueryVariables>(LeaderboardIdPageDocument, baseOptions);
      }
export function useLeaderboardIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeaderboardIdPageQuery, LeaderboardIdPageQueryVariables>) {
          return Apollo.useLazyQuery<LeaderboardIdPageQuery, LeaderboardIdPageQueryVariables>(LeaderboardIdPageDocument, baseOptions);
        }
export type LeaderboardIdPageQueryHookResult = ReturnType<typeof useLeaderboardIdPageQuery>;
export type LeaderboardIdPageLazyQueryHookResult = ReturnType<typeof useLeaderboardIdPageLazyQuery>;
export type LeaderboardIdPageQueryResult = Apollo.QueryResult<LeaderboardIdPageQuery, LeaderboardIdPageQueryVariables>;
export const LeaderboardIndexPageDocument = gql`
    query leaderboardIndexPage($page: Int!, $searchString: String, $year: String, $month: String, $week: String, $leaderboardId: ID, $state: String, $isCombatTeam: Boolean) {
  leaderboardAccounts(page: $page, searchString: $searchString, year: $year, month: $month, week: $week, leaderboardId: $leaderboardId, state: $state, isCombatTeam: $isCombatTeam) {
    count
    data {
      id
      score
      totalMatches
      team {
        id
        name
        icon
        tier
      }
      state
      leaderboard {
        beginningWeek
        endWeek
        month
        year
        totalPrize
        totalRegisteredTeamCounts
        state
      }
    }
  }
}
    `;

/**
 * __useLeaderboardIndexPageQuery__
 *
 * To run a query within a React component, call `useLeaderboardIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderboardIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderboardIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      week: // value for 'week'
 *      leaderboardId: // value for 'leaderboardId'
 *      state: // value for 'state'
 *      isCombatTeam: // value for 'isCombatTeam'
 *   },
 * });
 */
export function useLeaderboardIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<LeaderboardIndexPageQuery, LeaderboardIndexPageQueryVariables>) {
        return Apollo.useQuery<LeaderboardIndexPageQuery, LeaderboardIndexPageQueryVariables>(LeaderboardIndexPageDocument, baseOptions);
      }
export function useLeaderboardIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeaderboardIndexPageQuery, LeaderboardIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<LeaderboardIndexPageQuery, LeaderboardIndexPageQueryVariables>(LeaderboardIndexPageDocument, baseOptions);
        }
export type LeaderboardIndexPageQueryHookResult = ReturnType<typeof useLeaderboardIndexPageQuery>;
export type LeaderboardIndexPageLazyQueryHookResult = ReturnType<typeof useLeaderboardIndexPageLazyQuery>;
export type LeaderboardIndexPageQueryResult = Apollo.QueryResult<LeaderboardIndexPageQuery, LeaderboardIndexPageQueryVariables>;
export const LeaderboardCsvDocument = gql`
    query LeaderboardCsv($id: ID!) {
  leaderboardCsv(id: $id)
}
    `;

/**
 * __useLeaderboardCsvQuery__
 *
 * To run a query within a React component, call `useLeaderboardCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderboardCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderboardCsvQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaderboardCsvQuery(baseOptions?: Apollo.QueryHookOptions<LeaderboardCsvQuery, LeaderboardCsvQueryVariables>) {
        return Apollo.useQuery<LeaderboardCsvQuery, LeaderboardCsvQueryVariables>(LeaderboardCsvDocument, baseOptions);
      }
export function useLeaderboardCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeaderboardCsvQuery, LeaderboardCsvQueryVariables>) {
          return Apollo.useLazyQuery<LeaderboardCsvQuery, LeaderboardCsvQueryVariables>(LeaderboardCsvDocument, baseOptions);
        }
export type LeaderboardCsvQueryHookResult = ReturnType<typeof useLeaderboardCsvQuery>;
export type LeaderboardCsvLazyQueryHookResult = ReturnType<typeof useLeaderboardCsvLazyQuery>;
export type LeaderboardCsvQueryResult = Apollo.QueryResult<LeaderboardCsvQuery, LeaderboardCsvQueryVariables>;
export const WeekLeaderboardDocument = gql`
    query WeekLeaderboard {
  me {
    ...BasicAdmin
  }
  leaderboards {
    id
    beginningWeek
    endWeek
    numberOfWeek
    month
    year
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useWeekLeaderboardQuery__
 *
 * To run a query within a React component, call `useWeekLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeekLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeekLeaderboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useWeekLeaderboardQuery(baseOptions?: Apollo.QueryHookOptions<WeekLeaderboardQuery, WeekLeaderboardQueryVariables>) {
        return Apollo.useQuery<WeekLeaderboardQuery, WeekLeaderboardQueryVariables>(WeekLeaderboardDocument, baseOptions);
      }
export function useWeekLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeekLeaderboardQuery, WeekLeaderboardQueryVariables>) {
          return Apollo.useLazyQuery<WeekLeaderboardQuery, WeekLeaderboardQueryVariables>(WeekLeaderboardDocument, baseOptions);
        }
export type WeekLeaderboardQueryHookResult = ReturnType<typeof useWeekLeaderboardQuery>;
export type WeekLeaderboardLazyQueryHookResult = ReturnType<typeof useWeekLeaderboardLazyQuery>;
export type WeekLeaderboardQueryResult = Apollo.QueryResult<WeekLeaderboardQuery, WeekLeaderboardQueryVariables>;
export const LogsPageDocument = gql`
    query LogsPage($page: Int) {
  totalAdminLogs
  adminLogs(page: $page) {
    count
    data {
      ...AdminLog
    }
  }
}
    ${AdminLogFragmentDoc}`;

/**
 * __useLogsPageQuery__
 *
 * To run a query within a React component, call `useLogsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogsPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLogsPageQuery(baseOptions?: Apollo.QueryHookOptions<LogsPageQuery, LogsPageQueryVariables>) {
        return Apollo.useQuery<LogsPageQuery, LogsPageQueryVariables>(LogsPageDocument, baseOptions);
      }
export function useLogsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogsPageQuery, LogsPageQueryVariables>) {
          return Apollo.useLazyQuery<LogsPageQuery, LogsPageQueryVariables>(LogsPageDocument, baseOptions);
        }
export type LogsPageQueryHookResult = ReturnType<typeof useLogsPageQuery>;
export type LogsPageLazyQueryHookResult = ReturnType<typeof useLogsPageLazyQuery>;
export type LogsPageQueryResult = Apollo.QueryResult<LogsPageQuery, LogsPageQueryVariables>;
export const AdminLogsCsvDocument = gql`
    query AdminLogsCsv {
  adminLogsCsv
}
    `;

/**
 * __useAdminLogsCsvQuery__
 *
 * To run a query within a React component, call `useAdminLogsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLogsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLogsCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminLogsCsvQuery(baseOptions?: Apollo.QueryHookOptions<AdminLogsCsvQuery, AdminLogsCsvQueryVariables>) {
        return Apollo.useQuery<AdminLogsCsvQuery, AdminLogsCsvQueryVariables>(AdminLogsCsvDocument, baseOptions);
      }
export function useAdminLogsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminLogsCsvQuery, AdminLogsCsvQueryVariables>) {
          return Apollo.useLazyQuery<AdminLogsCsvQuery, AdminLogsCsvQueryVariables>(AdminLogsCsvDocument, baseOptions);
        }
export type AdminLogsCsvQueryHookResult = ReturnType<typeof useAdminLogsCsvQuery>;
export type AdminLogsCsvLazyQueryHookResult = ReturnType<typeof useAdminLogsCsvLazyQuery>;
export type AdminLogsCsvQueryResult = Apollo.QueryResult<AdminLogsCsvQuery, AdminLogsCsvQueryVariables>;
export const MarkupPricingIdPageDocument = gql`
    query MarkupPricingIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  markupPricing(id: $id) {
    ...MarkupPricing
  }
}
    ${BasicAdminFragmentDoc}
${MarkupPricingFragmentDoc}`;

/**
 * __useMarkupPricingIdPageQuery__
 *
 * To run a query within a React component, call `useMarkupPricingIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarkupPricingIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarkupPricingIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkupPricingIdPageQuery(baseOptions?: Apollo.QueryHookOptions<MarkupPricingIdPageQuery, MarkupPricingIdPageQueryVariables>) {
        return Apollo.useQuery<MarkupPricingIdPageQuery, MarkupPricingIdPageQueryVariables>(MarkupPricingIdPageDocument, baseOptions);
      }
export function useMarkupPricingIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarkupPricingIdPageQuery, MarkupPricingIdPageQueryVariables>) {
          return Apollo.useLazyQuery<MarkupPricingIdPageQuery, MarkupPricingIdPageQueryVariables>(MarkupPricingIdPageDocument, baseOptions);
        }
export type MarkupPricingIdPageQueryHookResult = ReturnType<typeof useMarkupPricingIdPageQuery>;
export type MarkupPricingIdPageLazyQueryHookResult = ReturnType<typeof useMarkupPricingIdPageLazyQuery>;
export type MarkupPricingIdPageQueryResult = Apollo.QueryResult<MarkupPricingIdPageQuery, MarkupPricingIdPageQueryVariables>;
export const MarkupPricingAccountsIndexPageDocument = gql`
    query MarkupPricingAccountsIndexPage($page: Int, $searchString: String) {
  markupPricingAccounts(page: $page, searchString: $searchString) {
    count
    data {
      ...MarkupPricing
    }
  }
}
    ${MarkupPricingFragmentDoc}`;

/**
 * __useMarkupPricingAccountsIndexPageQuery__
 *
 * To run a query within a React component, call `useMarkupPricingAccountsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarkupPricingAccountsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarkupPricingAccountsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useMarkupPricingAccountsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<MarkupPricingAccountsIndexPageQuery, MarkupPricingAccountsIndexPageQueryVariables>) {
        return Apollo.useQuery<MarkupPricingAccountsIndexPageQuery, MarkupPricingAccountsIndexPageQueryVariables>(MarkupPricingAccountsIndexPageDocument, baseOptions);
      }
export function useMarkupPricingAccountsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarkupPricingAccountsIndexPageQuery, MarkupPricingAccountsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<MarkupPricingAccountsIndexPageQuery, MarkupPricingAccountsIndexPageQueryVariables>(MarkupPricingAccountsIndexPageDocument, baseOptions);
        }
export type MarkupPricingAccountsIndexPageQueryHookResult = ReturnType<typeof useMarkupPricingAccountsIndexPageQuery>;
export type MarkupPricingAccountsIndexPageLazyQueryHookResult = ReturnType<typeof useMarkupPricingAccountsIndexPageLazyQuery>;
export type MarkupPricingAccountsIndexPageQueryResult = Apollo.QueryResult<MarkupPricingAccountsIndexPageQuery, MarkupPricingAccountsIndexPageQueryVariables>;
export const MatchMakingIdPageDocument = gql`
    query MatchMakingIdPage($id: ID!, $page: Int) {
  me {
    ...BasicAdmin
  }
  matchMaking(id: $id) {
    id
    matchCount
    state
    currency
    price
    minimumRankId
    maximumRankId
    maximumRank {
      ...GameRank
    }
    minimumRank {
      ...GameRank
    }
    queueLimit
    queueCount
    startTime
    endTime
    prosOnly
    post {
      ...Post
    }
    user {
      id
      nickname
      email
      phoneNumber
    }
    game {
      id
      name
    }
    bookingAccounts(page: $page) {
      count
      data {
        id
        state
        reporter {
          id
          nickname
          email
          phoneNumber
        }
        reportReason
        user {
          id
          nickname
          email
          phoneNumber
        }
        rating
        ratingRemark
      }
    }
  }
}
    ${BasicAdminFragmentDoc}
${GameRankFragmentDoc}
${PostFragmentDoc}`;

/**
 * __useMatchMakingIdPageQuery__
 *
 * To run a query within a React component, call `useMatchMakingIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchMakingIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchMakingIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useMatchMakingIdPageQuery(baseOptions?: Apollo.QueryHookOptions<MatchMakingIdPageQuery, MatchMakingIdPageQueryVariables>) {
        return Apollo.useQuery<MatchMakingIdPageQuery, MatchMakingIdPageQueryVariables>(MatchMakingIdPageDocument, baseOptions);
      }
export function useMatchMakingIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatchMakingIdPageQuery, MatchMakingIdPageQueryVariables>) {
          return Apollo.useLazyQuery<MatchMakingIdPageQuery, MatchMakingIdPageQueryVariables>(MatchMakingIdPageDocument, baseOptions);
        }
export type MatchMakingIdPageQueryHookResult = ReturnType<typeof useMatchMakingIdPageQuery>;
export type MatchMakingIdPageLazyQueryHookResult = ReturnType<typeof useMatchMakingIdPageLazyQuery>;
export type MatchMakingIdPageQueryResult = Apollo.QueryResult<MatchMakingIdPageQuery, MatchMakingIdPageQueryVariables>;
export const MatchMakingIndexPageDocument = gql`
    query MatchMakingIndexPage($page: Int) {
  matchMakingAccounts(page: $page) {
    count
    data {
      ...MatchMaking
    }
  }
}
    ${MatchMakingFragmentDoc}`;

/**
 * __useMatchMakingIndexPageQuery__
 *
 * To run a query within a React component, call `useMatchMakingIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchMakingIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchMakingIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useMatchMakingIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<MatchMakingIndexPageQuery, MatchMakingIndexPageQueryVariables>) {
        return Apollo.useQuery<MatchMakingIndexPageQuery, MatchMakingIndexPageQueryVariables>(MatchMakingIndexPageDocument, baseOptions);
      }
export function useMatchMakingIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatchMakingIndexPageQuery, MatchMakingIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<MatchMakingIndexPageQuery, MatchMakingIndexPageQueryVariables>(MatchMakingIndexPageDocument, baseOptions);
        }
export type MatchMakingIndexPageQueryHookResult = ReturnType<typeof useMatchMakingIndexPageQuery>;
export type MatchMakingIndexPageLazyQueryHookResult = ReturnType<typeof useMatchMakingIndexPageLazyQuery>;
export type MatchMakingIndexPageQueryResult = Apollo.QueryResult<MatchMakingIndexPageQuery, MatchMakingIndexPageQueryVariables>;
export const MerchandiseVariantIdPageDocument = gql`
    query MerchandiseVariantIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  merchandiseVariant(id: $id) {
    id
    name
    price
    position
    sku
    stock
    merchandise {
      id
      name
    }
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useMerchandiseVariantIdPageQuery__
 *
 * To run a query within a React component, call `useMerchandiseVariantIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchandiseVariantIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchandiseVariantIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchandiseVariantIdPageQuery(baseOptions?: Apollo.QueryHookOptions<MerchandiseVariantIdPageQuery, MerchandiseVariantIdPageQueryVariables>) {
        return Apollo.useQuery<MerchandiseVariantIdPageQuery, MerchandiseVariantIdPageQueryVariables>(MerchandiseVariantIdPageDocument, baseOptions);
      }
export function useMerchandiseVariantIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchandiseVariantIdPageQuery, MerchandiseVariantIdPageQueryVariables>) {
          return Apollo.useLazyQuery<MerchandiseVariantIdPageQuery, MerchandiseVariantIdPageQueryVariables>(MerchandiseVariantIdPageDocument, baseOptions);
        }
export type MerchandiseVariantIdPageQueryHookResult = ReturnType<typeof useMerchandiseVariantIdPageQuery>;
export type MerchandiseVariantIdPageLazyQueryHookResult = ReturnType<typeof useMerchandiseVariantIdPageLazyQuery>;
export type MerchandiseVariantIdPageQueryResult = Apollo.QueryResult<MerchandiseVariantIdPageQuery, MerchandiseVariantIdPageQueryVariables>;
export const MerchandiseIdPageDocument = gql`
    query MerchandiseIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  merchandise(id: $id) {
    ...Merchandise
  }
}
    ${BasicAdminFragmentDoc}
${MerchandiseFragmentDoc}`;

/**
 * __useMerchandiseIdPageQuery__
 *
 * To run a query within a React component, call `useMerchandiseIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchandiseIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchandiseIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchandiseIdPageQuery(baseOptions?: Apollo.QueryHookOptions<MerchandiseIdPageQuery, MerchandiseIdPageQueryVariables>) {
        return Apollo.useQuery<MerchandiseIdPageQuery, MerchandiseIdPageQueryVariables>(MerchandiseIdPageDocument, baseOptions);
      }
export function useMerchandiseIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchandiseIdPageQuery, MerchandiseIdPageQueryVariables>) {
          return Apollo.useLazyQuery<MerchandiseIdPageQuery, MerchandiseIdPageQueryVariables>(MerchandiseIdPageDocument, baseOptions);
        }
export type MerchandiseIdPageQueryHookResult = ReturnType<typeof useMerchandiseIdPageQuery>;
export type MerchandiseIdPageLazyQueryHookResult = ReturnType<typeof useMerchandiseIdPageLazyQuery>;
export type MerchandiseIdPageQueryResult = Apollo.QueryResult<MerchandiseIdPageQuery, MerchandiseIdPageQueryVariables>;
export const MerchandiseAccountsIndexPageDocument = gql`
    query MerchandiseAccountsIndexPage($page: Int, $searchString: String) {
  merchandiseAccounts(page: $page, searchString: $searchString) {
    count
    data {
      ...Merchandise
    }
  }
}
    ${MerchandiseFragmentDoc}`;

/**
 * __useMerchandiseAccountsIndexPageQuery__
 *
 * To run a query within a React component, call `useMerchandiseAccountsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchandiseAccountsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchandiseAccountsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useMerchandiseAccountsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<MerchandiseAccountsIndexPageQuery, MerchandiseAccountsIndexPageQueryVariables>) {
        return Apollo.useQuery<MerchandiseAccountsIndexPageQuery, MerchandiseAccountsIndexPageQueryVariables>(MerchandiseAccountsIndexPageDocument, baseOptions);
      }
export function useMerchandiseAccountsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchandiseAccountsIndexPageQuery, MerchandiseAccountsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<MerchandiseAccountsIndexPageQuery, MerchandiseAccountsIndexPageQueryVariables>(MerchandiseAccountsIndexPageDocument, baseOptions);
        }
export type MerchandiseAccountsIndexPageQueryHookResult = ReturnType<typeof useMerchandiseAccountsIndexPageQuery>;
export type MerchandiseAccountsIndexPageLazyQueryHookResult = ReturnType<typeof useMerchandiseAccountsIndexPageLazyQuery>;
export type MerchandiseAccountsIndexPageQueryResult = Apollo.QueryResult<MerchandiseAccountsIndexPageQuery, MerchandiseAccountsIndexPageQueryVariables>;
export const MonthlyReportHistoriesIndexPageDocument = gql`
    query MonthlyReportHistoriesIndexPage($page: Int, $level: String, $month: String, $year: String, $searchString: String!) {
  monthlyReportHistories(page: $page, level: $level, month: $month, year: $year, searchString: $searchString) {
    count
    data {
      ...MonthlyReport
    }
  }
}
    ${MonthlyReportFragmentDoc}`;

/**
 * __useMonthlyReportHistoriesIndexPageQuery__
 *
 * To run a query within a React component, call `useMonthlyReportHistoriesIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyReportHistoriesIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyReportHistoriesIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      level: // value for 'level'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useMonthlyReportHistoriesIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<MonthlyReportHistoriesIndexPageQuery, MonthlyReportHistoriesIndexPageQueryVariables>) {
        return Apollo.useQuery<MonthlyReportHistoriesIndexPageQuery, MonthlyReportHistoriesIndexPageQueryVariables>(MonthlyReportHistoriesIndexPageDocument, baseOptions);
      }
export function useMonthlyReportHistoriesIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyReportHistoriesIndexPageQuery, MonthlyReportHistoriesIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<MonthlyReportHistoriesIndexPageQuery, MonthlyReportHistoriesIndexPageQueryVariables>(MonthlyReportHistoriesIndexPageDocument, baseOptions);
        }
export type MonthlyReportHistoriesIndexPageQueryHookResult = ReturnType<typeof useMonthlyReportHistoriesIndexPageQuery>;
export type MonthlyReportHistoriesIndexPageLazyQueryHookResult = ReturnType<typeof useMonthlyReportHistoriesIndexPageLazyQuery>;
export type MonthlyReportHistoriesIndexPageQueryResult = Apollo.QueryResult<MonthlyReportHistoriesIndexPageQuery, MonthlyReportHistoriesIndexPageQueryVariables>;
export const MonthlyReportsIndexPageDocument = gql`
    query MonthlyReportsIndexPage($page: Int, $level: String) {
  monthlyReports(page: $page, level: $level) {
    count
    data {
      ...MonthlyReport
    }
  }
}
    ${MonthlyReportFragmentDoc}`;

/**
 * __useMonthlyReportsIndexPageQuery__
 *
 * To run a query within a React component, call `useMonthlyReportsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyReportsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyReportsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useMonthlyReportsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<MonthlyReportsIndexPageQuery, MonthlyReportsIndexPageQueryVariables>) {
        return Apollo.useQuery<MonthlyReportsIndexPageQuery, MonthlyReportsIndexPageQueryVariables>(MonthlyReportsIndexPageDocument, baseOptions);
      }
export function useMonthlyReportsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyReportsIndexPageQuery, MonthlyReportsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<MonthlyReportsIndexPageQuery, MonthlyReportsIndexPageQueryVariables>(MonthlyReportsIndexPageDocument, baseOptions);
        }
export type MonthlyReportsIndexPageQueryHookResult = ReturnType<typeof useMonthlyReportsIndexPageQuery>;
export type MonthlyReportsIndexPageLazyQueryHookResult = ReturnType<typeof useMonthlyReportsIndexPageLazyQuery>;
export type MonthlyReportsIndexPageQueryResult = Apollo.QueryResult<MonthlyReportsIndexPageQuery, MonthlyReportsIndexPageQueryVariables>;
export const NextLevelCountDocument = gql`
    query NextLevelCount {
  nextLevelCount {
    creepCount
    survivorCount
    conquerorCount
    dominatorCount
    savageCount
  }
}
    `;

/**
 * __useNextLevelCountQuery__
 *
 * To run a query within a React component, call `useNextLevelCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextLevelCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextLevelCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useNextLevelCountQuery(baseOptions?: Apollo.QueryHookOptions<NextLevelCountQuery, NextLevelCountQueryVariables>) {
        return Apollo.useQuery<NextLevelCountQuery, NextLevelCountQueryVariables>(NextLevelCountDocument, baseOptions);
      }
export function useNextLevelCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextLevelCountQuery, NextLevelCountQueryVariables>) {
          return Apollo.useLazyQuery<NextLevelCountQuery, NextLevelCountQueryVariables>(NextLevelCountDocument, baseOptions);
        }
export type NextLevelCountQueryHookResult = ReturnType<typeof useNextLevelCountQuery>;
export type NextLevelCountLazyQueryHookResult = ReturnType<typeof useNextLevelCountLazyQuery>;
export type NextLevelCountQueryResult = Apollo.QueryResult<NextLevelCountQuery, NextLevelCountQueryVariables>;
export const OfficialChannelIdPageDocument = gql`
    query OfficialChannelIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  officialChannel(id: $id) {
    ...OfficialChannel
  }
}
    ${BasicAdminFragmentDoc}
${OfficialChannelFragmentDoc}`;

/**
 * __useOfficialChannelIdPageQuery__
 *
 * To run a query within a React component, call `useOfficialChannelIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialChannelIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialChannelIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfficialChannelIdPageQuery(baseOptions?: Apollo.QueryHookOptions<OfficialChannelIdPageQuery, OfficialChannelIdPageQueryVariables>) {
        return Apollo.useQuery<OfficialChannelIdPageQuery, OfficialChannelIdPageQueryVariables>(OfficialChannelIdPageDocument, baseOptions);
      }
export function useOfficialChannelIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficialChannelIdPageQuery, OfficialChannelIdPageQueryVariables>) {
          return Apollo.useLazyQuery<OfficialChannelIdPageQuery, OfficialChannelIdPageQueryVariables>(OfficialChannelIdPageDocument, baseOptions);
        }
export type OfficialChannelIdPageQueryHookResult = ReturnType<typeof useOfficialChannelIdPageQuery>;
export type OfficialChannelIdPageLazyQueryHookResult = ReturnType<typeof useOfficialChannelIdPageLazyQuery>;
export type OfficialChannelIdPageQueryResult = Apollo.QueryResult<OfficialChannelIdPageQuery, OfficialChannelIdPageQueryVariables>;
export const OfficialChannelIndexPageDocument = gql`
    query OfficialChannelIndexPage {
  me {
    ...BasicAdmin
  }
  officialChannels {
    ...OfficialChannel
  }
}
    ${BasicAdminFragmentDoc}
${OfficialChannelFragmentDoc}`;

/**
 * __useOfficialChannelIndexPageQuery__
 *
 * To run a query within a React component, call `useOfficialChannelIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialChannelIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialChannelIndexPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfficialChannelIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<OfficialChannelIndexPageQuery, OfficialChannelIndexPageQueryVariables>) {
        return Apollo.useQuery<OfficialChannelIndexPageQuery, OfficialChannelIndexPageQueryVariables>(OfficialChannelIndexPageDocument, baseOptions);
      }
export function useOfficialChannelIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficialChannelIndexPageQuery, OfficialChannelIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<OfficialChannelIndexPageQuery, OfficialChannelIndexPageQueryVariables>(OfficialChannelIndexPageDocument, baseOptions);
        }
export type OfficialChannelIndexPageQueryHookResult = ReturnType<typeof useOfficialChannelIndexPageQuery>;
export type OfficialChannelIndexPageLazyQueryHookResult = ReturnType<typeof useOfficialChannelIndexPageLazyQuery>;
export type OfficialChannelIndexPageQueryResult = Apollo.QueryResult<OfficialChannelIndexPageQuery, OfficialChannelIndexPageQueryVariables>;
export const OrderIdPageDocument = gql`
    query OrderIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  order(id: $id) {
    id
    amount
    creditTransaction {
      ...CreditTransaction
    }
    status
    user {
      id
      nickname
      phoneNumber
      email
    }
    currency
    channel
    merchandiseVariant {
      id
      name
      price
      sku
      stock
      position
      merchandise {
        id
        name
        post {
          id
          url
          title
        }
      }
    }
    quantity
    trackingNumber
    trackingUrl
    createdAt
    shippingAddress {
      id
      addressName
      defaultAddress
      recipientName
      recipientPhoneNumber
      address1
      address2
      state
      town
      country
      postalCode
      longitude
      latitude
      user {
        id
        nickname
        phoneNumber
        email
      }
    }
  }
}
    ${BasicAdminFragmentDoc}
${CreditTransactionFragmentDoc}`;

/**
 * __useOrderIdPageQuery__
 *
 * To run a query within a React component, call `useOrderIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderIdPageQuery(baseOptions?: Apollo.QueryHookOptions<OrderIdPageQuery, OrderIdPageQueryVariables>) {
        return Apollo.useQuery<OrderIdPageQuery, OrderIdPageQueryVariables>(OrderIdPageDocument, baseOptions);
      }
export function useOrderIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderIdPageQuery, OrderIdPageQueryVariables>) {
          return Apollo.useLazyQuery<OrderIdPageQuery, OrderIdPageQueryVariables>(OrderIdPageDocument, baseOptions);
        }
export type OrderIdPageQueryHookResult = ReturnType<typeof useOrderIdPageQuery>;
export type OrderIdPageLazyQueryHookResult = ReturnType<typeof useOrderIdPageLazyQuery>;
export type OrderIdPageQueryResult = Apollo.QueryResult<OrderIdPageQuery, OrderIdPageQueryVariables>;
export const OrderIndexPageDocument = gql`
    query OrderIndexPage($page: Int, $searchString: String, $status: String) {
  orderAccounts(page: $page, searchString: $searchString, status: $status) {
    count
    data {
      id
      amount
      creditTransaction {
        ...CreditTransaction
      }
      status
      user {
        id
        nickname
        phoneNumber
        email
      }
      currency
      channel
      merchandiseVariant {
        id
        name
        price
        sku
        stock
      }
      quantity
      orderNumber
      trackingNumber
      trackingUrl
      createdAt
      shippingAddress {
        id
        addressName
        defaultAddress
        recipientName
        recipientPhoneNumber
        address1
        address2
        state
        town
        country
        postalCode
        longitude
        latitude
        user {
          id
          nickname
          phoneNumber
          email
        }
      }
    }
  }
}
    ${CreditTransactionFragmentDoc}`;

/**
 * __useOrderIndexPageQuery__
 *
 * To run a query within a React component, call `useOrderIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrderIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<OrderIndexPageQuery, OrderIndexPageQueryVariables>) {
        return Apollo.useQuery<OrderIndexPageQuery, OrderIndexPageQueryVariables>(OrderIndexPageDocument, baseOptions);
      }
export function useOrderIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderIndexPageQuery, OrderIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<OrderIndexPageQuery, OrderIndexPageQueryVariables>(OrderIndexPageDocument, baseOptions);
        }
export type OrderIndexPageQueryHookResult = ReturnType<typeof useOrderIndexPageQuery>;
export type OrderIndexPageLazyQueryHookResult = ReturnType<typeof useOrderIndexPageLazyQuery>;
export type OrderIndexPageQueryResult = Apollo.QueryResult<OrderIndexPageQuery, OrderIndexPageQueryVariables>;
export const RequestIdPageDocument = gql`
    query RequestIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  partneringRequest(id: $id) {
    id
    promoCode
    totalOriginalAmount
    pilot {
      id
      email
      nickname
      phoneNumber
      creditWallet {
        amount
      }
    }
    user {
      id
      email
      nickname
      phoneNumber
    }
    reporter {
      id
      nickname
    }
    game {
      id
      name
    }
    gameRank {
      id
      name
      imageUrl
      rankStarMaxCount
    }
    requestedPilots {
      id
      email
      nickname
      phoneNumber
    }
    createdAt
    totalAmount
    rating
    ratingRemark
    rankTier
    rankStar
    rankPoint
    totalDurationInMinutes
    startTime
    completeTime
    state
    createdAt
    reportReason
    chatroom {
      id
      messages {
        id
        createdAt
        text
        imageUrl
        user {
          id
          nickname
        }
      }
    }
    perspectives {
      id
      name
    }
    queues {
      id
      name
    }
    proficientRoles {
      id
      name
    }
    communicationRating
    skillRating
    durationHour
    adminNote
    pilotCount
    matchCount
    pilotServices {
      pilot {
        id
        nickname
      }
      state
      createdAt
    }
    pilotNicknames
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useRequestIdPageQuery__
 *
 * To run a query within a React component, call `useRequestIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRequestIdPageQuery(baseOptions?: Apollo.QueryHookOptions<RequestIdPageQuery, RequestIdPageQueryVariables>) {
        return Apollo.useQuery<RequestIdPageQuery, RequestIdPageQueryVariables>(RequestIdPageDocument, baseOptions);
      }
export function useRequestIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestIdPageQuery, RequestIdPageQueryVariables>) {
          return Apollo.useLazyQuery<RequestIdPageQuery, RequestIdPageQueryVariables>(RequestIdPageDocument, baseOptions);
        }
export type RequestIdPageQueryHookResult = ReturnType<typeof useRequestIdPageQuery>;
export type RequestIdPageLazyQueryHookResult = ReturnType<typeof useRequestIdPageLazyQuery>;
export type RequestIdPageQueryResult = Apollo.QueryResult<RequestIdPageQuery, RequestIdPageQueryVariables>;
export const AllPartneringRequestsPageDocument = gql`
    query AllPartneringRequestsPage($page: Int, $filterWhere: FilterPartneringQuery, $state: String, $suspicious: Boolean) {
  partneringRequestsIndex(page: $page, filterWhere: $filterWhere, state: $state, suspicious: $suspicious) {
    count
    data {
      id
      adminNote
      pilot {
        id
        phoneNumber
        nickname
      }
      user {
        id
        phoneNumber
        nickname
      }
      game {
        id
        name
      }
      requestedPilots {
        id
        phoneNumber
      }
      state
      createdAt
      pilotCount
      matchCount
      pilotNicknames
      pilotServices {
        pilot {
          id
          nickname
        }
      }
    }
  }
}
    `;

/**
 * __useAllPartneringRequestsPageQuery__
 *
 * To run a query within a React component, call `useAllPartneringRequestsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPartneringRequestsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPartneringRequestsPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filterWhere: // value for 'filterWhere'
 *      state: // value for 'state'
 *      suspicious: // value for 'suspicious'
 *   },
 * });
 */
export function useAllPartneringRequestsPageQuery(baseOptions?: Apollo.QueryHookOptions<AllPartneringRequestsPageQuery, AllPartneringRequestsPageQueryVariables>) {
        return Apollo.useQuery<AllPartneringRequestsPageQuery, AllPartneringRequestsPageQueryVariables>(AllPartneringRequestsPageDocument, baseOptions);
      }
export function useAllPartneringRequestsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPartneringRequestsPageQuery, AllPartneringRequestsPageQueryVariables>) {
          return Apollo.useLazyQuery<AllPartneringRequestsPageQuery, AllPartneringRequestsPageQueryVariables>(AllPartneringRequestsPageDocument, baseOptions);
        }
export type AllPartneringRequestsPageQueryHookResult = ReturnType<typeof useAllPartneringRequestsPageQuery>;
export type AllPartneringRequestsPageLazyQueryHookResult = ReturnType<typeof useAllPartneringRequestsPageLazyQuery>;
export type AllPartneringRequestsPageQueryResult = Apollo.QueryResult<AllPartneringRequestsPageQuery, AllPartneringRequestsPageQueryVariables>;
export const PayoutIdPageDocument = gql`
    query PayoutIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  payout(id: $id) {
    id
    amount
    recipient
    bank
    bankAccountNo
    approve
    createdAt
    state
    rejectReason
    adminNote
    pilot {
      id
      phoneNumber
      nickname
      email
      avatarUrl
      language
      gender
      trained
      creditWallet {
        ...CreditWallet
      }
      bankInfo {
        ...BankInfo
      }
      adminNote
    }
  }
}
    ${BasicAdminFragmentDoc}
${CreditWalletFragmentDoc}
${BankInfoFragmentDoc}`;

/**
 * __usePayoutIdPageQuery__
 *
 * To run a query within a React component, call `usePayoutIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePayoutIdPageQuery(baseOptions?: Apollo.QueryHookOptions<PayoutIdPageQuery, PayoutIdPageQueryVariables>) {
        return Apollo.useQuery<PayoutIdPageQuery, PayoutIdPageQueryVariables>(PayoutIdPageDocument, baseOptions);
      }
export function usePayoutIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayoutIdPageQuery, PayoutIdPageQueryVariables>) {
          return Apollo.useLazyQuery<PayoutIdPageQuery, PayoutIdPageQueryVariables>(PayoutIdPageDocument, baseOptions);
        }
export type PayoutIdPageQueryHookResult = ReturnType<typeof usePayoutIdPageQuery>;
export type PayoutIdPageLazyQueryHookResult = ReturnType<typeof usePayoutIdPageLazyQuery>;
export type PayoutIdPageQueryResult = Apollo.QueryResult<PayoutIdPageQuery, PayoutIdPageQueryVariables>;
export const AllPayoutsPageDocument = gql`
    query AllPayoutsPage($approve: Boolean, $page: Int, $searchString: String, $state: String) {
  payouts(approve: $approve, page: $page, searchString: $searchString, state: $state) {
    count
    data {
      id
      recipient
      email
      phone
      bank
      bankAccountNo
      amount
      approve
      createdAt
      state
      adminNote
    }
  }
}
    `;

/**
 * __useAllPayoutsPageQuery__
 *
 * To run a query within a React component, call `useAllPayoutsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPayoutsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPayoutsPageQuery({
 *   variables: {
 *      approve: // value for 'approve'
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAllPayoutsPageQuery(baseOptions?: Apollo.QueryHookOptions<AllPayoutsPageQuery, AllPayoutsPageQueryVariables>) {
        return Apollo.useQuery<AllPayoutsPageQuery, AllPayoutsPageQueryVariables>(AllPayoutsPageDocument, baseOptions);
      }
export function useAllPayoutsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPayoutsPageQuery, AllPayoutsPageQueryVariables>) {
          return Apollo.useLazyQuery<AllPayoutsPageQuery, AllPayoutsPageQueryVariables>(AllPayoutsPageDocument, baseOptions);
        }
export type AllPayoutsPageQueryHookResult = ReturnType<typeof useAllPayoutsPageQuery>;
export type AllPayoutsPageLazyQueryHookResult = ReturnType<typeof useAllPayoutsPageLazyQuery>;
export type AllPayoutsPageQueryResult = Apollo.QueryResult<AllPayoutsPageQuery, AllPayoutsPageQueryVariables>;
export const PayoutsCsvDocument = gql`
    query PayoutsCsv {
  payoutsCsv
}
    `;

/**
 * __usePayoutsCsvQuery__
 *
 * To run a query within a React component, call `usePayoutsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutsCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayoutsCsvQuery(baseOptions?: Apollo.QueryHookOptions<PayoutsCsvQuery, PayoutsCsvQueryVariables>) {
        return Apollo.useQuery<PayoutsCsvQuery, PayoutsCsvQueryVariables>(PayoutsCsvDocument, baseOptions);
      }
export function usePayoutsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayoutsCsvQuery, PayoutsCsvQueryVariables>) {
          return Apollo.useLazyQuery<PayoutsCsvQuery, PayoutsCsvQueryVariables>(PayoutsCsvDocument, baseOptions);
        }
export type PayoutsCsvQueryHookResult = ReturnType<typeof usePayoutsCsvQuery>;
export type PayoutsCsvLazyQueryHookResult = ReturnType<typeof usePayoutsCsvLazyQuery>;
export type PayoutsCsvQueryResult = Apollo.QueryResult<PayoutsCsvQuery, PayoutsCsvQueryVariables>;
export const PilotBannerIdPageDocument = gql`
    query PilotBannerIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  pilotBanner(id: $id) {
    id
    title
    createdAt
    url
    image
    active
    screen
    poster
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __usePilotBannerIdPageQuery__
 *
 * To run a query within a React component, call `usePilotBannerIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotBannerIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotBannerIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePilotBannerIdPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotBannerIdPageQuery, PilotBannerIdPageQueryVariables>) {
        return Apollo.useQuery<PilotBannerIdPageQuery, PilotBannerIdPageQueryVariables>(PilotBannerIdPageDocument, baseOptions);
      }
export function usePilotBannerIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotBannerIdPageQuery, PilotBannerIdPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotBannerIdPageQuery, PilotBannerIdPageQueryVariables>(PilotBannerIdPageDocument, baseOptions);
        }
export type PilotBannerIdPageQueryHookResult = ReturnType<typeof usePilotBannerIdPageQuery>;
export type PilotBannerIdPageLazyQueryHookResult = ReturnType<typeof usePilotBannerIdPageLazyQuery>;
export type PilotBannerIdPageQueryResult = Apollo.QueryResult<PilotBannerIdPageQuery, PilotBannerIdPageQueryVariables>;
export const PilotBannerIndexPageDocument = gql`
    query PilotBannerIndexPage {
  me {
    ...BasicAdmin
  }
  pilotBanners {
    id
    title
    createdAt
    url
    image
    active
    screen
    poster
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __usePilotBannerIndexPageQuery__
 *
 * To run a query within a React component, call `usePilotBannerIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotBannerIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotBannerIndexPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePilotBannerIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotBannerIndexPageQuery, PilotBannerIndexPageQueryVariables>) {
        return Apollo.useQuery<PilotBannerIndexPageQuery, PilotBannerIndexPageQueryVariables>(PilotBannerIndexPageDocument, baseOptions);
      }
export function usePilotBannerIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotBannerIndexPageQuery, PilotBannerIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotBannerIndexPageQuery, PilotBannerIndexPageQueryVariables>(PilotBannerIndexPageDocument, baseOptions);
        }
export type PilotBannerIndexPageQueryHookResult = ReturnType<typeof usePilotBannerIndexPageQuery>;
export type PilotBannerIndexPageLazyQueryHookResult = ReturnType<typeof usePilotBannerIndexPageLazyQuery>;
export type PilotBannerIndexPageQueryResult = Apollo.QueryResult<PilotBannerIndexPageQuery, PilotBannerIndexPageQueryVariables>;
export const PilotLevelIdPageDocument = gql`
    query PilotLevelIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  pilotLevel(id: $id) {
    ...PilotLevel
  }
}
    ${BasicAdminFragmentDoc}
${PilotLevelFragmentDoc}`;

/**
 * __usePilotLevelIdPageQuery__
 *
 * To run a query within a React component, call `usePilotLevelIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotLevelIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotLevelIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePilotLevelIdPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotLevelIdPageQuery, PilotLevelIdPageQueryVariables>) {
        return Apollo.useQuery<PilotLevelIdPageQuery, PilotLevelIdPageQueryVariables>(PilotLevelIdPageDocument, baseOptions);
      }
export function usePilotLevelIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotLevelIdPageQuery, PilotLevelIdPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotLevelIdPageQuery, PilotLevelIdPageQueryVariables>(PilotLevelIdPageDocument, baseOptions);
        }
export type PilotLevelIdPageQueryHookResult = ReturnType<typeof usePilotLevelIdPageQuery>;
export type PilotLevelIdPageLazyQueryHookResult = ReturnType<typeof usePilotLevelIdPageLazyQuery>;
export type PilotLevelIdPageQueryResult = Apollo.QueryResult<PilotLevelIdPageQuery, PilotLevelIdPageQueryVariables>;
export const PilotLevelIndexPageDocument = gql`
    query PilotLevelIndexPage {
  me {
    ...BasicAdmin
  }
  pilotLevels {
    ...PilotLevel
  }
}
    ${BasicAdminFragmentDoc}
${PilotLevelFragmentDoc}`;

/**
 * __usePilotLevelIndexPageQuery__
 *
 * To run a query within a React component, call `usePilotLevelIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotLevelIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotLevelIndexPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePilotLevelIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotLevelIndexPageQuery, PilotLevelIndexPageQueryVariables>) {
        return Apollo.useQuery<PilotLevelIndexPageQuery, PilotLevelIndexPageQueryVariables>(PilotLevelIndexPageDocument, baseOptions);
      }
export function usePilotLevelIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotLevelIndexPageQuery, PilotLevelIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotLevelIndexPageQuery, PilotLevelIndexPageQueryVariables>(PilotLevelIndexPageDocument, baseOptions);
        }
export type PilotLevelIndexPageQueryHookResult = ReturnType<typeof usePilotLevelIndexPageQuery>;
export type PilotLevelIndexPageLazyQueryHookResult = ReturnType<typeof usePilotLevelIndexPageLazyQuery>;
export type PilotLevelIndexPageQueryResult = Apollo.QueryResult<PilotLevelIndexPageQuery, PilotLevelIndexPageQueryVariables>;
export const PilotRewardIdPageDocument = gql`
    query PilotRewardIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  pilotReward(id: $id) {
    id
    state
    reward {
      id
      name
      pilotLevel {
        ...PilotLevel
      }
      rewardType
    }
    monthlyReport {
      ...MonthlyReport
    }
  }
}
    ${BasicAdminFragmentDoc}
${PilotLevelFragmentDoc}
${MonthlyReportFragmentDoc}`;

/**
 * __usePilotRewardIdPageQuery__
 *
 * To run a query within a React component, call `usePilotRewardIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotRewardIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotRewardIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePilotRewardIdPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotRewardIdPageQuery, PilotRewardIdPageQueryVariables>) {
        return Apollo.useQuery<PilotRewardIdPageQuery, PilotRewardIdPageQueryVariables>(PilotRewardIdPageDocument, baseOptions);
      }
export function usePilotRewardIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotRewardIdPageQuery, PilotRewardIdPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotRewardIdPageQuery, PilotRewardIdPageQueryVariables>(PilotRewardIdPageDocument, baseOptions);
        }
export type PilotRewardIdPageQueryHookResult = ReturnType<typeof usePilotRewardIdPageQuery>;
export type PilotRewardIdPageLazyQueryHookResult = ReturnType<typeof usePilotRewardIdPageLazyQuery>;
export type PilotRewardIdPageQueryResult = Apollo.QueryResult<PilotRewardIdPageQuery, PilotRewardIdPageQueryVariables>;
export const PilotRewardIndexPageDocument = gql`
    query PilotRewardIndexPage($page: Int!, $searchString: String) {
  pilotRewardAccounts(page: $page, searchString: $searchString) {
    count
    data {
      id
      state
      reward {
        id
        name
        pilotLevel {
          ...PilotLevel
        }
        rewardType
      }
      monthlyReport {
        ...MonthlyReport
      }
      fullName
      address
      icNumber
      inGameName
      inGameId
      remark
      createdAt
      pilotLevel {
        ...PilotLevel
      }
      pilot {
        id
        email
        phoneNumber
        nickname
      }
    }
  }
}
    ${PilotLevelFragmentDoc}
${MonthlyReportFragmentDoc}`;

/**
 * __usePilotRewardIndexPageQuery__
 *
 * To run a query within a React component, call `usePilotRewardIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotRewardIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotRewardIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function usePilotRewardIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotRewardIndexPageQuery, PilotRewardIndexPageQueryVariables>) {
        return Apollo.useQuery<PilotRewardIndexPageQuery, PilotRewardIndexPageQueryVariables>(PilotRewardIndexPageDocument, baseOptions);
      }
export function usePilotRewardIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotRewardIndexPageQuery, PilotRewardIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotRewardIndexPageQuery, PilotRewardIndexPageQueryVariables>(PilotRewardIndexPageDocument, baseOptions);
        }
export type PilotRewardIndexPageQueryHookResult = ReturnType<typeof usePilotRewardIndexPageQuery>;
export type PilotRewardIndexPageLazyQueryHookResult = ReturnType<typeof usePilotRewardIndexPageLazyQuery>;
export type PilotRewardIndexPageQueryResult = Apollo.QueryResult<PilotRewardIndexPageQuery, PilotRewardIndexPageQueryVariables>;
export const PilotTierAccountsIndexPageDocument = gql`
    query pilotTierAccountsIndexPage($pilotTierId: ID!, $page: Int!, $searchString: String, $gameName: String, $gameId: String!) {
  pilotTierAccounts(pilotTierId: $pilotTierId, page: $page, searchString: $searchString, gameName: $gameName, gameId: $gameId) {
    count
    data {
      id
      accountId
      gameRank {
        id
        name
      }
      game {
        id
        name
      }
      pilot {
        id
        email
        phoneNumber
        nickname
        trained
        creditWallet {
          amount
        }
        online
        bankInfo {
          ...BankInfo
        }
      }
      pilotTier {
        ...PilotTier
      }
      screenshotState
      aggregateRating
      completeGameBundlesCount
      completePartneringRequestsCount
    }
  }
}
    ${BankInfoFragmentDoc}
${PilotTierFragmentDoc}`;

/**
 * __usePilotTierAccountsIndexPageQuery__
 *
 * To run a query within a React component, call `usePilotTierAccountsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotTierAccountsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotTierAccountsIndexPageQuery({
 *   variables: {
 *      pilotTierId: // value for 'pilotTierId'
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      gameName: // value for 'gameName'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function usePilotTierAccountsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotTierAccountsIndexPageQuery, PilotTierAccountsIndexPageQueryVariables>) {
        return Apollo.useQuery<PilotTierAccountsIndexPageQuery, PilotTierAccountsIndexPageQueryVariables>(PilotTierAccountsIndexPageDocument, baseOptions);
      }
export function usePilotTierAccountsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotTierAccountsIndexPageQuery, PilotTierAccountsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotTierAccountsIndexPageQuery, PilotTierAccountsIndexPageQueryVariables>(PilotTierAccountsIndexPageDocument, baseOptions);
        }
export type PilotTierAccountsIndexPageQueryHookResult = ReturnType<typeof usePilotTierAccountsIndexPageQuery>;
export type PilotTierAccountsIndexPageLazyQueryHookResult = ReturnType<typeof usePilotTierAccountsIndexPageLazyQuery>;
export type PilotTierAccountsIndexPageQueryResult = Apollo.QueryResult<PilotTierAccountsIndexPageQuery, PilotTierAccountsIndexPageQueryVariables>;
export const PilotTierAccountCsvDocument = gql`
    query PilotTierAccountCsv {
  pilotTierAccountCsv
}
    `;

/**
 * __usePilotTierAccountCsvQuery__
 *
 * To run a query within a React component, call `usePilotTierAccountCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotTierAccountCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotTierAccountCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function usePilotTierAccountCsvQuery(baseOptions?: Apollo.QueryHookOptions<PilotTierAccountCsvQuery, PilotTierAccountCsvQueryVariables>) {
        return Apollo.useQuery<PilotTierAccountCsvQuery, PilotTierAccountCsvQueryVariables>(PilotTierAccountCsvDocument, baseOptions);
      }
export function usePilotTierAccountCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotTierAccountCsvQuery, PilotTierAccountCsvQueryVariables>) {
          return Apollo.useLazyQuery<PilotTierAccountCsvQuery, PilotTierAccountCsvQueryVariables>(PilotTierAccountCsvDocument, baseOptions);
        }
export type PilotTierAccountCsvQueryHookResult = ReturnType<typeof usePilotTierAccountCsvQuery>;
export type PilotTierAccountCsvLazyQueryHookResult = ReturnType<typeof usePilotTierAccountCsvLazyQuery>;
export type PilotTierAccountCsvQueryResult = Apollo.QueryResult<PilotTierAccountCsvQuery, PilotTierAccountCsvQueryVariables>;
export const AllPilotTierDocument = gql`
    query AllPilotTier {
  me {
    ...BasicAdmin
  }
  pilotTiers {
    id
    name
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useAllPilotTierQuery__
 *
 * To run a query within a React component, call `useAllPilotTierQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPilotTierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPilotTierQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPilotTierQuery(baseOptions?: Apollo.QueryHookOptions<AllPilotTierQuery, AllPilotTierQueryVariables>) {
        return Apollo.useQuery<AllPilotTierQuery, AllPilotTierQueryVariables>(AllPilotTierDocument, baseOptions);
      }
export function useAllPilotTierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPilotTierQuery, AllPilotTierQueryVariables>) {
          return Apollo.useLazyQuery<AllPilotTierQuery, AllPilotTierQueryVariables>(AllPilotTierDocument, baseOptions);
        }
export type AllPilotTierQueryHookResult = ReturnType<typeof useAllPilotTierQuery>;
export type AllPilotTierLazyQueryHookResult = ReturnType<typeof useAllPilotTierLazyQuery>;
export type AllPilotTierQueryResult = Apollo.QueryResult<AllPilotTierQuery, AllPilotTierQueryVariables>;
export const PilotsVerificationPageDocument = gql`
    query pilotsVerificationPage($filterWhere: FilterQuery, $searchString: String, $approve: Boolean, $gameName: String, $rankName: String, $bankInfoState: BankInfoState, $page: Int) {
  gameAccounts(filterWhere: $filterWhere, searchString: $searchString, approve: $approve, gameName: $gameName, rankName: $rankName, bankInfoState: $bankInfoState, page: $page) {
    count
    data {
      id
      codeState
      screenshotState
      testState
      approve
      createdAt
      updatedAt
      gameRank {
        id
        name
      }
      game {
        id
        name
      }
      pilot {
        id
        nickname
        bankInfo {
          id
          state
        }
      }
    }
  }
}
    `;

/**
 * __usePilotsVerificationPageQuery__
 *
 * To run a query within a React component, call `usePilotsVerificationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotsVerificationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotsVerificationPageQuery({
 *   variables: {
 *      filterWhere: // value for 'filterWhere'
 *      searchString: // value for 'searchString'
 *      approve: // value for 'approve'
 *      gameName: // value for 'gameName'
 *      rankName: // value for 'rankName'
 *      bankInfoState: // value for 'bankInfoState'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePilotsVerificationPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotsVerificationPageQuery, PilotsVerificationPageQueryVariables>) {
        return Apollo.useQuery<PilotsVerificationPageQuery, PilotsVerificationPageQueryVariables>(PilotsVerificationPageDocument, baseOptions);
      }
export function usePilotsVerificationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotsVerificationPageQuery, PilotsVerificationPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotsVerificationPageQuery, PilotsVerificationPageQueryVariables>(PilotsVerificationPageDocument, baseOptions);
        }
export type PilotsVerificationPageQueryHookResult = ReturnType<typeof usePilotsVerificationPageQuery>;
export type PilotsVerificationPageLazyQueryHookResult = ReturnType<typeof usePilotsVerificationPageLazyQuery>;
export type PilotsVerificationPageQueryResult = Apollo.QueryResult<PilotsVerificationPageQuery, PilotsVerificationPageQueryVariables>;
export const PilotsIdPageDocument = gql`
    query pilotsIdPage($id: ID!) {
  pilot(id: $id) {
    id
    phoneNumber
    nickname
    email
    gender
    avatarUrl
    language
    updatedAt
    createdAt
    aggregateRating
    averageRespondTime
    completeGameBundlesCount
    completePartneringRequestsCount
    suspend
    trained
    beStar
    bePro
    beKol
    verified
    bankInfo {
      ...BankInfo
    }
    adminNote
    gameAccounts {
      id
      accountId
      verificationCode
      codeState
      screenshotState
      testState
      screenshotUrl
      createdAt
      approve
      updatedAt
      testScore
      remindAt
      remindCount
      rejectReason
      gameRank {
        id
        name
        rankStarMaxCount
        rankPointMaxCount
      }
      rankTier
      rankStar
      rankPoint
      game {
        id
        name
        developerName
        testPassingScore
      }
      pairableGameRanks {
        id
        name
      }
    }
    creditWallet {
      ...CreditWallet
    }
    suspendRequests {
      id
      reason
      state
      createdAt
    }
  }
}
    ${BankInfoFragmentDoc}
${CreditWalletFragmentDoc}`;

/**
 * __usePilotsIdPageQuery__
 *
 * To run a query within a React component, call `usePilotsIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotsIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotsIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePilotsIdPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotsIdPageQuery, PilotsIdPageQueryVariables>) {
        return Apollo.useQuery<PilotsIdPageQuery, PilotsIdPageQueryVariables>(PilotsIdPageDocument, baseOptions);
      }
export function usePilotsIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotsIdPageQuery, PilotsIdPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotsIdPageQuery, PilotsIdPageQueryVariables>(PilotsIdPageDocument, baseOptions);
        }
export type PilotsIdPageQueryHookResult = ReturnType<typeof usePilotsIdPageQuery>;
export type PilotsIdPageLazyQueryHookResult = ReturnType<typeof usePilotsIdPageLazyQuery>;
export type PilotsIdPageQueryResult = Apollo.QueryResult<PilotsIdPageQuery, PilotsIdPageQueryVariables>;
export const PilotsIndexPageDocument = gql`
    query pilotsIndexPage($approve: Boolean, $gameName: String, $rankName: String, $page: Int, $searchString: String, $filterWhere: FilterQuery, $suspended: Boolean, $trained: Boolean, $streamer: Boolean, $online: Boolean) {
  gameAccounts(approve: $approve, gameName: $gameName, rankName: $rankName, page: $page, searchString: $searchString, filterWhere: $filterWhere, suspended: $suspended, trained: $trained, streamer: $streamer, online: $online) {
    count
    data {
      id
      accountId
      gameRank {
        id
        name
      }
      game {
        id
        name
      }
      pilot {
        id
        email
        phoneNumber
        nickname
        trained
        creditWallet {
          amount
        }
        adminNote
        online
        bankInfo {
          ...BankInfo
        }
      }
      screenshotState
    }
  }
}
    ${BankInfoFragmentDoc}`;

/**
 * __usePilotsIndexPageQuery__
 *
 * To run a query within a React component, call `usePilotsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotsIndexPageQuery({
 *   variables: {
 *      approve: // value for 'approve'
 *      gameName: // value for 'gameName'
 *      rankName: // value for 'rankName'
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      filterWhere: // value for 'filterWhere'
 *      suspended: // value for 'suspended'
 *      trained: // value for 'trained'
 *      streamer: // value for 'streamer'
 *      online: // value for 'online'
 *   },
 * });
 */
export function usePilotsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<PilotsIndexPageQuery, PilotsIndexPageQueryVariables>) {
        return Apollo.useQuery<PilotsIndexPageQuery, PilotsIndexPageQueryVariables>(PilotsIndexPageDocument, baseOptions);
      }
export function usePilotsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotsIndexPageQuery, PilotsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<PilotsIndexPageQuery, PilotsIndexPageQueryVariables>(PilotsIndexPageDocument, baseOptions);
        }
export type PilotsIndexPageQueryHookResult = ReturnType<typeof usePilotsIndexPageQuery>;
export type PilotsIndexPageLazyQueryHookResult = ReturnType<typeof usePilotsIndexPageLazyQuery>;
export type PilotsIndexPageQueryResult = Apollo.QueryResult<PilotsIndexPageQuery, PilotsIndexPageQueryVariables>;
export const PilotsCsvDocument = gql`
    query pilotsCsv {
  pilotsCsv
}
    `;

/**
 * __usePilotsCsvQuery__
 *
 * To run a query within a React component, call `usePilotsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotsCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function usePilotsCsvQuery(baseOptions?: Apollo.QueryHookOptions<PilotsCsvQuery, PilotsCsvQueryVariables>) {
        return Apollo.useQuery<PilotsCsvQuery, PilotsCsvQueryVariables>(PilotsCsvDocument, baseOptions);
      }
export function usePilotsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotsCsvQuery, PilotsCsvQueryVariables>) {
          return Apollo.useLazyQuery<PilotsCsvQuery, PilotsCsvQueryVariables>(PilotsCsvDocument, baseOptions);
        }
export type PilotsCsvQueryHookResult = ReturnType<typeof usePilotsCsvQuery>;
export type PilotsCsvLazyQueryHookResult = ReturnType<typeof usePilotsCsvLazyQuery>;
export type PilotsCsvQueryResult = Apollo.QueryResult<PilotsCsvQuery, PilotsCsvQueryVariables>;
export const PilotRecordsDocument = gql`
    query PilotRecords($id: ID!, $page: Int!) {
  pilot(id: $id) {
    id
    phoneNumber
    nickname
    email
    creditWallet {
      amount
      id
    }
    pilotGameBundles(page: $page) {
      count
      data {
        id
        pilot {
          id
          phoneNumber
          nickname
        }
        user {
          id
          phoneNumber
          nickname
        }
        game {
          id
          name
        }
        state
        createdAt
      }
    }
    pilotAcceptedPartneringRequests(page: $page) {
      count
      data {
        id
        adminNote
        pilot {
          id
          phoneNumber
          nickname
        }
        user {
          id
          phoneNumber
          nickname
        }
        game {
          id
          name
        }
        state
        createdAt
      }
    }
    pilotCreditTransactions(page: $page) {
      count
      data {
        ...CreditTransaction
      }
    }
  }
}
    ${CreditTransactionFragmentDoc}`;

/**
 * __usePilotRecordsQuery__
 *
 * To run a query within a React component, call `usePilotRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePilotRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePilotRecordsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePilotRecordsQuery(baseOptions?: Apollo.QueryHookOptions<PilotRecordsQuery, PilotRecordsQueryVariables>) {
        return Apollo.useQuery<PilotRecordsQuery, PilotRecordsQueryVariables>(PilotRecordsDocument, baseOptions);
      }
export function usePilotRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PilotRecordsQuery, PilotRecordsQueryVariables>) {
          return Apollo.useLazyQuery<PilotRecordsQuery, PilotRecordsQueryVariables>(PilotRecordsDocument, baseOptions);
        }
export type PilotRecordsQueryHookResult = ReturnType<typeof usePilotRecordsQuery>;
export type PilotRecordsLazyQueryHookResult = ReturnType<typeof usePilotRecordsLazyQuery>;
export type PilotRecordsQueryResult = Apollo.QueryResult<PilotRecordsQuery, PilotRecordsQueryVariables>;
export const PoolPrizeIdPageDocument = gql`
    query PoolPrizeIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  poolPrize(id: $id) {
    ...PoolPrize
  }
}
    ${BasicAdminFragmentDoc}
${PoolPrizeFragmentDoc}`;

/**
 * __usePoolPrizeIdPageQuery__
 *
 * To run a query within a React component, call `usePoolPrizeIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoolPrizeIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoolPrizeIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePoolPrizeIdPageQuery(baseOptions?: Apollo.QueryHookOptions<PoolPrizeIdPageQuery, PoolPrizeIdPageQueryVariables>) {
        return Apollo.useQuery<PoolPrizeIdPageQuery, PoolPrizeIdPageQueryVariables>(PoolPrizeIdPageDocument, baseOptions);
      }
export function usePoolPrizeIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PoolPrizeIdPageQuery, PoolPrizeIdPageQueryVariables>) {
          return Apollo.useLazyQuery<PoolPrizeIdPageQuery, PoolPrizeIdPageQueryVariables>(PoolPrizeIdPageDocument, baseOptions);
        }
export type PoolPrizeIdPageQueryHookResult = ReturnType<typeof usePoolPrizeIdPageQuery>;
export type PoolPrizeIdPageLazyQueryHookResult = ReturnType<typeof usePoolPrizeIdPageLazyQuery>;
export type PoolPrizeIdPageQueryResult = Apollo.QueryResult<PoolPrizeIdPageQuery, PoolPrizeIdPageQueryVariables>;
export const PostIdPageDocument = gql`
    query PostIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  post(id: $id) {
    ...Post
  }
}
    ${BasicAdminFragmentDoc}
${PostFragmentDoc}`;

/**
 * __usePostIdPageQuery__
 *
 * To run a query within a React component, call `usePostIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostIdPageQuery(baseOptions?: Apollo.QueryHookOptions<PostIdPageQuery, PostIdPageQueryVariables>) {
        return Apollo.useQuery<PostIdPageQuery, PostIdPageQueryVariables>(PostIdPageDocument, baseOptions);
      }
export function usePostIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostIdPageQuery, PostIdPageQueryVariables>) {
          return Apollo.useLazyQuery<PostIdPageQuery, PostIdPageQueryVariables>(PostIdPageDocument, baseOptions);
        }
export type PostIdPageQueryHookResult = ReturnType<typeof usePostIdPageQuery>;
export type PostIdPageLazyQueryHookResult = ReturnType<typeof usePostIdPageLazyQuery>;
export type PostIdPageQueryResult = Apollo.QueryResult<PostIdPageQuery, PostIdPageQueryVariables>;
export const PostIndexPageDocument = gql`
    query PostIndexPage($page: Int, $state: String, $scope: String) {
  paginatedPosts(page: $page, state: $state, scope: $scope) {
    count
    data {
      ...Post
    }
  }
}
    ${PostFragmentDoc}`;

/**
 * __usePostIndexPageQuery__
 *
 * To run a query within a React component, call `usePostIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      state: // value for 'state'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function usePostIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<PostIndexPageQuery, PostIndexPageQueryVariables>) {
        return Apollo.useQuery<PostIndexPageQuery, PostIndexPageQueryVariables>(PostIndexPageDocument, baseOptions);
      }
export function usePostIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostIndexPageQuery, PostIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<PostIndexPageQuery, PostIndexPageQueryVariables>(PostIndexPageDocument, baseOptions);
        }
export type PostIndexPageQueryHookResult = ReturnType<typeof usePostIndexPageQuery>;
export type PostIndexPageLazyQueryHookResult = ReturnType<typeof usePostIndexPageLazyQuery>;
export type PostIndexPageQueryResult = Apollo.QueryResult<PostIndexPageQuery, PostIndexPageQueryVariables>;
export const PrizeIdPageDocument = gql`
    query PrizeIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  prize(id: $id) {
    ...Prize
  }
}
    ${BasicAdminFragmentDoc}
${PrizeFragmentDoc}`;

/**
 * __usePrizeIdPageQuery__
 *
 * To run a query within a React component, call `usePrizeIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrizeIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrizeIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrizeIdPageQuery(baseOptions?: Apollo.QueryHookOptions<PrizeIdPageQuery, PrizeIdPageQueryVariables>) {
        return Apollo.useQuery<PrizeIdPageQuery, PrizeIdPageQueryVariables>(PrizeIdPageDocument, baseOptions);
      }
export function usePrizeIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrizeIdPageQuery, PrizeIdPageQueryVariables>) {
          return Apollo.useLazyQuery<PrizeIdPageQuery, PrizeIdPageQueryVariables>(PrizeIdPageDocument, baseOptions);
        }
export type PrizeIdPageQueryHookResult = ReturnType<typeof usePrizeIdPageQuery>;
export type PrizeIdPageLazyQueryHookResult = ReturnType<typeof usePrizeIdPageLazyQuery>;
export type PrizeIdPageQueryResult = Apollo.QueryResult<PrizeIdPageQuery, PrizeIdPageQueryVariables>;
export const PrizeIndexPageDocument = gql`
    query PrizeIndexPage($active: String, $page: Int) {
  me {
    ...BasicAdmin
  }
  prizeAccounts(active: $active, page: $page) {
    count
    data {
      ...Prize
    }
  }
}
    ${BasicAdminFragmentDoc}
${PrizeFragmentDoc}`;

/**
 * __usePrizeIndexPageQuery__
 *
 * To run a query within a React component, call `usePrizeIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrizeIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrizeIndexPageQuery({
 *   variables: {
 *      active: // value for 'active'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePrizeIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<PrizeIndexPageQuery, PrizeIndexPageQueryVariables>) {
        return Apollo.useQuery<PrizeIndexPageQuery, PrizeIndexPageQueryVariables>(PrizeIndexPageDocument, baseOptions);
      }
export function usePrizeIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrizeIndexPageQuery, PrizeIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<PrizeIndexPageQuery, PrizeIndexPageQueryVariables>(PrizeIndexPageDocument, baseOptions);
        }
export type PrizeIndexPageQueryHookResult = ReturnType<typeof usePrizeIndexPageQuery>;
export type PrizeIndexPageLazyQueryHookResult = ReturnType<typeof usePrizeIndexPageLazyQuery>;
export type PrizeIndexPageQueryResult = Apollo.QueryResult<PrizeIndexPageQuery, PrizeIndexPageQueryVariables>;
export const ProficientRoleIdPageDocument = gql`
    query ProficientRoleIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  proficientRole(id: $id) {
    id
    name
    kind
    imageUrl
    selectedImageUrl
    game {
      ...Game
    }
    proficientHeros {
      id
      name
      imageUrl
    }
  }
}
    ${BasicAdminFragmentDoc}
${GameFragmentDoc}`;

/**
 * __useProficientRoleIdPageQuery__
 *
 * To run a query within a React component, call `useProficientRoleIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProficientRoleIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProficientRoleIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProficientRoleIdPageQuery(baseOptions?: Apollo.QueryHookOptions<ProficientRoleIdPageQuery, ProficientRoleIdPageQueryVariables>) {
        return Apollo.useQuery<ProficientRoleIdPageQuery, ProficientRoleIdPageQueryVariables>(ProficientRoleIdPageDocument, baseOptions);
      }
export function useProficientRoleIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProficientRoleIdPageQuery, ProficientRoleIdPageQueryVariables>) {
          return Apollo.useLazyQuery<ProficientRoleIdPageQuery, ProficientRoleIdPageQueryVariables>(ProficientRoleIdPageDocument, baseOptions);
        }
export type ProficientRoleIdPageQueryHookResult = ReturnType<typeof useProficientRoleIdPageQuery>;
export type ProficientRoleIdPageLazyQueryHookResult = ReturnType<typeof useProficientRoleIdPageLazyQuery>;
export type ProficientRoleIdPageQueryResult = Apollo.QueryResult<ProficientRoleIdPageQuery, ProficientRoleIdPageQueryVariables>;
export const ProficientRolesIndexPageDocument = gql`
    query ProficientRolesIndexPage($page: Int, $searchString: String, $gameName: String) {
  proficientRoleAccounts(page: $page, searchString: $searchString, gameName: $gameName) {
    count
    data {
      id
      name
      imageUrl
      selectedImageUrl
      game {
        ...Game
      }
      proficientHeros {
        id
        name
        imageUrl
      }
      kind
    }
  }
}
    ${GameFragmentDoc}`;

/**
 * __useProficientRolesIndexPageQuery__
 *
 * To run a query within a React component, call `useProficientRolesIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProficientRolesIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProficientRolesIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      gameName: // value for 'gameName'
 *   },
 * });
 */
export function useProficientRolesIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<ProficientRolesIndexPageQuery, ProficientRolesIndexPageQueryVariables>) {
        return Apollo.useQuery<ProficientRolesIndexPageQuery, ProficientRolesIndexPageQueryVariables>(ProficientRolesIndexPageDocument, baseOptions);
      }
export function useProficientRolesIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProficientRolesIndexPageQuery, ProficientRolesIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<ProficientRolesIndexPageQuery, ProficientRolesIndexPageQueryVariables>(ProficientRolesIndexPageDocument, baseOptions);
        }
export type ProficientRolesIndexPageQueryHookResult = ReturnType<typeof useProficientRolesIndexPageQuery>;
export type ProficientRolesIndexPageLazyQueryHookResult = ReturnType<typeof useProficientRolesIndexPageLazyQuery>;
export type ProficientRolesIndexPageQueryResult = Apollo.QueryResult<ProficientRolesIndexPageQuery, ProficientRolesIndexPageQueryVariables>;
export const PromoCodeIdPageDocument = gql`
    query PromoCodeIdPage($id: ID!, $searchString: String) {
  me {
    ...BasicAdmin
  }
  promoCode(id: $id) {
    ...PromoCode
  }
  users(searchString: $searchString) {
    data {
      id
      nickname
    }
  }
}
    ${BasicAdminFragmentDoc}
${PromoCodeFragmentDoc}`;

/**
 * __usePromoCodeIdPageQuery__
 *
 * To run a query within a React component, call `usePromoCodeIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function usePromoCodeIdPageQuery(baseOptions?: Apollo.QueryHookOptions<PromoCodeIdPageQuery, PromoCodeIdPageQueryVariables>) {
        return Apollo.useQuery<PromoCodeIdPageQuery, PromoCodeIdPageQueryVariables>(PromoCodeIdPageDocument, baseOptions);
      }
export function usePromoCodeIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeIdPageQuery, PromoCodeIdPageQueryVariables>) {
          return Apollo.useLazyQuery<PromoCodeIdPageQuery, PromoCodeIdPageQueryVariables>(PromoCodeIdPageDocument, baseOptions);
        }
export type PromoCodeIdPageQueryHookResult = ReturnType<typeof usePromoCodeIdPageQuery>;
export type PromoCodeIdPageLazyQueryHookResult = ReturnType<typeof usePromoCodeIdPageLazyQuery>;
export type PromoCodeIdPageQueryResult = Apollo.QueryResult<PromoCodeIdPageQuery, PromoCodeIdPageQueryVariables>;
export const PromoCodeIndexPageDocument = gql`
    query PromoCodeIndexPage($page: Int, $searchString: String, $type: String) {
  promoCodes(page: $page, searchString: $searchString, type: $type) {
    count
    data {
      ...PromoCode
    }
  }
}
    ${PromoCodeFragmentDoc}`;

/**
 * __usePromoCodeIndexPageQuery__
 *
 * To run a query within a React component, call `usePromoCodeIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      type: // value for 'type'
 *   },
 * });
 */
export function usePromoCodeIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<PromoCodeIndexPageQuery, PromoCodeIndexPageQueryVariables>) {
        return Apollo.useQuery<PromoCodeIndexPageQuery, PromoCodeIndexPageQueryVariables>(PromoCodeIndexPageDocument, baseOptions);
      }
export function usePromoCodeIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeIndexPageQuery, PromoCodeIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<PromoCodeIndexPageQuery, PromoCodeIndexPageQueryVariables>(PromoCodeIndexPageDocument, baseOptions);
        }
export type PromoCodeIndexPageQueryHookResult = ReturnType<typeof usePromoCodeIndexPageQuery>;
export type PromoCodeIndexPageLazyQueryHookResult = ReturnType<typeof usePromoCodeIndexPageLazyQuery>;
export type PromoCodeIndexPageQueryResult = Apollo.QueryResult<PromoCodeIndexPageQuery, PromoCodeIndexPageQueryVariables>;
export const ReportRequestIdPageDocument = gql`
    query ReportRequestIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  post(id: $id) {
    id
    url
    title
    text
    reportRequestsCount
    postImages
    postVideos
    reportRequests {
      ...ReportRequest
    }
  }
}
    ${BasicAdminFragmentDoc}
${ReportRequestFragmentDoc}`;

/**
 * __useReportRequestIdPageQuery__
 *
 * To run a query within a React component, call `useReportRequestIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportRequestIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportRequestIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportRequestIdPageQuery(baseOptions?: Apollo.QueryHookOptions<ReportRequestIdPageQuery, ReportRequestIdPageQueryVariables>) {
        return Apollo.useQuery<ReportRequestIdPageQuery, ReportRequestIdPageQueryVariables>(ReportRequestIdPageDocument, baseOptions);
      }
export function useReportRequestIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportRequestIdPageQuery, ReportRequestIdPageQueryVariables>) {
          return Apollo.useLazyQuery<ReportRequestIdPageQuery, ReportRequestIdPageQueryVariables>(ReportRequestIdPageDocument, baseOptions);
        }
export type ReportRequestIdPageQueryHookResult = ReturnType<typeof useReportRequestIdPageQuery>;
export type ReportRequestIdPageLazyQueryHookResult = ReturnType<typeof useReportRequestIdPageLazyQuery>;
export type ReportRequestIdPageQueryResult = Apollo.QueryResult<ReportRequestIdPageQuery, ReportRequestIdPageQueryVariables>;
export const ReportRequestsIndexPageDocument = gql`
    query ReportRequestsIndexPage($page: Int, $state: String) {
  reportRequestAccounts(page: $page, state: $state) {
    count
    data {
      id
      url
      title
      text
      reportRequestsCount
      reportRequests {
        ...ReportRequest
      }
    }
  }
}
    ${ReportRequestFragmentDoc}`;

/**
 * __useReportRequestsIndexPageQuery__
 *
 * To run a query within a React component, call `useReportRequestsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportRequestsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportRequestsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useReportRequestsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<ReportRequestsIndexPageQuery, ReportRequestsIndexPageQueryVariables>) {
        return Apollo.useQuery<ReportRequestsIndexPageQuery, ReportRequestsIndexPageQueryVariables>(ReportRequestsIndexPageDocument, baseOptions);
      }
export function useReportRequestsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportRequestsIndexPageQuery, ReportRequestsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<ReportRequestsIndexPageQuery, ReportRequestsIndexPageQueryVariables>(ReportRequestsIndexPageDocument, baseOptions);
        }
export type ReportRequestsIndexPageQueryHookResult = ReturnType<typeof useReportRequestsIndexPageQuery>;
export type ReportRequestsIndexPageLazyQueryHookResult = ReturnType<typeof useReportRequestsIndexPageLazyQuery>;
export type ReportRequestsIndexPageQueryResult = Apollo.QueryResult<ReportRequestsIndexPageQuery, ReportRequestsIndexPageQueryVariables>;
export const ReportRequestPageDocument = gql`
    query ReportRequestPage($state: String, $page: Int) {
  partneringRequestsIndex(state: $state, page: $page) {
    count
    data {
      id
      pilot {
        id
        phoneNumber
        nickname
      }
      user {
        id
        phoneNumber
        nickname
      }
      game {
        id
        name
      }
      requestedPilots {
        id
        phoneNumber
      }
      state
      createdAt
      reportReason
      updatedAt
    }
  }
}
    `;

/**
 * __useReportRequestPageQuery__
 *
 * To run a query within a React component, call `useReportRequestPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportRequestPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportRequestPageQuery({
 *   variables: {
 *      state: // value for 'state'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useReportRequestPageQuery(baseOptions?: Apollo.QueryHookOptions<ReportRequestPageQuery, ReportRequestPageQueryVariables>) {
        return Apollo.useQuery<ReportRequestPageQuery, ReportRequestPageQueryVariables>(ReportRequestPageDocument, baseOptions);
      }
export function useReportRequestPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportRequestPageQuery, ReportRequestPageQueryVariables>) {
          return Apollo.useLazyQuery<ReportRequestPageQuery, ReportRequestPageQueryVariables>(ReportRequestPageDocument, baseOptions);
        }
export type ReportRequestPageQueryHookResult = ReturnType<typeof useReportRequestPageQuery>;
export type ReportRequestPageLazyQueryHookResult = ReturnType<typeof useReportRequestPageLazyQuery>;
export type ReportRequestPageQueryResult = Apollo.QueryResult<ReportRequestPageQuery, ReportRequestPageQueryVariables>;
export const ReportBundlePageDocument = gql`
    query ReportBundlePage($state: String, $page: Int) {
  gameBundlesIndex(state: $state, page: $page) {
    count
    data {
      id
      pilot {
        id
        phoneNumber
        nickname
      }
      user {
        id
        phoneNumber
        nickname
      }
      game {
        id
        name
      }
      state
      createdAt
      reportReason
      updatedAt
    }
  }
}
    `;

/**
 * __useReportBundlePageQuery__
 *
 * To run a query within a React component, call `useReportBundlePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportBundlePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportBundlePageQuery({
 *   variables: {
 *      state: // value for 'state'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useReportBundlePageQuery(baseOptions?: Apollo.QueryHookOptions<ReportBundlePageQuery, ReportBundlePageQueryVariables>) {
        return Apollo.useQuery<ReportBundlePageQuery, ReportBundlePageQueryVariables>(ReportBundlePageDocument, baseOptions);
      }
export function useReportBundlePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportBundlePageQuery, ReportBundlePageQueryVariables>) {
          return Apollo.useLazyQuery<ReportBundlePageQuery, ReportBundlePageQueryVariables>(ReportBundlePageDocument, baseOptions);
        }
export type ReportBundlePageQueryHookResult = ReturnType<typeof useReportBundlePageQuery>;
export type ReportBundlePageLazyQueryHookResult = ReturnType<typeof useReportBundlePageLazyQuery>;
export type ReportBundlePageQueryResult = Apollo.QueryResult<ReportBundlePageQuery, ReportBundlePageQueryVariables>;
export const ScrimIdPageDocument = gql`
    query ScrimIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  scrim(id: $id) {
    id
    state
    description
    matchCount
    duration
    startTime
    completeTime
    matchResults {
      id
      resultScreenshots
      winTeam {
        id
        club {
          id
          name
        }
      }
      loseTeam {
        id
        club {
          id
          name
        }
      }
    }
    host {
      id
      name
    }
    hostPlayers {
      id
      email
      phoneNumber
      nickname
      teamRole {
        id
        proficientRole {
          id
          name
        }
      }
    }
    guest {
      id
      name
    }
    guestPlayers {
      id
      email
      phoneNumber
      nickname
      teamRole {
        id
        proficientRole {
          id
          name
        }
      }
    }
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useScrimIdPageQuery__
 *
 * To run a query within a React component, call `useScrimIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useScrimIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScrimIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScrimIdPageQuery(baseOptions?: Apollo.QueryHookOptions<ScrimIdPageQuery, ScrimIdPageQueryVariables>) {
        return Apollo.useQuery<ScrimIdPageQuery, ScrimIdPageQueryVariables>(ScrimIdPageDocument, baseOptions);
      }
export function useScrimIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScrimIdPageQuery, ScrimIdPageQueryVariables>) {
          return Apollo.useLazyQuery<ScrimIdPageQuery, ScrimIdPageQueryVariables>(ScrimIdPageDocument, baseOptions);
        }
export type ScrimIdPageQueryHookResult = ReturnType<typeof useScrimIdPageQuery>;
export type ScrimIdPageLazyQueryHookResult = ReturnType<typeof useScrimIdPageLazyQuery>;
export type ScrimIdPageQueryResult = Apollo.QueryResult<ScrimIdPageQuery, ScrimIdPageQueryVariables>;
export const ScrimsIndexPageDocument = gql`
    query ScrimsIndexPage($page: Int!, $searchString: String, $state: String) {
  scrimAccounts(page: $page, searchString: $searchString, state: $state) {
    count
    data {
      id
      state
      host {
        id
        name
      }
      guest {
        id
        name
      }
      matchCount
      duration
      realtime
      startTime
      completeTime
      hostPlayers {
        id
        email
        phoneNumber
        nickname
        teamRole {
          proficientRole {
            id
            name
          }
        }
      }
      guestPlayers {
        id
        email
        phoneNumber
        nickname
        teamRole {
          proficientRole {
            id
            name
          }
        }
      }
      roles
    }
  }
}
    `;

/**
 * __useScrimsIndexPageQuery__
 *
 * To run a query within a React component, call `useScrimsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useScrimsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScrimsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useScrimsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<ScrimsIndexPageQuery, ScrimsIndexPageQueryVariables>) {
        return Apollo.useQuery<ScrimsIndexPageQuery, ScrimsIndexPageQueryVariables>(ScrimsIndexPageDocument, baseOptions);
      }
export function useScrimsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScrimsIndexPageQuery, ScrimsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<ScrimsIndexPageQuery, ScrimsIndexPageQueryVariables>(ScrimsIndexPageDocument, baseOptions);
        }
export type ScrimsIndexPageQueryHookResult = ReturnType<typeof useScrimsIndexPageQuery>;
export type ScrimsIndexPageLazyQueryHookResult = ReturnType<typeof useScrimsIndexPageLazyQuery>;
export type ScrimsIndexPageQueryResult = Apollo.QueryResult<ScrimsIndexPageQuery, ScrimsIndexPageQueryVariables>;
export const SponsorIdPageDocument = gql`
    query SponsorIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  sponsorAd(id: $id) {
    id
    adsName
    sponsorName
    url
    assetType
    layoutType
    imageUrl
    videoUrl
    active
    reachesCount
    adClicksCount
    position
    gamerBanner {
      id
      title
      url
    }
    screenRoute
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useSponsorIdPageQuery__
 *
 * To run a query within a React component, call `useSponsorIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSponsorIdPageQuery(baseOptions?: Apollo.QueryHookOptions<SponsorIdPageQuery, SponsorIdPageQueryVariables>) {
        return Apollo.useQuery<SponsorIdPageQuery, SponsorIdPageQueryVariables>(SponsorIdPageDocument, baseOptions);
      }
export function useSponsorIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SponsorIdPageQuery, SponsorIdPageQueryVariables>) {
          return Apollo.useLazyQuery<SponsorIdPageQuery, SponsorIdPageQueryVariables>(SponsorIdPageDocument, baseOptions);
        }
export type SponsorIdPageQueryHookResult = ReturnType<typeof useSponsorIdPageQuery>;
export type SponsorIdPageLazyQueryHookResult = ReturnType<typeof useSponsorIdPageLazyQuery>;
export type SponsorIdPageQueryResult = Apollo.QueryResult<SponsorIdPageQuery, SponsorIdPageQueryVariables>;
export const SponsorAdsIndexPageDocument = gql`
    query SponsorAdsIndexPage($page: Int, $searchString: String) {
  sponsorAdAccounts(page: $page, searchString: $searchString) {
    count
    data {
      id
      adsName
      sponsorName
      url
      active
      reachesCount
      adClicksCount
      position
    }
  }
}
    `;

/**
 * __useSponsorAdsIndexPageQuery__
 *
 * To run a query within a React component, call `useSponsorAdsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorAdsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorAdsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useSponsorAdsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<SponsorAdsIndexPageQuery, SponsorAdsIndexPageQueryVariables>) {
        return Apollo.useQuery<SponsorAdsIndexPageQuery, SponsorAdsIndexPageQueryVariables>(SponsorAdsIndexPageDocument, baseOptions);
      }
export function useSponsorAdsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SponsorAdsIndexPageQuery, SponsorAdsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<SponsorAdsIndexPageQuery, SponsorAdsIndexPageQueryVariables>(SponsorAdsIndexPageDocument, baseOptions);
        }
export type SponsorAdsIndexPageQueryHookResult = ReturnType<typeof useSponsorAdsIndexPageQuery>;
export type SponsorAdsIndexPageLazyQueryHookResult = ReturnType<typeof useSponsorAdsIndexPageLazyQuery>;
export type SponsorAdsIndexPageQueryResult = Apollo.QueryResult<SponsorAdsIndexPageQuery, SponsorAdsIndexPageQueryVariables>;
export const StarPlayerIndexPageDocument = gql`
    query StarPlayerIndexPage($page: Int, $searchString: String, $verified: String, $countryCode: String) {
  starPlayerAccounts(page: $page, searchString: $searchString, verified: $verified, countryCode: $countryCode) {
    count
    data {
      id
      nickname
      officialName
      fullName
      team {
        id
        club {
          id
          name
        }
      }
      verified
      countryCode
    }
  }
}
    `;

/**
 * __useStarPlayerIndexPageQuery__
 *
 * To run a query within a React component, call `useStarPlayerIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStarPlayerIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStarPlayerIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      verified: // value for 'verified'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useStarPlayerIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<StarPlayerIndexPageQuery, StarPlayerIndexPageQueryVariables>) {
        return Apollo.useQuery<StarPlayerIndexPageQuery, StarPlayerIndexPageQueryVariables>(StarPlayerIndexPageDocument, baseOptions);
      }
export function useStarPlayerIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StarPlayerIndexPageQuery, StarPlayerIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<StarPlayerIndexPageQuery, StarPlayerIndexPageQueryVariables>(StarPlayerIndexPageDocument, baseOptions);
        }
export type StarPlayerIndexPageQueryHookResult = ReturnType<typeof useStarPlayerIndexPageQuery>;
export type StarPlayerIndexPageLazyQueryHookResult = ReturnType<typeof useStarPlayerIndexPageLazyQuery>;
export type StarPlayerIndexPageQueryResult = Apollo.QueryResult<StarPlayerIndexPageQuery, StarPlayerIndexPageQueryVariables>;
export const StarTeamsIndexPageDocument = gql`
    query StarTeamsIndexPage($page: Int, $searchString: String, $verified: String) {
  starTeamAccounts(page: $page, searchString: $searchString, verified: $verified) {
    count
    data {
      id
      name
      teams {
        teamMembers
      }
      verified
    }
  }
}
    `;

/**
 * __useStarTeamsIndexPageQuery__
 *
 * To run a query within a React component, call `useStarTeamsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStarTeamsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStarTeamsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useStarTeamsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<StarTeamsIndexPageQuery, StarTeamsIndexPageQueryVariables>) {
        return Apollo.useQuery<StarTeamsIndexPageQuery, StarTeamsIndexPageQueryVariables>(StarTeamsIndexPageDocument, baseOptions);
      }
export function useStarTeamsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StarTeamsIndexPageQuery, StarTeamsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<StarTeamsIndexPageQuery, StarTeamsIndexPageQueryVariables>(StarTeamsIndexPageDocument, baseOptions);
        }
export type StarTeamsIndexPageQueryHookResult = ReturnType<typeof useStarTeamsIndexPageQuery>;
export type StarTeamsIndexPageLazyQueryHookResult = ReturnType<typeof useStarTeamsIndexPageLazyQuery>;
export type StarTeamsIndexPageQueryResult = Apollo.QueryResult<StarTeamsIndexPageQuery, StarTeamsIndexPageQueryVariables>;
export const TeamBankInfoIdPageDocument = gql`
    query teamBankInfoIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  teamBankInfo(id: $id) {
    id
    name
    bankName
    bankAccountNo
    country
    ic
    icImage
    selfie
    state
    rejectReason
    user {
      id
      avatarUrl
      nickname
      phoneNumber
      email
      language
      gender
      team {
        id
        name
        teamWallet {
          id
          amount
        }
      }
      gameAccounts {
        id
        game {
          id
          name
        }
      }
    }
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useTeamBankInfoIdPageQuery__
 *
 * To run a query within a React component, call `useTeamBankInfoIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamBankInfoIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamBankInfoIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamBankInfoIdPageQuery(baseOptions?: Apollo.QueryHookOptions<TeamBankInfoIdPageQuery, TeamBankInfoIdPageQueryVariables>) {
        return Apollo.useQuery<TeamBankInfoIdPageQuery, TeamBankInfoIdPageQueryVariables>(TeamBankInfoIdPageDocument, baseOptions);
      }
export function useTeamBankInfoIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamBankInfoIdPageQuery, TeamBankInfoIdPageQueryVariables>) {
          return Apollo.useLazyQuery<TeamBankInfoIdPageQuery, TeamBankInfoIdPageQueryVariables>(TeamBankInfoIdPageDocument, baseOptions);
        }
export type TeamBankInfoIdPageQueryHookResult = ReturnType<typeof useTeamBankInfoIdPageQuery>;
export type TeamBankInfoIdPageLazyQueryHookResult = ReturnType<typeof useTeamBankInfoIdPageLazyQuery>;
export type TeamBankInfoIdPageQueryResult = Apollo.QueryResult<TeamBankInfoIdPageQuery, TeamBankInfoIdPageQueryVariables>;
export const TeamBankInfosPageDocument = gql`
    query teamBankInfosPage($page: Int!, $searchString: String, $state: String) {
  teamBankInfoAccounts(page: $page, searchString: $searchString, state: $state) {
    count
    data {
      id
      name
      bankName
      bankAccountNo
      ic
      state
      user {
        id
        nickname
        team {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTeamBankInfosPageQuery__
 *
 * To run a query within a React component, call `useTeamBankInfosPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamBankInfosPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamBankInfosPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useTeamBankInfosPageQuery(baseOptions?: Apollo.QueryHookOptions<TeamBankInfosPageQuery, TeamBankInfosPageQueryVariables>) {
        return Apollo.useQuery<TeamBankInfosPageQuery, TeamBankInfosPageQueryVariables>(TeamBankInfosPageDocument, baseOptions);
      }
export function useTeamBankInfosPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamBankInfosPageQuery, TeamBankInfosPageQueryVariables>) {
          return Apollo.useLazyQuery<TeamBankInfosPageQuery, TeamBankInfosPageQueryVariables>(TeamBankInfosPageDocument, baseOptions);
        }
export type TeamBankInfosPageQueryHookResult = ReturnType<typeof useTeamBankInfosPageQuery>;
export type TeamBankInfosPageLazyQueryHookResult = ReturnType<typeof useTeamBankInfosPageLazyQuery>;
export type TeamBankInfosPageQueryResult = Apollo.QueryResult<TeamBankInfosPageQuery, TeamBankInfosPageQueryVariables>;
export const TeamPayoutIdPageDocument = gql`
    query TeamPayoutIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  teamPayout(id: $id) {
    id
    amount
    recipient
    bank
    bankAccountNo
    approve
    createdAt
    updatedAt
    state
    rejectReason
    adminNote
    ic
    teamWallet {
      ...TeamWallet
    }
    pilot {
      id
      phoneNumber
      nickname
      email
      avatarUrl
      language
      gender
      trained
      bankInfo {
        ...BankInfo
      }
      adminNote
      team {
        id
        name
        club {
          id
          name
        }
      }
    }
  }
}
    ${BasicAdminFragmentDoc}
${TeamWalletFragmentDoc}
${BankInfoFragmentDoc}`;

/**
 * __useTeamPayoutIdPageQuery__
 *
 * To run a query within a React component, call `useTeamPayoutIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPayoutIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPayoutIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamPayoutIdPageQuery(baseOptions?: Apollo.QueryHookOptions<TeamPayoutIdPageQuery, TeamPayoutIdPageQueryVariables>) {
        return Apollo.useQuery<TeamPayoutIdPageQuery, TeamPayoutIdPageQueryVariables>(TeamPayoutIdPageDocument, baseOptions);
      }
export function useTeamPayoutIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamPayoutIdPageQuery, TeamPayoutIdPageQueryVariables>) {
          return Apollo.useLazyQuery<TeamPayoutIdPageQuery, TeamPayoutIdPageQueryVariables>(TeamPayoutIdPageDocument, baseOptions);
        }
export type TeamPayoutIdPageQueryHookResult = ReturnType<typeof useTeamPayoutIdPageQuery>;
export type TeamPayoutIdPageLazyQueryHookResult = ReturnType<typeof useTeamPayoutIdPageLazyQuery>;
export type TeamPayoutIdPageQueryResult = Apollo.QueryResult<TeamPayoutIdPageQuery, TeamPayoutIdPageQueryVariables>;
export const AllTeamPayoutsPageDocument = gql`
    query AllTeamPayoutsPage($approve: Boolean, $page: Int, $searchString: String, $state: String) {
  teamPayoutAccounts(approve: $approve, page: $page, searchString: $searchString, state: $state) {
    count
    data {
      id
      recipient
      email
      phone
      bank
      bankAccountNo
      amount
      approve
      createdAt
      state
      adminNote
    }
  }
}
    `;

/**
 * __useAllTeamPayoutsPageQuery__
 *
 * To run a query within a React component, call `useAllTeamPayoutsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamPayoutsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamPayoutsPageQuery({
 *   variables: {
 *      approve: // value for 'approve'
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAllTeamPayoutsPageQuery(baseOptions?: Apollo.QueryHookOptions<AllTeamPayoutsPageQuery, AllTeamPayoutsPageQueryVariables>) {
        return Apollo.useQuery<AllTeamPayoutsPageQuery, AllTeamPayoutsPageQueryVariables>(AllTeamPayoutsPageDocument, baseOptions);
      }
export function useAllTeamPayoutsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTeamPayoutsPageQuery, AllTeamPayoutsPageQueryVariables>) {
          return Apollo.useLazyQuery<AllTeamPayoutsPageQuery, AllTeamPayoutsPageQueryVariables>(AllTeamPayoutsPageDocument, baseOptions);
        }
export type AllTeamPayoutsPageQueryHookResult = ReturnType<typeof useAllTeamPayoutsPageQuery>;
export type AllTeamPayoutsPageLazyQueryHookResult = ReturnType<typeof useAllTeamPayoutsPageLazyQuery>;
export type AllTeamPayoutsPageQueryResult = Apollo.QueryResult<AllTeamPayoutsPageQuery, AllTeamPayoutsPageQueryVariables>;
export const TeamPayoutsCsvDocument = gql`
    query TeamPayoutsCsv {
  teamPayoutsCsv
}
    `;

/**
 * __useTeamPayoutsCsvQuery__
 *
 * To run a query within a React component, call `useTeamPayoutsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPayoutsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPayoutsCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamPayoutsCsvQuery(baseOptions?: Apollo.QueryHookOptions<TeamPayoutsCsvQuery, TeamPayoutsCsvQueryVariables>) {
        return Apollo.useQuery<TeamPayoutsCsvQuery, TeamPayoutsCsvQueryVariables>(TeamPayoutsCsvDocument, baseOptions);
      }
export function useTeamPayoutsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamPayoutsCsvQuery, TeamPayoutsCsvQueryVariables>) {
          return Apollo.useLazyQuery<TeamPayoutsCsvQuery, TeamPayoutsCsvQueryVariables>(TeamPayoutsCsvDocument, baseOptions);
        }
export type TeamPayoutsCsvQueryHookResult = ReturnType<typeof useTeamPayoutsCsvQuery>;
export type TeamPayoutsCsvLazyQueryHookResult = ReturnType<typeof useTeamPayoutsCsvLazyQuery>;
export type TeamPayoutsCsvQueryResult = Apollo.QueryResult<TeamPayoutsCsvQuery, TeamPayoutsCsvQueryVariables>;
export const TeamIdPageDocument = gql`
    query TeamIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  team(id: $id) {
    ...Team
  }
}
    ${BasicAdminFragmentDoc}
${TeamFragmentDoc}`;

/**
 * __useTeamIdPageQuery__
 *
 * To run a query within a React component, call `useTeamIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamIdPageQuery(baseOptions?: Apollo.QueryHookOptions<TeamIdPageQuery, TeamIdPageQueryVariables>) {
        return Apollo.useQuery<TeamIdPageQuery, TeamIdPageQueryVariables>(TeamIdPageDocument, baseOptions);
      }
export function useTeamIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamIdPageQuery, TeamIdPageQueryVariables>) {
          return Apollo.useLazyQuery<TeamIdPageQuery, TeamIdPageQueryVariables>(TeamIdPageDocument, baseOptions);
        }
export type TeamIdPageQueryHookResult = ReturnType<typeof useTeamIdPageQuery>;
export type TeamIdPageLazyQueryHookResult = ReturnType<typeof useTeamIdPageLazyQuery>;
export type TeamIdPageQueryResult = Apollo.QueryResult<TeamIdPageQuery, TeamIdPageQueryVariables>;
export const TeamAccountsIndexPageDocument = gql`
    query teamAccountsIndexPage($page: Int!, $searchString: String, $teamMember: String, $lanePlayer: String, $totalMatch: String) {
  teamAccounts(page: $page, searchString: $searchString, teamMember: $teamMember, lanePlayer: $lanePlayer, totalMatch: $totalMatch) {
    count
    data {
      id
      icon
      name
      manager {
        nickname
      }
      teamRolePlayers {
        id
        email
        nickname
      }
      gameId
      pilots {
        id
        email
        phoneNumber
        nickname
        trained
        online
        bankInfo {
          ...BankInfo
        }
      }
      teamRoles {
        id
        proficientRole {
          id
          name
        }
        pilot {
          id
          email
          nickname
        }
      }
      allCombatRoomSize
    }
  }
}
    ${BankInfoFragmentDoc}`;

/**
 * __useTeamAccountsIndexPageQuery__
 *
 * To run a query within a React component, call `useTeamAccountsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamAccountsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamAccountsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      teamMember: // value for 'teamMember'
 *      lanePlayer: // value for 'lanePlayer'
 *      totalMatch: // value for 'totalMatch'
 *   },
 * });
 */
export function useTeamAccountsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<TeamAccountsIndexPageQuery, TeamAccountsIndexPageQueryVariables>) {
        return Apollo.useQuery<TeamAccountsIndexPageQuery, TeamAccountsIndexPageQueryVariables>(TeamAccountsIndexPageDocument, baseOptions);
      }
export function useTeamAccountsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamAccountsIndexPageQuery, TeamAccountsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<TeamAccountsIndexPageQuery, TeamAccountsIndexPageQueryVariables>(TeamAccountsIndexPageDocument, baseOptions);
        }
export type TeamAccountsIndexPageQueryHookResult = ReturnType<typeof useTeamAccountsIndexPageQuery>;
export type TeamAccountsIndexPageLazyQueryHookResult = ReturnType<typeof useTeamAccountsIndexPageLazyQuery>;
export type TeamAccountsIndexPageQueryResult = Apollo.QueryResult<TeamAccountsIndexPageQuery, TeamAccountsIndexPageQueryVariables>;
export const TeamsCsvDocument = gql`
    query TeamsCsv {
  teamsCsv
}
    `;

/**
 * __useTeamsCsvQuery__
 *
 * To run a query within a React component, call `useTeamsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsCsvQuery(baseOptions?: Apollo.QueryHookOptions<TeamsCsvQuery, TeamsCsvQueryVariables>) {
        return Apollo.useQuery<TeamsCsvQuery, TeamsCsvQueryVariables>(TeamsCsvDocument, baseOptions);
      }
export function useTeamsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsCsvQuery, TeamsCsvQueryVariables>) {
          return Apollo.useLazyQuery<TeamsCsvQuery, TeamsCsvQueryVariables>(TeamsCsvDocument, baseOptions);
        }
export type TeamsCsvQueryHookResult = ReturnType<typeof useTeamsCsvQuery>;
export type TeamsCsvLazyQueryHookResult = ReturnType<typeof useTeamsCsvLazyQuery>;
export type TeamsCsvQueryResult = Apollo.QueryResult<TeamsCsvQuery, TeamsCsvQueryVariables>;
export const TeamCombatRoomsDocument = gql`
    query TeamCombatRooms($id: ID!, $page: Int!) {
  team(id: $id) {
    id
    name
    allCombatRooms(page: $page) {
      count
      data {
        id
        state
        createdAt
        accepter {
          id
          team {
            id
            name
          }
        }
        challenger {
          id
          team {
            id
            name
          }
        }
        matchResults {
          id
          winTeam {
            id
            name
          }
          loseTeam {
            id
            name
          }
          createdAt
          updatedAt
        }
        winTeam {
          id
          club {
            id
            name
          }
        }
        loseTeam {
          id
          club {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTeamCombatRoomsQuery__
 *
 * To run a query within a React component, call `useTeamCombatRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCombatRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCombatRoomsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTeamCombatRoomsQuery(baseOptions?: Apollo.QueryHookOptions<TeamCombatRoomsQuery, TeamCombatRoomsQueryVariables>) {
        return Apollo.useQuery<TeamCombatRoomsQuery, TeamCombatRoomsQueryVariables>(TeamCombatRoomsDocument, baseOptions);
      }
export function useTeamCombatRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamCombatRoomsQuery, TeamCombatRoomsQueryVariables>) {
          return Apollo.useLazyQuery<TeamCombatRoomsQuery, TeamCombatRoomsQueryVariables>(TeamCombatRoomsDocument, baseOptions);
        }
export type TeamCombatRoomsQueryHookResult = ReturnType<typeof useTeamCombatRoomsQuery>;
export type TeamCombatRoomsLazyQueryHookResult = ReturnType<typeof useTeamCombatRoomsLazyQuery>;
export type TeamCombatRoomsQueryResult = Apollo.QueryResult<TeamCombatRoomsQuery, TeamCombatRoomsQueryVariables>;
export const TeamCreditTransactionDocument = gql`
    query TeamCreditTransaction($id: ID!, $page: Int) {
  team(id: $id) {
    id
    icon
    name
    manager {
      id
      nickname
    }
    teamWallet {
      id
      amount
      teamCreditTransactions(page: $page) {
        count
        data {
          id
          amount
          state
          transactionType
          createdAt
          targetType
          targetId
          spinnable
          token
          isDebit
          isCredit
          transactionCategory
          leaderboardPoolPrize {
            ...Leaderboard
          }
        }
      }
    }
  }
}
    ${LeaderboardFragmentDoc}`;

/**
 * __useTeamCreditTransactionQuery__
 *
 * To run a query within a React component, call `useTeamCreditTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCreditTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCreditTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTeamCreditTransactionQuery(baseOptions?: Apollo.QueryHookOptions<TeamCreditTransactionQuery, TeamCreditTransactionQueryVariables>) {
        return Apollo.useQuery<TeamCreditTransactionQuery, TeamCreditTransactionQueryVariables>(TeamCreditTransactionDocument, baseOptions);
      }
export function useTeamCreditTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamCreditTransactionQuery, TeamCreditTransactionQueryVariables>) {
          return Apollo.useLazyQuery<TeamCreditTransactionQuery, TeamCreditTransactionQueryVariables>(TeamCreditTransactionDocument, baseOptions);
        }
export type TeamCreditTransactionQueryHookResult = ReturnType<typeof useTeamCreditTransactionQuery>;
export type TeamCreditTransactionLazyQueryHookResult = ReturnType<typeof useTeamCreditTransactionLazyQuery>;
export type TeamCreditTransactionQueryResult = Apollo.QueryResult<TeamCreditTransactionQuery, TeamCreditTransactionQueryVariables>;
export const TopUpProductIdPageDocument = gql`
    query TopUpProductIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  topUpProduct(id: $id) {
    ...TopUpProduct
  }
}
    ${BasicAdminFragmentDoc}
${TopUpProductFragmentDoc}`;

/**
 * __useTopUpProductIdPageQuery__
 *
 * To run a query within a React component, call `useTopUpProductIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopUpProductIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopUpProductIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopUpProductIdPageQuery(baseOptions?: Apollo.QueryHookOptions<TopUpProductIdPageQuery, TopUpProductIdPageQueryVariables>) {
        return Apollo.useQuery<TopUpProductIdPageQuery, TopUpProductIdPageQueryVariables>(TopUpProductIdPageDocument, baseOptions);
      }
export function useTopUpProductIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopUpProductIdPageQuery, TopUpProductIdPageQueryVariables>) {
          return Apollo.useLazyQuery<TopUpProductIdPageQuery, TopUpProductIdPageQueryVariables>(TopUpProductIdPageDocument, baseOptions);
        }
export type TopUpProductIdPageQueryHookResult = ReturnType<typeof useTopUpProductIdPageQuery>;
export type TopUpProductIdPageLazyQueryHookResult = ReturnType<typeof useTopUpProductIdPageLazyQuery>;
export type TopUpProductIdPageQueryResult = Apollo.QueryResult<TopUpProductIdPageQuery, TopUpProductIdPageQueryVariables>;
export const TopUpProductIndexPageDocument = gql`
    query topUpProductIndexPage($page: Int, $searchString: String, $genre: GameGenre, $categoryId: ID, $isActive: String, $featured: String, $vendorId: ID, $publisherId: ID) {
  topUpProductAccounts(page: $page, searchString: $searchString, genre: $genre, categoryId: $categoryId, isActive: $isActive, featured: $featured, vendorId: $vendorId, publisherId: $publisherId) {
    count
    data {
      ...TopUpProduct
    }
  }
}
    ${TopUpProductFragmentDoc}`;

/**
 * __useTopUpProductIndexPageQuery__
 *
 * To run a query within a React component, call `useTopUpProductIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopUpProductIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopUpProductIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      genre: // value for 'genre'
 *      categoryId: // value for 'categoryId'
 *      isActive: // value for 'isActive'
 *      featured: // value for 'featured'
 *      vendorId: // value for 'vendorId'
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useTopUpProductIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<TopUpProductIndexPageQuery, TopUpProductIndexPageQueryVariables>) {
        return Apollo.useQuery<TopUpProductIndexPageQuery, TopUpProductIndexPageQueryVariables>(TopUpProductIndexPageDocument, baseOptions);
      }
export function useTopUpProductIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopUpProductIndexPageQuery, TopUpProductIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<TopUpProductIndexPageQuery, TopUpProductIndexPageQueryVariables>(TopUpProductIndexPageDocument, baseOptions);
        }
export type TopUpProductIndexPageQueryHookResult = ReturnType<typeof useTopUpProductIndexPageQuery>;
export type TopUpProductIndexPageLazyQueryHookResult = ReturnType<typeof useTopUpProductIndexPageLazyQuery>;
export type TopUpProductIndexPageQueryResult = Apollo.QueryResult<TopUpProductIndexPageQuery, TopUpProductIndexPageQueryVariables>;
export const TournamentEventIdPageDocument = gql`
    query TournamentEventIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  tournamentEvent(id: $id) {
    id
    name
    startDate
    endDate
    tournament {
      id
      name
    }
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useTournamentEventIdPageQuery__
 *
 * To run a query within a React component, call `useTournamentEventIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentEventIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentEventIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentEventIdPageQuery(baseOptions?: Apollo.QueryHookOptions<TournamentEventIdPageQuery, TournamentEventIdPageQueryVariables>) {
        return Apollo.useQuery<TournamentEventIdPageQuery, TournamentEventIdPageQueryVariables>(TournamentEventIdPageDocument, baseOptions);
      }
export function useTournamentEventIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentEventIdPageQuery, TournamentEventIdPageQueryVariables>) {
          return Apollo.useLazyQuery<TournamentEventIdPageQuery, TournamentEventIdPageQueryVariables>(TournamentEventIdPageDocument, baseOptions);
        }
export type TournamentEventIdPageQueryHookResult = ReturnType<typeof useTournamentEventIdPageQuery>;
export type TournamentEventIdPageLazyQueryHookResult = ReturnType<typeof useTournamentEventIdPageLazyQuery>;
export type TournamentEventIdPageQueryResult = Apollo.QueryResult<TournamentEventIdPageQuery, TournamentEventIdPageQueryVariables>;
export const TournamentTeamIdPageDocument = gql`
    query TournamentTeamIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  tournamentTeam(id: $id) {
    id
    team {
      id
      name
      club {
        id
        name
        manager {
          id
          nickname
          email
          phoneNumber
          icName
          icNumber
          discordId
          gameAccounts {
            id
            accountId
            inGameName
            serverId
          }
        }
      }
    }
    state
    captain {
      id
      email
      phoneNumber
      nickname
      icName
      icNumber
      discordId
      gameAccounts {
        id
        accountId
        inGameName
        serverId
      }
    }
    players {
      id
      nickname
      email
      phoneNumber
      icName
      icNumber
      discordId
      gameAccounts {
        id
        accountId
        inGameName
        serverId
      }
      team {
        id
        name
        club {
          id
          name
        }
      }
      teamRole {
        id
        proficientRole {
          id
          name
        }
      }
    }
    subPlayers {
      id
      nickname
      email
      phoneNumber
      icName
      icNumber
      discordId
      gameAccounts {
        id
        accountId
        inGameName
        serverId
      }
      team {
        id
        name
        club {
          id
          name
        }
      }
      teamRole {
        id
        proficientRole {
          id
          name
        }
      }
    }
    tournament {
      id
      state
      name
    }
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useTournamentTeamIdPageQuery__
 *
 * To run a query within a React component, call `useTournamentTeamIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentTeamIdPageQuery(baseOptions?: Apollo.QueryHookOptions<TournamentTeamIdPageQuery, TournamentTeamIdPageQueryVariables>) {
        return Apollo.useQuery<TournamentTeamIdPageQuery, TournamentTeamIdPageQueryVariables>(TournamentTeamIdPageDocument, baseOptions);
      }
export function useTournamentTeamIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentTeamIdPageQuery, TournamentTeamIdPageQueryVariables>) {
          return Apollo.useLazyQuery<TournamentTeamIdPageQuery, TournamentTeamIdPageQueryVariables>(TournamentTeamIdPageDocument, baseOptions);
        }
export type TournamentTeamIdPageQueryHookResult = ReturnType<typeof useTournamentTeamIdPageQuery>;
export type TournamentTeamIdPageLazyQueryHookResult = ReturnType<typeof useTournamentTeamIdPageLazyQuery>;
export type TournamentTeamIdPageQueryResult = Apollo.QueryResult<TournamentTeamIdPageQuery, TournamentTeamIdPageQueryVariables>;
export const TournamentTeamsIndexPageDocument = gql`
    query TournamentTeamsIndexPage($page: Int!, $searchString: String, $state: String) {
  tournamentTeamAccounts(page: $page, searchString: $searchString, state: $state) {
    count
    data {
      id
      createdAt
      updatedAt
      team {
        id
        name
        club {
          id
          name
        }
      }
      state
      captain {
        id
        email
        phoneNumber
        nickname
      }
      players {
        id
        nickname
        email
        phoneNumber
      }
      subPlayers {
        id
        nickname
        email
        phoneNumber
      }
      tournament {
        id
        state
        name
      }
    }
  }
}
    `;

/**
 * __useTournamentTeamsIndexPageQuery__
 *
 * To run a query within a React component, call `useTournamentTeamsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useTournamentTeamsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<TournamentTeamsIndexPageQuery, TournamentTeamsIndexPageQueryVariables>) {
        return Apollo.useQuery<TournamentTeamsIndexPageQuery, TournamentTeamsIndexPageQueryVariables>(TournamentTeamsIndexPageDocument, baseOptions);
      }
export function useTournamentTeamsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentTeamsIndexPageQuery, TournamentTeamsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<TournamentTeamsIndexPageQuery, TournamentTeamsIndexPageQueryVariables>(TournamentTeamsIndexPageDocument, baseOptions);
        }
export type TournamentTeamsIndexPageQueryHookResult = ReturnType<typeof useTournamentTeamsIndexPageQuery>;
export type TournamentTeamsIndexPageLazyQueryHookResult = ReturnType<typeof useTournamentTeamsIndexPageLazyQuery>;
export type TournamentTeamsIndexPageQueryResult = Apollo.QueryResult<TournamentTeamsIndexPageQuery, TournamentTeamsIndexPageQueryVariables>;
export const TournamentTeamsCsvDocument = gql`
    query TournamentTeamsCsv($tournamentId: ID!) {
  tournamentTeamsCsv(tournamentId: $tournamentId)
}
    `;

/**
 * __useTournamentTeamsCsvQuery__
 *
 * To run a query within a React component, call `useTournamentTeamsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamsCsvQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useTournamentTeamsCsvQuery(baseOptions?: Apollo.QueryHookOptions<TournamentTeamsCsvQuery, TournamentTeamsCsvQueryVariables>) {
        return Apollo.useQuery<TournamentTeamsCsvQuery, TournamentTeamsCsvQueryVariables>(TournamentTeamsCsvDocument, baseOptions);
      }
export function useTournamentTeamsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentTeamsCsvQuery, TournamentTeamsCsvQueryVariables>) {
          return Apollo.useLazyQuery<TournamentTeamsCsvQuery, TournamentTeamsCsvQueryVariables>(TournamentTeamsCsvDocument, baseOptions);
        }
export type TournamentTeamsCsvQueryHookResult = ReturnType<typeof useTournamentTeamsCsvQuery>;
export type TournamentTeamsCsvLazyQueryHookResult = ReturnType<typeof useTournamentTeamsCsvLazyQuery>;
export type TournamentTeamsCsvQueryResult = Apollo.QueryResult<TournamentTeamsCsvQuery, TournamentTeamsCsvQueryVariables>;
export const TournamentIdPageDocument = gql`
    query TournamentIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  tournament(id: $id) {
    id
    name
    state
    logo
    backgroundImage
    tournamentEvents {
      id
      name
      startDate
      endDate
    }
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useTournamentIdPageQuery__
 *
 * To run a query within a React component, call `useTournamentIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentIdPageQuery(baseOptions?: Apollo.QueryHookOptions<TournamentIdPageQuery, TournamentIdPageQueryVariables>) {
        return Apollo.useQuery<TournamentIdPageQuery, TournamentIdPageQueryVariables>(TournamentIdPageDocument, baseOptions);
      }
export function useTournamentIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentIdPageQuery, TournamentIdPageQueryVariables>) {
          return Apollo.useLazyQuery<TournamentIdPageQuery, TournamentIdPageQueryVariables>(TournamentIdPageDocument, baseOptions);
        }
export type TournamentIdPageQueryHookResult = ReturnType<typeof useTournamentIdPageQuery>;
export type TournamentIdPageLazyQueryHookResult = ReturnType<typeof useTournamentIdPageLazyQuery>;
export type TournamentIdPageQueryResult = Apollo.QueryResult<TournamentIdPageQuery, TournamentIdPageQueryVariables>;
export const TournamentsIndexPageDocument = gql`
    query TournamentsIndexPage($page: Int!, $searchString: String, $state: String, $gameId: String!) {
  tournamentAccounts(page: $page, searchString: $searchString, state: $state, gameId: $gameId) {
    count
    data {
      id
      name
      state
      game {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useTournamentsIndexPageQuery__
 *
 * To run a query within a React component, call `useTournamentsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentsIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      searchString: // value for 'searchString'
 *      state: // value for 'state'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useTournamentsIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<TournamentsIndexPageQuery, TournamentsIndexPageQueryVariables>) {
        return Apollo.useQuery<TournamentsIndexPageQuery, TournamentsIndexPageQueryVariables>(TournamentsIndexPageDocument, baseOptions);
      }
export function useTournamentsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentsIndexPageQuery, TournamentsIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<TournamentsIndexPageQuery, TournamentsIndexPageQueryVariables>(TournamentsIndexPageDocument, baseOptions);
        }
export type TournamentsIndexPageQueryHookResult = ReturnType<typeof useTournamentsIndexPageQuery>;
export type TournamentsIndexPageLazyQueryHookResult = ReturnType<typeof useTournamentsIndexPageLazyQuery>;
export type TournamentsIndexPageQueryResult = Apollo.QueryResult<TournamentsIndexPageQuery, TournamentsIndexPageQueryVariables>;
export const TournamentTournamentEventDocument = gql`
    query TournamentTournamentEvent($id: ID!) {
  tournament(id: $id) {
    tournamentEvents {
      id
      name
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useTournamentTournamentEventQuery__
 *
 * To run a query within a React component, call `useTournamentTournamentEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTournamentEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTournamentEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentTournamentEventQuery(baseOptions?: Apollo.QueryHookOptions<TournamentTournamentEventQuery, TournamentTournamentEventQueryVariables>) {
        return Apollo.useQuery<TournamentTournamentEventQuery, TournamentTournamentEventQueryVariables>(TournamentTournamentEventDocument, baseOptions);
      }
export function useTournamentTournamentEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentTournamentEventQuery, TournamentTournamentEventQueryVariables>) {
          return Apollo.useLazyQuery<TournamentTournamentEventQuery, TournamentTournamentEventQueryVariables>(TournamentTournamentEventDocument, baseOptions);
        }
export type TournamentTournamentEventQueryHookResult = ReturnType<typeof useTournamentTournamentEventQuery>;
export type TournamentTournamentEventLazyQueryHookResult = ReturnType<typeof useTournamentTournamentEventLazyQuery>;
export type TournamentTournamentEventQueryResult = Apollo.QueryResult<TournamentTournamentEventQuery, TournamentTournamentEventQueryVariables>;
export const UserPrizeIdPageDocument = gql`
    query UserPrizeIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  userPrize(id: $id) {
    ...UserPrize
    prize {
      ...Prize
    }
  }
}
    ${BasicAdminFragmentDoc}
${UserPrizeFragmentDoc}
${PrizeFragmentDoc}`;

/**
 * __useUserPrizeIdPageQuery__
 *
 * To run a query within a React component, call `useUserPrizeIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPrizeIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPrizeIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPrizeIdPageQuery(baseOptions?: Apollo.QueryHookOptions<UserPrizeIdPageQuery, UserPrizeIdPageQueryVariables>) {
        return Apollo.useQuery<UserPrizeIdPageQuery, UserPrizeIdPageQueryVariables>(UserPrizeIdPageDocument, baseOptions);
      }
export function useUserPrizeIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPrizeIdPageQuery, UserPrizeIdPageQueryVariables>) {
          return Apollo.useLazyQuery<UserPrizeIdPageQuery, UserPrizeIdPageQueryVariables>(UserPrizeIdPageDocument, baseOptions);
        }
export type UserPrizeIdPageQueryHookResult = ReturnType<typeof useUserPrizeIdPageQuery>;
export type UserPrizeIdPageLazyQueryHookResult = ReturnType<typeof useUserPrizeIdPageLazyQuery>;
export type UserPrizeIdPageQueryResult = Apollo.QueryResult<UserPrizeIdPageQuery, UserPrizeIdPageQueryVariables>;
export const AllUserPrizesPageDocument = gql`
    query AllUserPrizesPage($page: Int, $state: String) {
  userPrizes(page: $page, state: $state) {
    count
    data {
      ...UserPrize
    }
  }
}
    ${UserPrizeFragmentDoc}`;

/**
 * __useAllUserPrizesPageQuery__
 *
 * To run a query within a React component, call `useAllUserPrizesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserPrizesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserPrizesPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAllUserPrizesPageQuery(baseOptions?: Apollo.QueryHookOptions<AllUserPrizesPageQuery, AllUserPrizesPageQueryVariables>) {
        return Apollo.useQuery<AllUserPrizesPageQuery, AllUserPrizesPageQueryVariables>(AllUserPrizesPageDocument, baseOptions);
      }
export function useAllUserPrizesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUserPrizesPageQuery, AllUserPrizesPageQueryVariables>) {
          return Apollo.useLazyQuery<AllUserPrizesPageQuery, AllUserPrizesPageQueryVariables>(AllUserPrizesPageDocument, baseOptions);
        }
export type AllUserPrizesPageQueryHookResult = ReturnType<typeof useAllUserPrizesPageQuery>;
export type AllUserPrizesPageLazyQueryHookResult = ReturnType<typeof useAllUserPrizesPageLazyQuery>;
export type AllUserPrizesPageQueryResult = Apollo.QueryResult<AllUserPrizesPageQuery, AllUserPrizesPageQueryVariables>;
export const UserBlastScheduleIdPageDocument = gql`
    query UserBlastScheduleIdPage($id: ID!) {
  me {
    ...BasicAdmin
  }
  userBlastSchedule(id: $id) {
    id
    title
    body
    isScheduled
    scheduleDatetime
    state
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useUserBlastScheduleIdPageQuery__
 *
 * To run a query within a React component, call `useUserBlastScheduleIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBlastScheduleIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBlastScheduleIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserBlastScheduleIdPageQuery(baseOptions?: Apollo.QueryHookOptions<UserBlastScheduleIdPageQuery, UserBlastScheduleIdPageQueryVariables>) {
        return Apollo.useQuery<UserBlastScheduleIdPageQuery, UserBlastScheduleIdPageQueryVariables>(UserBlastScheduleIdPageDocument, baseOptions);
      }
export function useUserBlastScheduleIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBlastScheduleIdPageQuery, UserBlastScheduleIdPageQueryVariables>) {
          return Apollo.useLazyQuery<UserBlastScheduleIdPageQuery, UserBlastScheduleIdPageQueryVariables>(UserBlastScheduleIdPageDocument, baseOptions);
        }
export type UserBlastScheduleIdPageQueryHookResult = ReturnType<typeof useUserBlastScheduleIdPageQuery>;
export type UserBlastScheduleIdPageLazyQueryHookResult = ReturnType<typeof useUserBlastScheduleIdPageLazyQuery>;
export type UserBlastScheduleIdPageQueryResult = Apollo.QueryResult<UserBlastScheduleIdPageQuery, UserBlastScheduleIdPageQueryVariables>;
export const UserBlastSchedulesDocument = gql`
    query UserBlastSchedules($page: Int, $state: String) {
  userBlastScheduleAccounts(page: $page, state: $state) {
    count
    data {
      id
      title
      body
      isScheduled
      scheduleDatetime
      state
    }
  }
}
    `;

/**
 * __useUserBlastSchedulesQuery__
 *
 * To run a query within a React component, call `useUserBlastSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBlastSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBlastSchedulesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUserBlastSchedulesQuery(baseOptions?: Apollo.QueryHookOptions<UserBlastSchedulesQuery, UserBlastSchedulesQueryVariables>) {
        return Apollo.useQuery<UserBlastSchedulesQuery, UserBlastSchedulesQueryVariables>(UserBlastSchedulesDocument, baseOptions);
      }
export function useUserBlastSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBlastSchedulesQuery, UserBlastSchedulesQueryVariables>) {
          return Apollo.useLazyQuery<UserBlastSchedulesQuery, UserBlastSchedulesQueryVariables>(UserBlastSchedulesDocument, baseOptions);
        }
export type UserBlastSchedulesQueryHookResult = ReturnType<typeof useUserBlastSchedulesQuery>;
export type UserBlastSchedulesLazyQueryHookResult = ReturnType<typeof useUserBlastSchedulesLazyQuery>;
export type UserBlastSchedulesQueryResult = Apollo.QueryResult<UserBlastSchedulesQuery, UserBlastSchedulesQueryVariables>;
export const UsersIdPageDocument = gql`
    query usersIdPage($id: ID!) {
  user(id: $id) {
    avatarUrl
    proPhotoUrl
    coverPictureUrl
    createdAt
    creditWallet {
      amount
      currency
      creditTransactions {
        ...CreditTransaction
      }
      id
    }
    email
    adminNote
    gameBundles {
      id
      amount
      completionScreenshotUrl
      completionTime
      createdAt
      currentGameRank {
        id
        name
      }
      currentRankPoint
      currentRankStar
      currentRankTier
      game {
        id
        name
      }
      pilot {
        id
        nickname
      }
      rating
      ratingRemark
      remark
      reportReason
      reporter {
        id
        nickname
      }
      startTime
      state
      targetGameRank {
        id
        name
      }
      targetRankPoint
      targetRankStar
      targetRankTier
    }
    gender
    id
    language
    lastSignInAt
    nickname
    partneringRequests {
      amount
      completeTime
      createdAt
      game {
        id
        name
      }
      gameRank {
        id
        name
      }
      id
      originalAmount
      pilot {
        id
        nickname
      }
      promoApplied
      rankPoint
      rankStar
      rankTier
      rating
      ratingRemark
      reportReason
      reporter {
        id
        nickname
      }
      startTime
      state
      totalAmount
      totalDurationInMinutes
      totalOriginalAmount
    }
    phoneNumber
    referralCode
    signInCount
    updatedAt
    suspend
    suspendGamerRequests {
      id
      reason
      state
      createdAt
    }
    gameAccounts {
      id
      accountId
      verificationCode
      codeState
      screenshotState
      testState
      screenshotUrl
      createdAt
      approve
      updatedAt
      testScore
      remindAt
      remindCount
      rejectReason
      gameRank {
        id
        name
        rankStarMaxCount
        rankPointMaxCount
      }
      rankTier
      rankStar
      rankPoint
      game {
        id
        name
        developerName
        testPassingScore
      }
      pairableGameRanks {
        id
        name
      }
      proficientRoles {
        id
        name
      }
    }
    team {
      id
      country
      state
      club {
        id
        name
        icon
        tier
      }
      game {
        id
        name
      }
    }
    beStar
    bePro
    beKol
    verified
    proPhotoUrl
    themeColors
    fullName
    officialName
    nationality
    career
    overview
    introText
    careerHighlights {
      id
      title
      startYear
      endYear
      position
    }
  }
}
    ${CreditTransactionFragmentDoc}`;

/**
 * __useUsersIdPageQuery__
 *
 * To run a query within a React component, call `useUsersIdPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersIdPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersIdPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUsersIdPageQuery(baseOptions?: Apollo.QueryHookOptions<UsersIdPageQuery, UsersIdPageQueryVariables>) {
        return Apollo.useQuery<UsersIdPageQuery, UsersIdPageQueryVariables>(UsersIdPageDocument, baseOptions);
      }
export function useUsersIdPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersIdPageQuery, UsersIdPageQueryVariables>) {
          return Apollo.useLazyQuery<UsersIdPageQuery, UsersIdPageQueryVariables>(UsersIdPageDocument, baseOptions);
        }
export type UsersIdPageQueryHookResult = ReturnType<typeof useUsersIdPageQuery>;
export type UsersIdPageLazyQueryHookResult = ReturnType<typeof useUsersIdPageLazyQuery>;
export type UsersIdPageQueryResult = Apollo.QueryResult<UsersIdPageQuery, UsersIdPageQueryVariables>;
export const UsersIndexPageDocument = gql`
    query usersIndexPage($page: Int, $suspended: Boolean, $searchString: String) {
  users(page: $page, searchString: $searchString, suspended: $suspended) {
    count
    data {
      id
      nickname
      phoneNumber
      email
      creditWalletAmount
      suspend
      adminNote
    }
  }
}
    `;

/**
 * __useUsersIndexPageQuery__
 *
 * To run a query within a React component, call `useUsersIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersIndexPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      suspended: // value for 'suspended'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useUsersIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<UsersIndexPageQuery, UsersIndexPageQueryVariables>) {
        return Apollo.useQuery<UsersIndexPageQuery, UsersIndexPageQueryVariables>(UsersIndexPageDocument, baseOptions);
      }
export function useUsersIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersIndexPageQuery, UsersIndexPageQueryVariables>) {
          return Apollo.useLazyQuery<UsersIndexPageQuery, UsersIndexPageQueryVariables>(UsersIndexPageDocument, baseOptions);
        }
export type UsersIndexPageQueryHookResult = ReturnType<typeof useUsersIndexPageQuery>;
export type UsersIndexPageLazyQueryHookResult = ReturnType<typeof useUsersIndexPageLazyQuery>;
export type UsersIndexPageQueryResult = Apollo.QueryResult<UsersIndexPageQuery, UsersIndexPageQueryVariables>;
export const UsersCsvDocument = gql`
    query usersCsv {
  usersCsv
}
    `;

/**
 * __useUsersCsvQuery__
 *
 * To run a query within a React component, call `useUsersCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersCsvQuery(baseOptions?: Apollo.QueryHookOptions<UsersCsvQuery, UsersCsvQueryVariables>) {
        return Apollo.useQuery<UsersCsvQuery, UsersCsvQueryVariables>(UsersCsvDocument, baseOptions);
      }
export function useUsersCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersCsvQuery, UsersCsvQueryVariables>) {
          return Apollo.useLazyQuery<UsersCsvQuery, UsersCsvQueryVariables>(UsersCsvDocument, baseOptions);
        }
export type UsersCsvQueryHookResult = ReturnType<typeof useUsersCsvQuery>;
export type UsersCsvLazyQueryHookResult = ReturnType<typeof useUsersCsvLazyQuery>;
export type UsersCsvQueryResult = Apollo.QueryResult<UsersCsvQuery, UsersCsvQueryVariables>;
export const TransactionsDocument = gql`
    query transactions($id: ID!, $page: Int!) {
  user(id: $id) {
    nickname
    createdAt
    creditWallet {
      amount
      transactionHistory {
        ...CreditTransaction
      }
      id
    }
    userCreditTransaction(page: $page) {
      count
      data {
        ...CreditTransaction
      }
    }
  }
}
    ${CreditTransactionFragmentDoc}`;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
        return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, baseOptions);
      }
export function useTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
          return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, baseOptions);
        }
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<TransactionsQuery, TransactionsQueryVariables>;
export const CheckLocationDocument = gql`
    query CheckLocation($ipAddress: String!) {
  checkLocation(ipAddress: $ipAddress) {
    valid
  }
}
    `;

/**
 * __useCheckLocationQuery__
 *
 * To run a query within a React component, call `useCheckLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckLocationQuery({
 *   variables: {
 *      ipAddress: // value for 'ipAddress'
 *   },
 * });
 */
export function useCheckLocationQuery(baseOptions?: Apollo.QueryHookOptions<CheckLocationQuery, CheckLocationQueryVariables>) {
        return Apollo.useQuery<CheckLocationQuery, CheckLocationQueryVariables>(CheckLocationDocument, baseOptions);
      }
export function useCheckLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckLocationQuery, CheckLocationQueryVariables>) {
          return Apollo.useLazyQuery<CheckLocationQuery, CheckLocationQueryVariables>(CheckLocationDocument, baseOptions);
        }
export type CheckLocationQueryHookResult = ReturnType<typeof useCheckLocationQuery>;
export type CheckLocationLazyQueryHookResult = ReturnType<typeof useCheckLocationLazyQuery>;
export type CheckLocationQueryResult = Apollo.QueryResult<CheckLocationQuery, CheckLocationQueryVariables>;
export const GameChatroomsDocument = gql`
    query GameChatrooms {
  gameChatrooms {
    ...GameChatroom
  }
}
    ${GameChatroomFragmentDoc}`;

/**
 * __useGameChatroomsQuery__
 *
 * To run a query within a React component, call `useGameChatroomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameChatroomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameChatroomsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGameChatroomsQuery(baseOptions?: Apollo.QueryHookOptions<GameChatroomsQuery, GameChatroomsQueryVariables>) {
        return Apollo.useQuery<GameChatroomsQuery, GameChatroomsQueryVariables>(GameChatroomsDocument, baseOptions);
      }
export function useGameChatroomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameChatroomsQuery, GameChatroomsQueryVariables>) {
          return Apollo.useLazyQuery<GameChatroomsQuery, GameChatroomsQueryVariables>(GameChatroomsDocument, baseOptions);
        }
export type GameChatroomsQueryHookResult = ReturnType<typeof useGameChatroomsQuery>;
export type GameChatroomsLazyQueryHookResult = ReturnType<typeof useGameChatroomsLazyQuery>;
export type GameChatroomsQueryResult = Apollo.QueryResult<GameChatroomsQuery, GameChatroomsQueryVariables>;
export const GamesDocument = gql`
    query Games {
  games {
    ...Game
  }
}
    ${GameFragmentDoc}`;

/**
 * __useGamesQuery__
 *
 * To run a query within a React component, call `useGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGamesQuery(baseOptions?: Apollo.QueryHookOptions<GamesQuery, GamesQueryVariables>) {
        return Apollo.useQuery<GamesQuery, GamesQueryVariables>(GamesDocument, baseOptions);
      }
export function useGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamesQuery, GamesQueryVariables>) {
          return Apollo.useLazyQuery<GamesQuery, GamesQueryVariables>(GamesDocument, baseOptions);
        }
export type GamesQueryHookResult = ReturnType<typeof useGamesQuery>;
export type GamesLazyQueryHookResult = ReturnType<typeof useGamesLazyQuery>;
export type GamesQueryResult = Apollo.QueryResult<GamesQuery, GamesQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...BasicAdmin
  }
}
    ${BasicAdminFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RejectReasonsDocument = gql`
    query RejectReasons($kind: RejectReasonKind!) {
  rejectReasons(kind: $kind) {
    ...RejectReason
  }
}
    ${RejectReasonFragmentDoc}`;

/**
 * __useRejectReasonsQuery__
 *
 * To run a query within a React component, call `useRejectReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRejectReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRejectReasonsQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useRejectReasonsQuery(baseOptions?: Apollo.QueryHookOptions<RejectReasonsQuery, RejectReasonsQueryVariables>) {
        return Apollo.useQuery<RejectReasonsQuery, RejectReasonsQueryVariables>(RejectReasonsDocument, baseOptions);
      }
export function useRejectReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RejectReasonsQuery, RejectReasonsQueryVariables>) {
          return Apollo.useLazyQuery<RejectReasonsQuery, RejectReasonsQueryVariables>(RejectReasonsDocument, baseOptions);
        }
export type RejectReasonsQueryHookResult = ReturnType<typeof useRejectReasonsQuery>;
export type RejectReasonsLazyQueryHookResult = ReturnType<typeof useRejectReasonsLazyQuery>;
export type RejectReasonsQueryResult = Apollo.QueryResult<RejectReasonsQuery, RejectReasonsQueryVariables>;
export const SearchGameRanksDocument = gql`
    query SearchGameRanks($gameId: String!) {
  searchGameRanks(gameId: $gameId) {
    id
    name
    rankTierMaxCount
    rankStarMaxCount
    rankPointMaxCount
    ordering
    game {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchGameRanksQuery__
 *
 * To run a query within a React component, call `useSearchGameRanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGameRanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGameRanksQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useSearchGameRanksQuery(baseOptions?: Apollo.QueryHookOptions<SearchGameRanksQuery, SearchGameRanksQueryVariables>) {
        return Apollo.useQuery<SearchGameRanksQuery, SearchGameRanksQueryVariables>(SearchGameRanksDocument, baseOptions);
      }
export function useSearchGameRanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchGameRanksQuery, SearchGameRanksQueryVariables>) {
          return Apollo.useLazyQuery<SearchGameRanksQuery, SearchGameRanksQueryVariables>(SearchGameRanksDocument, baseOptions);
        }
export type SearchGameRanksQueryHookResult = ReturnType<typeof useSearchGameRanksQuery>;
export type SearchGameRanksLazyQueryHookResult = ReturnType<typeof useSearchGameRanksLazyQuery>;
export type SearchGameRanksQueryResult = Apollo.QueryResult<SearchGameRanksQuery, SearchGameRanksQueryVariables>;
export const SearchPilotsDocument = gql`
    query SearchPilots($searchString: String!, $gameId: String!) {
  searchPilots(searchString: $searchString, gameId: $gameId) {
    id
    accountId
    pilot {
      id
      email
      phoneNumber
      nickname
    }
    game {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchPilotsQuery__
 *
 * To run a query within a React component, call `useSearchPilotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPilotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPilotsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useSearchPilotsQuery(baseOptions?: Apollo.QueryHookOptions<SearchPilotsQuery, SearchPilotsQueryVariables>) {
        return Apollo.useQuery<SearchPilotsQuery, SearchPilotsQueryVariables>(SearchPilotsDocument, baseOptions);
      }
export function useSearchPilotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPilotsQuery, SearchPilotsQueryVariables>) {
          return Apollo.useLazyQuery<SearchPilotsQuery, SearchPilotsQueryVariables>(SearchPilotsDocument, baseOptions);
        }
export type SearchPilotsQueryHookResult = ReturnType<typeof useSearchPilotsQuery>;
export type SearchPilotsLazyQueryHookResult = ReturnType<typeof useSearchPilotsLazyQuery>;
export type SearchPilotsQueryResult = Apollo.QueryResult<SearchPilotsQuery, SearchPilotsQueryVariables>;
export const SearchPoolPrizesDocument = gql`
    query SearchPoolPrizes {
  searchPoolPrizes {
    ...PoolPrize
  }
}
    ${PoolPrizeFragmentDoc}`;

/**
 * __useSearchPoolPrizesQuery__
 *
 * To run a query within a React component, call `useSearchPoolPrizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPoolPrizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPoolPrizesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchPoolPrizesQuery(baseOptions?: Apollo.QueryHookOptions<SearchPoolPrizesQuery, SearchPoolPrizesQueryVariables>) {
        return Apollo.useQuery<SearchPoolPrizesQuery, SearchPoolPrizesQueryVariables>(SearchPoolPrizesDocument, baseOptions);
      }
export function useSearchPoolPrizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPoolPrizesQuery, SearchPoolPrizesQueryVariables>) {
          return Apollo.useLazyQuery<SearchPoolPrizesQuery, SearchPoolPrizesQueryVariables>(SearchPoolPrizesDocument, baseOptions);
        }
export type SearchPoolPrizesQueryHookResult = ReturnType<typeof useSearchPoolPrizesQuery>;
export type SearchPoolPrizesLazyQueryHookResult = ReturnType<typeof useSearchPoolPrizesLazyQuery>;
export type SearchPoolPrizesQueryResult = Apollo.QueryResult<SearchPoolPrizesQuery, SearchPoolPrizesQueryVariables>;
export const TournamentsDocument = gql`
    query Tournaments {
  tournaments {
    id
    name
    state
  }
}
    `;

/**
 * __useTournamentsQuery__
 *
 * To run a query within a React component, call `useTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTournamentsQuery(baseOptions?: Apollo.QueryHookOptions<TournamentsQuery, TournamentsQueryVariables>) {
        return Apollo.useQuery<TournamentsQuery, TournamentsQueryVariables>(TournamentsDocument, baseOptions);
      }
export function useTournamentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TournamentsQuery, TournamentsQueryVariables>) {
          return Apollo.useLazyQuery<TournamentsQuery, TournamentsQueryVariables>(TournamentsDocument, baseOptions);
        }
export type TournamentsQueryHookResult = ReturnType<typeof useTournamentsQuery>;
export type TournamentsLazyQueryHookResult = ReturnType<typeof useTournamentsLazyQuery>;
export type TournamentsQueryResult = Apollo.QueryResult<TournamentsQuery, TournamentsQueryVariables>;
export const UserPromoCodesDocument = gql`
    query UserPromoCodes($page: Int) {
  userPromoCodes(page: $page) {
    count
    data {
      id
      promoCode {
        id
      }
      user {
        id
        nickname
        email
        phoneNumber
      }
    }
  }
}
    `;

/**
 * __useUserPromoCodesQuery__
 *
 * To run a query within a React component, call `useUserPromoCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPromoCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPromoCodesQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserPromoCodesQuery(baseOptions?: Apollo.QueryHookOptions<UserPromoCodesQuery, UserPromoCodesQueryVariables>) {
        return Apollo.useQuery<UserPromoCodesQuery, UserPromoCodesQueryVariables>(UserPromoCodesDocument, baseOptions);
      }
export function useUserPromoCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPromoCodesQuery, UserPromoCodesQueryVariables>) {
          return Apollo.useLazyQuery<UserPromoCodesQuery, UserPromoCodesQueryVariables>(UserPromoCodesDocument, baseOptions);
        }
export type UserPromoCodesQueryHookResult = ReturnType<typeof useUserPromoCodesQuery>;
export type UserPromoCodesLazyQueryHookResult = ReturnType<typeof useUserPromoCodesLazyQuery>;
export type UserPromoCodesQueryResult = Apollo.QueryResult<UserPromoCodesQuery, UserPromoCodesQueryVariables>;
export const ListOfProductsDocument = gql`
    query ListOfProducts($searchString: String, $vendorName: String) {
  listOfProducts(searchString: $searchString, vendorName: $vendorName) {
    id
    title
    code
    typeOrder
    category {
      id
      name
    }
    vendor
  }
}
    `;

/**
 * __useListOfProductsQuery__
 *
 * To run a query within a React component, call `useListOfProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfProductsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      vendorName: // value for 'vendorName'
 *   },
 * });
 */
export function useListOfProductsQuery(baseOptions?: Apollo.QueryHookOptions<ListOfProductsQuery, ListOfProductsQueryVariables>) {
        return Apollo.useQuery<ListOfProductsQuery, ListOfProductsQueryVariables>(ListOfProductsDocument, baseOptions);
      }
export function useListOfProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfProductsQuery, ListOfProductsQueryVariables>) {
          return Apollo.useLazyQuery<ListOfProductsQuery, ListOfProductsQueryVariables>(ListOfProductsDocument, baseOptions);
        }
export type ListOfProductsQueryHookResult = ReturnType<typeof useListOfProductsQuery>;
export type ListOfProductsLazyQueryHookResult = ReturnType<typeof useListOfProductsLazyQuery>;
export type ListOfProductsQueryResult = Apollo.QueryResult<ListOfProductsQuery, ListOfProductsQueryVariables>;
export const VendorsDocument = gql`
    query Vendors {
  vendors {
    id
    name
    description
  }
}
    `;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVendorsQuery(baseOptions?: Apollo.QueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
        return Apollo.useQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, baseOptions);
      }
export function useVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
          return Apollo.useLazyQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, baseOptions);
        }
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = Apollo.QueryResult<VendorsQuery, VendorsQueryVariables>;
export const PublishersDocument = gql`
    query Publishers {
  publishers {
    id
    name
    description
  }
}
    `;

/**
 * __usePublishersQuery__
 *
 * To run a query within a React component, call `usePublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublishersQuery(baseOptions?: Apollo.QueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
        return Apollo.useQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, baseOptions);
      }
export function usePublishersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
          return Apollo.useLazyQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, baseOptions);
        }
export type PublishersQueryHookResult = ReturnType<typeof usePublishersQuery>;
export type PublishersLazyQueryHookResult = ReturnType<typeof usePublishersLazyQuery>;
export type PublishersQueryResult = Apollo.QueryResult<PublishersQuery, PublishersQueryVariables>;
export const CategoriesDocument = gql`
    query categories {
  categories {
    id
    name
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const TopUpProductItemsDocument = gql`
    query TopUpProductItems($searchString: String, $productId: ID, $vendorName: String) {
  topUpProductItems(searchString: $searchString, productId: $productId, vendorName: $vendorName) {
    id
    name
    price
  }
}
    `;

/**
 * __useTopUpProductItemsQuery__
 *
 * To run a query within a React component, call `useTopUpProductItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopUpProductItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopUpProductItemsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      productId: // value for 'productId'
 *      vendorName: // value for 'vendorName'
 *   },
 * });
 */
export function useTopUpProductItemsQuery(baseOptions?: Apollo.QueryHookOptions<TopUpProductItemsQuery, TopUpProductItemsQueryVariables>) {
        return Apollo.useQuery<TopUpProductItemsQuery, TopUpProductItemsQueryVariables>(TopUpProductItemsDocument, baseOptions);
      }
export function useTopUpProductItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopUpProductItemsQuery, TopUpProductItemsQueryVariables>) {
          return Apollo.useLazyQuery<TopUpProductItemsQuery, TopUpProductItemsQueryVariables>(TopUpProductItemsDocument, baseOptions);
        }
export type TopUpProductItemsQueryHookResult = ReturnType<typeof useTopUpProductItemsQuery>;
export type TopUpProductItemsLazyQueryHookResult = ReturnType<typeof useTopUpProductItemsLazyQuery>;
export type TopUpProductItemsQueryResult = Apollo.QueryResult<TopUpProductItemsQuery, TopUpProductItemsQueryVariables>;
export const TopUpProductItemDocument = gql`
    query TopUpProductItem($id: ID!, $vendorName: String!, $productId: ID!) {
  topUpProductItem(id: $id, vendorName: $vendorName, productId: $productId) {
    id
    name
    price
  }
}
    `;

/**
 * __useTopUpProductItemQuery__
 *
 * To run a query within a React component, call `useTopUpProductItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopUpProductItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopUpProductItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      vendorName: // value for 'vendorName'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useTopUpProductItemQuery(baseOptions?: Apollo.QueryHookOptions<TopUpProductItemQuery, TopUpProductItemQueryVariables>) {
        return Apollo.useQuery<TopUpProductItemQuery, TopUpProductItemQueryVariables>(TopUpProductItemDocument, baseOptions);
      }
export function useTopUpProductItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopUpProductItemQuery, TopUpProductItemQueryVariables>) {
          return Apollo.useLazyQuery<TopUpProductItemQuery, TopUpProductItemQueryVariables>(TopUpProductItemDocument, baseOptions);
        }
export type TopUpProductItemQueryHookResult = ReturnType<typeof useTopUpProductItemQuery>;
export type TopUpProductItemLazyQueryHookResult = ReturnType<typeof useTopUpProductItemLazyQuery>;
export type TopUpProductItemQueryResult = Apollo.QueryResult<TopUpProductItemQuery, TopUpProductItemQueryVariables>;